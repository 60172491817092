import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';

import {
    contactSuggestionStatusChanged,
    contactSuggestionReset,
} from './contact-suggestion.actions';

export enum CreateContactSuggestionResult {
    SUCCESS,
    ERROR,
}

export interface IContactSuggestionStore {
    status: CreateContactSuggestionResult | null;
}

const initialState: IContactSuggestionStore = {
    status: null,
};

export const contactSuggestionReducer = createReducer<
    IContactSuggestionStore,
    RootActions
>(initialState)
    .handleAction(contactSuggestionStatusChanged, (state, action) => ({
        ...state,
        status: action.payload,
    }))
    .handleAction(contactSuggestionReset, (state) => ({
        ...state,
        status: null,
    }));
