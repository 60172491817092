import React from 'react';
import {
    ClickToPlay,
    ControlGroup,
    Controls,
    ControlSpacer,
    LoadingScreen,
    PlaybackControl,
    Scrim,
    ScrubberControl,
    TimeProgress,
    Ui,
    MuteControl,
    VolumeControl,
    Spinner,
    FullscreenControl,
} from '@vime/react';

import { useVideoPlayerControls } from './use-video-player-controls';
import { VIDEO_PLAYER_CONTROLS_VISIBILITY_DURATION_MS } from './video-player-controls.constants';
import { IVideoPlayerControlsProps } from './video-player-controls.types';

export const VideoPlayerControls = (props: IVideoPlayerControlsProps) => {
    const {
        controlsRef,
        hideWhenPaused,
        hideOnMouseLeave,
        waitForPlaybackStart,
        showFullscreenButton,
        isEnoughSpaceForTimeProgress,
        isEnoughSpaceForVolumeControl,
        showSpinner,
    } = useVideoPlayerControls(props);

    return (
        <Ui>
            <LoadingScreen />
            {showSpinner && <Spinner />}
            <ClickToPlay useOnMobile />
            <Scrim />
            <Controls
                ref={controlsRef}
                hideWhenPaused={hideWhenPaused}
                hideOnMouseLeave={hideOnMouseLeave}
                waitForPlaybackStart={waitForPlaybackStart}
                activeDuration={VIDEO_PLAYER_CONTROLS_VISIBILITY_DURATION_MS}
            >
                <ControlGroup>
                    <ScrubberControl />
                </ControlGroup>
                <ControlGroup space="top">
                    <PlaybackControl hideTooltip />
                    {isEnoughSpaceForVolumeControl ? (
                        <VolumeControl hideTooltip />
                    ) : (
                        <MuteControl hideTooltip />
                    )}
                    {isEnoughSpaceForTimeProgress && <TimeProgress />}
                    <ControlSpacer />
                    {showFullscreenButton && <FullscreenControl hideTooltip />}
                </ControlGroup>
            </Controls>
        </Ui>
    );
};
