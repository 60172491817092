import React, { useEffect } from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { TextField } from '../../components/text-field/text-field';
import { TextArea } from '../../components/text-area/text-area';
import { Checkbox } from '../../components/checkbox/checkbox';
import { ICondolenceFormValues } from '../condolence-form-values';
import {
    PrimaryButton,
    PrimaryButtonProps,
} from '../../components/primary-button/primary-button';
import { ErrorMessage } from '../../components/error-message/error-message';
import { ICreatedCondolence } from '../../interfaces/condolence';
import { ReactComponent as PublishIcon } from '../../assets/icons/publish-icon.svg';
import { getHeartsEnabled } from '../../model/configuration/selectors/get-hearts-enabled.selector';
import { CancelButton } from '../../components/cancel-button/cancel-button';
import { ImageUploadWithModal } from '../../components/inputs/image-upload-with-modal/image-upload-with-modal';
import { IImageUploadWithModalItem } from '../../components/inputs/image-upload-with-modal/image-upload-with-modal-types';
import { getCondolencesImagesEnabledSelector } from '../../model/configuration/selectors/get-condolences-images-enabled.selector';
import { UploadInputPreview } from '../../components/upload-input-preview';
import { getCondolenceLoading } from '../../model/condolences/selectors/get-condolence-loading.selector';
import { Spinner } from '../../components/spinner/spinner';
import { useBooleanState } from '../../../shared/hooks/useBooleanState';

import { condolenceFormValidation } from './condolence-form-validation';
import styles from './condolence-form.module.scss';
import { convertMediaFilesToImageUploadItems } from './condolence-form-helpers';

const MAX_CONDOLENCE_LENGTH = 3000;

type OuterProps = {
    initialValues?: Partial<ICreatedCondolence> | null;
    edit?: boolean;
    editedCondolence?: ICreatedCondolence | null;
    onCreate?: (values: ICondolenceFormValues) => void;
    onUpdate?: (condolence: ICreatedCondolence) => void;
    customButtonText?: string | null;
    customButtonIcon?: PrimaryButtonProps['icon'] | null;
    candle?: boolean;
    cancelForm?: () => void;
    openImageUploaderOnRender?: boolean;
    canSaveWithoutEdits?: boolean;
    customLoading?: boolean;
    customError?: string | null;
};

type Props = FormikProps<ICondolenceFormValues> & OuterProps;

export const CondolenceForm = ({
    errors,
    dirty,
    isValid,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    cancelForm,
    customButtonText,
    customButtonIcon,
    setFieldValue,
    openImageUploaderOnRender,
    onUpdate,
    onCreate,
    customLoading = false,
    customError = null,
    canSaveWithoutEdits = false,
}: Props) => {
    const { t } = useTranslation();
    const heartsEnabled = useSelector(getHeartsEnabled);
    const imagesEnabled = useSelector(getCondolencesImagesEnabledSelector);
    const condolenceLoading = useSelector(getCondolenceLoading);
    const isSubmitting = useBooleanState(false);

    const loading = condolenceLoading || customLoading || isSubmitting.state;
    const canSave = (dirty || canSaveWithoutEdits) && isValid && !loading;

    const handleImageUploadOnChange = (images: IImageUploadWithModalItem[]) => {
        setFieldValue('images', images);
    };

    const handleRemoveImage = (item: IImageUploadWithModalItem) => {
        let newList = [...(values?.images ?? [])];

        if (item.isNew) {
            newList = newList.filter((listItem) => {
                return listItem.id !== item.id;
            });
        } else {
            newList = newList.map((listItem) => {
                const mappedItem = {
                    ...listItem,
                };

                if (listItem.id === item.id) {
                    mappedItem.isDeleted = true;
                }

                return mappedItem;
            });
        }

        handleImageUploadOnChange(newList);
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if (canSave) {
            isSubmitting.handleSetAsTrue();
            handleSubmit();
        }
    };

    useEffect(() => {
        if (customError) {
            isSubmitting.handleSetAsFalse();
        }
    }, [isSubmitting, customError]);

    const showImagesPreview = !!values.images?.length;

    const submitButtonIcon = loading
        ? Spinner
        : customButtonIcon ?? PublishIcon;
    const submitButtonText = customButtonText || t('condolences.publishButton');

    return (
        <form className={styles.Container}>
            <div className={styles.InnerContainer}>
                <div className={styles.InputsContainer}>
                    <div className={styles.AuthorInputAndCheckboxContainer}>
                        <div className={styles.AuthorInputContainer}>
                            <TextField
                                autoFocus
                                name="displayName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={t(
                                    'condolenceForm.authorFieldPlaceholder',
                                )}
                                value={values.displayName}
                            />
                            <ErrorMessage className={styles.ErrorMessage}>
                                {errors.displayName ?? ''}
                            </ErrorMessage>
                        </div>
                        <div className={styles.CheckboxContainer}>
                            <Checkbox
                                checked={values.candle}
                                className={styles.Checkbox}
                                label={t('condolenceForm.candleCheckboxLabel')}
                                name="candle"
                                onChange={handleChange}
                            />
                            {heartsEnabled && (
                                <Checkbox
                                    checked={values.heart}
                                    className={styles.Checkbox}
                                    label={t(
                                        'condolenceForm.heartCheckboxLabel',
                                    )}
                                    name="heart"
                                    onChange={handleChange}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.CondolenceTextAreaContainer}>
                        <TextArea
                            className={styles.CondolenceTextArea}
                            name="content"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t(
                                'condolenceForm.contentFieldPlaceholder',
                            )}
                            rows={10}
                            value={values.content}
                            maxLength={MAX_CONDOLENCE_LENGTH + 1}
                        />
                        <ErrorMessage className={styles.ErrorMessage}>
                            {errors.content ?? ''}
                        </ErrorMessage>
                    </div>
                </div>
                {imagesEnabled && (
                    <div className={styles.UploadContainer}>
                        <p className={styles.Label}>
                            {t('labels.imageFileInput')}
                        </p>
                        <ImageUploadWithModal
                            onChange={handleImageUploadOnChange}
                            images={values.images ?? []}
                            className={styles.Upload}
                            openOnRender={openImageUploaderOnRender}
                        />
                    </div>
                )}
            </div>
            {imagesEnabled && showImagesPreview && (
                <div className={styles.imagesPreviewContainer}>
                    <UploadInputPreview
                        files={values.images ?? []}
                        onItemRemove={handleRemoveImage}
                    />
                </div>
            )}
            {!!customError && (
                <ErrorMessage
                    className={cx(
                        styles.ErrorMessage,
                        styles.CustomErrorMessage,
                    )}
                >
                    {customError}
                </ErrorMessage>
            )}
            {(onUpdate || onCreate) && (
                <div className={styles.ButtonContainer}>
                    {cancelForm && (
                        <CancelButton
                            className={styles.CancelButton}
                            onClick={cancelForm}
                        />
                    )}
                    <PrimaryButton
                        disabled={!canSave}
                        className={styles.PublishButton}
                        icon={submitButtonIcon}
                        onClick={handleSubmitClick}
                    >
                        {submitButtonText}
                    </PrimaryButton>
                </div>
            )}
        </form>
    );
};

export const ConnectedCondolenceForm = compose<Props, OuterProps>(
    withFormik<OuterProps, ICondolenceFormValues>({
        handleSubmit(values, { props }) {
            if (props.edit && props.onUpdate) {
                props.onUpdate({
                    ...props.editedCondolence!,
                    ...values,
                });
            }

            if (!props.edit && props.onCreate) {
                props.onCreate(values);
            }
        },
        mapPropsToValues({ initialValues = {}, candle }) {
            const defaultValues = {
                displayName: '',
                candle: !!candle,
                heart: false,
                content: '',
                images: [],
            };

            return {
                displayName:
                    initialValues?.displayName ?? defaultValues.displayName,
                candle: initialValues?.candle ?? defaultValues.candle,
                heart: initialValues?.heart ?? defaultValues.heart,
                content: initialValues?.content ?? defaultValues.content,
                images: initialValues?.mediaFiles?.length
                    ? convertMediaFilesToImageUploadItems(
                          initialValues?.mediaFiles,
                      )
                    : defaultValues.images,
            };
        },
        validationSchema: condolenceFormValidation,
        enableReinitialize: true,
    }),
)(CondolenceForm);
