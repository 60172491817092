import { createSelector } from 'reselect';

import { EventType } from '../../../interfaces/event-type';
import { getLanguage } from '../../configuration/selectors/get-language.selector';

import { getEventsStore } from './get-events-store.selector';

export const getIsCeremonyEvent = createSelector(
    getEventsStore,
    getLanguage,
    (store, lang) => {
        return !!store.events.find((item) => item.type === EventType.CEREMONY);
    },
);
