import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { sendDonationApiActions } from '../donation-api.actions';

import { sendDonationApiSaga } from './send-donation-api.saga';

export function* donationApiSaga() {
    yield takeEvery(
        getType(sendDonationApiActions.request),
        sendDonationApiSaga,
    );
}
