import React, { HTMLAttributes } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { NavigationLinks } from '../../routing/navigation-links';
import { ComposedLanguageSelector } from '../language-selector/language-selector';
import { RoutePaths } from '../../routing/route-paths';
import { INavRoute } from '../../interfaces/nav-route';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import { THEME, THEME_DETAILS } from '../../utils/get-theme';
import { CompanyLogo } from '../company-logo/company-logo';
import { Search } from '../search/search';
import { getPublicUrl } from '../../utils/url-resolvers';
import { getGalleryItems } from '../../model/configuration/selectors/get-gallery-items.selector';

import { NavbarLink } from './navbar-link/navbar-link';
import styles from './navbar.module.scss';

type Props = RouteComponentProps & OuterProps;

type OuterProps = HTMLAttributes<HTMLDivElement> & {
    links?: INavRoute[];
};

export const Navbar = ({
    location,
    className,
    links = NavigationLinks,
}: Props) => {
    const theme = useSelector(getTheme);
    const galleryItems = useSelector(getGalleryItems);

    const isLinkMatched = (href: string) =>
        href === RoutePaths.HOME
            ? location.pathname === href
            : location.pathname.includes(href);

    return (
        <nav className={cx(styles.Navbar, className)}>
            {theme === THEME.VG && (
                <div className={cx(styles.NavbarLogoContainer, styles[theme])}>
                    <div className={cx(styles.ContentContainer, styles[theme])}>
                        <div className={styles.NavbarLogo}>
                            <CompanyLogo
                                url={`${getPublicUrl(
                                    window.location.toString(),
                                )}/${
                                    THEME_DETAILS[
                                        theme as keyof typeof THEME_DETAILS
                                    ].logo
                                }`}
                                href={
                                    THEME_DETAILS[
                                        theme as keyof typeof THEME_DETAILS
                                    ].link
                                }
                                className={styles.VGLogo}
                            />
                        </div>
                        <div
                            className={cx(
                                styles.NavbarAdditionalText,
                                styles[theme],
                            )}
                        >
                            <span>
                                {
                                    THEME_DETAILS[
                                        theme as keyof typeof THEME_DETAILS
                                    ].additionalText
                                }
                            </span>
                            <span className={styles.SecondAdditionalText}>
                                {
                                    THEME_DETAILS[
                                        theme as keyof typeof THEME_DETAILS
                                    ].secondAdditionalText
                                }
                            </span>
                        </div>
                        <div className={styles.MenuContainer}>
                            {theme === THEME.VG && (
                                <ComposedLanguageSelector vg />
                            )}
                            <Search />
                        </div>
                    </div>
                </div>
            )}
            <div className={cx(styles.NavbarLinksContainer, styles[theme])}>
                <div className={cx(styles.ContentContainer, styles[theme])}>
                    <div
                        className={cx(styles.NavbarLinks, {
                            [styles.SingleLink]: links.length === 1,
                        })}
                    >
                        {links.map((link) => {
                            return (
                                <NavbarLink
                                    key={link.id}
                                    active={isLinkMatched(link.href)}
                                    {...link}
                                    items={
                                        link.href === RoutePaths.GALLERY
                                            ? galleryItems
                                            : null
                                    }
                                />
                            );
                        })}
                    </div>
                    {theme === THEME.DEFAULT && <ComposedLanguageSelector />}
                </div>
            </div>
        </nav>
    );
};

export const ComposedNavbar = compose<Props, OuterProps>(withRouter)(Navbar);
