import { createAsyncAction } from 'typesafe-actions';

import { ITaskVolunteerData } from '../../interfaces/task-volunteer-data';
import { ITaskVolunteerStatus } from '../../interfaces/task-volunteer-status';

export const volunteerTaskApiActions = createAsyncAction(
    'api/tasks/VOLUNTEER_TASK_REQUESTED',
    'api/tasks/VOLUNTEER_TASK_SUCCEEDED',
    'api/tasks/VOLUNTEER_TASK_FAILED',
)<ITaskVolunteerData, ITaskVolunteerStatus, ITaskVolunteerStatus>();
