import React from 'react';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';

import { ReactComponent as LikeIcon } from '../../assets/icons/like.svg';
import { ReactComponent as CommentIcon } from '../../assets/icons/comment.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-icon.svg';

import { IIconProps, IIconType } from './icon-types';

export const Icon: React.FC<IIconProps> = ({ type }) => {
    switch (type) {
        case IIconType.Like:
            return <LikeIcon />;
        case IIconType.Comment:
            return <CommentIcon />;
        case IIconType.Share:
            return <ShareIcon />;
        case IIconType.Trash:
            return <TrashIcon />;
        case IIconType.Movie:
            return <SmartDisplayIcon />;
        default:
            return null;
    }
};
