import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { INavRoute, NavigationLinkIds } from '../../../interfaces/nav-route';
import { getTheme } from '../../../model/configuration/selectors/get-theme.selector';
import { getShorterTranslationIfExists } from '../../../../shared/helpers/getShorterTranslationIfExists';

import styles from './navbar-link.module.scss';

type Props = {
    active: boolean;
    items: number | null;
} & INavRoute;

export const NavbarLink = ({
    active,
    href,
    state,
    icon,
    promoted = false,
    isButton = false,
    items,
    id,
}: Props) => {
    const [, i18n] = useTranslation();
    const theme = useSelector(getTheme);
    const Icon = icon;
    const isActive = !isButton && active;
    const linkContainerClass = cx(styles.Container, styles[theme], styles[id], {
        [styles.Active]: isActive,
    });

    const iconClass = cx(styles.Icon, {
        [styles.Promoted]: promoted,
        [styles.DonationIconFix]: id === NavigationLinkIds.SEND_DONATION,
    });

    const to = {
        pathname: href,
        state,
    };

    const name = getShorterTranslationIfExists(i18n, `navigationLinks.${id}`);

    return (
        <Link className={linkContainerClass} key={id} to={to}>
            <div className={styles.InnerContainer}>
                {Icon && <Icon className={iconClass} />}
                <div className={styles.Text}>
                    <div className={styles.DesktopName}>
                        {name} {items !== null && ` (${items})`}
                    </div>
                    <div className={styles.MobileName}>{name}</div>
                </div>
            </div>
        </Link>
    );
};
