import React, { HTMLAttributes, useEffect } from 'react';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextArea } from '../../../components/text-area/text-area';

import { ICardFormValues } from './card-form-values';
import styles from './card-form.module.scss';

interface IOuterProps {
    prefilledValues?: Partial<ICardFormValues>;
    onChange(values: ICardFormValues): unknown;
}

interface IProps
    extends FormikProps<ICardFormValues>,
        IOuterProps,
        Omit<HTMLAttributes<HTMLFormElement>, 'onChange'> {}

const Form = ({
    className,
    handleBlur,
    handleChange,
    handleSubmit,
    onChange,
    values,
}: IProps) => {
    useEffect(() => {
        onChange(values);
    }, [values, onChange]);

    const { t } = useTranslation();

    return (
        <form className={cx(className, styles.Form)} onSubmit={handleSubmit}>
            <TextArea
                name="cardText"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('flowers.details.cardTextLabel')}
                rows={2}
                value={values.cardText}
                data-testid="flowers-details-card-text-input"
            />
        </form>
    );
};

export const CardForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, ICardFormValues>({
        handleSubmit() {},
        mapPropsToValues({ prefilledValues = {} }) {
            return {
                cardText: prefilledValues.cardText || '',
            };
        },
    }),
)(Form);
