import { createAction } from 'typesafe-actions';

import { IMemorialEvent } from '../../interfaces/memorial-event';
import { IEventAttendanceData } from '../../interfaces/event-attendance-data';
import { IEventAttendanceStatus } from '../../interfaces/event-attendance-status';

export const eventsDataReceived = createAction(
    'model/events/DATA_RECEIVED',
    (payload: { events: IMemorialEvent[] }) => payload,
)();

export const attendEventRequested = createAction(
    'model/events/ATTEND_EVENT_REQUESTED',
    (payload: IEventAttendanceData) => payload,
)();

export const setEventAttendanceStatus = createAction(
    'model/events/SET_EVENT_ATTENDANCE_STATUS',
    (payload: IEventAttendanceStatus | null) => payload,
)();
