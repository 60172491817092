import { createReducer } from 'typesafe-actions';

import { IDeceasedPersonData } from '../../interfaces/deceased-person-data';
import { RootActions } from '../../store/actions';

import { deceasedDataReceived } from './deceased-person.actions';

export interface IDeceasedPersonStore {
    data: IDeceasedPersonData;
    backgroundImageSrc: string;
    avatarSrc: string;
    tributeText: string | null;
    obituaryText: string | null;
}

const initialState: IDeceasedPersonStore = {
    data: {
        name: '',
        deathPlace: null,
        deathDate: null,
        birthPlace: null,
        birthDate: null,
        birthPlaceVisible: false,
        deathPlaceVisible: false,
    },
    backgroundImageSrc: '',
    avatarSrc: '',
    tributeText: null,
    obituaryText: null,
};

export const deceasedPersonReducer = createReducer<
    IDeceasedPersonStore,
    RootActions
>(initialState).handleAction(deceasedDataReceived, (state, action) => ({
    ...state,
    data: action.payload.data,
    avatarSrc: action.payload.photoThumb,
    backgroundImageSrc: action.payload.backgroundSrc,
    tributeText: action.payload.tributeText,
    obituaryText: action.payload.obituaryText,
}));
