import { put } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { deceasedDataReceived } from '../deceased-person.actions';
import { IDeceasedPersonResponseDto } from '../../../api/DTOs/deceased-person-response.dto';
import { IBackgroundImageDto } from '../../../api/DTOs/background-image.dto';

export function* getDeceasedDataFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const deceasedData = action.payload.included.find(
        (inclusion) => inclusion.type === 'deceased_person',
    ) as IDeceasedPersonResponseDto;

    const backgroundData = action.payload.included.find(
        (inclusion) => inclusion.type === 'background',
    ) as IBackgroundImageDto;

    const pageAttributes = action.payload.data.attributes;

    if (!deceasedData || !backgroundData) {
        /**
         * Required data is not here. Probably some error should be thrown
         * TODO: Handle error
         */
        return;
    }

    const {
        displayName,
        deathLocationName,
        deathDate,
        birthLocationName,
        birthDate,
        photoThumbUrl,
    } = deceasedData.attributes;

    const { birthPlaceVisible, deathPlaceVisible } = pageAttributes;

    yield put(
        deceasedDataReceived({
            data: {
                deathPlace: deathLocationName,
                deathDate,
                birthDate,
                birthPlace: birthLocationName,
                name: displayName,
                birthPlaceVisible,
                deathPlaceVisible,
            },
            photoThumb: photoThumbUrl,
            backgroundSrc: backgroundData.attributes.backgroundUrl,
            tributeText: pageAttributes.tributeText,
            obituaryText: pageAttributes.obituaryText,
        }),
    );
}
