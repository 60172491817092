import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { IDeathNotice } from '../../interfaces/death-notice';
import { ILeaflet } from '../../interfaces/leaflet';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg';
import { ReactComponent as PreviewIcon } from '../../assets/icons/preview-icon.svg';
import { useDateFormatter } from '../../utils/use-date-formatter';

import styles from './downloads-item.module.scss';

type Props = React.HTMLAttributes<HTMLIFrameElement> & {
    image: IDeathNotice | ILeaflet;
    title?: string;
    containerClassname?: string;
    imageClassname?: string;
    bottomTitle?: boolean;
};

export const DownloadsItem = ({
    image,
    title,
    containerClassname,
    imageClassname,
    bottomTitle = false,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const { getShortDate } = useDateFormatter();
    const DNImageRef = useRef<HTMLImageElement>(null);

    const isDeathNotice = (
        file: IDeathNotice | ILeaflet,
    ): file is IDeathNotice => {
        return (file as IDeathNotice).previewUrl !== undefined;
    };

    const renderDNTitle = () => {
        if (isDeathNotice(image)) {
            if (!image.publicationDate) {
                const DNtitle = bottomTitle
                    ? image.publisherName
                    : `${t('downloads.deathNotice')}, ${image.publisherName}`;

                return DNtitle;
            }
            const DNtitle = bottomTitle
                ? `${image.publisherName}, ${getShortDate(
                      image.publicationDate,
                  )}`
                : `${t('downloads.deathNotice')}, ${
                      image.publisherName
                  }, ${getShortDate(image.publicationDate)}`;

            return DNtitle;
        }

        return null;
    };

    const isSmallHeightDN = () => {
        if (
            DNImageRef.current &&
            DNImageRef.current.naturalWidth / DNImageRef.current.naturalHeight >
                1
        ) {
            DNImageRef.current.classList.add(styles.SmallHeightDN);
        }
    };

    return (
        <div className={cx(containerClassname, styles.DownloadsContainer)}>
            {!isDeathNotice(image) && (
                <iframe
                    className={styles.DownloadsItem}
                    src={image.url}
                    title={title}
                    {...props}
                />
            )}
            {isDeathNotice(image) && !image.previewUrl && (
                <div className={styles.DownloadsItemNoPreview}>
                    {t('downloads.noPreview')}
                </div>
            )}
            {isDeathNotice(image) && image.previewUrl && (
                <img
                    src={image.previewUrl}
                    className={cx(imageClassname, styles.DownloadsItemImage)}
                    alt={title}
                    title={title}
                    ref={DNImageRef}
                    onLoad={isSmallHeightDN}
                />
            )}
            <span
                className={cx(styles.DownloadsName, {
                    [styles.BottomTitle]: bottomTitle,
                })}
            >
                {renderDNTitle()}
            </span>
            <a
                className={styles.DownloadsLink}
                href={image.url}
                onClick={(e) => e.stopPropagation()}
                target="_blank"
                rel="noopener noreferrer"
            >
                {isDeathNotice(image) ? <DownloadIcon /> : <PreviewIcon />}
            </a>
        </div>
    );
};
