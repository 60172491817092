import { IFlowerVariant } from '../../interfaces/flower-variant';

export const getInitiallySelectedVariant = (
    props: IFlowerVariant[],
): IFlowerVariant => {
    const variants = [...props];
    if (variants.length === 1) {
        return variants[0];
    }
    if (variants.length === 3) {
        return variants.sort((a, b) => a.grossPrice - b.grossPrice)[1];
    }
    return variants.sort((a, b) => a.grossPrice - b.grossPrice)[
        variants.length - 1
    ];
};
