import { FlowerDeliveryType } from '../../interfaces/flower-delivery-type';

const labels = {
    [FlowerDeliveryType.CEREMONY]: 'flowers.delivery.ceremonyDeliveryLabel',
    [FlowerDeliveryType.PRIVATE_ADDRESS]:
        'flowers.delivery.privateAddressDeliveryLabel',
    [FlowerDeliveryType.GRAVE]: 'flowers.delivery.graveDeliveryLabel',
};

export const resolveDeliveryLabel = (type: FlowerDeliveryType) => labels[type];
