import React from 'react';
import cx from 'classnames';

import { Button } from '../../../components/button/button';
import { ReactComponent as FacebookWhiteIcon } from '../../../assets/icons/facebook-white-icon.svg';

import styles from './facebook-button.module.scss';

type Props = React.HTMLAttributes<HTMLButtonElement> & { theme: string };

export const FacebookButton = ({
    children,
    className,
    theme,
    ...props
}: Props) => {
    return (
        <Button
            className={cx(styles.FacebookButton, className)}
            icon={FacebookWhiteIcon}
            iconBoxClassName={cx(styles.FacebookButtonIconBox, styles[theme])}
            {...props}
        >
            {children}
        </Button>
    );
};
