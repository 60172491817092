import { createSelector } from 'reselect';

import { VideoStreamError } from '../video-stream.store';

import { getVideoStreamError } from './get-video-stream-error.selector';

export const getVideoStreamErrorMessage = createSelector(
    getVideoStreamError,
    (error) => {
        const VideoStreamErrorMessage = {
            [VideoStreamError.INVALID_PASSWORD]: 'videoStream.invalidPassword',
        };
        return error ? VideoStreamErrorMessage[error] : null;
    },
);
