import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { IForgottenPasswordFormValues } from '../forgotten-password-form-values';
import { forgottenPasswordFormValidation } from '../forgotten-password-form-validation';
import { TextField } from '../../components/text-field/text-field';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import { FieldErrorMessage } from '../../components/error-message/error-message';

import styles from './forgotten-password-form.module.scss';

type OuterProps = {
    onSubmit(values: IForgottenPasswordFormValues): unknown;
    prefillEmail?: string | null;
} & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

type Props = FormikProps<IForgottenPasswordFormValues> & OuterProps;

export const ForgottenPasswordForm = ({
    className,
    handleChange,
    handleSubmit,
    values,
    errors,
    handleBlur,
    isValid,
    prefillEmail,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const dirty =
        props.dirty || (!!prefillEmail && prefillEmail === values.email);
    const canSave = dirty && isValid;
    return (
        <form className={cx(className)} onSubmit={handleSubmit}>
            <p className={styles.HintText}>{t('forgottenPassword.hintText')}</p>
            <div className={styles.InputsContainer}>
                <div className={styles.Control}>
                    <TextField
                        autoFocus
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('forgottenPassword.emailLabel')}
                        value={values.email}
                        error={Boolean(errors.email)}
                    />
                    <FieldErrorMessage name="email" />
                </div>
            </div>
            <PrimaryButton
                className={styles.SubmitButton}
                icon={ArrowIcon}
                type="submit"
                disabled={!canSave}
            >
                {t('forgottenPassword.submitButton')}
            </PrimaryButton>
        </form>
    );
};

export const ConnectedForgottenPasswordForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IForgottenPasswordFormValues>({
        mapPropsToValues({ prefillEmail }) {
            return {
                email: prefillEmail || '',
            };
        },
        handleSubmit(values, formikBag) {
            formikBag.props.onSubmit(values);
        },
        validationSchema: forgottenPasswordFormValidation,
    }),
)(ForgottenPasswordForm);
