import { createSelector } from 'reselect';

import { getFlowerShopCategoriesSelector } from './get-flower-shop-categories.selector';

export const getFlowerShopCategorySelector = (categoryId?: string | null) =>
    createSelector(getFlowerShopCategoriesSelector, (categories) => {
        return categoryId
            ? categories.find((category) => category.id === categoryId)
            : null;
    });
