import React from 'react';
import cx from 'classnames';

import styles from './small-gray-button.module.scss';

type Props = React.HTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
};

export const SmallGrayButton = ({
    children,
    className,
    disabled = false,
    type = 'button',
    ...props
}: Props) => {
    const buttonClass = cx(
        styles.Button,
        { [styles.ButtonDisabled]: disabled },
        className,
    );

    return (
        <button
            className={buttonClass}
            disabled={disabled}
            type={type}
            {...props}
        >
            {children}
        </button>
    );
};
