import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';
import { IDeathNotice } from '../../interfaces/death-notice';

import { deathNoticeDataReceived } from './death-notice.actions';

export interface IDeathNoticeStore {
    deathNotices: IDeathNotice[];
}

const initialState: IDeathNoticeStore = {
    deathNotices: [],
};

export const deathNoticesReducer = createReducer<
    IDeathNoticeStore,
    RootActions
>(initialState).handleAction(deathNoticeDataReceived, (state, action) => ({
    ...state,
    deathNotices: action.payload.deathNotices,
}));
