import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { refreshSessionActions } from '../../app/api/refresh-session/refresh-session.actions';

export const withSessionRestoring = () => <Props extends {}>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(refreshSessionActions.request());
    }, [dispatch]);

    return <Component {...props} />;
};
