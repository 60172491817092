import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { attendEventApiActions } from '../events-api.actions';

import { attendEventSaga } from './attend-event.saga';

export function* eventsApiSaga() {
    yield takeEvery(getType(attendEventApiActions.request), attendEventSaga);
}
