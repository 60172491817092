import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook-icon.svg';
import { getTheme } from '../../../model/configuration/selectors/get-theme.selector';

import styles from './small-facebook-button.module.scss';

type Props = React.HTMLAttributes<HTMLButtonElement> & {};

export const SmallFacebookButton = ({
    children,
    className,
    ...props
}: Props) => {
    const theme = useSelector(getTheme);

    return (
        <button
            className={cx(styles.SmallFacebookButton, styles[theme], className)}
            {...props}
        >
            <FacebookIcon className={styles.Icon} />
            <span className={styles.SmallFacebookText}>{children}</span>
        </button>
    );
};
