import React from 'react';
import parseStringToHtml from 'react-html-parser';
import cx from 'classnames';

import styles from './string-to-html-parser.module.scss';

interface IProps {
    stringToParse: string;
    withHeadingsMargin?: boolean;
}

const StringToHtmlParser = ({
    stringToParse,
    withHeadingsMargin = false,
}: IProps) => {
    if (!stringToParse) {
        return null;
    }

    return (
        <div
            className={cx(styles.Container, {
                [styles.WithHeadingsMargin]: withHeadingsMargin,
            })}
        >
            {parseStringToHtml(stringToParse)}
        </div>
    );
};

export default StringToHtmlParser;
