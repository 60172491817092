import { useDispatch, useSelector } from 'react-redux';

import { volunteerTaskRequested } from '../../model/tasks/tasks.actions';
import {
    getTaskVolunteerStatus,
    getTaskVolunteerStatusMessage,
} from '../../model/tasks/tasks.selector';
import { ITaskVolunteerData } from '../../interfaces/task-volunteer-data';

export const useVolunteerTask = () => {
    const dispatch = useDispatch();
    const volunteerStatus = useSelector(getTaskVolunteerStatus);
    const volunteerStatusMessage = useSelector(getTaskVolunteerStatusMessage);

    return {
        volunteerStatus,
        volunteerStatusMessage,
        volunteerTaskRequested(payload: ITaskVolunteerData) {
            dispatch(volunteerTaskRequested(payload));
        },
    };
};
