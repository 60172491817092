import { apiService } from '../../api.service';
import { IVideoStreamRequestDto } from '../../DTOs/video-stream-request.dto';

export const sendVideoStream = ({
    slug,
    dto,
}: {
    slug: string;
    dto: IVideoStreamRequestDto;
}) => {
    return apiService
        .post(`/public/memorial_pages/${slug}/video_streams`, {
            json: {
                password: dto.password,
            },
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
};
