import { useMemo } from 'react';
import { take } from 'lodash';

import { useBooleanState } from '../../../../../../../shared/hooks/useBooleanState';

import { ICondolenceCommentsListProps } from './condolence-comments-list-types';

const initialCondolenceCommentsCount = 3;

export const useCondolenceCommentsList = ({
    condolence,
    onLikeCondolenceComment,
    onUnlikeCondolenceComment,
}: ICondolenceCommentsListProps) => {
    const isListExpanded = useBooleanState(false);

    const commentsList = useMemo(() => {
        const sortedComments = condolence.comments.sort((c1, c2) =>
            c1.createdAt < c2.createdAt ? 1 : -1,
        );
        return isListExpanded.state
            ? sortedComments
            : take(sortedComments, initialCondolenceCommentsCount);
    }, [condolence.comments, isListExpanded.state]);

    const commentsLeftToExpand =
        condolence.comments.length - initialCondolenceCommentsCount;

    return {
        isListExpanded,
        commentsList,
        commentsLeftToExpand,
        onLikeCondolenceComment,
        onUnlikeCondolenceComment,
    };
};
