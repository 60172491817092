import { put, take, delay } from '@redux-saga/core/effects';
import { isActionOf } from 'typesafe-actions';

import { addCondolenceCommentApiActions } from '../../../api/condolences/condolences-api.actions';
import {
    setCondolenceStatus,
    setCondolenceError,
    condolenceCommentRequested,
} from '../condolences.actions';

export function* handleAddCommentToCondolenceSaga(
    action: ReturnType<typeof condolenceCommentRequested>,
) {
    yield put(addCondolenceCommentApiActions.request(action.payload));

    const response = yield take([
        addCondolenceCommentApiActions.success,
        addCondolenceCommentApiActions.failure,
    ]);

    if (isActionOf(addCondolenceCommentApiActions.success, response)) {
        yield put(setCondolenceError(false));
    } else {
        yield put(setCondolenceError(true));
        yield put(setCondolenceStatus(response.payload.status));
        yield delay(3000);
        yield put(setCondolenceStatus(null));
    }
}
