import React from 'react';
import cx from 'classnames';

import styles from './text-field.module.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    type?: string;
    value?: string | number;
    error?: boolean;
    startAdornment?: string | null;
};

export const TextField = ({
    className,
    type = 'text',
    disabled,
    error,
    startAdornment,
    ...props
}: Props) => {
    return (
        <div className={styles.Container}>
            {!!startAdornment && (
                <div className={styles.StartAdornment}>{startAdornment}</div>
            )}
            <input
                disabled={disabled}
                className={cx(styles.TextField, className, {
                    [styles.Disabled]: disabled,
                    [styles.Error]: error,
                    [styles.WithStartAdornment]: !!startAdornment,
                })}
                type={type}
                {...props}
            />
        </div>
    );
};
