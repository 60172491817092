import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import { invitationTokenNotFound } from '../invitation.actions';
import { RoutePaths } from '../../../routing/route-paths';

export function* handleMissingInvitationTokenSaga(
    action: ReturnType<typeof invitationTokenNotFound>,
) {
    // TODO: Probably show some error etc, redirect to error page?

    yield put(push(RoutePaths.HOME));
}
