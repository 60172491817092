import { call, select, put } from '@redux-saga/core/effects';

import { contactSuggestionApiActions } from '../contact-suggestion.actions';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { suggestContact } from '../http/suggest-contact';

export function* createContactSuggestionApiSaga(
    action: ReturnType<typeof contactSuggestionApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        yield call(suggestContact, {
            slug,
            dto: action.payload,
        });

        yield put(contactSuggestionApiActions.success());
    } catch (e) {
        yield put(contactSuggestionApiActions.failure(e.statusText));
    }
}
