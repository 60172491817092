import { select, put, call } from 'redux-saga/effects';

import { getFlowerDeliveryTypesApiActions } from '../flowers-delivery-api.actions';
import { getPageSlug } from '../../../../model/configuration/selectors/get-page-slug.selector';
import { fetchFlowerDeliveryTypes } from '../http/fetch-flower-delivery-types';
import { IFlowerDeliveryTypesDTO } from '../../../DTOs/flower-delivery-types.dto';

export function* getFlowersDeliveryTypesApiSaga() {
    const slug = yield select(getPageSlug);

    try {
        const resp: IFlowerDeliveryTypesDTO = yield call(
            fetchFlowerDeliveryTypes,
            {
                slug,
            },
        );

        yield put(
            getFlowerDeliveryTypesApiActions.success(
                resp.data.map((deliveryType) => ({
                    locationRequired:
                        deliveryType.attributes.locationAttributesRequired,
                    name: deliveryType.attributes.name,
                    recipientRequired:
                        deliveryType.attributes.recipientRequired,
                    id: deliveryType.id,
                })),
            ),
        );
    } catch (e) {
        yield put(getFlowerDeliveryTypesApiActions.failure(e.statusText));
    }
}
