import { createAction } from 'typesafe-actions';

import { IViolationReportRequestDto } from '../../api/DTOs/violation-report-request.dto';

export const violationReportFormSubmitted = createAction(
    'model/violation_report/VIOLATION_REPORT_FORM_SUBMITTED',
    (payload: IViolationReportRequestDto) => payload,
)();

export const setViolationReportError = createAction(
    'model/violation_report/SET_VIOLATION_REPORT_ERROR',
    (payload: boolean) => payload,
)();
