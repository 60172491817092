import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { getTokenFromUrl } from '../../app/utils/get-token-from-url';
import { RoutePaths } from '../../app/routing/route-paths';
import {
    invitationTokenDetected,
    invitationTokenNotFound,
} from '../../app/invitation/model/invitation.actions';

export const withInvitationTokenValidation = <
    Props extends RouteComponentProps
>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    const dispatch = useDispatch();
    const { location } = props;

    useEffect(() => {
        if (location.pathname !== RoutePaths.INVITATION) {
            return;
        }

        const token = getTokenFromUrl();

        if (!token) {
            dispatch(invitationTokenNotFound());

            return;
        }

        dispatch(invitationTokenDetected({ token }));
    }, [dispatch, location.pathname]);

    return <Component {...props} />;
};
