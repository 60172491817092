import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlowersData } from '../../model/flowers/hooks/use-flowers-data';
import { FlowersFlowActions } from '../../model/flowers/flowers.actions';
import { getFlowerShopCategoriesDescription } from '../../model/configuration/selectors/get-flower-shop-categories-description.selector';

import { FlowersCategoryItem } from './flowers-category-item/flowers-category-item';
import styles from './flowers-category.module.scss';

export const FlowersCategory = () => {
    const {
        categories,
        noAvailableCategories,
        onlyOneAvailableCategory,
    } = useFlowersData();
    const dispatch = useDispatch();

    const flowerShopCategoriesDescription = useSelector(
        getFlowerShopCategoriesDescription,
    );

    useEffect(() => {
        if (onlyOneAvailableCategory) {
            dispatch(
                FlowersFlowActions.categoryChosen({
                    categoryID: categories[0].id,
                }),
            );
        }
    }, [onlyOneAvailableCategory, categories, dispatch]);

    if (noAvailableCategories) {
        return null;
    }

    const onCategoryClicked = (id: string) => {
        dispatch(
            FlowersFlowActions.categoryChosen({
                categoryID: id,
            }),
        );
    };

    return (
        <>
            <div>
                {flowerShopCategoriesDescription && (
                    <div className={styles.Description}>
                        {flowerShopCategoriesDescription}
                    </div>
                )}
                <div className={styles.FlowersCategoryItems}>
                    {categories.map((item) => (
                        <FlowersCategoryItem
                            key={item.id}
                            item={item}
                            onClick={() => onCategoryClicked(item.id)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
