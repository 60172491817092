import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { volunteerTaskApiActions } from '../tasks-api.actions';

import { volunteerTaskSaga } from './volunteer-task.saga';

export function* tasksApiSaga() {
    yield takeEvery(
        getType(volunteerTaskApiActions.request),
        volunteerTaskSaga,
    );
}
