import React from 'react';
import cx from 'classnames';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

import { Button } from '../../button/button';
import styles from '../image-carousel.module.scss';
import { IImageCarouselNavigationProps } from '../image-carousel.types';

export const NavigationPrev = React.memo(
    ({ innerProps }: IImageCarouselNavigationProps) => {
        return (
            <Button
                className={cx(
                    styles.NavigationButton,
                    styles.NavigationButtonPrev,
                )}
                onClick={innerProps?.onClick}
            >
                <div className={cx(styles.Icon, styles.NavigationIcon)}>
                    <ArrowBackIosNewRoundedIcon />
                </div>
            </Button>
        );
    },
);
