import React from 'react';

import { useDateFormatter } from '../../../../../../../utils/use-date-formatter';
import { IIconType } from '../../../../../../../components/icon/icon-types';
import { SocialItem } from '../../social-item';
import {
    decodeText,
    getDecodedText,
} from '../../../../../../../../shared/helpers/getDecodedText';

import styles from './condolence-comments-item.module.scss';
import { ICondolenceCommentProps } from './condolence-comments-item-types';
import { useCondolenceCommentsItem } from './use-condolence-comments-item';

export const CondolenceCommentsItem = (props: ICondolenceCommentProps) => {
    const { getDaysAgo } = useDateFormatter();

    const {
        comment,
        isLikedByCurrentUser,
        handleLikeOrUnlikeCondolenceComment,
    } = useCondolenceCommentsItem(props);

    return (
        <div className={styles.Container}>
            <div className={styles.AuthorBar}>
                <div className={styles.FirstColumn}>
                    <span className={styles.Author}>
                        {decodeText(comment.signature)}
                    </span>
                    <span className={styles.CondolenceDate}>
                        {getDaysAgo(comment.createdAt)}
                    </span>
                </div>
                <div className={styles.SecondColumn}>
                    <SocialItem
                        like
                        selected={isLikedByCurrentUser}
                        count={comment.likesCount}
                        icon={IIconType.Like}
                        onClick={handleLikeOrUnlikeCondolenceComment}
                    />
                </div>
            </div>
            <div className={styles.Content}>{getDecodedText(comment.body)}</div>
        </div>
    );
};
