import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBooleanState } from '../../shared/hooks/useBooleanState';
import { setCookiesAccepted } from '../model/cookies-accepted/cookies-accepted.actions';
import { getCookiesAccepted } from '../model/cookies-accepted/selectors/get-cookies-accepted.selector';
import { getHasUserAccess } from '../model/user/selectors/get-has-user-access.selector';
import { ButtonLink } from '../components/button-link/button-link';
import { Container } from '../components/container/container';
import { ReactComponent as PlusIcon } from '../assets/icons/plus-icon.svg';
import { useIsMobileBreakpoint } from '../../shared/hooks/useIsViewPortSmallerThan';

import { openMemcarePrivacyPolicyPage } from './helpers';
import styles from './accept-cookies.module.scss';

export type AcceptCookiesProps = {
    navbarHeight?: number;
};

const AcceptCookies = ({ navbarHeight }: AcceptCookiesProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const cookiesAccepted = useSelector(getCookiesAccepted);
    const isHidden = useBooleanState(cookiesAccepted);
    const hasAccessToThisMemorial = useSelector(getHasUserAccess);

    const isMobile = useIsMobileBreakpoint();
    const style = isMobile ? { bottom: navbarHeight } : {};

    const handleAcceptCookies = () => {
        dispatch(setCookiesAccepted());
        setTimeout(() => {
            isHidden.handleSetAsTrue();
        }, 1000);
    };

    if (isHidden.state || !hasAccessToThisMemorial) {
        return null;
    }
    return (
        <div
            className={cx(styles.Container, cookiesAccepted && styles.Accepted)}
            style={style}
        >
            <Container className={styles.InnerContainer}>
                <div className={styles.Content}>
                    <span className={styles.Text}>
                        {t('acceptCookies.description')}
                    </span>
                    <ButtonLink
                        className={styles.Link}
                        onClick={openMemcarePrivacyPolicyPage}
                    >
                        {t('acceptCookies.more')}
                    </ButtonLink>
                </div>
                <div
                    className={styles.IconContainer}
                    onClick={handleAcceptCookies}
                >
                    <PlusIcon className={styles.Icon} />
                </div>
            </Container>
        </div>
    );
};

export default AcceptCookies;
