import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Container } from '../../components/container/container';
import { VideoStream } from '../../video-stream/video-stream';
import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { getEvent } from '../../model/events/selectors/get-event.selector';
import { RoutePaths } from '../../routing/route-paths';
import { ConnectedVideoStreamForm } from '../../video-stream/video-stream-form/video-stream-form';
import { IVideoStreamRequestDto } from '../../api/DTOs/video-stream-request.dto';
import { Section } from '../../components/section/section';
import { videoStreamFormSubmitted } from '../../model/video-stream/video-stream.actions';
import { getVideoStreamUrl } from '../../model/video-stream/selectors/get-video-stream.selector';
import { getVideoStreamErrorMessage } from '../../model/video-stream/selectors/get-video-stream-error-message.selector';
import { ErrorMessage } from '../../components/error-message/error-message';

import styles from './video-stream-page.module.scss';

interface IParamType {
    eventId: string;
}

export const VideoStreamPage = () => {
    const { eventId } = useParams<IParamType>();
    const event = useSelector(getEvent(eventId));
    const videoStreamUrl = useSelector(getVideoStreamUrl);
    const videoStreamError = useSelector(getVideoStreamErrorMessage);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isPasswordNeeded, setIsPasswordNeeded] = useState<boolean>();

    useEffect(() => {
        setIsPasswordNeeded(
            event?.videoStreamEnabled && !event?.videoStreamUrl,
        );
    }, [event]);

    const onSubmit = (values: IVideoStreamRequestDto) => {
        dispatch(videoStreamFormSubmitted(values));
        setIsPasswordNeeded(false);
    };

    return (
        <>
            <DeceasedDisplay />
            <Container>
                {!eventId && <Redirect to={RoutePaths.HOME} />}
                {event &&
                    event.videoStreamEnabled &&
                    (isPasswordNeeded || videoStreamError) && (
                        <Section
                            headerClassName={styles.SectionTitle}
                            headerText={t('videoStream.sectionPasswordTitle')}
                            className={styles.VideoStreamSection}
                        >
                            <div className={styles.SectionSubtitle}>
                                {t('videoStream.passwordInfo')}
                            </div>
                            <ConnectedVideoStreamForm onSend={onSubmit} />
                            {videoStreamError && (
                                <ErrorMessage>
                                    {t(`${videoStreamError}`)}
                                </ErrorMessage>
                            )}
                        </Section>
                    )}
                {event &&
                    !isPasswordNeeded &&
                    !!videoStreamUrl &&
                    !videoStreamError && <VideoStream url={videoStreamUrl} />}
                {event &&
                    !isPasswordNeeded &&
                    !!event.videoStreamUrl &&
                    !videoStreamError && (
                        <VideoStream url={event.videoStreamUrl} />
                    )}
                {event && !event.videoStreamEnabled && (
                    <Redirect to={RoutePaths.HOME} />
                )}
            </Container>
        </>
    );
};
