import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';

import {
    userAccessToMemorialDetected,
    userEmailReceived,
    userLoggedStatusReceived,
    userLogoutRequested,
    userMPAStatusReceived,
} from './user.actions';

interface IUserStore {
    logged: boolean | undefined;
    hasAccessToThisMemorial: boolean | undefined;
    email?: string;
    isMPA: boolean;
}

const initialState: IUserStore = {
    logged: undefined,
    hasAccessToThisMemorial: undefined,
    isMPA: false,
};

export const userReducer = createReducer<IUserStore, RootActions>(initialState)
    .handleAction(userLoggedStatusReceived, (state, action) => ({
        ...state,
        logged: action.payload.logged,
    }))
    .handleAction(userAccessToMemorialDetected, (state, action) => ({
        ...state,
        hasAccessToThisMemorial: action.payload.hasAccess,
    }))
    .handleAction(userEmailReceived, (state, action) => ({
        ...state,
        email: action.payload.email,
    }))
    .handleAction(userLogoutRequested, (state) => ({
        ...state,
        logged: false,
    }))
    .handleAction(userMPAStatusReceived, (state, action) => ({
        ...state,
        isMPA: action.payload.isAdmin,
    }));
