import { put, take } from '@redux-saga/core/effects';

import { deleteCondolenceApiActions } from '../../../api/condolences/condolences-api.actions';
import { condolenceDeletionSucceeded } from '../condolences.actions';

export function* handleCondolenceDeletionSaga(
    action: ReturnType<typeof condolenceDeletionSucceeded>,
) {
    const { id } = action.payload;

    yield put(deleteCondolenceApiActions.request({ id }));

    yield take(deleteCondolenceApiActions.success);

    yield put(condolenceDeletionSucceeded({ id }));
}
