import React from 'react';
import cx from 'classnames';

import { Button } from '../button/button';

import styles from './secondary-button.module.scss';

type Props = React.HTMLAttributes<HTMLButtonElement> & {
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    iconBoxClassName?: string;
    iconClassName?: string;
    iconLeft?: boolean;
    type?: 'button' | 'submit' | 'reset';
};

export const SecondaryButton = ({
    children,
    className,
    iconBoxClassName,
    iconClassName,
    iconLeft = false,
    ...props
}: Props) => {
    const buttonClass = cx(
        styles.SecondaryButton,
        { [styles.SecondaryButtonIconLeft]: iconLeft },
        className,
    );

    return (
        <Button
            className={buttonClass}
            iconBoxClassName={cx(
                styles.SecondaryButtonIconBox,
                iconBoxClassName,
            )}
            iconClassName={iconClassName}
            {...props}
        >
            {children}
        </Button>
    );
};
