import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import React, { HTMLAttributes } from 'react';

export const DonationIcon = ({ className }: HTMLAttributes<SVGElement>) => {
    return (
        <VolunteerActivismOutlinedIcon
            style={{ width: '1.35em', height: '1.35em' }}
            className={className}
        />
    );
};
