import { apiService } from '../../api.service';
import { ICreateContactSuggestionDto } from '../../DTOs/create-contact-suggestion.dto';

export const suggestContact = ({
    slug,
    dto,
}: {
    slug: string;
    dto: ICreateContactSuggestionDto;
}): Promise<Response> =>
    apiService.post(`/public/memorial_pages/${slug}/suggestions`, {
        json: {
            suggestable: dto,
            suggestableType: 'Participant',
        },
    });
