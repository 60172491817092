import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import {
    galleryPhotoUploadFormSubmitted,
    downloadGalleryRequested,
} from '../gallery.actions';

import { getGalleryPhotosDataFromMemorialPageFetchSaga } from './get-gallery-photos-from-memorial-page-fetch.saga';
import { handlePhotoUploadFormSubmittedSaga } from './handle-photo-upload-form-submitted.saga';
import { handleDownloadGallerySaga } from './handle-download-gallery.saga';

export function* gallerySaga() {
    yield takeEvery(
        getType(fetchPageActions.success),
        getGalleryPhotosDataFromMemorialPageFetchSaga,
    );
    yield takeEvery(
        getType(galleryPhotoUploadFormSubmitted),
        handlePhotoUploadFormSubmittedSaga,
    );
    yield takeEvery(
        getType(downloadGalleryRequested),
        handleDownloadGallerySaga,
    );
}
