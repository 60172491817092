import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '../../components/container/container';
import { TimelineDisplay } from '../../timeline/timeline-display/timeline-display';
import { GalleryDisplay } from '../../gallery/gallery-display/gallery-display';
import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { TributeText } from '../../deceased-person/tribute-text/tribute-text';
import { DownloadsDisplay } from '../../gallery/downloads-display/downloads-display';
import { PrimaryButton } from '../../components/primary-button/primary-button';

import styles from './gallery-page.module.scss';
import { useGalleryPage } from './use-gallery-page';

export const GalleryPage = () => {
    const { t } = useTranslation();

    const {
        tribute,
        downloads,
        scrollToDownloads,
        scrollToMilestones,
        downloadsRef,
        milestones,
        milestonesRef,
        photos,
        condolencesPhotos,
    } = useGalleryPage();

    return (
        <>
            <DeceasedDisplay />
            <Container>
                {tribute && <TributeText content={tribute} />}
                <div className={styles.LinksContainer}>
                    {downloads && downloads.length > 0 && (
                        <PrimaryButton
                            className={styles.Link}
                            onClick={scrollToDownloads}
                        >
                            {t('gallery.downloadsTitle')}
                        </PrimaryButton>
                    )}
                    {milestones.length > 0 && (
                        <PrimaryButton
                            className={styles.Link}
                            onClick={scrollToMilestones}
                        >
                            {t('timeline.title')}
                        </PrimaryButton>
                    )}
                </div>
                <GalleryDisplay
                    className={styles.GalleryDisplay}
                    photos={photos}
                />
                {condolencesPhotos.length > 0 && (
                    <GalleryDisplay
                        withoutActions
                        className={styles.GalleryDisplay}
                        photos={condolencesPhotos}
                        title={t('gallery.condolencesPhotos')}
                    />
                )}
                {downloads && downloads.length > 0 && (
                    <DownloadsDisplay
                        className={styles.GalleryDisplay}
                        downloads={downloads}
                        ref={downloadsRef}
                    />
                )}
                {milestones.length > 0 && (
                    <TimelineDisplay
                        className={styles.TimelineDisplay}
                        events={milestones}
                        ref={milestonesRef}
                    />
                )}
            </Container>
        </>
    );
};
