import { call, put, select } from 'redux-saga/effects';

import { sendDonation } from '../http/send-donation';
import { sendDonationApiActions } from '../donation-api.actions';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { getDonationOrganizationId } from '../../../model/configuration/selectors/get-donation-organization-id.selector';

export function* sendDonationApiSaga(
    action: ReturnType<typeof sendDonationApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const donationOrganizationId = yield select(getDonationOrganizationId);

    const dto = action.payload;

    try {
        const response = yield call(sendDonation, {
            slug,
            dto,
            charityId: donationOrganizationId,
        });
        const paymentUrl = response?.data?.attributes?.paymentUrl;
        if (paymentUrl) {
            yield put(sendDonationApiActions.success({ dto, paymentUrl }));
        } else {
            yield put(sendDonationApiActions.failure());
        }
    } catch (e) {
        yield put(sendDonationApiActions.failure());
    }
}
