import React from 'react';
import Masonry from 'react-masonry-component';

import { ViolationReportModal } from '../../violation-reports/violation-report-modal';
import { ViolationReportableType } from '../../interfaces/violation-reportable-type';
import { DeleteCondolenceModal } from '../delete-condolence-modal';
import { CommentCondolenceModal } from '../comment-condolence-modal';

import { CondolencesListItem } from './components/condolences-list-item/condolences-list-item';
import { useCondolencesList } from './use-condolences-list';
import styles from './condolences-list.module.scss';
import { ICondolencesListProps } from './condolences-list.types';

const masonryOptions = {
    transitionDuration: 0,
};

export const CondolencesList: React.FC<ICondolencesListProps> = ({
    onEdit,
    onLikeCondolence,
    onUnlikeCondolence,
    onLikeCondolenceComment,
    onUnlikeCondolenceComment,
}) => {
    const {
        condolences,
        violationReportModalRef,
        deleteCondolenceModalRef,
        addCommendModalRef,
        handleSubmitViolationReport,
        handleOpenViolationModal,
        handleOpenDeleteCondolenceModal,
        handleOpenCommentCondolenceModal,
    } = useCondolencesList();

    if (!condolences.length) {
        return null;
    }

    return (
        <div className={styles.Container}>
            <Masonry options={masonryOptions} enableResizableChildren>
                {condolences.map((condolence) => {
                    return (
                        <CondolencesListItem
                            onOpenViolationModal={handleOpenViolationModal}
                            onOpenDeleteCondolenceModal={
                                handleOpenDeleteCondolenceModal
                            }
                            onOpenCondolenceModal={
                                handleOpenCommentCondolenceModal
                            }
                            condolence={condolence}
                            key={condolence.id}
                            onEdit={onEdit}
                            onLikeCondolence={onLikeCondolence}
                            onUnlikeCondolence={onUnlikeCondolence}
                            onLikeCondolenceComment={onLikeCondolenceComment}
                            onUnlikeCondolenceComment={
                                onUnlikeCondolenceComment
                            }
                        />
                    );
                })}
            </Masonry>
            <ViolationReportModal
                ref={violationReportModalRef}
                onConfirm={handleSubmitViolationReport}
                violationReportableType={ViolationReportableType.CONDOLENCE}
            />
            <DeleteCondolenceModal ref={deleteCondolenceModalRef} />
            <CommentCondolenceModal ref={addCommendModalRef} />
        </div>
    );
};
