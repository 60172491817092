import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { condolenceDeletionRequested } from '../../model/condolences/condolences.actions';

export const useDeleteCondolenceModal = () => {
    const dispatch = useDispatch();

    const [condolenceId, setCondolenceId] = useState<string>('');

    const handleClose = () => {
        setCondolenceId('');
    };

    const handleConfirm = () => {
        dispatch(condolenceDeletionRequested({ id: condolenceId }));
        setCondolenceId('');
    };

    return {
        condolenceId,
        handleClose,
        handleConfirm,
        setCondolenceId,
    };
};
