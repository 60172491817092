import { IImageUploadWithModalItem } from '../../components/inputs/image-upload-with-modal/image-upload-with-modal-types';
import { IMediaFile } from '../../interfaces/media-files';

export const convertMediaFilesToImageUploadItems = (
    mediaFiles?: IMediaFile[],
): IImageUploadWithModalItem[] => {
    if (mediaFiles) {
        return mediaFiles.map((item) => {
            return {
                previewUrl: item.previewUrl,
                id: item.id,
                isNew: false,
                isDeleted: false,
            };
        });
    }

    return [];
};
