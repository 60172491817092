import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';
import { ILeaflet } from '../../interfaces/leaflet';

import { leafletsDataReceived } from './leaflets.actions';

export interface ILeafletsStore {
    leaflets: ILeaflet[];
}

const initialState: ILeafletsStore = {
    leaflets: [],
};

export const LeafletsReducer = createReducer<ILeafletsStore, RootActions>(
    initialState,
).handleAction(leafletsDataReceived, (state, action) => ({
    ...state,
    leaflets: action.payload.leaflets,
}));
