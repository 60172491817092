import { createAction } from 'typesafe-actions';

import { IContactSuggestionFormValues } from '../contact-suggestion-form-values';

import { CreateContactSuggestionResult } from './contact-suggestion.store';

export const contactSuggestionFormSubmitted = createAction(
    'contact-suggestion/FORM_SUBMITTED',
    (payload: IContactSuggestionFormValues) => payload,
)();

export const contactSuggestionStatusChanged = createAction(
    'contact-suggestion/CONTACT_SUGGESTION_SUCCEEDED',
    (payload: CreateContactSuggestionResult | null) => payload,
)();

export const contactSuggestionReset = createAction(
    'contact-suggestion/CONTACT_SUGGESTION_RESET',
)();
