import { createSelector } from 'reselect';
import { get } from 'lodash';

import { RootStore } from '../../store/store';
import { TaskVolunteerStatusCode } from '../../interfaces/task-volunteer-status';
import { sortAscByKey } from '../../../shared/helpers/sortAscByKey';
import { ITask } from '../../interfaces/task';

import { TaskVolunteerStatusMessages } from './tasks.store';

const getTasksStore = (store: RootStore) => store.tasks;

const getTasks = createSelector(getTasksStore, (store) =>
    store.tasks.sort(sortAscByKey<ITask>('expirationDate')),
);

export const TasksSelectors = {
    getTasks,
};

export const getTaskVolunteerStatus = createSelector(
    getTasksStore,
    (store) => store.taskVolunteerStatus,
);

export const getTaskVolunteerStatusCode = createSelector(
    getTasksStore,
    (store) => get(store, 'taskVolunteerStatus.status', null),
);

export const getTaskVolunteerStatusMessage = createSelector(
    getTaskVolunteerStatusCode,
    (statusCode) =>
        statusCode &&
        TaskVolunteerStatusMessages[statusCode as TaskVolunteerStatusCode],
);
