import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../components/modal/modal';
import { ConnectedViolationReportForm } from '../violation-report-form/violation-report-form';
import { IViolationReportRequestDto } from '../../api/DTOs/violation-report-request.dto';

import styles from './violation-report-modal.module.scss';
import {
    IViolationReportModalModalRefProps,
    IViolationReportModalModalProps,
} from './violation-report-types';

export const ViolationReportModal = React.forwardRef<
    IViolationReportModalModalRefProps,
    IViolationReportModalModalProps
>((props, ref) => {
    const { t } = useTranslation();
    const [violationId, setViolationId] = useState<string>('');

    const { onConfirm, violationReportableType } = props;

    useImperativeHandle(ref, () => {
        return {
            openFor: setViolationId,
        };
    });

    const handleCancel = () => {
        setViolationId('');
    };

    const handleConfirm = (submitValues: IViolationReportRequestDto) => {
        onConfirm(submitValues);
        setViolationId('');
    };

    return (
        <Modal
            onClose={handleCancel}
            open={Boolean(violationId)}
            title={t('violationReport.modalTitle')}
            classname={styles.Modal}
            overlayClassname={styles.Overlay}
        >
            <ConnectedViolationReportForm
                violationReportableType={violationReportableType}
                violationId={violationId}
                onCreate={handleConfirm}
            />
        </Modal>
    );
});
