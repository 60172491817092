import React from 'react';
import cx from 'classnames';

import styles from './spinner.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {};

export const Spinner: React.FunctionComponent<Props> = ({
    className,
    ...props
}: Props) => {
    return (
        <div className={cx(styles.Spinner, className)} {...props}>
            <span />
        </div>
    );
};
