import { createReducer } from 'typesafe-actions';

import { IGalleryPhoto } from '../../interfaces/gallery-photo';
import { RootActions } from '../../store/actions';

import {
    galleryDataReceived,
    setDownloadingGalleryInProgress,
    setUploadPhotoInProgress,
    setUploadPhotoStatus,
} from './gallery.actions';

export enum UploadGalleryPhotoStatus {
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface IGalleryStore {
    downloadingGalleryInProgress: boolean;
    uploadPhotoInProgress: boolean;
    photos: IGalleryPhoto[];
    uploadPhotoStatus: UploadGalleryPhotoStatus | null;
}

const initialState: IGalleryStore = {
    downloadingGalleryInProgress: false,
    uploadPhotoInProgress: false,
    photos: [],
    uploadPhotoStatus: null,
};

export const galleryReducer = createReducer<IGalleryStore, RootActions>(
    initialState,
)
    .handleAction(galleryDataReceived, (state, action) => ({
        ...state,
        photos: action.payload.photos,
    }))
    .handleAction(setDownloadingGalleryInProgress, (state, action) => ({
        ...state,
        downloadingGalleryInProgress: action.payload,
    }))
    .handleAction(setUploadPhotoStatus, (state, action) => ({
        ...state,
        uploadPhotoStatus: action.payload,
    }))
    .handleAction(setUploadPhotoInProgress, (state, action) => ({
        ...state,
        uploadPhotoInProgress: action.payload,
    }));
