import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);
};

export const GApageView = (page: string, theme: string) => {
    ReactGA.set({ dimension1: theme });
    ReactGA.pageview(page);
};
