import React, { HTMLAttributes, useEffect } from 'react';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../../components/text-field/text-field';
import { ErrorMessage } from '../../../components/error-message/error-message';
import { ICanBeControlled } from '../use-form-controller';
import { PhoneInput } from '../../../components/phone-input/phone-input';

import styles from './delivery-recipient-form.module.scss';
import { IDeliveryRecipientFormValues } from './delivery-recipient-form-values';
import { DeliveryRecipientFormValidation } from './delivery-recipient-form-validation';

interface IOuterProps
    extends Omit<HTMLAttributes<HTMLFormElement>, 'onChange'>,
        ICanBeControlled<IDeliveryRecipientFormValues> {
    preselectedValues: IDeliveryRecipientFormValues | null;
}

interface IProps
    extends IOuterProps,
        FormikProps<IDeliveryRecipientFormValues> {}

const Form = ({
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleChange,
    values,
    onChange,
    errors,
    className,
    isValid,
    validateForm,
}: IProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        onChange(values, validateForm, isValid);
    }, [isValid, onChange, validateForm, values]);

    return (
        <form onSubmit={handleSubmit} className={cx(styles.Form, className)}>
            <div>
                <TextField
                    placeholder={t('flowers.delivery.recipientForm.nameLabel')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    data-testid="flowers-delivery-recipient-name"
                />
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </div>
            <div>
                <TextField
                    placeholder={t('flowers.delivery.recipientForm.emailLabel')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    data-testid="flowers-delivery-recipient-email"
                />
                {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </div>
            <div>
                <PhoneInput
                    placeholder={t('flowers.delivery.recipientForm.phoneLabel')}
                    onChange={(phone) => setFieldValue('phone', phone)}
                    value={values.phone}
                    name="phone"
                    data-testid="flowers-delivery-recipient-phone"
                    onBlur={handleBlur}
                />
                {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
            </div>
        </form>
    );
};

export const DeliveryRecipientForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IDeliveryRecipientFormValues>({
        mapPropsToValues({ preselectedValues }) {
            if (preselectedValues) {
                return preselectedValues;
            }

            return { phone: '', email: '', name: '' };
        },
        validationSchema: DeliveryRecipientFormValidation,
        validateOnMount: true,
        handleSubmit(values, bag) {},
    }),
)(Form);
