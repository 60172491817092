import { utcToZonedTime } from 'date-fns-tz';

import { IEventResponseDto } from '../../../api/DTOs/event-response.dto';
import { IMemorialEvent } from '../../../interfaces/memorial-event';
import { IEventTypeResponseDto } from '../../../api/DTOs/event-type-response.dto';
import { ILocationResponseDto } from '../../../api/DTOs/location-response.dto';
import { IMemorialPageDataPublicResponseDto } from '../../../api/DTOs/memorial-page-data-public-response.dto';
import { sortAscByKey } from '../../../../shared/helpers/sortAscByKey';

export const mapMemorialPageToEvents = (
    memorialPageData: IMemorialPageDataPublicResponseDto,
): IMemorialEvent[] => {
    const events = memorialPageData.included.filter(
        (inclusion) => inclusion.type === 'event',
    ) as IEventResponseDto[];

    return events
        .map((event: IEventResponseDto) => {
            const { id } = event;
            const eventTypeRelation = event.relationships.schedulable.data;
            const {
                description,
                schedulableType,
                startTimestamp,
                timeZone,
                ...otherEventAttributes
            } = event.attributes;

            const relatedEventType = memorialPageData.included.find(
                (inclusion) => {
                    return (
                        inclusion.type === eventTypeRelation.type &&
                        inclusion.id === eventTypeRelation.id
                    );
                },
            ) as IEventTypeResponseDto;

            const relatedLocationData = memorialPageData.included.find(
                (inclusion) =>
                    inclusion.type === 'location' &&
                    inclusion.id ===
                        relatedEventType.relationships.location.data.id,
            ) as ILocationResponseDto;

            return {
                id,
                date: startTimestamp
                    ? utcToZonedTime(startTimestamp, timeZone).toISOString()
                    : null,
                description: description || '',
                location: relatedLocationData?.attributes,
                type: schedulableType,
                schedulable: {
                    id: eventTypeRelation.id,
                    type: schedulableType,
                },
                ...otherEventAttributes,
            };
        })
        .sort(sortAscByKey<IMemorialEvent>('date'));
};
