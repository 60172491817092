import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FacebookButton } from '../components/facebook-button/facebook-button';
import { useFacebookSharing } from '../use-facebook-sharing';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';

import styles from './share-facebook-section.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {};

export const ShareFacebookSection = ({ className, ...props }: Props) => {
    const { t } = useTranslation();
    const theme = useSelector(getTheme);

    const { shareOnFacebook } = useFacebookSharing();

    return (
        <div className={cx(styles.Container, className)} {...props}>
            <FacebookButton
                className={styles.ShareButton}
                onClick={shareOnFacebook}
                theme={theme}
            >
                {t('share.facebookShareSectionButtonLabel')}
            </FacebookButton>
        </div>
    );
};
