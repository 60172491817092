import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';

import { getDeceasedDataFromMemorialPageFetchSaga } from './get-deceased-data-from-memorial-page-fetch.saga';

export function* deceasedPersonSaga() {
    yield takeEvery(
        getType(fetchPageActions.success),
        getDeceasedDataFromMemorialPageFetchSaga,
    );
}
