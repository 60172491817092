import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './deceased-avatar.module.scss';

interface IDeceasedAvatarProps
    extends Omit<HTMLAttributes<HTMLImageElement>, 'src'> {
    avatarSrc: string;
}

export const DeceasedAvatar = ({
    avatarSrc,
    className,
    ...props
}: IDeceasedAvatarProps) => (
    <img
        src={avatarSrc}
        alt=""
        className={cx(styles.DeceasedAvatar, className)}
        {...props}
    />
);
