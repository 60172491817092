import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NoteIcon } from '../../assets/icons/icon-dialog.svg';
import { ReactComponent as DateIcon } from '../../assets/icons/icon-time.svg';
import { ITask } from '../../interfaces/task';
import { useDateFormatter } from '../../utils/use-date-formatter';
import { SmallGrayButton } from '../../components/small-gray-button/small-gray-button';
import { EmailInput } from '../../events/components/email-input/email-input';
import { StatusMessage } from '../../components/status-message/status-message';
import { getUserLoggedStatus } from '../../model/user/selectors/get-user-logged-status.selector';
import { ITaskVolunteerData } from '../../interfaces/task-volunteer-data';
import {
    ITaskVolunteerStatus,
    TaskVolunteerStatusCode,
} from '../../interfaces/task-volunteer-status';
import StringToHtmlParser from '../../components/string-to-html-parser/string-to-html-parser';

import styles from './single-task.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
    onVolunteerClick(payload: ITaskVolunteerData): unknown;
    task: ITask;
    volunteerStatus: ITaskVolunteerStatus | null;
    volunteerStatusMessage: string | null;
};

export const SingleTask = ({
    className,
    onVolunteerClick,
    task,
    volunteerStatus,
    volunteerStatusMessage,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const logged = useSelector(getUserLoggedStatus);
    const { getLongDate } = useDateFormatter();

    const renderButtons = () =>
        logged ? (
            <SmallGrayButton
                onClick={() => onVolunteerClick({ taskId: task.id })}
            >
                {t('tasks.volunteerButton')}
            </SmallGrayButton>
        ) : (
            <EmailInput
                buttonLabel={t('tasks.volunteerButton')}
                onSubmit={(email) => {
                    onVolunteerClick({ taskId: task.id, email });
                }}
                placeholder={t('tasks.emailPlaceholder')}
            />
        );

    const showVolunteerStatusMessage =
        volunteerStatus && volunteerStatus.taskId === task.id;
    const isVolunteerStatusMessageError = Boolean(
        volunteerStatus &&
            volunteerStatus.status !== TaskVolunteerStatusCode.SUCCESS,
    );

    return (
        <div className={cx(className)} {...props}>
            <div>
                {task.expirationDate && (
                    <div className={styles.ListRow}>
                        <div className={styles.IconContainer}>
                            <DateIcon className={styles.Icon} />
                        </div>
                        <div className={styles.RowContent}>
                            {getLongDate(task.expirationDate)}
                        </div>
                    </div>
                )}
                {task.description && (
                    <div className={styles.ListRow}>
                        <div className={styles.IconContainer}>
                            <NoteIcon className={styles.Icon} />
                        </div>
                        <div className={styles.RowContent}>
                            <StringToHtmlParser
                                stringToParse={task.description}
                            />
                        </div>
                    </div>
                )}
                {!showVolunteerStatusMessage && renderButtons()}
                {showVolunteerStatusMessage && (
                    <StatusMessage error={isVolunteerStatusMessageError}>
                        {volunteerStatusMessage}
                    </StatusMessage>
                )}
            </div>
        </div>
    );
};
