import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { RoutePaths } from '../../routing/route-paths';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as DetailsIcon } from '../../assets/icons/details-icon.svg';
import { Section } from '../../components/section/section';
import { sendDonationActions } from '../../model/donation/donation.actions';
import { getSubmittedDonationDto } from '../../model/donation/selectors/get-submitted-donation-dto.selector';

import styles from './donation-confirmation.module.scss';

export const DonationConfirmation = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const submittedDonationDtoStore = useSelector(getSubmittedDonationDto);
    const [submittedDonationDtoState] = useState(submittedDonationDtoStore);

    const redirectToHomePage = () => {
        history.push(RoutePaths.HOME);
    };

    useEffect(() => {
        if (!submittedDonationDtoStore) {
            redirectToHomePage();
        }
        dispatch(sendDonationActions.cancel());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <Section className={styles.Section}>
            <div className={styles.Container}>
                <h1 className={styles.Headline}>
                    {t('donation.confirmation.headline')}
                </h1>
                <h2 className={styles.Message}>
                    {t('donation.confirmation.detailsSentTo')}{' '}
                    {submittedDonationDtoState?.giverEmail}
                </h2>
                <PrimaryButton
                    className={styles.Button}
                    icon={DetailsIcon}
                    onClick={redirectToHomePage}
                >
                    {t('donation.confirmation.actionButton')}
                </PrimaryButton>
            </div>
        </Section>
    );
};
