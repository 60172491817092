import { createAsyncAction } from 'typesafe-actions';

import { IFetchFlowersCartCalculationRequestDto } from '../../DTOs/fetch-flowers-cart-calculation-request.dto';
import { IFlowerSummaryCost } from '../../../interfaces/flower-summary-cost';
import { ICreateOrderRequestDto } from '../../DTOs/create-order-request.dto';
import { ICreatePaymentRequestDto } from '../../DTOs/create-payment-request.dto';
import { ICreatePaymentResponseDto } from '../../DTOs/create-payment-response.dto';
import { FlowersOrderStatus } from '../../../model/flowers/flowers-order-status';
import { ICreateOrderResponseDto } from '../../DTOs/create-order-response.dto';

export const fetchCartCalculationApiActions = createAsyncAction(
    'api/flowers/cart/FETCH_CALCULATION_REQUESTED',
    'api/flowers/cart/FETCH_CALCULATION_SUCCEEDED',
    'api/flowers/cart/FETCH_CALCULATION_FAILED',
)<IFetchFlowersCartCalculationRequestDto, IFlowerSummaryCost, string>();

export const createOrderApiActions = createAsyncAction(
    'api/flowers/cart/CREATE_ORDER_REQUESTED',
    'api/flowers/cart/CREATE_ORDER_SUCCEEDED',
    'api/flowers/cart/CREATE_ORDER_FAILED',
)<
    ICreateOrderRequestDto,
    ICreateOrderRequestDto & ICreateOrderResponseDto,
    { status: FlowersOrderStatus }
>();

export const createPaymentApiActions = createAsyncAction(
    'api/flowers/cart/CREATE_PAYMENT_REQUESTED',
    'api/flowers/cart/CREATE_PAYMENT_SUCCEEDED',
    'api/flowers/cart/CREATE_PAYMENT_FAILED',
)<ICreatePaymentRequestDto, ICreatePaymentResponseDto, string>();
