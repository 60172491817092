import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFacebookSharing } from '../../use-facebook-sharing';
import { SmallFacebookButton } from '../small-facebook-button/small-facebook-button';
import { ShareButton } from '../share-button/share-button';
import { webApiSharing } from '../../web-API-sharing';
import { getMemorialPageUrl } from '../../../model/configuration/selectors/get-memorial-page-url.selector';

import styles from './share-buttons.module.scss';

export const ShareButtons = () => {
    const { t } = useTranslation();
    const { shareOnFacebook } = useFacebookSharing();
    const memorialPageUrl = useSelector(getMemorialPageUrl);

    const [isShareApiSupported, setIsShareApiSupported] = useState<boolean>(
        false,
    );

    useEffect(() => {
        const nav = window.navigator as any;
        if ('share' in nav) {
            setIsShareApiSupported(true);
        }
    }, []);

    return (
        <>
            <SmallFacebookButton
                className={styles.ShareButton}
                onClick={shareOnFacebook}
            >
                {t('deceased.share')}
            </SmallFacebookButton>
            {isShareApiSupported && (
                <ShareButton
                    className={cx(styles.ShareButton, styles.SecondShare)}
                    onClick={() => {
                        webApiSharing({
                            url: memorialPageUrl,
                        });
                    }}
                >
                    {t('deceased.share')}
                </ShareButton>
            )}
        </>
    );
};
