import { useContext } from 'react';
import Rollbar from 'rollbar';

import { RollbarContext } from '../setup/rollbar-context';

export const useRollbar = (): { rollbar: Rollbar } => {
    const rollbar = useContext(RollbarContext);

    return { rollbar };
};
