import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './deceased-name.module.scss';

interface IDeceasedNameProps extends HTMLAttributes<HTMLHeadingElement> {
    name: string;
}

export const DeceasedName = ({
    name,
    className,
    ...props
}: IDeceasedNameProps) => {
    return (
        <h1 className={cx(styles.DeceasedName, className)} {...props}>
            {name}
        </h1>
    );
};
