import { createAction } from 'typesafe-actions';

import {
    IGalleryPhoto,
    IGalleryPhotoWithMetaData,
} from '../../interfaces/gallery-photo';

import { UploadGalleryPhotoStatus } from './gallery.store';

export const galleryDataReceived = createAction(
    'model/gallery/DATA_RECEIVED',
    (payload: { photos: IGalleryPhoto[] }) => payload,
)();

export const galleryPhotoUploadFormSubmitted = createAction(
    'model/gallery/PHOTO_UPLOAD_FORM_SUBMITTED',
    (payload: IGalleryPhotoWithMetaData) => payload,
)();

export const downloadGalleryRequested = createAction(
    'model/gallery/DOWNLOAD_GALLERY_REQUESTED',
)();

export const setDownloadingGalleryInProgress = createAction(
    'model/gallery/SET_DOWNLOADING_GALLERY_IN_PROGRESS',
    (payload: boolean) => payload,
)();

export const setUploadPhotoStatus = createAction(
    'model/gallery/SET_UPLOAD_PHOTO_STATUS',
    (payload: UploadGalleryPhotoStatus | null) => payload,
)();

export const setUploadPhotoInProgress = createAction(
    'model/gallery/SET_UPLOAD_PHOTO_IN_PROGRESS',
    (payload: boolean) => payload,
)();
