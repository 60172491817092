import { VIDEO_PLAYER_CURRENT_TIME_PRECISION } from './video-player.constants';

const getHours = (value: number) => Math.trunc((value / 60 / 60) % 60);
const getMinutes = (value: number) => Math.trunc((value / 60) % 60);
const getSeconds = (value: number) => Math.trunc(value % 60);

export const formatTime = (seconds = 0): string => {
    const format = (value: number) => `0${value}`.slice(-2);

    const hours = getHours(seconds);
    const mins = getMinutes(seconds);
    const secs = getSeconds(seconds);

    return `${hours > 0 ? `${hours}:` : ''}${format(mins)}:${format(secs)}`;
};

export const getFormattedCurrentTime = (
    currentTime?: number,
    duration?: number,
) => {
    if (!currentTime || !duration) {
        return '';
    }
    if (
        Math.abs(currentTime - duration) >= VIDEO_PLAYER_CURRENT_TIME_PRECISION
    ) {
        return `${formatTime(currentTime)} / ${formatTime(duration)}`;
    }
    return null;
};
