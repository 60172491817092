import { ITokensResponseDto } from '../../DTOs/tokens-response.dto';
import { apiService } from '../../api.service';
import { LoginErrorCode, LoginErrorsDto } from '../../DTOs/login-errors.dto';

type Payload = {
    email: string;
    password: string;
};

export const loginUser = ({
    email,
    password,
}: Payload): Promise<ITokensResponseDto> =>
    apiService
        .post(`/sessions`, {
            json: {
                auth: {
                    email,
                    password,
                },
            },
            /**
             * Disable error on non-200 to handle it manually and get error codes
             */
            throwHttpErrors: false,
        })
        .then(async (resp) => {
            if (resp.status === 401) {
                const respBody = await resp.json();

                return Promise.reject(respBody.errors);
            }

            return resp.json();
        })
        .then((resp) => resp.data.attributes)
        .catch((err: LoginErrorsDto | Error) => {
            if (Array.isArray(err)) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(err[0].code as LoginErrorCode);
            }

            return Promise.reject(LoginErrorCode.UNKNOWN);
        });
