import React from 'react';
import { isIOS } from 'react-device-detect';

import { VideoPlayerPlayMask } from './components/video-player-play-mask';
import { VideoPlayerPausedMask } from './components/video-player-paused-mask';
import { VideoPlayerReplayMask } from './components/video-player-replay-mask';
import { useVideoPlayerMask } from './use-video-player-mask';
import { IVideoPlayerMaskProps } from './video-player-mask.types';

export const VideoPlayerMask = (props: IVideoPlayerMaskProps) => {
    const {
        isReplayMaskVisible,
        isPlayMaskVisible,
        isPausedMaskVisible,
        pausedMaskText,
    } = useVideoPlayerMask(props);

    if (isIOS) {
        return null;
    }
    if (isReplayMaskVisible) {
        return <VideoPlayerReplayMask />;
    }
    if (isPausedMaskVisible) {
        return <VideoPlayerPausedMask maskText={pausedMaskText} />;
    }
    if (isPlayMaskVisible) {
        return <VideoPlayerPlayMask />;
    }

    return null;
};
