import React from 'react';
import cx from 'classnames';

import styles from './checkbox.module.scss';

type Props = React.HTMLAttributes<HTMLInputElement> & {
    checked?: boolean;
    label?: string;
    name: string;
    onChange(event: React.ChangeEvent): unknown;
    icon?: JSX.Element;
};

export const Checkbox = ({
    className,
    label,
    name,
    onChange,
    icon,
    ...props
}: Props) => {
    return (
        <label className={cx(styles.CheckboxContainer, className)}>
            <input
                className={styles.Checkbox}
                onChange={(e) => {
                    const event = {
                        ...e,
                        target: {
                            ...e.target,
                            name,
                            value: e.target.checked,
                        },
                    };

                    onChange(event);
                }}
                type="checkbox"
                {...props}
            />
            {label && <span className={styles.CheckboxLabel}>{label}</span>}
            {icon && <span className={styles.CheckboxIcon}>{icon}</span>}
        </label>
    );
};
