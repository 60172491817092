import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';

import {
    likeCondolenceSucceeded,
    unlikeCondolenceSucceeded,
    likeCondolenceCommentSucceeded,
    unlikeCondolenceCommentSucceeded,
} from './condolences.actions';

interface ICondolenceLike {
    id: string;
    likeToken?: string;
}
interface ICondolencesLikesStore {
    condolencesLikes: ICondolenceLike[];
    condolencesCommentsLikes: ICondolenceLike[];
}

const initialState: ICondolencesLikesStore = {
    condolencesLikes: [],
    condolencesCommentsLikes: [],
};

export const condolencesLikesReducer = createReducer<
    ICondolencesLikesStore,
    RootActions
>(initialState)
    .handleAction(likeCondolenceSucceeded, (state, action) =>
        action.payload.likeToken
            ? {
                  ...state,
                  condolencesLikes: [
                      ...state.condolencesLikes,
                      {
                          id: action.payload.id,
                          likeToken: action.payload.likeToken,
                      },
                  ],
              }
            : state,
    )
    .handleAction(unlikeCondolenceSucceeded, (state, action) =>
        action.payload.likeToken
            ? {
                  ...state,
                  condolencesLikes: state.condolencesLikes.filter(
                      (condolence) => condolence.id !== action.payload.id,
                  ),
              }
            : state,
    )
    .handleAction(likeCondolenceCommentSucceeded, (state, action) =>
        action.payload.likeToken
            ? {
                  ...state,
                  condolencesCommentsLikes: [
                      ...state.condolencesCommentsLikes,
                      {
                          id: action.payload.id,
                          likeToken: action.payload.likeToken,
                      },
                  ],
              }
            : state,
    )
    .handleAction(unlikeCondolenceCommentSucceeded, (state, action) =>
        action.payload.likeToken
            ? {
                  ...state,
                  condolencesCommentsLikes: state.condolencesCommentsLikes.filter(
                      (condolenceComment) =>
                          condolenceComment.id !== action.payload.id,
                  ),
              }
            : state,
    );
