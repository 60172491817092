import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    createOrderApiActions,
    createPaymentApiActions,
    fetchCartCalculationApiActions,
} from '../cart-api.actions';

import { fetchCartCalculationSaga } from './fetch-cart-calculation.saga';
import { createOrderApiSaga } from './create-order-api.saga';
import { createPaymentApiSaga } from './create-payment-api.saga';

export function* flowersCartApiSaga() {
    yield takeEvery(
        getType(fetchCartCalculationApiActions.request),
        fetchCartCalculationSaga,
    );
    yield takeEvery(getType(createOrderApiActions.request), createOrderApiSaga);
    yield takeEvery(
        getType(createPaymentApiActions.request),
        createPaymentApiSaga,
    );
}
