import { put } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { deathNoticeDataReceived } from '../death-notice.actions';
import { IDeathNotice } from '../../../interfaces/death-notice';

export function* getDeathNoticesDataFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const memorialPageData = action.payload;

    try {
        const deathNotices: IDeathNotice[] = memorialPageData.included
            .filter((inclusion) => inclusion.type === 'death_notice')
            .map((data: any) => ({
                url: data.attributes.url,
                publisherName: data.attributes.publisherName,
                publicationDate: data.attributes.publicationDate,
                previewUrl: data.attributes.previewUrl,
            }));

        yield put(
            deathNoticeDataReceived({
                deathNotices,
            }),
        );
    } catch (e) {
        /**
         * TODO: Handle missing data and assume privacy mode is set
         */
        console.log('Error getting death notice from memorial page');
        console.error(e);
    }
}
