import { apiService } from '../../api.service';
import { IGalleryPhotoDto } from '../../DTOs/gallery-photo.dto';
import { IGalleryPhotoMetaData } from '../../../interfaces/gallery-photo';

export const uploadPhoto = ({
    slug,
    meta,
    s3StringifyImageResponse,
}: {
    slug: string;
    meta: IGalleryPhotoMetaData;
    s3StringifyImageResponse: string;
}): Promise<IGalleryPhotoDto> => {
    return apiService
        .post(`/public/memorial_pages/${slug}/suggestions`, {
            json: {
                suggestable: {
                    published: true,
                    image: s3StringifyImageResponse,
                    ...meta,
                },
                suggestableType: 'GalleryPhoto',
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data);
};
