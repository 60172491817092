import { LanguageCode } from './get-language-codes';

export const getTimeLanguagePrefix = (lang: string) => {
    switch (lang) {
        case LanguageCode.PL:
            return ', godz. ';
        case LanguageCode.NO:
            return ', kl. ';
        default:
            return ', ';
    }
};
