import { createAction } from 'typesafe-actions';

import { IInvitationFormValues } from '../invitation-form-values';
import { IInvitationCheckResponseDto } from '../../api/DTOs/invitation-check-response.dto';

export const invitationTokenNotFound = createAction(
    'invitation/TOKEN_NOT_FOUND',
)();

export const invitationTokenDetected = createAction(
    'invitation/TOKEN_DETECTED',
    (payload: { token: string }) => payload,
)();

export const invitationTokenInvalid = createAction(
    'invitation/TOKEN_INVALID',
)();

export const invitationFormSubmitted = createAction(
    'invitation/FORM_SUBMITTED',
    (payload: IInvitationFormValues) => payload,
)();

export const invitationSessionReceived = createAction(
    'invitation/SESSION_RECEIVED',
    (payload: IInvitationCheckResponseDto) => payload,
)();
