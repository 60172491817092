import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { languageChangeRequested } from '../configuration.actions';
import { fetchPageActions } from '../../../api/page/page-api.actions';

import { handleLanguageChangeRequested } from './handle-language-change.requested';
import { getLanguageFromMemorialPage } from './get-language-from-memorial-page-fetch.saga';

export function* configurationSaga() {
    yield takeEvery(
        getType(languageChangeRequested),
        handleLanguageChangeRequested,
    );
    yield takeEvery(
        getType(fetchPageActions.success),
        getLanguageFromMemorialPage,
    );
}
