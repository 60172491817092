import { call, put } from '@redux-saga/core/effects';

import { sendViolationReportApiActions } from '../violation-reports-api.actions';
import { sendViolationReports } from '../http/send-violation-report';
import { mapViolationsReportDtoToViolationReport } from '../mappers/map-violation-reports-dto-to-violation-reports';

export function* sendViolationReportsApiSaga(
    action: ReturnType<typeof sendViolationReportApiActions.request>,
) {
    const response = yield call(sendViolationReports, {
        dto: action.payload,
    });

    if (!response.errors) {
        const createdReport = mapViolationsReportDtoToViolationReport(
            response.data,
        );

        yield put(sendViolationReportApiActions.success(createdReport));
    } else {
        yield put(sendViolationReportApiActions.failure(response.errors));
    }
}
