import { pl, nb, enGB, de, fr, arDZ, sv, nl } from 'date-fns/locale';

import { SupportedLanguageCode } from '../app/interfaces/supported-language-code';

const languagesDict = {
    en: enGB,
    pl,
    nb,
    de,
    fr,
    ar: arDZ,
    sv,
    nl,
};

export const getDateLocale = (languageCode: SupportedLanguageCode) =>
    languagesDict[languageCode];
