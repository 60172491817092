import React, { HTMLAttributes, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { IMemorialEvent } from '../../interfaces/memorial-event';
import { Collapse } from '../../components/collapse/collapse';
import { SingleEventDisplay } from '../single-event-display/single-event-display';
import { ILeaflet } from '../../interfaces/leaflet';
import { IDeathNotice } from '../../interfaces/death-notice';
import { DownloadsItem } from '../../gallery/downloads-item/downloads-item';
import { SmallGrayButton } from '../../components/small-gray-button/small-gray-button';
import styles from '../single-event-display/single-event-display.module.scss';
import { SectionHeader } from '../../components/section-header/section-header';
import { getClosestUpcomingEvent } from '../../../shared/helpers/getClosestUpcomingEvent';

import { useAttendEvent } from './use-attend-event';

const COLLAPSED_SECTION_HEIGHT = 130;

type Props = HTMLAttributes<HTMLDivElement> & {
    events: IMemorialEvent[];
    leaflets: ILeaflet[];
    deathNotices: IDeathNotice[];
    publicMessageForPrivateCeremony: string | null;
    isUserLoggedIn: boolean;
    ceremonyCreated: boolean;
    fullWidth: boolean;
};

export const EventsDisplay = ({
    events,
    leaflets,
    deathNotices,
    className,
    publicMessageForPrivateCeremony,
    isUserLoggedIn,
    ceremonyCreated,
    fullWidth,
}: Props) => {
    const [openEventId, setOpenEventId] = useState(
        getClosestUpcomingEvent(events)?.id,
    );
    const [DNExpanded, setDNExpanded] = useState(false);

    const {
        attendEventRequested,
        attendanceStatus,
        attendanceStatusMessage,
    } = useAttendEvent();

    const { t } = useTranslation();

    const downloads = ceremonyCreated
        ? deathNotices
        : [...deathNotices, ...leaflets];

    const DNPreviewRef = useRef<HTMLDivElement>(null);

    const collapseSection = () => {
        if (DNPreviewRef.current) {
            const sectionHeight = DNPreviewRef.current.scrollHeight;
            const actualTransition = DNPreviewRef.current.style.transition;
            DNPreviewRef.current.style.transition = '';
            requestAnimationFrame(() => {
                if (DNPreviewRef.current) {
                    DNPreviewRef.current.style.height = `${sectionHeight}px`;
                    DNPreviewRef.current.style.transition = actualTransition;
                }

                requestAnimationFrame(() => {
                    if (DNPreviewRef.current) {
                        DNPreviewRef.current.style.height = `${COLLAPSED_SECTION_HEIGHT}px`;
                    }
                });
            });
        }
    };

    const expandSection = () => {
        if (DNPreviewRef.current) {
            const sectionHeight = DNPreviewRef.current.scrollHeight;
            DNPreviewRef.current.style.height = `${sectionHeight}px`;
            DNPreviewRef.current.style.height = 'none';
        }
    };

    const sectionExpandHandler = () => {
        if (DNExpanded) {
            collapseSection();
        } else {
            expandSection();
        }
        setDNExpanded(!DNExpanded);
    };

    const setDNPreviewAutoHeight = () => {
        if (DNPreviewRef.current) {
            DNPreviewRef.current.style.height = 'auto';
        }
    };

    useEffect(() => {
        if (DNExpanded) {
            window.addEventListener(
                'orientationchange',
                setDNPreviewAutoHeight,
            );
        } else {
            window.removeEventListener(
                'orientationchange',
                setDNPreviewAutoHeight,
            );
        }

        return () =>
            window.removeEventListener(
                'orientationchange',
                setDNPreviewAutoHeight,
            );
    }, [DNExpanded]);

    const isDownloadsSectionVisible = downloads.length > 0;
    const isTwoColumnsLayout = fullWidth && isDownloadsSectionVisible;
    return (
        <div
            className={cx(className, styles.EventsContainer, {
                [styles.TwoColumn]: isTwoColumnsLayout,
            })}
        >
            <div>
                <SectionHeader>{t('events.title')}</SectionHeader>
                {publicMessageForPrivateCeremony && !isUserLoggedIn && (
                    <Collapse
                        headerText={publicMessageForPrivateCeremony}
                        open
                        onToggle={() => {}}
                        divider
                        disableArrow
                    />
                )}
                {events.length > 0 &&
                    events.map((event, index) => (
                        <Collapse
                            headerText={event.title}
                            open={event.id === openEventId}
                            onToggle={() => setOpenEventId(event.id)}
                            divider={index !== events.length - 1}
                            key={event.id}
                            disableArrow={events.length === 1}
                        >
                            <SingleEventDisplay
                                attendanceStatus={attendanceStatus}
                                attendanceStatusMessage={
                                    attendanceStatusMessage
                                }
                                event={event}
                                onAttendClick={attendEventRequested}
                                leaflets={leaflets.map((el, idx) => {
                                    return {
                                        url: el.url,
                                        title:
                                            leaflets.length === 1
                                                ? t('downloads.leaflet')
                                                : `${t(
                                                      'downloads.leaflet',
                                                  )}_${idx}`,
                                    };
                                })}
                            />
                        </Collapse>
                    ))}
            </div>
            {isDownloadsSectionVisible && (
                <div
                    className={cx(styles.Downloads, {
                        [styles.Divider]:
                            downloads.length > 0 && events.length > 0,
                    })}
                >
                    <>
                        <div
                            className={styles.DowloadsPreview}
                            ref={DNPreviewRef}
                        >
                            <div className={styles.DNInnerContainer}>
                                <button
                                    className={styles.DNPreviewContainer}
                                    onClick={sectionExpandHandler}
                                >
                                    {downloads &&
                                        downloads.length > 0 &&
                                        downloads.map((download, idx) => (
                                            <DownloadsItem
                                                key={idx}
                                                image={download}
                                                containerClassname={
                                                    styles.DownloadsContainer
                                                }
                                                imageClassname={
                                                    styles.DownloadsImage
                                                }
                                                bottomTitle
                                            />
                                        ))}
                                </button>
                                <div
                                    className={cx(styles.Fade, {
                                        [styles.expanded]: !!DNExpanded,
                                    })}
                                />
                            </div>
                        </div>
                        <SmallGrayButton
                            onClick={sectionExpandHandler}
                            className={styles.CollapseButton}
                        >
                            {DNExpanded
                                ? t('downloads.showLess')
                                : t('downloads.showAll')}
                        </SmallGrayButton>
                    </>
                </div>
            )}
        </div>
    );
};
