import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { Container } from '../../components/container/container';
import { Section } from '../../components/section/section';
import { RoutePaths } from '../../routing/route-paths';
import { getTokenFromUrl } from '../../utils/get-token-from-url';
import { ConnectedSetNewPasswordForm } from '../../forgotten-password/set-new-password-form/set-new-password-form';
import { ISetNewPasswordFormValues } from '../../forgotten-password/set-new-password-form-values';
import { PrivacyMode } from '../../interfaces/privacy-mode';
import { setNewPasswordFormSubmitted } from '../../forgotten-password/model/forgotten-password.actions';
import { getPagePrivacyMode } from '../../model/configuration/selectors/get-page-privacy-mode.selector';

import styles from './set-new-password-page.module.scss';

type Props = RouteComponentProps;

export const SetNewPasswordPage = (props: Props) => {
    const [token, setToken] = useState<string | null>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation<{ email?: string }>();
    const history = useHistory();
    const privacyMode = useSelector(getPagePrivacyMode);

    useEffect(() => {
        if (location.pathname !== RoutePaths.SET_NEW_PASSWORD) {
            return;
        }

        const tokenInUrl = getTokenFromUrl();

        if (!tokenInUrl) {
            history.push(RoutePaths.HOME);

            return;
        }

        setToken(tokenInUrl);
    }, [dispatch, history, location.pathname]);

    const onFormSubmitted = (values: ISetNewPasswordFormValues) => {
        dispatch(
            setNewPasswordFormSubmitted({
                password: values.password,
                token: token!,
            }),
        );
    };

    return (
        <>
            {privacyMode !== PrivacyMode.PRIVATE && <DeceasedDisplay />}
            <Container>
                <Section
                    centerHeader
                    headerText={t('setNewPassword.header')}
                    className={styles.Form}
                >
                    <ConnectedSetNewPasswordForm onSubmit={onFormSubmitted} />
                </Section>
            </Container>
        </>
    );
};
