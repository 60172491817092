import { createReducer } from 'typesafe-actions';

import { LoginErrorCode } from '../../api/DTOs/login-errors.dto';
import { RootActions } from '../../store/actions';

import { loginApiErrorReceived } from './login-page.actions';

interface ILoginPageStore {
    globalError: LoginErrorCode | null;
}

const initialState: ILoginPageStore = {
    globalError: null,
};

export const loginPageReducer = createReducer<ILoginPageStore, RootActions>(
    initialState,
).handleAction(loginApiErrorReceived, (state, action) => ({
    ...state,
    globalError: action.payload.error,
}));
