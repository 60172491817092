import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';
import { fetchFlowerShopApiActions } from '../../api/flowers/flower-shop/flower-shop-api.actions';
import { IFlowerShop } from '../../interfaces/flower-shop';
import { FlowerDeadlineType } from '../../interfaces/flower-deadline';

export interface IFlowerShopStore extends IFlowerShop {}

const initialState: IFlowerShopStore = {
    products: [],
    categories: [],
    deadline: FlowerDeadlineType.NONE,
    customDeadline: null,
    bandExamples: [],
};

export const flowerShopReducer = createReducer<IFlowerShopStore, RootActions>(
    initialState,
)
    .handleAction(fetchFlowerShopApiActions.success, (state, action) => {
        return action.payload;
    })
    .handleAction(fetchFlowerShopApiActions.failure, () => {
        return initialState;
    });
