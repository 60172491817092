import React from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import cx from 'classnames';

import { IGalleryPhoto } from '../../../../interfaces/gallery-photo';
import { useImageCarouselFullscreenContext } from '../../../image-carousel/contexts/fullscreen-context/use-fullscreen-context';
import { checkIfURLIsVideo } from '../../../image-carousel/image-carousel.helpers';
import { ImageContainer } from '../../../image-container/image-container';

import styles from './image-carousel-gallery-item.module.scss';
import { renderImgTitle } from './image-carousel-gallery-item-helpers';

type Props = React.HTMLAttributes<HTMLImageElement> & {
    image: IGalleryPhoto;
    onClick: () => void;
};

export const ImageCarouselGalleryItem = ({ image, onClick }: Props) => {
    const {
        enterFullscreen,
        isFullscreen,
    } = useImageCarouselFullscreenContext();

    const handleOnClick = () => {
        if (!isFullscreen) {
            onClick();
            enterFullscreen();
        }
    };

    const isVideoPreview = checkIfURLIsVideo(image.originalUrl);

    const title = renderImgTitle(image);
    const { description } = image;

    return (
        <div onClick={handleOnClick}>
            <div className={styles.ImageContainer}>
                {isVideoPreview && (
                    <div className={styles.PlayIcon}>
                        <PlayCircleOutlineIcon />
                    </div>
                )}
                <ImageContainer
                    src={image.previewUrl || image.originalUrl}
                    containerClassName={styles.ImageInnerContainer}
                    imageClassName={cx(styles.Image, {
                        [styles.DarkBackgroundFilter]: checkIfURLIsVideo(
                            image.originalUrl,
                        ),
                    })}
                />
            </div>
            {title && <div className={styles.TitleContainer}>{title}</div>}
            {description && (
                <div className={styles.DescriptionContainer}>{description}</div>
            )}
        </div>
    );
};
