import React from 'react';
import cx from 'classnames';

import { Icon } from '../../../../../../components/icon/icon';

import { ISocialItemProps } from './social-item-types';
import styles from './social-item.module.scss';
import { SocialItemLabel } from './social-item-label';

export const SocialItem: React.FC<ISocialItemProps> = ({
    like,
    label,
    icon,
    count,
    onClick,
    selected,
}) => {
    return (
        <div
            className={cx(
                styles.Container,
                selected && styles.Selected,
                like && styles.Like,
            )}
            onClick={onClick}
        >
            <span className={styles.Icon}>
                <Icon type={icon} />
            </span>
            <SocialItemLabel label={label} count={count} />
        </div>
    );
};
