import { createSelector } from 'reselect';

import { IFlowerShop } from '../../../interfaces/flower-shop';

import { getFlowerShopStore } from './get-flower-shop.selector';

export const getFlowerShopProductsSelector = createSelector(
    getFlowerShopStore,
    (store): IFlowerShop['products'] => store.products,
);
