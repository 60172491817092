import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';

import { ICondolenceComment } from '../../interfaces/condolence';
import { condolenceCommentRequested } from '../../model/condolences/condolences.actions';

import { commentCondolenceFormValidation } from './comment-condolence-form-validation';

export const useCommentCondolenceModal = () => {
    const dispatch = useDispatch();

    const [condolenceId, setCondolenceId] = useState<string>('');

    const handleClose = () => {
        setCondolenceId('');
    };

    const handleConfirm = (
        formState: ICondolenceComment,
        helpers: FormikHelpers<ICondolenceComment>,
    ) => {
        dispatch(
            condolenceCommentRequested({
                condolenceId,
                comment: formState,
            }),
        );
        helpers.resetForm();
        setCondolenceId('');
    };

    const initialFormState: Omit<ICondolenceComment, 'createdAt'> = {
        body: '',
        signature: '',
    };

    const formLogic = useFormik({
        initialValues: initialFormState,
        onSubmit: handleConfirm,
        validationSchema: commentCondolenceFormValidation,
    });

    return {
        condolenceId,
        handleClose,
        handleConfirm,
        setCondolenceId,
        formLogic,
    };
};
