import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const Search = ({ className, ...props }: Props) => {
    return (
        /**
         * Component need to be designed and implemented for VG MINNESIDER design
         */
        <div />
    );
};
