import { PaymentMethod, PaymentProvider } from './payment.types';
import { dinteroService } from './dintero/dintero.service';
import { stripeService } from './stripe/stripe.service';

export abstract class PaymentService {
    static getPaymentProviderByPaymentMethod(paymentMethod?: PaymentMethod) {
        switch (paymentMethod) {
            case PaymentMethod.VIPPS:
                return PaymentProvider.DINTERO;
            default:
                return PaymentProvider.STRIPE;
        }
    }

    static initByPaymentProvider(paymentProvider: PaymentProvider) {
        if (paymentProvider === PaymentProvider.DINTERO) {
            return dinteroService;
        }
        return stripeService;
    }

    static initByPaymentMethod(paymentMethod?: PaymentMethod) {
        const paymentProvider: PaymentProvider = PaymentService.getPaymentProviderByPaymentMethod(
            paymentMethod,
        );
        return PaymentService.initByPaymentProvider(paymentProvider);
    }
}
