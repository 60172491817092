import React, { HTMLAttributes } from 'react';

import { ReactComponent as BasketIcon } from '../../assets/icons/basket-icon.svg';

import styles from './basket.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    productsInBasketNo: number;
}

export const Basket = ({ productsInBasketNo, ...props }: IProps) => {
    return (
        <div className={styles.BasketContainer} {...props}>
            <BasketIcon />
            <span>{productsInBasketNo}</span>
        </div>
    );
};
