import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import ReactImageMagnify from 'react-image-magnify';
import { useTranslation } from 'react-i18next';

import { loadImage } from './image-magnifier.helpers';
import styles from './image-magnifier.module.scss';

export type ImageMagnifierProps = {
    src: string;
    imagePortalId: string;
    imagePortalRef: React.RefObject<HTMLDivElement>;
};

export const ImageMagnifier = ({
    src,
    imagePortalRef,
    imagePortalId,
}: ImageMagnifierProps) => {
    const { t } = useTranslation();

    const [imageDimensions, setImageDimensions] = useState({
        width: 0,
        height: 0,
    });
    const [imagePortalDimensions, setImagePortalDimensions] = useState({
        width: 0,
        height: 0,
    });

    const updateImageDimensions = (img: HTMLImageElement) => {
        setImageDimensions({
            height: img.height,
            width: img.width,
        });
    };

    const resizeFunction = useCallback(() => {
        if (imagePortalRef?.current) {
            setImagePortalDimensions({
                width: imagePortalRef.current.clientWidth,
                height: imagePortalRef.current.clientHeight,
            });
        }
    }, [imagePortalRef]);

    useEffect(() => {
        loadImage(src, updateImageDimensions);
    }, [src]);

    useEffect(() => {
        resizeFunction();
        const throttledResize = throttle(resizeFunction, 1000);
        window.addEventListener('resize', throttledResize);
        return () => window.removeEventListener('resize', throttledResize);
    }, [resizeFunction]);

    const imageMagnifierCommonProps = {
        smallImage: {
            src,
            isFluidWidth: true,
        },
        largeImage: {
            src,
            width: imageDimensions.width * 2,
            height: imageDimensions.height * 2,
        },
        imageClassName: styles.Image,
        enlargedImageContainerClassName: styles.EnlargedImageContainer,
        className: styles.ImageContainer,
        isHintEnabled: true,
        hintTextMouse: t('common.hoverToZoom'),
        hintTextTouch: t('common.longTouchToZoom'),
    };

    const imageMagnifierDesktopProps = {
        ...imageMagnifierCommonProps,
        enlargedImagePortalId: imagePortalId,
        enlargedImageContainerDimensions: imagePortalDimensions,
        enlargedImagePosition: 'beside',
    };

    const imageMagnifierMobileProps = {
        ...imageMagnifierCommonProps,
        enlargedImagePosition: 'over',
        hintTextMouse: t('common.longTouchToZoom'),
    };

    return (
        <>
            <div className={cx(styles.ImageWrapper, styles.ImageWrapperMobile)}>
                <ReactImageMagnify {...imageMagnifierMobileProps} />
            </div>
            <div
                className={cx(styles.ImageWrapper, styles.ImageWrapperDesktop)}
            >
                <ReactImageMagnify {...imageMagnifierDesktopProps} />
            </div>
        </>
    );
};
