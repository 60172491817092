import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './deadline-bar.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    startTime: number;
};

export const DeadlineBar = ({ startTime }: Props) => {
    const { t } = useTranslation();

    const [leftMinutes, setLeftMinutes] = useState(Math.floor(startTime / 60));
    const [leftSeconds, setLeftSeconds] = useState(startTime % 60);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval>;
        let secInterval: ReturnType<typeof setInterval>;
        if (startTime > 0) {
            secInterval = setInterval(() => {
                setLeftSeconds((seconds) => seconds - 1);
            }, 1000);
            if (leftSeconds === 0) {
                clearInterval(secInterval);
                interval = setInterval(() => {
                    setLeftMinutes((minutes) => minutes - 1);
                }, 60000);
            }
        }

        return () => {
            clearInterval(interval);
            clearInterval(secInterval);
        };
    }, [leftSeconds, startTime]);

    return (
        <>
            {leftMinutes > 0 || leftSeconds > 0 ? (
                <div className={styles.Container}>
                    {t('flowerShop.deadlineBarInfo', {
                        minutes: leftSeconds ? leftMinutes + 1 : leftMinutes,
                    })}
                </div>
            ) : (
                <div
                    className={cx(styles.Container, { [styles.passed]: true })}
                >
                    {t('flowerShop.deadlinePassed')}
                </div>
            )}
        </>
    );
};
