import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './tabs.module.scss';

interface ITabsProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    activeIndex: number;
    onChange(index: number): unknown;
    tabs: string[];
}

export const Tabs = ({
    className,
    tabs,
    activeIndex,
    onChange,
    ...props
}: ITabsProps) => {
    return (
        <div
            className={cx(styles.Container, className)}
            style={{
                gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
            }}
            {...props}
        >
            {tabs.map((tab, index) => (
                <button
                    onClick={() => onChange(index)}
                    key={tab}
                    className={cx(styles.Tab, {
                        [styles.Active]: activeIndex === index,
                    })}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
};
