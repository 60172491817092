import React from 'react';
import { useFullScreenHandle } from 'react-full-screen';
import { isIOS } from 'react-device-detect';

import { useBooleanState } from '../../../shared/hooks/useBooleanState';
import { useDisableScrolling } from '../../../shared/hooks/useDisableScrolling';

import { IImageCarouselFullscreenContext } from './contexts/fullscreen-context/fullscreen-context.types';

export const useImageCarouselFullscreen = () => {
    const {
        state: isPseudoFullscreen,
        handleSetAsTrue: enterPseudoFullscreen,
        handleSetAsFalse: exitPseudoFullscreen,
    } = useBooleanState(false);
    const { enableScrolling, disableScrolling } = useDisableScrolling();
    const nativeFullscreenHandle = useFullScreenHandle();

    const handleEnterPseudoFullscreen = React.useCallback(() => {
        disableScrolling();
        enterPseudoFullscreen();
    }, [disableScrolling, enterPseudoFullscreen]);
    const handleExitPseudoFullscreen = React.useCallback(() => {
        enableScrolling();
        exitPseudoFullscreen();
    }, [enableScrolling, exitPseudoFullscreen]);

    const contextForNativeFullscreen: IImageCarouselFullscreenContext = {
        isFullscreen: nativeFullscreenHandle.active,
        enterFullscreen: nativeFullscreenHandle.enter,
        exitFullscreen: nativeFullscreenHandle.exit,
        isNativeFullscreen: true,
    };
    const contextForPseudoFullscreen: IImageCarouselFullscreenContext = {
        isFullscreen: isPseudoFullscreen,
        enterFullscreen: handleEnterPseudoFullscreen,
        exitFullscreen: handleExitPseudoFullscreen,
        isNativeFullscreen: false,
    };

    const fullscreenContext: IImageCarouselFullscreenContext = isIOS
        ? contextForPseudoFullscreen
        : contextForNativeFullscreen;

    return {
        nativeFullscreenHandle,
        fullscreenContext,
    };
};
