import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';

import enTranslations from '../locales/en/translations.json';
import plTranslations from '../locales/pl/translations.json';
import nbTranslations from '../locales/nb/translations.json';
import deTranslations from '../locales/de/translations.json';
import frTranslations from '../locales/fr/translations.json';
import nlTranslations from '../locales/nl/translations.json';
import svTranslations from '../locales/sv/translations.json';
import arTranslations from '../locales/ar/translations.json';

setLocale({
    mixed: {
        default: 'error.invalidField',
        required: 'error.requiredField',
    },
    string: {
        email: 'error.invalidEmail',
        max: 'error.tooLong',
        min: 'error.tooShort',
    },
});

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: enTranslations,
        },
        pl: {
            translation: plTranslations,
        },
        nb: {
            translation: nbTranslations,
        },
        de: {
            translation: deTranslations,
        },
        fr: {
            translation: frTranslations,
        },
        nl: {
            translation: nlTranslations,
        },
        sv: {
            translation: svTranslations,
        },
        ar: {
            translation: arTranslations,
        },
    },
});

export default i18n;
