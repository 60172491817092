import { createSelector } from 'reselect';
import i18n from 'i18next';

import { CondolenceStatus } from '../condolences.store';

import { getCondolenceStatus } from './get-condolence-status.selector';

export const getCondolenceStatusMessage = createSelector(
    getCondolenceStatus,
    (status) => {
        if (!status) return null;

        switch (status) {
            case CondolenceStatus.INAPPROPRIATE_WORDS:
                return i18n.t('condolenceStatus.inappropriateWords');
            case CondolenceStatus.MEDIA_FILE_UPLOAD_ERROR:
                return i18n.t('condolenceStatus.mediaFileUploadError');
            default:
                return i18n.t('error.server');
        }
    },
);
