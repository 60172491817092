import React, { useState } from 'react';
import cx from 'classnames';

import { IFlower } from '../../../interfaces/flower';
import {
    IVariantPickerFlowerVariant,
    VariantPicker,
} from '../../components/variant-picker/variant-picker';
import { useFlowerPhoto } from '../../hooks/use-flower-photo';
import { getInitiallySelectedVariant } from '../../helpers/flower-helpers';

import styles from './flower-type.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    item: IFlower;
    onVariantChosen?(variantID: string): unknown;
};

export const FlowerType = ({ item, onVariantChosen, ...props }: Props) => {
    const [
        hoveredVariant,
        setHoveredVariant,
    ] = useState<IVariantPickerFlowerVariant | null>(null);

    const pickVariant = () => {
        if (onVariantChosen) {
            onVariantChosen(getInitiallySelectedVariant(item.variants).id);
        }
    };

    const { imageUrl, isPlaceholderPhoto } = useFlowerPhoto({
        product: item,
        hoveredVariant,
    });

    return (
        <div className={styles.FlowerType} {...props}>
            <div className={styles.FlowerTypeBox}>
                <div className={styles.FlowerTypeImageContainer}>
                    <div
                        className={styles.FlowerTypeImageWrapper}
                        onClick={pickVariant}
                    >
                        {!!imageUrl && (
                            <img
                                alt={item.name}
                                className={cx(
                                    styles.FlowerTypeImage,
                                    isPlaceholderPhoto &&
                                        styles.PlaceholderPhoto,
                                )}
                                src={imageUrl}
                            />
                        )}
                        {!imageUrl && (
                            <span
                                className={cx(
                                    styles.FlowerTypeImage,
                                    styles.NoSrc,
                                )}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.FlowerTypeName}>
                    <span>{item.name}</span>
                </div>
            </div>
            <VariantPicker
                className={styles.FlowerTypeVariants}
                items={item.variants}
                onSelectItem={(variant) => {
                    if (onVariantChosen) {
                        onVariantChosen(variant.id);
                    }
                }}
                onHover={setHoveredVariant}
                itemsLimit={3}
            />
        </div>
    );
};
