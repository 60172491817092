import { delay, put, take } from '@redux-saga/core/effects';

import { volunteerTaskApiActions } from '../../../api/tasks/tasks-api.actions';
import {
    volunteerTaskRequested,
    setTaskVolunteerStatus,
} from '../tasks.actions';

export function* volunteerTaskSaga(
    action: ReturnType<typeof volunteerTaskRequested>,
) {
    yield put(volunteerTaskApiActions.request(action.payload));

    const result = yield take([
        volunteerTaskApiActions.success,
        volunteerTaskApiActions.failure,
    ]);

    yield put(setTaskVolunteerStatus(result.payload));
    yield delay(3000);
    yield put(setTaskVolunteerStatus(null));
}
