import { createSelector } from 'reselect';

import { IFlowerShop } from '../../../interfaces/flower-shop';

import { getFlowerShopStore } from './get-flower-shop.selector';

export const getFlowerShopDeadlineSelector = createSelector(
    getFlowerShopStore,
    (store): IFlowerShop['customDeadline'] | IFlowerShop['deadline'] =>
        store.customDeadline ?? store.deadline,
);
