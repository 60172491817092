import { createReducer } from 'typesafe-actions';

import { AccessRequestStatus } from '../access-request-status';
import { RootActions } from '../../store/actions';
import { setAccessRequestStatus } from '../access-request.actions';

interface IAccessRequestStore {
    accessRequestStatus: AccessRequestStatus | null;
}

const initialState = {
    accessRequestStatus: null,
};

export const accessRequestReducer = createReducer<
    IAccessRequestStore,
    RootActions
>(initialState).handleAction(setAccessRequestStatus, (state, action) => ({
    ...state,
    accessRequestStatus: action.payload,
}));
