import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { take } from 'lodash';

import { getDecodedText } from '../../../../shared/helpers/getDecodedText';

import styles from './examples-picker.module.scss';

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
    examples: string[];
    onChange(text: string): unknown;
    selectedExample?: string;
};

const maxCountWithoutExtending = 10;

export const ExamplesPicker = ({
    examples,
    className,
    onChange,
    selectedExample,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<boolean>(false);

    const filteredExamples = expanded
        ? examples
        : take(examples, maxCountWithoutExtending);

    const showToggle = examples.length > maxCountWithoutExtending;

    return (
        <div className={cx(styles.Examples, className)} {...props}>
            {filteredExamples.map((example) => (
                <div
                    className={cx(styles.Example, {
                        [styles.ExampleSelected]: selectedExample === example,
                    })}
                    key={example}
                    onClick={() => onChange(example)}
                >
                    {getDecodedText(example)}
                </div>
            ))}
            {showToggle && (
                <div
                    className={styles.Toggle}
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded
                        ? t('flowers.details.showLess')
                        : t('flowers.details.showMore')}
                </div>
            )}
        </div>
    );
};
