import { IPaymentService, PaymentProvider } from '../payment.types';

export class StripeService extends IPaymentService {
    stripe: Stripe;

    constructor() {
        super(PaymentProvider.STRIPE);

        this.stripe = (window as any).Stripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
        );
    }

    redirectToCheckout(sessionId: string) {
        return this.stripe.redirectToCheckout({ sessionId });
    }
}

export const stripeService = new StripeService();
