import React from 'react';

export enum ModalSize {
    Regular = 'Regular',
    Wide = 'Wide',
}

export interface IModalProps {
    onClose(): unknown;
    open: boolean;
    children?: React.ReactNode;
    title?: string;
    classname?: string;
    overlayClassname?: string;
    size?: ModalSize;
    shouldCloseOnOverlayClick?: boolean;
}
