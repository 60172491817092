import * as yup from 'yup';

export const getPasswordValidation = () =>
    yup
        .string()
        .test({
            name: 'oneUpper',
            message: 'error.upperLetterRequired',
            test: (value: string | undefined) =>
                RegExp(/[A-Z]/).test(String(value)),
        })
        .test({
            name: 'oneLower',
            message: 'error.lowerLetterRequired',
            test: (value: string | undefined) =>
                RegExp(/[a-z]/).test(String(value)),
        })
        .test({
            name: 'digit',
            message: 'error.digitRequired',
            test: (value: string | undefined) =>
                RegExp(/[0-9]/).test(String(value)),
        })
        .min(6);
