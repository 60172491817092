import { createSelector } from 'reselect';

import { IFlowerVariant } from '../../../interfaces/flower-variant';

import { getFlowerShopProductSelector } from './get-flower-shop-product.selector';

export const getFlowerShopProductPopularVariantsSelector = (
    productId?: string | null,
) =>
    createSelector(
        getFlowerShopProductSelector(productId),
        (product): IFlowerVariant[] => {
            return product
                ? product.variants.filter((variant) => variant.isPopular)
                : [];
        },
    );
