import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Modal } from '../../components/modal/modal';
import { FileUploader } from '../../components/file-uploader/file-uploader';
import { ConnectedGalleryPhotoMetadataForm } from '../gallery-photo-metadata-form/gallery-photo-metadata-form';
import { IGalleryPhotoMetadataFormValues } from '../gallery-photo-metadata-form-values';
import { IGalleryPhotoWithMetaData } from '../../interfaces/gallery-photo';
import { IModalProps } from '../../components/modal/modal-types';

import styles from './gallery-photo-upload-modal.module.scss';

type Props = IModalProps & {
    onConfirm(payload: IGalleryPhotoWithMetaData): void;
};

export const GalleryPhotoUploadModal = ({
    onClose,
    onConfirm,
    open,
}: Props) => {
    const { t } = useTranslation();
    const [photo, setPhoto] = useState<File | null>(null);

    const onSubmit = (values: IGalleryPhotoMetadataFormValues) => {
        const payload = {
            file: photo!,
            meta: values,
        };

        if (photo) {
            onConfirm(payload);
        }
    };

    const hansleSetPhoto = (files: File[]) => {
        if (files.length) {
            setPhoto(files[0]);
        }
    };

    return (
        <Modal
            onClose={onClose}
            open={open}
            title={t('galleryPhotoUploadModal.title')}
        >
            <div className={styles.FormContainer}>
                <FileUploader
                    label={t('galleryPhotoUploadModal.fileUplaoderLabel')}
                    onChange={hansleSetPhoto}
                    openOnRender
                />
                <span className={styles.FileUplaoderHint}>
                    {t('galleryPhotoUploadModal.fileUploaderHint')}
                </span>
                <span
                    className={cx(
                        styles.FileUplaoderHint,
                        styles.FileUplaoderHintMobile,
                    )}
                >
                    {t('galleryPhotoUploadModal.fileUploaderHintMobile')}
                </span>
                <ConnectedGalleryPhotoMetadataForm
                    onSubmit={onSubmit}
                    isPhotoUploaded={!!photo}
                />
            </div>
        </Modal>
    );
};
