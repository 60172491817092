import React, { HTMLAttributes, useRef } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { ComposedNavbar } from '../navbar/navbar';
import { EmptyFooter, Footer } from '../footer/footer';
import { getHasUserAccess } from '../../model/user/selectors/get-has-user-access.selector';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import AcceptCookies from '../../accept-cookies/accept-cookies';
import { useNavigationLinks } from '../../../shared/hooks/useNavigationLinks';

import styles from './layout.module.scss';

interface ILayoutProps extends HTMLAttributes<HTMLDivElement> {}

export const Layout = ({ children }: ILayoutProps) => {
    const theme = useSelector(getTheme);
    const hasAccessToPage = useSelector(getHasUserAccess);
    const navigationLinks = useNavigationLinks();

    const navbarRef = useRef<HTMLDivElement>(null);
    const navbarHeight = navbarRef.current?.firstElementChild?.clientHeight;

    return (
        <>
            <main className={cx(styles.Layout, styles[theme])}>
                <div ref={navbarRef}>
                    <ComposedNavbar
                        className={cx(styles.Navbar, styles[theme])}
                        links={navigationLinks}
                    />
                </div>
                {children}
            </main>
            {hasAccessToPage ? <Footer /> : <EmptyFooter />}
            <AcceptCookies navbarHeight={navbarHeight} />
        </>
    );
};
