import React from 'react';

import { ISocialItemLabelProps } from './social-item-types';
import { useSocialItemLabel } from './use-social-item-label';

export const SocialItemLabel = (props: ISocialItemLabelProps) => {
    const {
        mobileValues,
        desktopValues,
        labelClassesMobile,
        labelClassesDesktop,
        countClasses,
    } = useSocialItemLabel(props);
    return (
        <>
            {!!mobileValues.count && (
                <span className={labelClassesMobile}>{mobileValues.count}</span>
            )}
            <span className={labelClassesDesktop}>
                {desktopValues.label}
                {!!desktopValues.count && (
                    <span className={countClasses}>{desktopValues.count}</span>
                )}
            </span>
        </>
    );
};

export default React.memo(SocialItemLabel);
