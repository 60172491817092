import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TextField } from '../../components/text-field/text-field';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import { IInvitationFormValues } from '../invitation-form-values';
import { invitationFormValidation } from '../invitation-form-validation';
import { FieldErrorMessage } from '../../components/error-message/error-message';
import { PrivacyMode } from '../../interfaces/privacy-mode';
import { getPagePrivacyMode } from '../../model/configuration/selectors/get-page-privacy-mode.selector';

import styles from './invitation-form.module.scss';

type OuterProps = {
    onSubmit(values: IInvitationFormValues): unknown;
    emailPrefill?: string | null;
} & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

type Props = FormikProps<IInvitationFormValues> & OuterProps;

export const InvitationForm = ({
    className,
    handleChange,
    handleSubmit,
    values,
    handleBlur,
    emailPrefill,
}: Props) => {
    const { t } = useTranslation();
    const privacyMode = useSelector(getPagePrivacyMode);

    return (
        <form
            className={cx(className, {
                [styles.Public]: privacyMode === PrivacyMode.PUBLIC,
            })}
            onSubmit={handleSubmit}
        >
            {privacyMode !== PrivacyMode.PUBLIC && (
                <p className={styles.HintText}>{t('register.hintText')}</p>
            )}
            <div className={styles.InputsContainer}>
                <div className={styles.Control}>
                    <TextField
                        name="email"
                        disabled={Boolean(emailPrefill)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('register.emailLabel')}
                        value={values.email}
                    />
                    <FieldErrorMessage name="email" />
                </div>
                <div className={styles.Control}>
                    <TextField
                        type="password"
                        className={styles.Control}
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('register.passwordLabel')}
                        value={values.password}
                    />
                    <FieldErrorMessage name="password" />
                </div>
                <div className={styles.Control}>
                    <TextField
                        type="password"
                        className={styles.Control}
                        name="repeatPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('register.repeatPasswordLabel')}
                        value={values.repeatPassword}
                    />
                    <FieldErrorMessage name="repeatPassword" />
                </div>
            </div>
            <PrimaryButton
                className={styles.SubmitButton}
                icon={ArrowIcon}
                type="submit"
            >
                {t('register.submitButton')}
            </PrimaryButton>
        </form>
    );
};

export const ConnectedInvitationForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IInvitationFormValues>({
        mapPropsToValues({ emailPrefill }) {
            return {
                email: emailPrefill || '',
                password: '',
                repeatPassword: '',
            };
        },
        handleSubmit(values, formikBag) {
            formikBag.props.onSubmit(values);
        },
        validationSchema: invitationFormValidation,
        enableReinitialize: true,
    }),
)(InvitationForm);
