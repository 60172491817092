import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { throttle } from 'lodash';
import { useSelector } from 'react-redux';

import { SecondaryButton } from '../../../components/secondary-button/secondary-button';
import { ReactComponent as FlowerIcon } from '../../../assets/icons/flower-icon.svg';
import { ReactComponent as BasketIcon } from '../../../assets/icons/basket-icon.svg';
import { getLanguage } from '../../../model/configuration/selectors/get-language.selector';
import { getTheme } from '../../../model/configuration/selectors/get-theme.selector';
import { useFlowerPhoto } from '../../hooks/use-flower-photo';
import { IFlower } from '../../../interfaces/flower';

import styles from './flower-row.module.scss';

const MIN_FLOWER_ROW_SIZE = 420;

type Props = {
    flower: IFlower;
    onButtonClick(): unknown;
};

export const FlowerRow = ({ flower, onButtonClick }: Props) => {
    const { t } = useTranslation();
    const language = useSelector(getLanguage);
    const theme = useSelector(getTheme);

    const [showBasketIcon, setShowBasketIcon] = useState(false);
    const [smallerSendFlowersButton, setSmallerSendFlowersButton] = useState(
        false,
    );

    const flowerContainerRef = useRef<HTMLButtonElement>(null);
    const sendFlowersButtonRef = useRef<HTMLButtonElement>(null);
    const flowerRowRef = useRef<HTMLDivElement>(null);

    const resizeFunction = () => {
        if (
            flowerRowRef.current &&
            flowerRowRef.current.clientWidth <= MIN_FLOWER_ROW_SIZE
        ) {
            setShowBasketIcon(true);
        } else {
            setShowBasketIcon(false);
        }
        if (
            flowerContainerRef.current &&
            sendFlowersButtonRef.current &&
            sendFlowersButtonRef.current.clientWidth >
                flowerContainerRef.current.clientWidth
        ) {
            setSmallerSendFlowersButton(true);
        } else {
            setSmallerSendFlowersButton(false);
        }
    };

    useEffect(() => {
        resizeFunction();
        const throttledResize = throttle(resizeFunction, 1000);
        window.addEventListener('resize', throttledResize);
        return () => window.removeEventListener('resize', throttledResize);
    }, []);

    useEffect(() => {
        resizeFunction();
    }, [language]);

    const { imageUrl } = useFlowerPhoto({ product: flower });

    return (
        <div className={cx(styles.FlowerRow, styles[theme])} ref={flowerRowRef}>
            <button
                className={cx(styles.FlowerBox, {
                    [styles.BasketIcon]: showBasketIcon,
                })}
                onClick={onButtonClick}
                ref={flowerContainerRef}
            >
                {!!imageUrl && (
                    <img alt="" className={styles.FlowerImage} src={imageUrl} />
                )}
                {!imageUrl && (
                    <span className={cx(styles.FlowerImage, styles.NoSrc)} />
                )}
                <span className={styles.FlowerName}>{flower.name}</span>
            </button>
            <span ref={sendFlowersButtonRef}>
                <SecondaryButton
                    className={cx(styles.OrderButton, {
                        [styles.BasketIcon]: showBasketIcon,
                        [styles.SmallerButton]: smallerSendFlowersButton,
                    })}
                    icon={showBasketIcon ? undefined : FlowerIcon}
                    onClick={onButtonClick}
                    data-testid="featured-flowers-shortcut-buy-button"
                    title={
                        showBasketIcon ? t('sendFlowers.sendFlowersButton') : ''
                    }
                >
                    {showBasketIcon ? (
                        <BasketIcon />
                    ) : (
                        t('sendFlowers.sendFlowersButton')
                    )}
                </SecondaryButton>
            </span>
        </div>
    );
};
