import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Section } from '../../components/section/section';
import {
    IGalleryPhoto,
    IGalleryPhotoWithMetaData,
} from '../../interfaces/gallery-photo';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { Spinner } from '../../components/spinner/spinner';
import { GalleryPhotoUploadModal } from '../gallery-photo-upload-modal/gallery-photo-upload-modal';
import {
    downloadGalleryRequested,
    galleryPhotoUploadFormSubmitted,
} from '../../model/gallery/gallery.actions';
import { getDownloadingGalleryInProgress } from '../../model/gallery/selectors/get-downloading-gallery-in-progress.selector';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';
import { getUploadPhotoStatusMessage } from '../../model/gallery/selectors/get-upload-photo-status-message.selector';
import { getUploadPhotoInProgress } from '../../model/gallery/selectors/get-upload-photo-in-progress.selector';
import { ImageCarouselGallery } from '../../components/image-carousel-gallery/image-carousel-gallery';

import styles from './gallery-display.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    photos: IGalleryPhoto[];
    withoutActions?: boolean;
    title?: string;
};

export const GalleryDisplay = React.memo(
    ({ className, photos, withoutActions, title, ...props }: Props) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [showModal, setShowModal] = useState<boolean>(false);
        const downloadingGalleryInProgress = useSelector(
            getDownloadingGalleryInProgress,
        );
        const uploadPhotoStatusMessage = useSelector(
            getUploadPhotoStatusMessage,
        );
        const uploadPhotoInProgress = useSelector(getUploadPhotoInProgress);

        const onConfirmUpload = (payload: IGalleryPhotoWithMetaData) => {
            dispatch(galleryPhotoUploadFormSubmitted(payload));
            setShowModal(false);
        };

        const downloadGallery = () => {
            dispatch(downloadGalleryRequested());
        };

        const sectionActions = withoutActions ? null : (
            <div className={styles.SectionActions}>
                {photos.length > 0 && (
                    <PrimaryButton
                        className={styles.SectionActionButton}
                        icon={
                            downloadingGalleryInProgress
                                ? Spinner
                                : DownloadIcon
                        }
                        onClick={downloadGallery}
                    >
                        {downloadingGalleryInProgress
                            ? t('gallery.loadingButton')
                            : t('gallery.downloadAllButton')}
                    </PrimaryButton>
                )}
                <PrimaryButton
                    className={styles.SectionActionButton}
                    icon={uploadPhotoInProgress ? Spinner : PlusIcon}
                    onClick={() => setShowModal(true)}
                >
                    {uploadPhotoInProgress
                        ? t('gallery.uploadingButton')
                        : t('gallery.addPhotoButton')}
                </PrimaryButton>
            </div>
        );

        const headerText = title || t('gallery.title');

        return (
            <Section
                className={className}
                headerClassName={styles.SectionHeader}
                headerText={headerText}
                actions={sectionActions}
                {...props}
            >
                {uploadPhotoStatusMessage && (
                    <p className={styles.InfoMessage}>
                        {uploadPhotoStatusMessage}
                    </p>
                )}
                {photos.length > 0 && <ImageCarouselGallery photos={photos} />}
                <GalleryPhotoUploadModal
                    onClose={() => setShowModal(false)}
                    onConfirm={onConfirmUpload}
                    open={showModal}
                />
            </Section>
        );
    },
);
