import { createSelector } from 'reselect';

import { getFlowerShopProductsSelector } from './get-flower-shop-products.selector';

export const getFlowerShopProductSelector = (productId?: string | null) =>
    createSelector(getFlowerShopProductsSelector, (products) => {
        return productId
            ? products.find((product) => product.id === productId)
            : null;
    });
