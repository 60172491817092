import { ILanguage } from '../interfaces/language';

import { getSlugFromLocation } from './parse-location';

const getStorageKey = () =>
    `${getSlugFromLocation(window.location)}: memorial-page-language`;

export const saveLanguageInStorage = (language: ILanguage) => {
    localStorage.setItem(getStorageKey(), JSON.stringify({ language }));
};

export const getLanguageFromStorage = () => {
    const language = localStorage.getItem(getStorageKey());

    return language && JSON.parse(language).language;
};
