import { put, take, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { loginFormSubmitted } from '../login-page.actions';
import { loginApiActions } from '../../../api/login/login-api.actions';
import { RoutePaths } from '../../../routing/route-paths';
import { fetchPageActions } from '../../../api/page/page-api.actions';
import { userEmailReceived } from '../../../model/user/user.actions';
import { saveEmailToStorage } from '../../../model/user/email-storage';

/**
 * TODO: Handle errors
 */
export function* loginFormSubmittedSaga(
    action: ReturnType<typeof loginFormSubmitted>,
) {
    const data = action.payload;

    yield put(loginApiActions.request(data));

    yield take(loginApiActions.success);

    yield put(
        userEmailReceived({
            email: data.email,
        }),
    );

    yield call(saveEmailToStorage, data.email);

    /**
     * After user is logged, fetch page again with possibly more data
     */
    yield put(fetchPageActions.request());

    yield take(fetchPageActions.success);

    yield put(push(RoutePaths.HOME));
}
