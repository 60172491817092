import { call, put, select, take } from '@redux-saga/core/effects';

import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { sendEventAttendanceApiActions } from '../event-attendance-api.actions';
import { sendEventAttendanceForm } from '../http/send-event-attendance-form';
import { fetchPageActions } from '../../page/page-api.actions';

export function* sendEventAttendanceApiSaga(
    action: ReturnType<typeof sendEventAttendanceApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const response = yield call(sendEventAttendanceForm, {
        slug,
        dto: action.payload,
    });

    if (!response.errors) {
        yield put(fetchPageActions.request());
        yield take(fetchPageActions.success);
        yield put(sendEventAttendanceApiActions.success(response.data));
    } else {
        yield put(
            sendEventAttendanceApiActions.failure(response.errors[0].title),
        );
    }
}
