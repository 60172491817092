import { getTokens } from './auth-token-storage';

export const attachAuthHeader = (headers: Headers) => {
    const newHeaders = new Headers(headers);

    const tokens = getTokens();

    if (!tokens) {
        return headers;
    }

    newHeaders.append('Authorization', `JWT ${tokens.accessToken}`);

    return newHeaders;
};
