import { createSelector } from 'reselect';

import { getCondolencesLikesStore } from './get-condolences-likes-store.selector';

export const getCondolenceLikeTokenByCondolenceId = (id) =>
    createSelector(
        getCondolencesLikesStore,
        (store) =>
            store.condolencesLikes.find(
                (condolencesLike) => condolencesLike.id === id,
            )?.likeToken,
    );
