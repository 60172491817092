import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './container.module.scss';

interface IContainerProps extends HTMLAttributes<HTMLDivElement> {}

export const Container = ({ className, ...props }: IContainerProps) => (
    <div className={cx(styles.Container, className)} {...props} />
);
