import { createReducer } from 'typesafe-actions';

import { IMilestone } from '../../interfaces/milestone';
import { RootActions } from '../../store/actions';

import { milestonesDataReceived } from './milestones.actions';

export interface IMilestonesStore {
    milestones: IMilestone[];
}

const initialState: IMilestonesStore = {
    milestones: [],
};

export const milestonesReducer = createReducer<IMilestonesStore, RootActions>(
    initialState,
).handleAction(milestonesDataReceived, (state, action) => ({
    ...state,
    milestones: action.payload.milestones,
}));
