import React from 'react';
import { useTranslation } from 'react-i18next';

import { FileUploader } from '../../file-uploader/file-uploader';

import { useImageUploadWithModal } from './use-image-upload-with-modal';
import { IImageUploadWithModalProps } from './image-upload-with-modal-types';

export const ImageUploadWithModal: React.FC<IImageUploadWithModalProps> = ({
    onChange,
    images,
    className,
    openOnRender,
}) => {
    const { t } = useTranslation();
    const { handleFileUpload } = useImageUploadWithModal(images, onChange);

    return (
        <FileUploader
            maxFiles={10}
            onChange={handleFileUpload}
            label={t('labels.addImage')}
            className={className}
            openOnRender={openOnRender}
            displayPreview={false}
        />
    );
};
