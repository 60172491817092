export enum EventAttendanceStatusCode {
    SUCCESS = 'success',
    EMAIL_ALREADY_USED = 'email_already_used',
    ERROR = 'error',
}

export interface IEventAttendanceStatus {
    eventId: string;
    status: EventAttendanceStatusCode;
}
