import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';

import {
    setVideoStreamPasswordError,
    videoStreamPasswordSend,
} from './video-stream.actions';

export enum VideoStreamError {
    INVALID_PASSWORD = 'INVALID_PASSWORD',
}

export interface IVideoStreamsStore {
    videoStreamUrl?: string;
    error?: VideoStreamError;
}

const initialState: IVideoStreamsStore = {
    videoStreamUrl: undefined,
    error: undefined,
};

export const videoStreamsReducer = createReducer<
    IVideoStreamsStore,
    RootActions
>(initialState)
    .handleAction(videoStreamPasswordSend, (state, action) => ({
        ...state,
        videoStreamUrl: action.payload.videoStreamUrl,
    }))
    .handleAction(setVideoStreamPasswordError, (state, action) => ({
        ...state,
        error: action.payload,
    }));
