import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { ILoginFormValues } from '../login-form-values';
import { loginFormValidation } from '../login-form-validation';
import { TextField } from '../../components/text-field/text-field';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import {
    ErrorMessage,
    FieldErrorMessage,
} from '../../components/error-message/error-message';

import styles from './login-form.module.scss';

type OuterProps = {
    onSubmit(values: ILoginFormValues): unknown;
    prefillEmail?: string | null;
    globalError?: string;
} & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

type Props = FormikProps<ILoginFormValues> & OuterProps;

export const LoginForm = ({
    className,
    handleChange,
    handleSubmit,
    values,
    errors,
    handleBlur,
    globalError,
    dirty,
    isValid,
}: Props) => {
    const { t } = useTranslation();
    const canSave = dirty && isValid;
    return (
        <form className={cx(className)} onSubmit={handleSubmit}>
            <div className={styles.InputsContainer}>
                <div className={styles.Control}>
                    <TextField
                        autoFocus
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('login.emailLabel')}
                        value={values.email}
                        error={Boolean(errors.email)}
                    />
                    <FieldErrorMessage name="email" />
                </div>
                <div className={styles.Control}>
                    <TextField
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('login.passwordLabel')}
                        value={values.password}
                    />
                    <FieldErrorMessage name="password" />
                </div>
                {globalError && <ErrorMessage>{globalError}</ErrorMessage>}
            </div>
            <PrimaryButton
                disabled={!canSave}
                className={styles.SubmitButton}
                icon={ArrowIcon}
                type="submit"
            >
                {t('login.submitButton')}
            </PrimaryButton>
        </form>
    );
};

export const ConnectedLoginForm = compose<Props, OuterProps>(
    withFormik<OuterProps, ILoginFormValues>({
        mapPropsToValues({ prefillEmail }) {
            return {
                email: prefillEmail || '',
                password: '',
            };
        },
        handleSubmit(values, formikBag) {
            formikBag.props.onSubmit(values);
        },
        validationSchema: loginFormValidation,
        enableReinitialize: true,
        isInitialValid({ globalError }) {
            return !globalError;
        },
    }),
)(LoginForm);
