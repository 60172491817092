import { IGalleryPhotoDto } from '../../../api/DTOs/gallery-photo.dto';
import { IGalleryPhoto } from '../../../interfaces/gallery-photo';
import { IMemorialPageDataPublicResponseDto } from '../../../api/DTOs/memorial-page-data-public-response.dto';

import { mapGalleryPhotoDtoToGalleryPhoto } from './map-gallery-photo-dto-to-gallery-photo';

export const mapMemorialPageToGalleryPhotos = (
    memorialPageData: IMemorialPageDataPublicResponseDto,
): IGalleryPhoto[] => {
    const galleryPhotos = memorialPageData.included.filter(
        (inclusion) => inclusion.type === 'gallery_photo',
    ) as IGalleryPhotoDto[];

    return galleryPhotos.map(mapGalleryPhotoDtoToGalleryPhoto);
};
