import { call, put, select } from '@redux-saga/core/effects';

import { unlikeCondolenceApiActions } from '../condolences-api.actions';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { unlikeCondolence } from '../http/unlike-condolence';
import { getCondolenceLikeTokenByCondolenceId } from '../../../model/condolences/selectors/get-condolence-like-token-by-condolence-id.selector';
import { getUserLoggedStatus } from '../../../model/user/selectors/get-user-logged-status.selector';

export function* unlikeCondolenceApiSaga(
    action: ReturnType<typeof unlikeCondolenceApiActions.request>,
) {
    const { id } = action.payload;

    const slug = yield select(getPageSlug);
    const logged = yield select(getUserLoggedStatus);
    const condolenceLikeToken = yield select(
        getCondolenceLikeTokenByCondolenceId(id),
    );

    const likeToken = !logged && condolenceLikeToken;

    try {
        const response = yield call(unlikeCondolence, { slug, id, likeToken });
        if (response.ok) {
            yield put(unlikeCondolenceApiActions.success({ likeToken }));
        } else {
            yield put(unlikeCondolenceApiActions.failure());
        }
    } catch (err) {
        yield put(unlikeCondolenceApiActions.failure());
    }
}
