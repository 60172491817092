import { createSelector } from 'reselect';

import i18n from '../../../../setup/i18n';
import { EventAttendanceStatusCode } from '../../../interfaces/event-attendance-status';

import { getEventAttendanceStatusCode } from './get-event-attendance-status-code.selector';

export const getEventAttendanceStatusMessage = createSelector(
    getEventAttendanceStatusCode,
    (statusCode) => {
        const EventAttendanceStatusMessages = {
            [EventAttendanceStatusCode.SUCCESS]: i18n.t(
                'eventAttendanceStatusMessages.success',
            ),
            [EventAttendanceStatusCode.EMAIL_ALREADY_USED]: i18n.t(
                'eventAttendanceStatusMessages.emailAlreadyUsed',
            ),
            [EventAttendanceStatusCode.ERROR]: i18n.t(
                'eventAttendanceStatusMessages.error',
            ),
        };

        return (
            statusCode &&
            EventAttendanceStatusMessages[
                statusCode as EventAttendanceStatusCode
            ]
        );
    },
);
