import { createSelector } from 'reselect';

import { IFlowerShop } from '../../../interfaces/flower-shop';

import { getFlowerShopStore } from './get-flower-shop.selector';

export const getFlowerShopBandExamplesSelector = createSelector(
    getFlowerShopStore,
    (store): IFlowerShop['bandExamples'] => store.bandExamples,
);
