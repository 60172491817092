import { IImageUploadWithModalItem } from '../../app/components/inputs/image-upload-with-modal/image-upload-with-modal-types';
import { IMediaFilesPostObject } from '../../app/interfaces/media-files';

export const mapIImageUploadItemToMediaFileAttribute = (
    uploadedImages?: IImageUploadWithModalItem[],
    s3Response?: string[],
): IMediaFilesPostObject[] => {
    const mediaFileAttributes: IMediaFilesPostObject[] = [];

    if (uploadedImages) {
        uploadedImages
            .filter((item) => {
                return item.isDeleted;
            })
            .forEach((item) => {
                mediaFileAttributes.push({
                    id: item.id,
                    _destroy: true,
                });
            });
    }

    if (s3Response) {
        s3Response.forEach((image) => {
            mediaFileAttributes.push({
                file: image,
            });
        });
    }

    return mediaFileAttributes;
};
