import { useCallback, useState } from 'react';

export const useBooleanState = (initialState?: boolean) => {
    const [state, setState] = useState(initialState ?? false);

    const handleSetAsTrue = useCallback(() => {
        setState(true);
    }, []);

    const handleSetAsFalse = useCallback(() => {
        setState(false);
    }, []);

    const handleToggle = useCallback(() => {
        setState(!state);
    }, [state]);

    return {
        state,
        handleSetAsTrue,
        handleSetAsFalse,
        handleToggle,
    };
};
