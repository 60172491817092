import { call, put, select } from '@redux-saga/core/effects';
import { find } from 'lodash';

import {
    createCondolenceApiActions,
    updateCondolenceApiActions,
} from '../condolences-api.actions';
import { updateCondolence } from '../http/update-condolence';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { CondolenceStatus } from '../../../model/condolences/condolences.store';
import { ICreatedCondolence } from '../../../interfaces/condolence';
import { deserializeApiResponseSaga } from '../../json-api/saga/deserializeApiResponse.saga';
import { fetchPageActions } from '../../page/page-api.actions';

export function* updateCondolenceApiSaga(
    action: ReturnType<typeof updateCondolenceApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const { id } = action.payload;

    const response = yield call(updateCondolence, {
        id,
        slug,
        dto: action.payload,
    });

    if (!response.errors) {
        const deserializedBody: ICreatedCondolence = yield call(
            deserializeApiResponseSaga,
            response,
        );

        yield put(updateCondolenceApiActions.success(deserializedBody));

        yield put(fetchPageActions.request());
    } else if (find(response.errors, { code: 'not_decent' })) {
        yield put(
            updateCondolenceApiActions.failure({
                status: CondolenceStatus.INAPPROPRIATE_WORDS,
            }),
        );
    } else if (find(response.errors, { code: 'is_blank' })) {
        yield put(
            createCondolenceApiActions.failure({
                status: CondolenceStatus.MEDIA_FILE_UPLOAD_ERROR,
            }),
        );
    } else {
        yield put(
            updateCondolenceApiActions.failure({
                status: response.errors[0].title,
            }),
        );
    }
}
