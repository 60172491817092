import { call, put, select } from 'redux-saga/effects';

import { fetchPageActions } from '../page-api.actions';
import { fetchMemorialPagePublicData } from '../http/fetch-memorial-page-public-data';
import { IMemorialPageDataPublicResponseDto } from '../../DTOs/memorial-page-data-public-response.dto';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { MemorialPageFetchErrorsDto } from '../../DTOs/memorial-page-fetch-errors.dto';
import { userAccessToMemorialDetected } from '../../../model/user/user.actions';
import { privacyModeDetected } from '../../../model/configuration/configuration.actions';
import { PrivacyMode } from '../../../interfaces/privacy-mode';
import { fetchCondolencesApiActions } from '../../condolences/condolences-api.actions';
import { fetchFlowerShopApiActions } from '../../flowers/flower-shop/flower-shop-api.actions';

export function* fetchPageSaga() {
    const slug = yield select(getPageSlug);

    try {
        yield put(fetchFlowerShopApiActions.request());
        yield put(fetchCondolencesApiActions.request());
        const response: IMemorialPageDataPublicResponseDto = yield call(
            fetchMemorialPagePublicData,
            slug,
        );

        yield put(fetchPageActions.success(response));
    } catch (err) {
        const errorStatus = err as MemorialPageFetchErrorsDto;

        yield put(fetchPageActions.failure(errorStatus));
        yield put(userAccessToMemorialDetected({ hasAccess: false }));

        /**
         * Detect if page is private 401
         */
        if (errorStatus === MemorialPageFetchErrorsDto.NOT_AUTHORIZED) {
            yield put(privacyModeDetected({ privacy: PrivacyMode.PRIVATE }));
        }
    }
}
