import { RouteProps } from 'react-router';

import { MainPage } from '../pages/main-page/main-page';
import { GalleryPage } from '../pages/gallery-page/gallery-page';
import { LoginPage } from '../pages/login-page/login-page';
import { SharePage } from '../pages/share-page/share-page';
import { ComposedInvitationPage } from '../pages/invitation-page/invitation-page';
import { SetNewPasswordPage } from '../pages/set-new-password-page/set-new-password-page';
import { AccessRequestPage } from '../pages/access-request-page/access-request-page';
import { FlowersPage } from '../pages/flowers/flowers-page';
import { FlowersConfirmationPage } from '../pages/flowers-confirmation-page/flowers-confirmation-page';
import { FlowersPaymentFailed } from '../pages/flowers-payment-failed/flowers-payment-failed';
import { VideoStreamPage } from '../pages/video-stream-page/video-stream-page';
import { EventAttendancePage } from '../pages/event-attendance/event-attendance-page';
import { DonationDetailsPage } from '../pages/donation-page/donation-details-page/donation-details-page';
import { DonationPaymentFailedPage } from '../pages/donation-page/donation-payment-failed-page/donation-payment-failed-page';
import { DonationConfirmationPage } from '../pages/donation-page/donation-confirmation-page/donation-confirmation-page';

import { RoutePaths } from './route-paths';

const OPEN_ROUTES: RouteProps[] = [
    {
        path: [RoutePaths.LOGIN, RoutePaths.FORGOT_PASSWORD],
        component: LoginPage,
        exact: true,
    },
    {
        path: RoutePaths.INVITATION,
        component: ComposedInvitationPage,
        exact: true,
    },
    {
        path: RoutePaths.REQUEST_ACCESS,
        component: AccessRequestPage,
        exact: true,
    },
    {
        path: RoutePaths.SET_NEW_PASSWORD,
        component: SetNewPasswordPage,
        exact: true,
    },
];

const PRIVATE_ROUTES: RouteProps[] = [
    {
        path: RoutePaths.HOME,
        component: MainPage,
        exact: true,
    },
    {
        path: RoutePaths.GALLERY,
        component: GalleryPage,
        exact: true,
    },
    {
        path: RoutePaths.VIDEO_STREAM,
        component: VideoStreamPage,
        exact: true,
    },
    {
        path: RoutePaths.EVENT_ATTENDANCE,
        component: EventAttendancePage,
        exact: true,
    },
    {
        path: RoutePaths.SHARE,
        component: SharePage,
        exact: true,
    },
];

const FLOWERS_ROUTES: RouteProps[] = [
    {
        path: [
            RoutePaths.FLOWERS_CATEGORY,
            RoutePaths.FLOWERS_DETAILS,
            RoutePaths.FLOWERS_TYPE,
            RoutePaths.FLOWERS_SUMMARY,
        ],
        component: FlowersPage,
        exact: true,
    },
    {
        path: RoutePaths.FLOWERS_CONFIRMATION,
        component: FlowersConfirmationPage,
        exact: true,
    },
    {
        path: RoutePaths.FLOWERS_PAYMENT_FAILED,
        component: FlowersPaymentFailed,
        exact: true,
    },
];

const DONATIONS_ROUTES: RouteProps[] = [
    {
        path: RoutePaths.DONATION_DETAILS,
        component: DonationDetailsPage,
        exact: true,
    },
    {
        path: RoutePaths.DONATION_CONFIRMATION,
        component: DonationConfirmationPage,
        exact: true,
    },
    {
        path: RoutePaths.DONATION_PAYMENT_FAILED,
        component: DonationPaymentFailedPage,
        exact: true,
    },
];

export const APPLICATION_ROUTES = {
    OPEN: [...OPEN_ROUTES],
    COMMON: [...OPEN_ROUTES, ...PRIVATE_ROUTES],
    FLOWERS: [...FLOWERS_ROUTES],
    DONATIONS: [...DONATIONS_ROUTES],
    get ALL() {
        return [...this.COMMON, ...this.FLOWERS, ...this.DONATIONS];
    },
};
