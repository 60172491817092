import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { createUserApiActions } from '../create-user.actions';

import { handleUserCreationInApiSaga } from './handle-user-creation-in-api.saga';

export function* createUserApiSaga() {
    yield takeEvery(
        getType(createUserApiActions.request),
        handleUserCreationInApiSaga,
    );
}
