import * as yup from 'yup';

import { MAX_CONDOLENCE_COMMENT_LENGTH } from './comment-condolence-constants';

export const commentCondolenceFormValidation = () =>
    yup.object().shape({
        signature: yup.string().required(),
        body: yup
            .string()
            .max(
                MAX_CONDOLENCE_COMMENT_LENGTH,
                'commentCondolenceModal.tooLongComment',
            )
            .required(),
    });
