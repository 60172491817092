import { put, race, take } from '@redux-saga/core/effects';

import { unlikeCondolenceCommentApiActions } from '../../../api/condolences/condolences-api.actions';
import {
    unlikeCondolenceCommentRequested,
    unlikeCondolenceCommentSucceeded,
} from '../condolences.actions';

export function* handleUnlikeCondolenceCommentSaga(
    action: ReturnType<typeof unlikeCondolenceCommentRequested>,
) {
    const { id } = action.payload;

    yield put(unlikeCondolenceCommentApiActions.request({ id }));

    const { success } = yield race({
        success: take(unlikeCondolenceCommentApiActions.success),
        failure: take(unlikeCondolenceCommentApiActions.failure),
    });

    if (success) {
        const { likeToken } = success.payload;
        yield put(unlikeCondolenceCommentSucceeded({ id, likeToken }));
    }
}
