import React, { useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Presets } from 'react-component-transition';

import { Modal } from '../../components/modal/modal';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-icon.svg';
import { TextField } from '../../components/text-field/text-field';
import { ErrorMessage } from '../../components/error-message/error-message';
import { TextArea } from '../../components/text-area/text-area';

import styles from './comment-condolence-modal.module.scss';
import { ICommentCondolenceModalRefProps } from './comment-condolence-modal-types';
import { useCommentCondolenceModal } from './use-comment-condolence-modal';
import { MAX_CONDOLENCE_COMMENT_LENGTH } from './comment-condolence-constants';

export const CommentCondolenceModal = React.forwardRef<
    ICommentCondolenceModalRefProps
>((props, ref) => {
    const { t } = useTranslation();

    const {
        condolenceId,
        handleClose,
        setCondolenceId,
        formLogic,
    } = useCommentCondolenceModal();

    useImperativeHandle(ref, () => {
        return {
            openFor: setCondolenceId,
        };
    });

    const canSave = formLogic.dirty && formLogic.isValid;

    return (
        <Modal
            onClose={handleClose}
            open={Boolean(condolenceId)}
            title={t('commentCondolenceModal.modalTitle')}
            classname={styles.Modal}
            overlayClassname={styles.Overlay}
        >
            <form onSubmit={formLogic.handleSubmit}>
                <Presets.TransitionFade>
                    <TextArea
                        autoFocus
                        name="body"
                        onChange={formLogic.handleChange}
                        onBlur={formLogic.handleBlur}
                        placeholder={t(
                            'commentCondolenceModal.bodyPlaceholder',
                        )}
                        rows={10}
                        value={formLogic.values.body}
                        maxLength={MAX_CONDOLENCE_COMMENT_LENGTH + 1}
                    />
                    <Presets.TransitionFade>
                        {formLogic.touched.body && formLogic.errors.body && (
                            <ErrorMessage>{formLogic.errors.body}</ErrorMessage>
                        )}
                    </Presets.TransitionFade>
                    <TextField
                        className={styles.SignatureField}
                        name="signature"
                        onChange={formLogic.handleChange}
                        onBlur={formLogic.handleBlur}
                        placeholder={t(
                            'commentCondolenceModal.signaturePlaceholder',
                        )}
                        value={formLogic.values.signature}
                    />
                    <Presets.TransitionFade>
                        {formLogic.touched.signature &&
                            formLogic.errors.signature && (
                                <ErrorMessage>
                                    {formLogic.errors.signature}
                                </ErrorMessage>
                            )}
                    </Presets.TransitionFade>
                    <div className={styles.ButtonsContainer}>
                        <PrimaryButton
                            icon={EditIcon}
                            type="submit"
                            disabled={!canSave}
                        >
                            {t('commentCondolenceModal.confirm')}
                        </PrimaryButton>
                    </div>
                </Presets.TransitionFade>
            </form>
        </Modal>
    );
});
