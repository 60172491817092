import { createAsyncAction } from 'typesafe-actions';

import { ICreateCondolenceDto } from '../DTOs/create-condolence.dto';
import { ICreatedCondolence } from '../../interfaces/condolence';
import { CondolenceStatus } from '../../model/condolences/condolences.store';
import { ICreatedCondolenceComment } from '../../condolences/comment-condolence-modal';
import { IUpdateCondolenceDto } from '../DTOs/update-condolence.dto';
import { ICondolenceResponseDto } from '../DTOs/condolence-response.dto';

export const createCondolenceApiActions = createAsyncAction(
    'api/create-condolence/REQUESTED',
    'api/create-condolence/SUCCEEDED',
    'api/create-condolence/FAILED',
)<ICreateCondolenceDto, ICreatedCondolence, { status: CondolenceStatus }>();

export const updateCondolenceApiActions = createAsyncAction(
    'api/update-condolence/REQUESTED',
    'api/update-condolence/SUCCEEDED',
    'api/update-condolence/FAILED',
)<IUpdateCondolenceDto, ICreatedCondolence, { status: CondolenceStatus }>();

export const deleteCondolenceApiActions = createAsyncAction(
    'api/delete-condolence/REQUESTED',
    'api/delete-condolence/SUCCEEDED',
    'api/delete-condolence/FAILED',
)<{ id: string }, undefined, string>();

export const addCondolenceCommentApiActions = createAsyncAction(
    'api/add-condolence-comment/REQUESTED',
    'api/add-condolence-comment/SUCCEEDED',
    'api/add-condolence-comment/FAILED',
)<ICreatedCondolenceComment, undefined, { status: CondolenceStatus }>();

export const fetchCondolencesApiActions = createAsyncAction(
    'api/get-condolences/REQUESTED',
    'api/get-condolences/SUCCEEDED',
    'api/get-condolences/FAILED',
)<undefined, ICondolenceResponseDto, { status: string }>();

export const likeCondolenceApiActions = createAsyncAction(
    'api/like-condolence/REQUESTED',
    'api/like-condolence/SUCCEEDED',
    'api/like-condolence/FAILED',
)<{ id: string }, { likeToken?: string }, undefined>();

export const unlikeCondolenceApiActions = createAsyncAction(
    'api/unlike-condolence/REQUESTED',
    'api/unlike-condolence/SUCCEEDED',
    'api/unlike-condolence/FAILED',
)<{ id: string }, { likeToken?: string }, undefined>();

export const likeCondolenceCommentApiActions = createAsyncAction(
    'api/like-condolence-comment/REQUESTED',
    'api/like-condolence-comment/SUCCEEDED',
    'api/like-condolence-comment/FAILED',
)<{ id: string }, { likeToken?: string }, undefined>();

export const unlikeCondolenceCommentApiActions = createAsyncAction(
    'api/unlike-condolence-comment/REQUESTED',
    'api/unlike-condolence-comment/SUCCEEDED',
    'api/unlike-condolence-comment/FAILED',
)<{ id: string }, { likeToken?: string }, undefined>();
