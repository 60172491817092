import { put } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import { invitationCheckApiActions } from '../../../api/invitation-check/invitation-check-api.actions';
import { RoutePaths } from '../../../routing/route-paths';
import { invitationTokenInvalid } from '../invitation.actions';

export function* handleInvitationValidateFailedSaga(
    action: ReturnType<typeof invitationCheckApiActions.failure>,
) {
    /**
     * TODO: Probably redirect to some specific error page
     */
    yield put(push(RoutePaths.HOME));

    yield put(invitationTokenInvalid());
}
