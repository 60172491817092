import { put, take } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import {
    invitationSessionReceived,
    invitationTokenDetected,
} from '../invitation.actions';
import { invitationCheckApiActions } from '../../../api/invitation-check/invitation-check-api.actions';
import { RoutePaths } from '../../../routing/route-paths';

export function* handleDetectedInvitationTokenSaga(
    action: ReturnType<typeof invitationTokenDetected>,
) {
    yield put(
        invitationCheckApiActions.request({
            token: action.payload.token,
        }),
    );

    const resp: ReturnType<typeof invitationCheckApiActions.success> = yield take(
        invitationCheckApiActions.success,
    );

    yield put(invitationSessionReceived(resp.payload));

    if (resp.payload.isUserPersisted) {
        yield put(push(RoutePaths.LOGIN, { email: resp.payload.email }));
    }
}
