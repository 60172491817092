import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from '@redux-saga/core';
import { StateType } from 'typesafe-actions';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { browserHistory } from '../routing/browser-history';
import { deceasedPersonReducer } from '../model/deceased-person/deceased-person.store';
import { configurationReducer } from '../model/configuration/configuration.store';
import { eventsReducer } from '../model/events/events.store';
import { milestonesReducer } from '../model/milestones/milestones.store';
import { galleryReducer } from '../model/gallery/gallery.store';
import { invitationReducer } from '../invitation/model/invitation.store';
import { loginPageReducer } from '../login/model/login-page.store';
import { condolencesReducer } from '../model/condolences/condolences.store';
import { condolencesLikesReducer } from '../model/condolences/condolences-likes.store';
import { contactSuggestionReducer } from '../contact-suggestion/model/contact-suggestion.store';
import { userReducer } from '../model/user/user.store';
import { accessRequestReducer } from '../access-request/model/access-request.store';
import { tasksReducer } from '../model/tasks/tasks.store';
import { flowersCombinedReducer } from '../model/flowers/flowers.store';
import { deathNoticesReducer } from '../model/death-notice/death-notice.store';
import { LeafletsReducer } from '../model/leaflets/leaflets.store';
import { getSlugFromLocation } from '../utils/parse-location';
import { eventAttendancePageReducer } from '../event-attendance/model/event-attendance-page.store';
import { videoStreamsReducer } from '../model/video-stream/video-stream.store';
import { cookiesAcceptedReducer } from '../model/cookies-accepted/cookies-accepted.store';
import { flowerShopReducer } from '../model/flower-shop/flower-shop.store';
import { donationReducer } from '../model/donation/donation.store';

import { runSagas } from './sagas';
import { RootActions } from './actions';

export const sagaMiddleware = createSagaMiddleware();

const slug = getSlugFromLocation(window.location);

export const rootReducers = {
    router: connectRouter(browserHistory),
    deceasedPerson: deceasedPersonReducer,
    configuration: configurationReducer,
    events: eventsReducer,
    milestones: milestonesReducer,
    gallery: galleryReducer,
    invitation: invitationReducer,
    loginPage: loginPageReducer,
    condolences: condolencesReducer,
    condolencesLikes: persistReducer(
        {
            key: `${slug}/condolencesLikes`,
            storage,
        },
        condolencesLikesReducer,
    ),
    contactSuggestion: contactSuggestionReducer,
    user: userReducer,
    accessRequest: accessRequestReducer,
    tasks: tasksReducer,
    deathNotices: deathNoticesReducer,
    leaflets: LeafletsReducer,
    flowers: persistReducer(
        {
            key: `${slug}/flowershop2`,
            storage,
        },
        flowersCombinedReducer,
    ),
    flowerShop: persistReducer(
        {
            key: `${slug}/flowershop3`,
            storage,
        },
        flowerShopReducer,
    ),
    donation: persistReducer(
        {
            key: `${slug}/donation`,
            storage,
        },
        donationReducer,
    ),
    eventAttendance: eventAttendancePageReducer,
    videoStream: videoStreamsReducer,
    cookiesAccepted: persistReducer(
        {
            key: `${slug}/cookiesAccepted`,
            storage,
        },
        cookiesAcceptedReducer,
    ),
};

export const createRootReducers = () => combineReducers(rootReducers);

const rootReducer = createRootReducers();

export type RootStore = StateType<typeof rootReducer>;

const initReduxStore = () =>
    createStore<RootStore, RootActions, unknown, unknown>(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(sagaMiddleware, routerMiddleware(browserHistory)),
        ),
    );

export const store = initReduxStore();
export const persistor = persistStore(store);

runSagas(sagaMiddleware);
