const PUBLIC_URLS: Record<string, string> = {
    default: process.env.PUBLIC_URL,
    'vg.no': process.env.PUBLIC_URL_VG,
    'www.vg.no': process.env.PUBLIC_URL_VG,
    localhost: process.env.PUBLIC_URL_VG,
};

const REACT_APP_EDITOR_URLS: Record<string, string> = {
    default: process.env.REACT_APP_EDITOR_URL,
    'vg.no': process.env.REACT_APP_EDITOR_URL_VG,
    'www.vg.no': process.env.REACT_APP_EDITOR_URL_VG,
    localhost: process.env.REACT_APP_EDITOR_URL_VG,
};

function getUrl(urlSet: Record<string, string>, currentUrl?: string): string {
    if (!currentUrl) {
        return urlSet.default;
    }

    const parsedUrl = new URL(currentUrl);

    const url = urlSet[parsedUrl.hostname];

    if (!url) {
        return urlSet.default;
    }
    return url;
}

export const getPublicUrl = (currentUrl?: string) => {
    return getUrl(PUBLIC_URLS, currentUrl);
};

export const getNotifyBaseUrl = (currentUrl?: string) => {
    return getUrl(REACT_APP_EDITOR_URLS, currentUrl);
};
