import React from 'react';
import '@vime/core/themes/default.css';
import Carousel from 'react-images';
import cx from 'classnames';
import { FullScreen } from 'react-full-screen';

import styles from './image-carousel.module.scss';
import { IImageCarouselProps } from './image-carousel.types';
import { useImageCarousel } from './use-image-carousel';
import { checkIfImageCarouselPropsAreEqual } from './image-carousel.helpers';
import { ImageCarouselFullscreenContext } from './contexts/fullscreen-context/fullscreen-context';

const ImageCarousel = React.memo((props: IImageCarouselProps) => {
    const {
        isReady,
        carouselProps,
        fullscreenContext,
        nativeFullscreenHandle,
    } = useImageCarousel(props);

    const imageCarouselContainerStyles = cx(styles.ImageCarousel, {
        [styles.Fullscreen]:
            fullscreenContext.isFullscreen &&
            !fullscreenContext.isNativeFullscreen,
    });

    if (!isReady) {
        return null;
    }

    return (
        <FullScreen handle={nativeFullscreenHandle}>
            <ImageCarouselFullscreenContext.Provider value={fullscreenContext}>
                <div className={imageCarouselContainerStyles}>
                    <Carousel {...carouselProps} />
                </div>
            </ImageCarouselFullscreenContext.Provider>
        </FullScreen>
    );
}, checkIfImageCarouselPropsAreEqual);

export default ImageCarousel;
