import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { ConditionalWrapper } from '../../shared/helpers/conditionalWrapper';

import { useRouting } from './use-routing';
import { RoutePaths } from './route-paths';

export const Routing = () => {
    const {
        isWaitingForTheme,
        hasAccessToThePage,
        routesConfig,
    } = useRouting();

    if (isWaitingForTheme) {
        return <div />;
    }

    if (!hasAccessToThePage) {
        return <Redirect to={RoutePaths.LOGIN} />;
    }

    return (
        <>
            {routesConfig.map((config) => {
                const rootPathWrapper = (childPaths) => {
                    return <Route path={config.rootPath}>{childPaths}</Route>;
                };

                return (
                    <Switch key={config.id}>
                        <ConditionalWrapper
                            condition={!!config.rootPath}
                            wrapper={rootPathWrapper}
                        >
                            {config.accessible ? (
                                <Switch>
                                    {config.routes.map((route) => (
                                        <Route
                                            key={`${config.id}-${route.path}`}
                                            {...route}
                                        />
                                    ))}
                                    {config.defaultPath && (
                                        <Route
                                            render={() => (
                                                <Redirect
                                                    to={config.defaultPath!}
                                                />
                                            )}
                                        />
                                    )}
                                </Switch>
                            ) : (
                                <Route
                                    render={() => (
                                        <Redirect
                                            to={
                                                config.redirectToPathIfNotAccessible
                                            }
                                        />
                                    )}
                                />
                            )}
                        </ConditionalWrapper>
                    </Switch>
                );
            })}
        </>
    );
};
