import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { Container } from '../../components/container/container';
import { Section } from '../../components/section/section';
import { RoutePaths } from '../../routing/route-paths';
import { getHasUserAccess } from '../../model/user/selectors/get-has-user-access.selector';
import { getUserLoggedStatus } from '../../model/user/selectors/get-user-logged-status.selector';
import { ConnectedAccessRequestForm } from '../../access-request/access-request-form/access-request-form';
import { IAccessRequestFormValues } from '../../access-request/access-request-form-values';
import { accessRequestFormSubmitted } from '../../access-request/access-request.actions';
import { ButtonLink } from '../../components/button-link/button-link';
import { getAccessRequestStatusMessage } from '../../access-request/model/selectors/get-access-request-status-message.selector';
import { getPagePrivacyMode } from '../../model/configuration/selectors/get-page-privacy-mode.selector';
import { PrivacyMode } from '../../interfaces/privacy-mode';

import styles from './access-request-page.module.scss';

export const AccessRequestPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const hasAccessToThisMemorial = useSelector(getHasUserAccess);
    const logged = useSelector(getUserLoggedStatus);
    const statusMessage = useSelector(getAccessRequestStatusMessage);
    const privacyMode = useSelector(getPagePrivacyMode);

    useEffect(() => {
        if (hasAccessToThisMemorial && logged) {
            history.push(RoutePaths.HOME);
        }

        if (privacyMode === PrivacyMode.PRIVATE) {
            history.push(RoutePaths.LOGIN);
        }
    }, [history, hasAccessToThisMemorial, logged, privacyMode]);

    const onSubmit = (values: IAccessRequestFormValues) => {
        dispatch(accessRequestFormSubmitted(values));
    };

    return (
        <>
            {hasAccessToThisMemorial && <DeceasedDisplay />}
            <Container>
                <Section
                    centerHeader
                    headerText={t('accessRequest.header')}
                    className={styles.Form}
                >
                    <ConnectedAccessRequestForm onSubmit={onSubmit} />
                    {statusMessage && (
                        <span className={styles.StatusMessage}>
                            {statusMessage}
                        </span>
                    )}
                    <ButtonLink
                        onClick={() => history.push(RoutePaths.LOGIN)}
                        className={styles.LoginButton}
                    >
                        {t('accessRequest.goToLogin')}
                    </ButtonLink>
                </Section>
            </Container>
        </>
    );
};
