import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mapProps } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import { FooterLinks } from '../../routing/footer-links';
import { Container } from '../container/container';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import { ViolationReportableType } from '../../interfaces/violation-reportable-type';
import {
    ViolationReportModal,
    IViolationReportModalModalRefProps,
} from '../../violation-reports/violation-report-modal';
import { violationReportFormSubmitted } from '../../model/violation-report/violation-report.actions';
import { IViolationReportRequestDto } from '../../api/DTOs/violation-report-request.dto';
import { getPageId } from '../../model/configuration/selectors/get-page-id.selector';
import { ShareButtons } from '../../sharing/components/share-buttons/share-buttons';
import { LoginButton } from '../login-button/login-button';
import { INavRoute } from '../../interfaces/nav-route';
import { useNavigationLinks } from '../../../shared/hooks/useNavigationLinks';

import styles from './footer.module.scss';

type Props = {
    links?: Array<Omit<INavRoute, 'icon'>>;
};

export const Footer = ({ links = FooterLinks }: Props) => {
    const violationReportModalRef = useRef<IViolationReportModalModalRefProps>(
        null,
    );

    const { t } = useTranslation();
    const theme = useSelector(getTheme);
    const dispatch = useDispatch();
    const pageId = useSelector(getPageId);
    const navigationLinks = useNavigationLinks(links);

    const noLinks = navigationLinks.length === 0;

    const onViolationReport = () => {
        if (violationReportModalRef.current && pageId) {
            violationReportModalRef.current.openFor(pageId as string);
        }
    };

    const onSubmit = (values: IViolationReportRequestDto) => {
        dispatch(violationReportFormSubmitted(values));
    };

    return (
        <>
            <div className={styles.ButtonsContainer}>
                <LoginButton />
                <ShareButtons />
            </div>
            <footer className={cx(styles.Footer, styles[theme])}>
                <Container className={styles.Container}>
                    <a
                        href="https://memcare.com"
                        className={cx(styles.Copyright, styles[theme])}
                    >
                        <span>
                            {t('navigation.copyright.companyName', {
                                currentYear: new Date().getFullYear(),
                            })}
                        </span>
                        <span>
                            {t('navigation.copyright.allRightsReserved')}
                        </span>
                    </a>
                    <div
                        className={cx(styles.LinksAndReport, {
                            [styles.ReportOnly]: noLinks,
                        })}
                    >
                        {navigationLinks.map((link) => (
                            <Link
                                className={cx(styles.Link, styles[theme])}
                                key={link.id}
                                to={link.href}
                            >
                                {t(`navigationLinks.${link.id}`)}
                            </Link>
                        ))}
                        <div
                            className={cx(
                                styles.ViolationReport,
                                styles[theme],
                            )}
                            onClick={onViolationReport}
                        >
                            {t('navigation.violationReport')}
                        </div>
                    </div>
                </Container>
                <ViolationReportModal
                    ref={violationReportModalRef}
                    onConfirm={onSubmit}
                    violationReportableType={ViolationReportableType.PAGE}
                />
            </footer>
        </>
    );
};

export const EmptyFooter = mapProps<Props, Props>((props) => ({
    links: [],
    ...props,
}))(Footer);
