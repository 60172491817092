import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { violationReportFormSubmitted } from '../violation-report.actions';

import { handleViolationReportFormSubmittedSaga } from './handle-violation-report-form-submitted.saga';

export function* violationReportSaga() {
    yield takeEvery(
        getType(violationReportFormSubmitted),
        handleViolationReportFormSubmittedSaga,
    );
}
