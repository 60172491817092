import { uniqueId } from 'lodash';

export const useImageUploadWithModal = (files, onChange) => {
    const handleFileUpload = (newFiles: File[]) => {
        if (newFiles && newFiles.length > 0) {
            const processedFiles = newFiles.map((newFile) => {
                const isVideo = newFile.type.includes('video');

                return {
                    file: newFile,
                    previewUrl: isVideo
                        ? undefined
                        : URL.createObjectURL(newFile),
                    id: uniqueId('uploaded-image'),
                    isNew: true,
                    isDeleted: false,
                };
            });

            onChange([...files, ...processedFiles]);
        }
    };

    return {
        handleFileUpload,
    };
};
