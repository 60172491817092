import { put, race, take } from '@redux-saga/core/effects';

import { likeCondolenceCommentApiActions } from '../../../api/condolences/condolences-api.actions';
import {
    likeCondolenceCommentRequested,
    likeCondolenceCommentSucceeded,
} from '../condolences.actions';

export function* handleLikeCondolenceCommentSaga(
    action: ReturnType<typeof likeCondolenceCommentRequested>,
) {
    const { id } = action.payload;

    yield put(likeCondolenceCommentApiActions.request({ id }));

    const { success } = yield race({
        success: take(likeCondolenceCommentApiActions.success),
        failure: take(likeCondolenceCommentApiActions.failure),
    });

    if (success) {
        const { likeToken } = success.payload;
        yield put(likeCondolenceCommentSucceeded({ id, likeToken }));
    }
}
