import { put } from '@redux-saga/core/effects';
import i18next from 'i18next';

import {
    languageChanged,
    languageChangeRequested,
} from '../configuration.actions';
import { saveLanguageInStorage } from '../../../utils/language-storage-utils';

export function* handleLanguageChangeRequested(
    action: ReturnType<typeof languageChangeRequested>,
) {
    const { language } = action.payload;

    yield saveLanguageInStorage(language);

    yield i18next.changeLanguage(language.code);

    yield put(languageChanged({ language }));
}
