import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { loginFormSubmitted } from '../login-page.actions';
import { loginApiActions } from '../../../api/login/login-api.actions';

import { loginFormSubmittedSaga } from './login-form-submitted.saga';
import { handleLoginApiErrorSaga } from './handle-login-api-error.saga';

export function* loginSaga() {
    yield takeEvery(getType(loginFormSubmitted), loginFormSubmittedSaga);
    yield takeEvery(getType(loginApiActions.failure), handleLoginApiErrorSaga);
}
