import { call, put } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import { RoutePaths } from '../../../routing/route-paths';
import { clearTokens } from '../../../api/auth-token-storage';

export function* handleLogoutRequestedSaga() {
    yield call(clearTokens);
    yield put(push(RoutePaths.HOME));

    // eslint-disable-next-line
    location.reload();
}
