import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from '../../components/section/section';
import { ITask } from '../../interfaces/task';
import { Collapse } from '../../components/collapse/collapse';
import { SingleTask } from '../single-task/single-task';

import { useVolunteerTask } from './use-volunteer-task';
import styles from './tasks-display.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    tasks: ITask[];
};

export const TasksDisplay = ({ className, tasks, ...props }: Props) => {
    const { t } = useTranslation();
    const [openTaskID, setOpenTaskID] = useState(
        tasks.length > 0 && tasks[0].id,
    );

    const {
        volunteerTaskRequested,
        volunteerStatus,
        volunteerStatusMessage,
    } = useVolunteerTask();

    return (
        <Section className={className} headerText={t('tasks.title')} {...props}>
            <div className={styles.TasksContainer}>
                {tasks.map((task, index) => (
                    <Collapse
                        headerText={task.task}
                        open={task.id === openTaskID}
                        onToggle={() => setOpenTaskID(task.id)}
                        divider={index !== tasks.length - 1}
                        key={task.id}
                        disableArrow={tasks.length === 1}
                    >
                        <SingleTask
                            volunteerStatus={volunteerStatus}
                            volunteerStatusMessage={volunteerStatusMessage}
                            onVolunteerClick={volunteerTaskRequested}
                            task={task}
                        />
                    </Collapse>
                ))}
            </div>
        </Section>
    );
};
