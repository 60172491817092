import React from 'react';
import cx from 'classnames';

import { SectionHeader } from '../section-header/section-header';

import styles from './section.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    actions?: React.ReactNode;
    headerClassName?: string;
    headerText?: string;
    centerHeader?: boolean;
};

export const Section = React.forwardRef(
    (
        {
            actions,
            children,
            className,
            headerClassName,
            headerText,
            centerHeader = false,
        }: Props,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        return (
            <div className={cx(styles.Section, className)} ref={ref}>
                {headerText && (
                    <div
                        className={cx({
                            [styles.SectionHeaderContainer]: actions,
                        })}
                    >
                        <SectionHeader
                            className={cx(
                                styles.SectionHeader,
                                headerClassName,
                                {
                                    [styles.CenterHeader]: centerHeader,
                                },
                            )}
                        >
                            {headerText}
                        </SectionHeader>
                        {actions}
                    </div>
                )}
                <div className={styles.SectionContent}>{children}</div>
            </div>
        );
    },
);
