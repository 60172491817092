import { takeEvery } from '@redux-saga/core/effects';
import { getType } from 'typesafe-actions';

import { refreshSessionActions } from '../refresh-session.actions';

import { getNewTokensSaga } from './get-new-tokens.saga';

export function* refreshSessionSaga() {
    yield takeEvery(getType(refreshSessionActions.request), getNewTokensSaga);
}
