export enum LanguageCode {
    EN = 'en',
    PL = 'pl',
    NO = 'nb',
    DE = 'de',
    FR = 'fr',
    SV = 'sv',
    AR = 'ar',
    NL = 'nl',
    ES = 'es',
}
