import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import { getUserLoggedStatus } from '../../model/user/selectors/get-user-logged-status.selector';
import { userLogoutRequested } from '../../model/user/user.actions';
import { RoutePaths } from '../../routing/route-paths';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';

import styles from './login-button.module.scss';

export const LoginButton = () => {
    const logged = useSelector(getUserLoggedStatus);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const theme = useSelector(getTheme);

    const handleOnLoginClick = () => {
        if (logged) {
            dispatch(userLogoutRequested());
        } else {
            history.push(RoutePaths.LOGIN);
        }
    };

    const label = logged
        ? t('navigationLinks.logout')
        : t('navigationLinks.login');

    const Icon = logged ? LogoutIcon : LoginIcon;

    if (location.pathname === RoutePaths.LOGIN && !logged) {
        return null;
    }
    return (
        <button
            className={cx(styles.Button, styles[theme])}
            onClick={handleOnLoginClick}
        >
            <Icon className={styles.Icon} />
            <span className={styles.Label}>{label}</span>
        </button>
    );
};
