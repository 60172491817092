import React from 'react';

import { IMilestone } from '../../interfaces/milestone';
import { useDateFormatter } from '../../utils/use-date-formatter';
import StringToHtmlParser from '../../components/string-to-html-parser/string-to-html-parser';

import styles from './timeline-event.module.scss';

type Props = {
    event: IMilestone;
    last: boolean;
};

export const TimelineEvent = ({ event, last }: Props) => {
    const { getMonthYearDate } = useDateFormatter();

    return (
        <div className={styles.TimelineEvent}>
            {!last && <span className={styles.Line} />}
            <span className={styles.Ball} />
            <div className={styles.Container}>
                <span className={styles.Date}>
                    {getMonthYearDate(event.year, event.month)}
                </span>
                <div className={styles.InfoBox}>
                    <span className={styles.Title}>{event.title}</span>
                    {event.description && (
                        <span className={styles.Description}>
                            <StringToHtmlParser
                                stringToParse={event.description}
                            />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
