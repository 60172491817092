import { call, select, put } from '@redux-saga/core/effects';

import { createUserApiActions } from '../create-user.actions';
import { getInvitationToken } from '../../../invitation/model/selectors/get-invitation-token.selector';
import { ITokensResponseDto } from '../../DTOs/tokens-response.dto';
import { createUser } from '../http/create-user';

export function* handleUserCreationInApiSaga(
    action: ReturnType<typeof createUserApiActions.request>,
) {
    const token = yield select(getInvitationToken);
    const { email, password } = action.payload;

    try {
        const response: ITokensResponseDto = yield call(createUser, {
            token,
            email,
            password,
        });

        yield put(createUserApiActions.success(response));
    } catch (e) {
        yield put(createUserApiActions.failure(e.statusText));
    }
}
