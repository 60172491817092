import { apiService } from '../../../api.service';
import { IFlowerDeliveryTypesDTO } from '../../../DTOs/flower-delivery-types.dto';

export const fetchFlowerDeliveryTypes = ({
    slug,
}: {
    slug: string;
}): Promise<IFlowerDeliveryTypesDTO> =>
    apiService
        .get(`/public/memorial_pages/${slug}/orders/delivery_types`)
        .then((resp) => resp.json());
