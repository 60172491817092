import cx from 'classnames';

import { ISocialItemLabelProps } from './social-item-types';
import styles from './social-item.module.scss';

export const useSocialItemLabel = ({ label, count }: ISocialItemLabelProps) => {
    const isCountExists = typeof count === 'number';
    const isCountGreaterThanZero = isCountExists && Number(count);

    const generateLabels = () => {
        const getDesktopCount = () => {
            if (!label) {
                return count || 0;
            }
            if (isCountGreaterThanZero) {
                return count;
            }
            return null;
        };

        return {
            mobile: {
                count: count || 0,
            },
            desktop: {
                label,
                count: getDesktopCount(),
            },
        };
    };
    const generatedLabels = generateLabels();
    const mobileValues = generatedLabels.mobile;
    const desktopValues = generatedLabels.desktop;

    const labelClasses = [styles.Label];
    const labelClassesMobile = cx(
        ...labelClasses,
        styles.LabelMobile,
        !isCountGreaterThanZero && styles.Hidden,
    );
    const labelClassesDesktop = cx(
        ...labelClasses,
        styles.LabelDesktop,
        !isCountGreaterThanZero && !desktopValues.label && styles.Hidden,
    );
    const countClasses = cx(
        desktopValues.label && styles.Count,
        !isCountGreaterThanZero && !desktopValues.label && styles.Hidden,
    );

    return {
        labelClassesMobile,
        labelClassesDesktop,
        countClasses,
        desktopValues,
        mobileValues,
    };
};
