import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import {
    condolenceFormSubmitted,
    condolenceEditRequested,
    condolenceUpdateRequested,
    likeCondolenceRequested,
    unlikeCondolenceRequested,
    likeCondolenceCommentRequested,
    unlikeCondolenceCommentRequested,
    condolenceEditClear,
} from '../../model/condolences/condolences.actions';
import { ICondolenceFormValues } from '../condolence-form-values';
import {
    ICreatedCondolenceComment,
    ICreatedCondolence,
} from '../../interfaces/condolence';
import { getCondolences } from '../../model/condolences/selectors/get-condolences.selector';
import { getEditedCondolence } from '../../model/condolences/selectors/get-edited-condolence.selector';
import { getCondolenceStatusMessage } from '../../model/condolences/selectors/get-condolence-status-message.selector';
import { getCondolenceError } from '../../model/condolences/selectors/get-condolence-error.selector';
import { THEME } from '../../utils/get-theme';
import { NAV_HEIGHT, VG_NAV_HEIGHT } from '../../../shared/constants/layout';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import { getCondolencesImagesEnabledSelector } from '../../model/configuration/selectors/get-condolences-images-enabled.selector';

import {
    ICondolencesDisplayFormState,
    ICondolencesDisplayLocationState,
} from './condolences-display-types';

export const useCondolencesDisplay = () => {
    const dispatch = useDispatch();

    const condolences = useSelector(getCondolences);
    const editedCondolence = useSelector(getEditedCondolence);
    const condolenceStatus = useSelector(getCondolenceStatusMessage);
    const error = useSelector(getCondolenceError);
    const theme = useSelector(getTheme);
    const history = useHistory();
    const location = useLocation<ICondolencesDisplayLocationState>();
    const imagesEnabled = useSelector(getCondolencesImagesEnabledSelector);

    const condolenceFormRef = useRef<HTMLDivElement>(null);
    const [condolenceFormState, setCondolenceFormState] = useState<
        ICondolencesDisplayFormState
    >({ isOpened: false });

    const handleClearFormState = useCallback(() => {
        dispatch(condolenceEditClear());
    }, [dispatch]);

    const handleCloseCondolencesForm = useCallback(() => {
        setCondolenceFormState({
            isOpened: false,
        });
    }, [setCondolenceFormState]);

    const handleOpenCondolencesForm = useCallback(() => {
        handleClearFormState();
        setCondolenceFormState({
            isOpened: true,
        });
    }, [setCondolenceFormState, handleClearFormState]);

    const handleOpenCondolencesFormWithLightedCandle = useCallback(() => {
        handleClearFormState();
        setCondolenceFormState({
            isOpened: true,
            withLightedCandle: true,
        });
    }, [setCondolenceFormState, handleClearFormState]);

    const handleOpenCondolencesFormWithImageUploaderOpened = useCallback(() => {
        handleClearFormState();
        setCondolenceFormState({
            isOpened: true,
            withOpenedFileUploader: true,
        });
    }, [setCondolenceFormState, handleClearFormState]);

    useEffect(() => {
        if (!error) {
            handleCloseCondolencesForm();
        }
    }, [handleCloseCondolencesForm, error, condolences]);

    const navBarHeight = theme === THEME.VG ? VG_NAV_HEIGHT : NAV_HEIGHT;
    const scrollOffset =
        condolenceFormRef && condolenceFormRef.current
            ? condolenceFormRef.current.offsetTop - navBarHeight
            : 0;

    useEffect(() => {
        if (location.state?.scrollToCondolence && scrollOffset) {
            handleOpenCondolencesForm();
            process.nextTick(() => {
                window.scrollTo({
                    top: scrollOffset,
                });
                history.replace(location.pathname, {
                    scrollToCondolence: false,
                });
            });
        }
    }, [
        location.state,
        scrollOffset,
        handleOpenCondolencesForm,
        history,
        location.pathname,
    ]);

    return {
        condolences,
        editedCondolence,
        condolenceFormState,
        onSubmit(values: ICondolenceFormValues) {
            dispatch(condolenceFormSubmitted(values));
        },
        onEdit(condolence: ICreatedCondolence) {
            handleOpenCondolencesForm();
            dispatch(condolenceEditRequested(condolence));
        },
        onUpdate(condolence: ICreatedCondolence) {
            dispatch(condolenceUpdateRequested(condolence));
        },
        onLikeCondolence(condolence: ICreatedCondolence) {
            dispatch(likeCondolenceRequested(condolence));
        },
        onUnlikeCondolence(condolence: ICreatedCondolence) {
            dispatch(unlikeCondolenceRequested(condolence));
        },
        onLikeCondolenceComment(comment: ICreatedCondolenceComment) {
            dispatch(likeCondolenceCommentRequested(comment));
        },
        onUnlikeCondolenceComment(comment: ICreatedCondolenceComment) {
            dispatch(unlikeCondolenceCommentRequested(comment));
        },
        condolenceStatus,
        handleCloseCondolencesForm,
        handleOpenCondolencesForm,
        handleOpenCondolencesFormWithLightedCandle,
        handleOpenCondolencesFormWithImageUploaderOpened,
        condolenceFormRef,
        imagesEnabled,
    };
};
