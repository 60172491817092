import { createSelector } from 'reselect';

import { getDeathNotices } from '../../death-notice/selectors/get-death-notices.selector';
import { getGalleryPhotos } from '../../gallery/selectors/get-gallery-photos.selector';
import { getLeaflets } from '../../leaflets/selectors/get-leaflets.selector';
import { getCondolencesMediaFiles } from '../../condolences/selectors/get-condolences-media-files.selector';

export const getGalleryItems = createSelector(
    getLeaflets,
    getDeathNotices,
    getGalleryPhotos,
    getCondolencesMediaFiles,
    (leaflets, deathNotices, photos, condolencesMediaFiles) => {
        return (
            leaflets.length +
            deathNotices.length +
            photos.length +
            condolencesMediaFiles.length
        );
    },
);
