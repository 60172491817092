import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { getUserEmail } from '../../model/user/selectors/get-user-email';
import { sendDonationActions } from '../../model/donation/donation.actions';
import { Section } from '../../components/section/section';
import { getSubmittedDonationDto } from '../../model/donation/selectors/get-submitted-donation-dto.selector';
import { RoutePaths } from '../../routing/route-paths';
import { getLocale } from '../../model/configuration/selectors/get-locale.selector';

import {
    ConnectedDonationDetailsForm,
    IDonationDetailsFormValues,
} from './form/donation-details-form';
import styles from './donation-details.module.scss';
import { getPersonalIdValidatorByLocale } from './form/donation-details-form.helpers';

export const DonationDetails = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const userEmail = useSelector(getUserEmail);
    const locale = useSelector(getLocale);
    const personalIdValidator = getPersonalIdValidatorByLocale(locale);

    const submittedDonationDtoStore = useSelector(getSubmittedDonationDto);

    const handleSubmit = (formState: IDonationDetailsFormValues) => {
        dispatch(sendDonationActions.request({ formValues: formState }));
    };

    const redirectToPaymentFailedPage = () => {
        history.push(RoutePaths.DONATION_PAYMENT_FAILED);
    };

    useEffect(() => {
        if (submittedDonationDtoStore) {
            redirectToPaymentFailedPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Section
            className={styles.Container}
            headerClassName={styles.Title}
            headerText={t('donation.title')}
        >
            <ConnectedDonationDetailsForm
                userEmail={userEmail}
                personalIdValidator={personalIdValidator}
                onSubmit={handleSubmit}
            />
        </Section>
    );
};
