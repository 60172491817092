import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { Container } from '../../components/container/container';
import { ConnectedInvitationForm } from '../../invitation/invitation-form/invitation-form';
import { IInvitationFormValues } from '../../invitation/invitation-form-values';
import { invitationFormSubmitted } from '../../invitation/model/invitation.actions';
import { withInvitationTokenValidation } from '../../../shared/hoc/with-invitation-token-validation';
import { Section } from '../../components/section/section';
import { getEmailFromInvitation } from '../../invitation/model/selectors/get-email-from-invitation.selector';
import { PrivacyMode } from '../../interfaces/privacy-mode';
import { getPagePrivacyMode } from '../../model/configuration/selectors/get-page-privacy-mode.selector';

import styles from './invitation-page.module.scss';

type Props = RouteComponentProps;

export const InvitationPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const invitedUserEmail = useSelector(getEmailFromInvitation);
    const privacyMode = useSelector(getPagePrivacyMode);

    const onFormSubmit = (values: IInvitationFormValues) => {
        dispatch(invitationFormSubmitted(values));
    };

    return (
        <>
            {privacyMode !== PrivacyMode.PRIVATE && <DeceasedDisplay />}
            <Container>
                <Section
                    centerHeader
                    headerText={t('register.headline')}
                    className={styles.InvitationForm}
                >
                    <ConnectedInvitationForm
                        emailPrefill={invitedUserEmail}
                        onSubmit={onFormSubmit}
                    />
                </Section>
            </Container>
        </>
    );
};

export const ComposedInvitationPage = compose<Props, RouteComponentProps>(
    withInvitationTokenValidation,
)(InvitationPage);
