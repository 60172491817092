import { put } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { leafletsDataReceived } from '../leaflets.actions';
import { ILeaflet } from '../../../interfaces/leaflet';

export function* getLeafletsDataFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const memorialPageData = action.payload;

    try {
        const leaflets: ILeaflet[] = memorialPageData.included
            .filter(
                (inclusion) =>
                    inclusion.type === 'leaflet' ||
                    inclusion.type === 'funeral_leaflet',
            )
            .map((data: any) => ({
                url: data.attributes.url,
            }));

        yield put(
            leafletsDataReceived({
                leaflets,
            }),
        );
    } catch (e) {
        /**
         * TODO: Handle missing data and assume privacy mode is set
         */
        console.log('Error getting leaflets from memorial page');
        console.error(e);
    }
}
