import * as yup from 'yup';

import { getPasswordValidation } from '../utils/password-validation';

export const setNewPasswordFormValidation = () =>
    yup.object().shape({
        password: getPasswordValidation().required(),
        repeatPassword: yup
            .string()
            .oneOf([yup.ref('password'), ''], 'error.passwordsDontMatch')
            .required(),
    });
