import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './orange-headline.module.scss';

interface IProps extends HTMLAttributes<HTMLHeadingElement> {
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    marginBottom?: boolean | string;
}

export const OrangeHeadline = ({
    className,
    tag = 'h2',
    marginBottom,
    style,
    ...props
}: IProps) => {
    return React.createElement(tag, {
        className: cx(styles.Headline, className, {
            [styles.marginBottom]: marginBottom,
        }),
        style: {
            ...style,
            marginBottom:
                typeof marginBottom === 'string' ? marginBottom : undefined,
        },
        ...props,
    });
};
