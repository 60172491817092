import { put, take } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';

import {
    setVideoStreamPasswordError,
    videoStreamFormSubmitted,
    videoStreamPasswordSend,
} from '../video-stream.actions';
import { sendVideoStreamApiActions } from '../../../api/video-stream/video-stream-api.actions';

export function* handleVideoStreamFormSubmittedSaga(
    action: ReturnType<typeof videoStreamFormSubmitted>,
) {
    yield put(
        sendVideoStreamApiActions.request({
            password: action.payload.password,
        }),
    );

    const response = yield take([
        sendVideoStreamApiActions.success,
        sendVideoStreamApiActions.failure,
    ]);

    if (isActionOf(sendVideoStreamApiActions.success, response)) {
        yield put(videoStreamPasswordSend(response.payload.attributes));
        yield put(setVideoStreamPasswordError(undefined));
    } else {
        yield put(setVideoStreamPasswordError(response.payload));
    }
}
