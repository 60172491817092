import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { forgottenPasswordFormSubmitted } from '../model/forgotten-password.actions';

import { handleForgottenPasswordFormSubmitSaga } from './handle-forgotten-password-form-submit.saga';

export function* forgottenPasswordSaga() {
    yield takeEvery(
        getType(forgottenPasswordFormSubmitted),
        handleForgottenPasswordFormSubmitSaga,
    );
}
