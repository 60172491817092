import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import { RoutePaths } from '../../routing/route-paths';
import { ReactComponent as PinIcon } from '../../assets/icons/icon-pin.svg';
import { ReactComponent as NoteIcon } from '../../assets/icons/icon-dialog.svg';
import { ReactComponent as DateIcon } from '../../assets/icons/icon-time.svg';
import { ReactComponent as VideoStreamIcon } from '../../assets/icons/icon-video-stream.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/copy-icon.svg';
import { IMemorialEvent } from '../../interfaces/memorial-event';
import { useDateFormatter } from '../../utils/use-date-formatter';
import { SmallGrayButton } from '../../components/small-gray-button/small-gray-button';
import { EmailInput } from '../components/email-input/email-input';
import { StatusMessage } from '../../components/status-message/status-message';
import { getUserLoggedStatus } from '../../model/user/selectors/get-user-logged-status.selector';
import { IEventAttendanceData } from '../../interfaces/event-attendance-data';
import {
    IEventAttendanceStatus,
    EventAttendanceStatusCode,
} from '../../interfaces/event-attendance-status';
import { getGoogleDomain } from '../../utils/get-google.domain';
import { getLanguage } from '../../model/configuration/selectors/get-language.selector';
import { EventType } from '../../interfaces/event-type';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-icon.svg';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import StringToHtmlParser from '../../components/string-to-html-parser/string-to-html-parser';
import Map from '../../components/map/Map';
import { LanguageCode } from '../../utils/get-language-codes';
import { ConditionalWrapper } from '../../../shared/helpers/conditionalWrapper';
import { ImageContainer } from '../../components/image-container/image-container';
import { useIsMobileBreakpoint } from '../../../shared/hooks/useIsViewPortSmallerThan';

import { getEncodedGoogleMapsQueryParams } from './getEncodedGoogleMapsQueryParams';
import { getLocationDisplayText } from './getLocationDisplayText';
import styles from './single-event-display.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
    attendanceStatus: IEventAttendanceStatus | null;
    attendanceStatusMessage: string | null;
    event: IMemorialEvent;
    onAttendClick(payload: IEventAttendanceData): unknown;
    leaflets: Array<{ url: string; title: string }>;
};

export const SingleEventDisplay = ({
    attendanceStatus,
    attendanceStatusMessage,
    event,
    className,
    onAttendClick,
    leaflets,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const logged = useSelector(getUserLoggedStatus);
    const lang = useSelector(getLanguage);
    const { getTextDate, checkIfDateIsAfterNow } = useDateFormatter();

    const history = useHistory();
    const isMobile = useIsMobileBreakpoint();

    const onAddressClick = () => {
        const encodedAddress = getEncodedGoogleMapsQueryParams(event.location);

        window.open(
            process.env.REACT_APP_MAPS_URL.replace(
                '{{domain}}',
                getGoogleDomain(lang.code as LanguageCode),
            ).replace('{{query}}', encodedAddress),
        );
    };

    const renderButtons = () => (
        /**
         * hide render buttons for know
         */
        <div style={{ display: 'none' }}>
            {logged ? (
                <SmallGrayButton
                    onClick={() => onAttendClick({ eventId: event.id })}
                >
                    {t('events.attendButton')}
                </SmallGrayButton>
            ) : (
                <EmailInput
                    buttonLabel={t('events.attendButton')}
                    onSubmit={(email) => {
                        onAttendClick({ eventId: event.id, email });
                    }}
                    placeholder={t('events.emailPlaceholder')}
                />
            )}
        </div>
    );

    const showAttandanceStatusMessage =
        attendanceStatus && attendanceStatus.eventId === event.id;
    const isAttendanceStatusMessageError = Boolean(
        attendanceStatus &&
            attendanceStatus.status !== EventAttendanceStatusCode.SUCCESS,
    );

    const isEventWithLocation = !!event.location && !!event.location.name;

    const eventDateItem = event.date ? (
        <div className={styles.ListRow}>
            <div className={styles.IconContainer}>
                <DateIcon className={styles.Icon} />
            </div>
            <div className={styles.RowContent}>{getTextDate(event.date)}</div>
        </div>
    ) : null;

    const displayMap =
        event?.location?.latitude &&
        event?.location?.longitude &&
        parseFloat(event?.location?.latitude.toString()) &&
        parseFloat(event?.location?.longitude.toString());

    const mapImageUrl = isMobile
        ? event?.location?.mapImage?.originalUrl
        : event?.location?.mapImage?.thumbUrl;

    const mapInteractionHandlers = {
        onClick: onAddressClick,
        'data-translation': t('events.clickToOpenMap'),
    };

    const isAttendanceRegistrationExpired = event?.date
        ? checkIfDateIsAfterNow(event.date)
        : false;

    const isAttendanceRegistrationLimitReached =
        !isAttendanceRegistrationExpired &&
        event.attendanceRegistrationLimitEnabled &&
        !!event.attendanceRegistrationLimit &&
        event.attendanceRegistrationLimit <= event.attendanceRegistrationCount;

    return (
        <div className={cx(className)} {...props}>
            <div>
                {isEventWithLocation ? (
                    <div className={styles.ListRowWithMap}>
                        <div className={styles.ListRowContainer}>
                            {eventDateItem}
                            <div
                                className={cx(
                                    styles.ListRow,
                                    styles.clickableListRow,
                                )}
                                onClick={onAddressClick}
                            >
                                <div className={styles.IconContainer}>
                                    <PinIcon className={styles.Icon} />
                                </div>
                                <div
                                    className={styles.RowContent}
                                    style={{ textAlign: 'start' }}
                                >
                                    {getLocationDisplayText(event.location)}
                                </div>
                            </div>
                        </div>
                        {displayMap && (
                            <div className={styles.MapContainer}>
                                {mapImageUrl && (
                                    <ImageContainer
                                        src={mapImageUrl}
                                        useLightMode
                                        containerClassName={
                                            styles.MapImageContainer
                                        }
                                        containerDivProps={
                                            mapInteractionHandlers
                                        }
                                    />
                                )}
                                {!mapImageUrl && (
                                    <div className={styles.MapInnerContainer}>
                                        <Map location={event.location} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    eventDateItem
                )}
                {event.type === EventType.CEREMONY && leaflets.length > 0 && (
                    <div className={styles.ListRow}>
                        <div className={styles.IconContainer}>
                            <FileIcon className={styles.Icon} />
                        </div>
                        <div className={styles.RowContent}>
                            {leaflets.map((el, idx) => (
                                <a
                                    className={cx(
                                        styles.Link,
                                        styles.LeafletLink,
                                    )}
                                    key={idx}
                                    href={el.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {el.title}
                                </a>
                            ))}
                        </div>
                    </div>
                )}
                {event.attendanceRegistrationEnabled && (
                    <div className={styles.ListRow}>
                        <div className={styles.IconContainer}>
                            <NoteIcon className={styles.Icon} />
                        </div>
                        <div className={styles.RowContent}>
                            <div className={styles.RowContent}>
                                {event.attendanceRegistrationTextParagraphTop ??
                                    t(
                                        'eventAttendance.beforeRegistrationLinkText',
                                    )}
                            </div>
                            <ConditionalWrapper
                                condition={isAttendanceRegistrationLimitReached}
                                wrapper={(children) => (
                                    <Tooltip
                                        arrow
                                        placement="bottom"
                                        title={t(
                                            'eventAttendance.registrationFullyBooked',
                                        ).toString()}
                                        classes={{
                                            tooltip: styles.Tooltip,
                                        }}
                                    >
                                        {children}
                                    </Tooltip>
                                )}
                            >
                                <div
                                    className={cx(
                                        isAttendanceRegistrationLimitReached &&
                                            styles.TooltipInnerContainer,
                                    )}
                                >
                                    <PrimaryButton
                                        className={styles.ActionLinkButton}
                                        disabled={
                                            isAttendanceRegistrationExpired ||
                                            isAttendanceRegistrationLimitReached
                                        }
                                        icon={EditIcon}
                                        onClick={() => {
                                            history.push(
                                                RoutePaths.EVENT_ATTENDANCE,
                                                {
                                                    eventId: event.id,
                                                },
                                            );
                                        }}
                                    >
                                        {t(
                                            'eventAttendance.linkToRegistrationPage',
                                        )}
                                    </PrimaryButton>
                                </div>
                            </ConditionalWrapper>
                            <div className={styles.RowContent}>
                                {event.attendanceRegistrationTextParagraphBottom ??
                                    t(
                                        'eventAttendance.afterRegistrationLinkText',
                                    )}
                            </div>
                        </div>
                    </div>
                )}
                {event.description && (
                    <div className={styles.ListRow}>
                        <div className={styles.IconContainer}>
                            <NoteIcon className={styles.Icon} />
                        </div>
                        <div className={styles.RowContent}>
                            <StringToHtmlParser
                                stringToParse={event.description}
                            />
                        </div>
                    </div>
                )}
                {event.videoStreamEnabled && (
                    <div className={cx(styles.ListRow, styles.ListRowCentered)}>
                        <div className={styles.IconContainer}>
                            <VideoStreamIcon className={styles.Icon} />
                        </div>
                        <PrimaryButton
                            className={styles.ActionLinkButton}
                            icon={VideoStreamIcon}
                            onClick={() => {
                                history.push(
                                    RoutePaths.VIDEO_STREAM.replace(
                                        ':eventId',
                                        event.id,
                                    ),
                                );
                            }}
                            iconBoxClassName={styles.VideoStreamIconBox}
                        >
                            {t('events.goToVideoStream')}
                        </PrimaryButton>
                    </div>
                )}
                {!showAttandanceStatusMessage && renderButtons()}
                {showAttandanceStatusMessage && (
                    <StatusMessage error={isAttendanceStatusMessageError}>
                        {attendanceStatusMessage}
                    </StatusMessage>
                )}
            </div>
        </div>
    );
};
