import { getSlugFromLocation } from '../../utils/parse-location';

const getStorageKey = () =>
    btoa(`${getSlugFromLocation(window.location)}: userEmail`);

export const saveEmailToStorage = (email: string) => {
    localStorage.setItem(getStorageKey(), email);
};

export const getEmailFromStorage = () => localStorage.getItem(getStorageKey());
