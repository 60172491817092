import { useContext } from 'react';

import { ImageCarouselFullscreenContext } from './fullscreen-context';
import { IImageCarouselFullscreenContext } from './fullscreen-context.types';

export const useImageCarouselFullscreenContext = (): IImageCarouselFullscreenContext => {
    const fullscreenContext = useContext(ImageCarouselFullscreenContext);

    return { ...fullscreenContext };
};
