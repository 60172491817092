import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { Counter } from '../../components/counter/counter';
import { IFlowerSummaryEntry } from '../../../interfaces/flower-summary-entry';
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash-icon.svg';
import { FlowersFlowActions } from '../../../model/flowers/flowers.actions';
import { FlowerAddon } from '../../../model/flowers/flowers-order.store';
import { roundPriceToString } from '../../../utils/set-price';
import { getDecodedText } from '../../../../shared/helpers/getDecodedText';
import { useFlowerPhoto } from '../../hooks/use-flower-photo';

import styles from './flowers-summary-item-mobile.module.scss';

type Props = {
    item: IFlowerSummaryEntry;
} & HTMLAttributes<HTMLDivElement>;

export const FlowersSummaryItemMobile = ({ item, ...props }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const hasBandAddon =
        item.selectedAddon === FlowerAddon.BANDS_AND_CARD &&
        (get(item, 'leftBandText.length') || get(item, 'rightBandText.length'));

    const hasCardAddon =
        item.selectedAddon === FlowerAddon.CARD && get(item, 'cardText.length');

    const calculatePrice = () => {
        if (hasBandAddon) {
            return roundPriceToString(
                item.quantity *
                    (item.selectedVariant!.grossPrice + item.grossBandPrice),
            );
        }
        if (hasCardAddon) {
            return roundPriceToString(
                item.quantity *
                    (item.selectedVariant!.grossPrice + item.grossCardPrice),
            );
        }

        return roundPriceToString(
            item.quantity * item.selectedVariant!.grossPrice,
        );
    };

    const renderAdjustmentsLabel = () => {
        if (hasBandAddon) {
            return (
                <span className={styles.AdjustmentsInfo}>
                    {t('flowers.summary.bandsLabel')}
                </span>
            );
        }

        if (hasCardAddon) {
            return (
                <span className={styles.AdjustmentsInfo}>
                    {t('flowers.summary.cardLabel')}
                </span>
            );
        }

        return (
            <span className={styles.AdjustmentsInfo}>
                {t('flowers.summary.withoutBandLabel')}
            </span>
        );
    };

    const { imageUrl } = useFlowerPhoto({
        product: item,
        selectedVariant: item?.selectedVariant,
    });

    const unitPrice = `${item.selectedVariant?.currency} ${roundPriceToString(
        item.selectedVariant?.grossPrice,
    )}`;

    return (
        <div className={styles.Item} {...props}>
            <div className={styles.InfoWithPhoto}>
                <div className={styles.Info}>
                    <span className={styles.FlowersCategory}>{item.name}</span>
                    {renderAdjustmentsLabel()}
                    {item.leftBandText && (
                        <span className={styles.BandText}>
                            {getDecodedText(item.leftBandText)}
                        </span>
                    )}
                    {item.rightBandText && (
                        <span className={styles.BandText}>
                            {getDecodedText(item.rightBandText)}
                        </span>
                    )}
                    {item.cardText && (
                        <span className={styles.BandText}>
                            {getDecodedText(item.cardText)}
                        </span>
                    )}
                </div>
                <img alt={item.name} src={imageUrl} className={styles.Photo} />
            </div>
            <div className={styles.Controls}>
                <span className={styles.VariantName}>
                    {item.selectedVariant?.label}
                </span>
                <div className={styles.Icons}>
                    <span
                        className={styles.IconWrapper}
                        data-testid="flower-summary-item-edit-button"
                        onClick={() => {
                            dispatch(
                                FlowersFlowActions.productEditRequested({
                                    localID: item.id,
                                }),
                            );
                        }}
                    >
                        <EditIcon className={styles.Icon} />
                    </span>
                    <span
                        className={styles.IconWrapper}
                        data-testid="flowers-summary-product-delete-button"
                        onClick={() => {
                            dispatch(
                                FlowersFlowActions.productDeleteRequested({
                                    localID: item.id,
                                }),
                            );
                        }}
                    >
                        <DeleteIcon className={styles.Icon} />
                    </span>
                </div>
            </div>
            <div className={styles.QuantityAndPrices}>
                <Counter
                    count={item.quantity}
                    maxCount={9}
                    onChange={(newQuantity) => {
                        dispatch(
                            FlowersFlowActions.quantityChanged({
                                localID: item.id,
                                newQuantity,
                            }),
                        );
                    }}
                />
                <div className={styles.Prices}>
                    <div className={styles.TotalBox}>
                        <span className={styles.TotalLabel}>
                            {t('flowers.summary.column21')}
                        </span>
                        <span
                            className={cx(
                                styles.TotalLabel,
                                styles.TotalLabelBold,
                            )}
                        >
                            {unitPrice}
                        </span>
                    </div>
                    <div className={styles.TotalBox}>
                        <span className={styles.TotalLabel}>
                            {t('flowers.summary.totalLabel')}
                        </span>
                        <span
                            className={cx(
                                styles.TotalLabel,
                                styles.TotalLabelBold,
                            )}
                        >
                            {item.selectedVariant!.currency} {calculatePrice()}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
