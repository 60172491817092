import { apiService } from '../../../api.service';
import { ICreatePaymentRequestDto } from '../../../DTOs/create-payment-request.dto';
import { ICreatePaymentResponseDto } from '../../../DTOs/create-payment-response.dto';

export const createPayment = ({
    slug,
    dto,
}: {
    slug: string;
    dto: ICreatePaymentRequestDto;
}): Promise<ICreatePaymentResponseDto> =>
    apiService
        .post(`/public/memorial_pages/${slug}/orders/${dto.uuid}/payment`, {
            json: {
                successUrl: dto.successUrl,
                cancelUrl: dto.cancelUrl,
                provider: dto.provider,
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes);
