import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../components/text-field/text-field';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import {
    ErrorMessage,
    FieldErrorMessage,
} from '../../components/error-message/error-message';
import { IAccessRequestFormValues } from '../access-request-form-values';
import { accessRequestFormValidation } from '../access-request-form-validation';
import { PhoneInput } from '../../components/phone-input/phone-input';

import styles from './access-request-form.module.scss';

type OuterProps = {
    onSubmit(values: IAccessRequestFormValues): unknown;
} & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

type Props = FormikProps<IAccessRequestFormValues> & OuterProps;

export const AccessRequestForm = ({
    className,
    handleChange,
    handleSubmit,
    values,
    errors,
    handleBlur,
    setFieldValue,
    dirty,
    isValid,
}: Props) => {
    const { t } = useTranslation();

    const phoneOrEmailError = (errors as any).phoneOrEmail;
    const canSave = dirty && isValid;
    return (
        <form className={cx(className)} onSubmit={handleSubmit}>
            <p className={styles.HintText}>{t('accessRequest.hintText')}</p>
            <div className={styles.InputsContainer}>
                <div className={styles.Control}>
                    <TextField
                        autoFocus
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('accessRequest.nameLabel')}
                        value={values.name}
                        error={Boolean(errors.name)}
                    />
                    <FieldErrorMessage name="name" />
                </div>
                <div className={styles.Control}>
                    <TextField
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('accessRequest.emailLabel')}
                        value={values.email}
                        error={
                            Boolean(errors.email) || Boolean(phoneOrEmailError)
                        }
                    />
                    <FieldErrorMessage name="email" />
                    <ErrorMessage>{phoneOrEmailError}</ErrorMessage>
                </div>
                <div className={styles.Control}>
                    <PhoneInput
                        className={styles.TextField}
                        name="phone"
                        onChange={(phone) => setFieldValue('phone', phone)}
                        inputProps={{
                            onBlur: handleBlur,
                            placeholder: t('suggestGuest.phonePlaceholder'),
                        }}
                        value={values.phone}
                        onBlur={(event) => {
                            handleBlur(event);

                            accessRequestFormValidation()
                                .validateAt('phone', values)
                                .then((phoneValue: any) => {
                                    if (!phoneValue.length) {
                                        setFieldValue('phone', '');
                                    }
                                });
                        }}
                    />
                    <FieldErrorMessage name="phone" />
                    <ErrorMessage>{phoneOrEmailError}</ErrorMessage>
                </div>
            </div>
            <PrimaryButton
                className={styles.SubmitButton}
                icon={ArrowIcon}
                type="submit"
                disabled={!canSave}
            >
                {t('accessRequest.submitButton')}
            </PrimaryButton>
        </form>
    );
};

export const ConnectedAccessRequestForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IAccessRequestFormValues>({
        handleSubmit(values, formikBag) {
            formikBag.props.onSubmit(values);
        },
        mapPropsToValues() {
            return {
                phone: '',
                email: '',
                name: '',
            };
        },
        validationSchema: accessRequestFormValidation,
    }),
)(AccessRequestForm);
