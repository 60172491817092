import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { sendEventAttendanceApiActions } from '../event-attendance-api.actions';

import { sendEventAttendanceApiSaga } from './send-event-attendance-api.saga';

export function* eventAttendanceApiSaga() {
    yield takeEvery(
        getType(sendEventAttendanceApiActions.request),
        sendEventAttendanceApiSaga,
    );
}
