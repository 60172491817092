import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { useDateFormatter } from '../../utils/use-date-formatter';
import { getLanguage } from '../../model/configuration/selectors/get-language.selector';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import { LanguageCode } from '../../utils/get-language-codes';

import styles from './deceased-date-and-place.module.scss';

interface IDeceasedDateAndPlaceProps extends HTMLAttributes<HTMLDivElement> {
    label: string;
    isoDate?: string | null;
    place?: string | null;
    placeVisible?: boolean;
    death?: boolean;
}

export const DeceasedDateAndPlace = ({
    label,
    isoDate,
    place,
    className,
    placeVisible = false,
    death = false,
    ...props
}: IDeceasedDateAndPlaceProps) => {
    const { getShortDate, getShortDateNo, getLongDateNo } = useDateFormatter();
    const language = useSelector(getLanguage);
    const norwegianLang = language.code === LanguageCode.NO;
    const theme = useSelector(getTheme);

    return (
        <div className={cx(styles.Container, className)} {...props}>
            <span
                className={cx(
                    styles.Label,
                    styles[theme],
                    death && styles.death,
                )}
            >
                {label}
            </span>
            {!norwegianLang && (
                <span
                    className={cx(
                        styles.Date,
                        styles[theme],
                        death && styles.death,
                    )}
                >
                    {isoDate ? getShortDate(isoDate) : '-'}
                </span>
            )}
            {norwegianLang && (
                <span
                    className={cx(
                        styles.NoDateMobile,
                        styles[theme],
                        death && styles.death,
                    )}
                >
                    {isoDate ? getShortDateNo(isoDate) : '-'}
                </span>
            )}
            {isoDate && norwegianLang && (
                <span
                    className={cx(
                        styles.NoDate,
                        styles[theme],
                        death && styles.death,
                    )}
                >
                    {getLongDateNo(isoDate)}
                </span>
            )}
            {place && placeVisible && (
                <span className={styles.Place}>{place}</span>
            )}
        </div>
    );
};
