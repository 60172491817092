import { ImgHTMLAttributes } from 'react';

import { useBooleanState } from '../../../shared/hooks/useBooleanState';

import {
    IImageContainerProps,
    IImageContainerState,
} from './image-container.types';

export const useImageContainer = ({
    src,
    withBlurredBackground,
    containerDivProps = {},
    useLightMode = false,
}: IImageContainerProps) => {
    const error = useBooleanState(false);
    const loading = useBooleanState(true);

    const isLoading = loading.state;
    const isError = error.state;
    const isHidden = isLoading || isError;

    const handleError = () => {
        error.handleSetAsTrue();
    };
    const handleLoadingFinished = () => {
        loading.handleSetAsFalse();
    };

    const imageContainerHandlers: ImgHTMLAttributes<HTMLImageElement> = {
        onError: handleError,
        onLoad: handleLoadingFinished,
    };

    const imageContainerState: IImageContainerState = {
        src,
        isLoading,
        isError,
        isHidden,
    };

    return {
        imageContainerHandlers,
        imageContainerState,
        containerDivProps,
        withBlurredBackground,
        useLightMode,
    };
};
