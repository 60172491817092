import { createAction } from 'typesafe-actions';

import { ICreatedCondolence } from '../../interfaces/condolence';
import { ICondolenceFormValues } from '../../condolences/condolence-form-values';
import { ICreatedCondolenceComment } from '../../condolences/comment-condolence-modal';

import { CondolenceStatus } from './condolences.store';

export const condolenceFormSubmitted = createAction(
    'model/condolences/CONDOLENCE_FORM_SUBMITTED',
    (payload: ICondolenceFormValues) => payload,
)();

export const condolenceCreated = createAction(
    'model/condolences/CONDOLENCE_CREATED',
    (payload: ICreatedCondolence) => payload,
)();

export const condolencesDataReceived = createAction(
    'model/condolences/DATA_RECEIVED',
    (payload: { condolences: ICreatedCondolence[] }) => payload,
)();

export const condolenceEditRequested = createAction(
    'model/condolences/EDIT_REQUESTED',
    (payload: ICreatedCondolence) => payload,
)();

export const condolenceEditClear = createAction('model/condolences/CLEAR')();

export const condolenceUpdateRequested = createAction(
    'model/condolences/UPDATE_REQUESTED',
    (payload: ICreatedCondolence) => payload,
)();

export const condolenceDeletionRequested = createAction(
    'model/condolences/DELETION_REQUESTED',
    (payload: { id: string }) => payload,
)();

export const condolenceDeletionSucceeded = createAction(
    'model/condolences/DELETION_SUCCEEDED',
    (payload: { id: string }) => payload,
)();

export const condolenceCommentRequested = createAction(
    'model/condolences/ADD_COMMENT_REQUESTED',
    (payload: ICreatedCondolenceComment) => payload,
)();

export const setCondolenceStatus = createAction(
    'model/condolences/SET_CONDOLENCE_STATUS',
    (payload: CondolenceStatus | null) => payload,
)();

export const setCondolenceError = createAction(
    'model/condolences/SET_CONDOLENCE_ERROR',
    (payload: boolean) => payload,
)();

export const likeCondolenceRequested = createAction(
    'model/condolences/LIKE_REQUESTED',
    (payload: { id: string }) => payload,
)();

export const likeCondolenceSucceeded = createAction(
    'model/condolences/LIKE_SUCCEEDED',
    (payload: { id: string; likeToken?: string }) => payload,
)();

export const unlikeCondolenceRequested = createAction(
    'model/condolences/UNLIKE_REQUESTED',
    (payload: { id: string }) => payload,
)();

export const unlikeCondolenceSucceeded = createAction(
    'model/condolences/UNLIKE_SUCCEEDED',
    (payload: { id: string; likeToken?: string }) => payload,
)();

export const likeCondolenceCommentRequested = createAction(
    'model/condolences/comment/LIKE_REQUESTED',
    (payload: { id: string }) => payload,
)();

export const likeCondolenceCommentSucceeded = createAction(
    'model/condolences/comment/LIKE_SUCCEEDED',
    (payload: { id: string; likeToken?: string }) => payload,
)();

export const unlikeCondolenceCommentRequested = createAction(
    'model/condolences/comment/UNLIKE_REQUESTED',
    (payload: { id: string }) => payload,
)();

export const unlikeCondolenceCommentSucceeded = createAction(
    'model/condolences/comment/UNLIKE_SUCCEEDED',
    (payload: { id: string; likeToken?: string }) => payload,
)();
