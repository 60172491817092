import { createAction } from 'typesafe-actions';

import { ILoginFormValues } from '../login-form-values';
import { LoginErrorCode } from '../../api/DTOs/login-errors.dto';

export const loginFormSubmitted = createAction(
    'login/LOGIN_FORM_SUBMITTED',
    (payload: ILoginFormValues) => payload,
)();

export const loginApiErrorReceived = createAction(
    'login/LOGIN_API_ERROR_RECEIVED',
    (payload: { error: LoginErrorCode }) => payload,
)();
