import React, { useCallback, useEffect, useState } from 'react';
import { last } from 'lodash';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import cx from 'classnames';

import { getPreviewFromFile } from '../../utils/get-preview-from-file';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';

import styles from './file-uploader.module.scss';
import {
    FileMimeFormatForType,
    IFileUploaderProps,
} from './file-uploader-types';

export const FileUploader = ({
    fileType,
    label,
    onChange,
    maxFiles,
    className,
    openOnRender,
    displayPreview = true,
}: IFileUploaderProps) => {
    const [files, setFiles] = useState<File[]>([]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles.length) {
                onChange(acceptedFiles as File[]);
                setFiles(acceptedFiles as File[]);
            }
        },
        [onChange],
    );

    const uploadInputOptions: DropzoneOptions = {
        onDrop,
        maxSize: 200 * 1024 * 1024, // 200MB
        maxFiles,
        accept: fileType.join(','),
        multiple: maxFiles > 1,
    };

    const previewImage =
        files && files.length > 0 && getPreviewFromFile(last(files) as File);
    const { getRootProps, getInputProps, inputRef } = useDropzone(
        uploadInputOptions,
    );

    const onClick = useCallback(() => {
        inputRef.current!.click();
    }, [inputRef]);

    useEffect(() => {
        if (openOnRender) {
            onClick();
        }
    }, [onClick, openOnRender]);
    return (
        <div
            className={cx(styles.FileUploader, className)}
            onClick={onClick}
            {...getRootProps()}
        >
            {previewImage && displayPreview ? (
                <img
                    alt=""
                    className={styles.FileUploaderPreview}
                    src={previewImage}
                />
            ) : (
                <>
                    <span className={styles.FileUploaderIconContainer}>
                        <PlusIcon className={styles.FileUploaderIcon} />
                    </span>
                    {label && (
                        <span className={styles.FileUploaderLabel}>
                            {label}
                        </span>
                    )}
                </>
            )}
            <input {...getInputProps()} />
        </div>
    );
};

FileUploader.defaultProps = {
    fileType: [FileMimeFormatForType.Image, FileMimeFormatForType.Video],
    maxFiles: 1,
};
