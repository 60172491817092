import { apiService } from '../../api.service';
import { IUpdateCondolenceDto } from '../../DTOs/update-condolence.dto';
import { ICondolenceResponseDto } from '../../DTOs/condolence-response.dto';

export const updateCondolence = ({
    slug,
    id,
    dto,
}: {
    slug: string;
    id: string;
    dto: IUpdateCondolenceDto;
}): Promise<ICondolenceResponseDto> => {
    return apiService
        .patch(`/public/memorial_pages/${slug}/condolences/${id}`, {
            json: {
                condolence: dto,
            },
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
};
