import { createAction } from 'typesafe-actions';

import { FlowerDeliveryType } from '../../interfaces/flower-delivery-type';
import { ICondolenceFormValues } from '../../condolences/condolence-form-values';
import { PaymentMethod } from '../../api/payment/payment.types';

import { FlowersOrderStatus } from './flowers-order-status';
import { FlowerAddon, IFlowerInOrder } from './flowers-order.store';

const deliveryChosen = createAction(
    'model/flowers/DELIVERY_CHOSEN',
    (
        payload: {
            deliveryType: FlowerDeliveryType | null;
            address?: {
                address: string;
                city: string;
                postalCode: string;
            };
            contactData?: {
                name?: string;
                email: string;
                phone: string;
            };
            recipientData?: {
                name: string;
                email: string;
                phone?: string;
            };
        },
        meta: {
            isEdited: boolean;
            isFilledInSummary: boolean;
        },
    ) => ({ payload, meta }),
)();

const categoryChosen = createAction(
    'model/flowers/CATEGORY_CHOSEN',
    (payload: { categoryID: string }) => payload,
)();

const productChosen = createAction(
    'model/flowers/PRODUCT_CHOSEN',
    (payload: { productID: string; variantID: string }) => payload,
)();

const localProductCreated = createAction(
    'model/flowers/LOCAL_PRODUCT_CREATED',
    (payload: { productID: string; variantID: string; localID: string }) =>
        payload,
)();

const detailsSet = createAction(
    'model/flowers/DETAILS_SET',
    (payload: {
        leftBand?: string;
        rightBand?: string;
        card?: string;
        selectedAddon: FlowerAddon;
    }) => payload,
)();

const variantChanged = createAction(
    'model/flowers/VARIANT_CHANGED',
    (payload: { localID: string; newVariant: string }) => payload,
)();

const quantityChanged = createAction(
    'model/flowers/QUANTITY_CHANGED',
    (payload: { localID: string; newQuantity: number }) => payload,
)();

const productEditRequested = createAction(
    'model/flowers/PRODUCT_EDIT_REQUESTED',
    (payload: { localID: string }) => payload,
)();

const productDeleteRequested = createAction(
    'model/flowers/PRODUCT_DELETE_REQUESTED',
    (payload: { localID: string }) => payload,
)();

const paymentRequested = createAction('model/flowers/PAYMENT_REQUESTED')();

const productEditPrepared = createAction(
    'model/flowers/PRODUCT_EDIT_PREPARED',
    (payload: IFlowerInOrder) => payload,
)();

const setFlowerOrderCondolence = createAction(
    'model/flowers/SET_FLOWER_ORDER_CONDOLENCE',
    (payload: ICondolenceFormValues | null) => payload,
)();

/**
 * Call in cart summary initially and each time order changes, so backend will calculate
 * prices etc
 */
const cartCalculationRequested = createAction(
    'model/flowers/CART_CALCULATION_REQUESTED',
)();

const deliveryEditRequested = createAction(
    'model/flowers/DELIVERY_EDIT_REQUESTED',
)();

const orderSubmitted = createAction(
    'model/flowers/ORDER_SUBMITTED',
    (payload?: ICondolenceFormValues | undefined) => payload,
)();

const setPaymentMethod = createAction(
    'model/flowers/SET_PAYMENT_METHOD',
    (payload: PaymentMethod) => payload,
)();

export const featuredFlowerBuyRequested = createAction(
    'model/flowers/FEATURED_FLOWER_BUY_REQUESTED',
    (payload: { id: string; categoryId: string }) => payload,
)();

const lastProductInCartRemoved = createAction(
    'model/flowers/LAST_PRODUCT_IN_CART_REMOVED',
)();

const paymentFailed = createAction(
    'model/flowers/PAYMENT_FAILED',
    (payload: { errorCode: string; errorMessage: string }) => payload,
)();

const resetLoading = createAction('model/flowers/RESET_LOADING')();

const paymentSucceeded = createAction('model/flowers/PAYMENT_SUCCEEDED')();

const paymentCancelled = createAction('model/flowers/PAYMENT_CANCELLED')();

const orderConfirmationClosed = createAction(
    'model/flowers/ORDER_CONFIRMATION_CLOSED',
)();

const setFlowersOrderStatus = createAction(
    'model/condolences/SET_FLOWERS_ORDER_STATUS',
    (payload: FlowersOrderStatus | null) => payload,
)();

export const FlowersFlowActions = {
    deliveryChosen,
    resetLoading,
    categoryChosen,
    productChosen,
    detailsSet,
    variantChanged,
    quantityChanged,
    productEditRequested,
    productDeleteRequested,
    paymentRequested,
    localProductCreated,
    productEditPrepared,
    cartCalculationRequested,
    deliveryEditRequested,
    setFlowerOrderCondolence,
    orderSubmitted,
    lastProductInCartRemoved,
    paymentCancelled,
    paymentFailed,
    paymentSucceeded,
    orderConfirmationClosed,
    setFlowersOrderStatus,
    setPaymentMethod,
};
