export enum FlowerDeliveryType {
    PRIVATE_ADDRESS = 'to_private_address',
    CEREMONY = 'to_ceremony',
    GRAVE = 'to_cemetery',
}

export interface IFlowerDeliveryType {
    id: string;
    locationRequired: boolean;
    recipientRequired: boolean;
    name: FlowerDeliveryType;
}
