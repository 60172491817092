import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { times } from 'lodash';

import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import { Basket } from '../basket/basket';

import styles from './progress-steps.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
    stepsNo?: number;
    activeStep: number;
    productsInBasketNo: number;
    onSummaryStepClick: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void;
}

const renderCompletedStep = (i: number) => (
    <div key={i} className={cx(styles.StepItem, styles.Completed)}>
        <CheckIcon />
    </div>
);

const renderActiveStep = (i: number) => (
    <div key={i} className={cx(styles.StepItem, styles.Active)} />
);

const renderFutureStep = (i: number) => (
    <div key={i} className={cx(styles.StepItem, styles.FutureStep)} />
);

const renderSummaryStep = (
    i: number,
    productsInCart: number,
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
) => {
    if (productsInCart > 0) {
        return (
            <button
                key={i}
                className={cx(styles.StepItem, styles.FutureStep)}
                onClick={onClick}
            >
                <Basket productsInBasketNo={productsInCart} />
            </button>
        );
    }
    return <div key={i} className={cx(styles.StepItem, styles.FutureStep)} />;
};

export const ProgressSteps = ({
    stepsNo = 4,
    activeStep,
    className,
    productsInBasketNo,
    onSummaryStepClick,
    ...props
}: IProps) => {
    if (activeStep + 1 > stepsNo) {
        throw new Error(
            'Active step has to be not bigger than total steps count',
        );
    }

    const renderStep = (step: number) => {
        if (step === activeStep) {
            return renderActiveStep(step);
        }
        if (step === stepsNo - 1) {
            return renderSummaryStep(
                step,
                productsInBasketNo,
                onSummaryStepClick,
            );
        }
        if (step < activeStep) {
            return renderCompletedStep(step);
        }
        if (step > activeStep) {
            return renderFutureStep(step);
        }

        return null;
    };

    const getProgressWidth = () => {
        if (activeStep === 0) {
            return '0%';
        }

        return `${(activeStep / (stepsNo - 1)) * 100}%`;
    };

    return (
        <div className={cx(styles.Container, className)} {...props}>
            <div
                className={styles.ProgressBar}
                style={{
                    width: getProgressWidth(),
                }}
            />
            {times(stepsNo).map((i) => {
                return renderStep(i);
            })}
        </div>
    );
};
