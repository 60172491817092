import { IGalleryPhoto } from '../../../../interfaces/gallery-photo';

export const renderImgTitle = (image: IGalleryPhoto) => {
    if (!!image.title && !!image.year) {
        return `${image.title} (${image.year})`;
    }
    if (!!image.title && !image.year) {
        return image.title;
    }
    if (!image.title && !!image.year) {
        return image.year;
    }
    return '';
};
