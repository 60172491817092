import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { contactSuggestionApiActions } from '../contact-suggestion.actions';

import { createContactSuggestionApiSaga } from './create-contact-suggestion-api.saga';

export function* contactSuggestionApiSaga() {
    yield takeEvery(
        getType(contactSuggestionApiActions.request),
        createContactSuggestionApiSaga,
    );
}
