import { createSelector } from 'reselect';

import { IFlowerShop } from '../../../interfaces/flower-shop';

import { getFlowerShopProductsSelector } from './get-flower-shop-products.selector';

export const getFlowerShopCategoryProductsSelector = (
    categoryId?: string | null,
) =>
    createSelector(
        getFlowerShopProductsSelector,
        (products): IFlowerShop['products'] => {
            return categoryId
                ? products.filter(
                      (product) => product.categoryID === categoryId,
                  )
                : [];
        },
    );
