import { apiService } from '../../api.service';
import { ILikeCondolenceResponseDto } from '../../DTOs/like-condolence-response.dto';

export const likeCondolence = ({
    slug,
    id,
}: {
    slug: string;
    id: string;
}): Promise<ILikeCondolenceResponseDto> => {
    return apiService
        .post(`/public/memorial_pages/${slug}/condolences/${id}/like`, {
            throwHttpErrors: false,
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes);
};
