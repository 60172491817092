import React from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from '../../components/section/section';
import { IDeathNotice } from '../../interfaces/death-notice';
import { DownloadsItem } from '../downloads-item/downloads-item';
import { ILeaflet } from '../../interfaces/leaflet';

import styles from './downloads-display.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    downloads: Array<IDeathNotice | ILeaflet>;
};

export const DownloadsDisplay = React.forwardRef(
    (
        { className, downloads, ...props }: Props,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const { t } = useTranslation();

        return (
            <Section
                className={className}
                headerClassName={styles.SectionHeader}
                headerText={t('gallery.downloadsTitle')}
                actions={<div className={styles.SectionActions} />}
                ref={ref}
                {...props}
            >
                <div className={styles.Gallery}>
                    {downloads.map((download, idx) => (
                        <DownloadsItem
                            key={idx}
                            image={download}
                            title={`downloads_${idx}`}
                        />
                    ))}
                </div>
            </Section>
        );
    },
);
