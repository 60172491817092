import React from 'react';
import { compose, withProps } from 'recompose';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';

import { ILocation } from '../../interfaces/location';
import MarkerIcon from '../../assets/icons/map-marker.svg';

import styles from './Map.module.scss';

interface IProps {
    location: ILocation;
}

const GOOGLE_MAP = {
    API_URL: 'https://maps.googleapis.com/maps/api/js',
    API_KEY: 'AIzaSyDjxAoGaQ6b9DqklfYf0M_da0oLCxJ48Eo',
    get URL() {
        return `${this.API_URL}?key=${this.API_KEY}`;
    },
};

const Map = ({ location }: IProps) => {
    const position: google.maps.LatLngLiteral = {
        lat: Number(location.latitude),
        lng: Number(location.longitude),
    };

    const icon: google.maps.Icon = {
        url: MarkerIcon,
    };

    const options: google.maps.MapOptions = {
        controlSize: 30,
        keyboardShortcuts: false,
    };

    return (
        <GoogleMap
            defaultZoom={15}
            defaultCenter={position}
            clickableIcons={false}
            options={options}
        >
            <Marker position={position} icon={icon} />
        </GoogleMap>
    );
};

export default compose<IProps, any>(
    withProps({
        googleMapURL: `${GOOGLE_MAP.URL}&libraries=places`,
        loadingElement: <div className={styles.Container} />,
        containerElement: <div className={styles.Container} />,
        mapElement: <div className={styles.Container} />,
    }),
    withScriptjs,
    withGoogleMap,
)(Map);
