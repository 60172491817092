export const THEME = {
    DEFAULT: 'default',
    VG: 'vg',
};

export const THEME_DETAILS = {
    default: {
        logo: 'VG_logo.png',
        additionalText: '',
        secondAdditionalText: '',
        link: '',
    },
    vg: {
        logo: 'VG_logo.png',
        additionalText: 'MINNESIDER',
        secondAdditionalText: 'EN TJENESTE FRA VG',
        link: 'https://www.vg.no/minnesider',
    },
};
