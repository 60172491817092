import React, { RefObject, useImperativeHandle } from 'react';
import { Player, Video } from '@vime/react';

import { VideoPlayerMask } from './components/video-player-mask/video-player-mask';
import { VideoPlayerControls } from './components/video-player-controls/video-player-controls';
import { useVideoPlayer } from './use-video-player';
import { IVideoPlayerRef, IVideoPlayerProps } from './video-player.types';
import { VIDEO_PLAYER_THEME } from './video-player.constants';

export const VideoPlayer = React.forwardRef(
    (
        { controlsOptions, playerOptions, src }: IVideoPlayerProps,
        ref: RefObject<IVideoPlayerRef>,
    ) => {
        const { playerRef, playerState, playerHandlers } = useVideoPlayer({
            playerOptions,
            src,
        });

        useImperativeHandle(ref, () => {
            return {
                pause: async () => {
                    if (playerRef.current?.playing) {
                        await playerRef.current?.pause();
                    }
                },
            };
        });

        return (
            <Player
                ref={playerRef}
                style={VIDEO_PLAYER_THEME}
                {...playerHandlers}
            >
                <VideoPlayerMask playerState={playerState} />
                {playerRef && (
                    <>
                        <VideoPlayerControls
                            {...controlsOptions}
                            showSpinner={playerState.isReady}
                        />
                        <Video>
                            <source type="video/mp4" src={playerState.src} />
                        </Video>
                    </>
                )}
            </Player>
        );
    },
);
