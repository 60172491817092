import { IRoute } from './route';

export enum NavigationLinkIds {
    DETAILS = 'details',
    SEND_FLOWERS = 'sendFlowers',
    SEND_DONATION = 'sendDonation',
    SEND_CONDOLENCE = 'sendCondolence',
    GALLERY = 'gallery',
    SHARE = 'share',
    REQUEST_ACCESS = 'requestAccess',
    LOGIN = 'login',
    SUGGEST_GUEST = 'suggestGuest',
}

export interface INavRoute extends IRoute {
    id: NavigationLinkIds;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
    promoted?: boolean;
    isButton?: boolean;
}
