import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FlowerDeliveryType } from '../../interfaces/flower-delivery-type';
import { FlowersSelectors } from '../../model/flowers/flowers.selector';
import { checkIfDeliveryRecipientIsRequired } from '../../../shared/helpers/checkIfDeliveryRecipientIsRequired';
import { checkIfDeliveryAddressIsRequired } from '../../../shared/helpers/checkIfDeliveryAddressIsRequired';

export const useFlowerDeliveryState = () => {
    const deliveryTypes = useSelector(FlowersSelectors.getFlowerDeliveryTypes);
    const deliveryInStore = useSelector(
        FlowersSelectors.getSelectedDeliveryType,
    );

    const [
        activeDelivery,
        setActiveDelivery,
    ] = useState<FlowerDeliveryType | null>(deliveryInStore || null);

    const ceremonyDeliveryAvailable = Boolean(
        deliveryTypes &&
            deliveryTypes.find(
                (delivery) => delivery.name === FlowerDeliveryType.CEREMONY,
            ),
    );

    const graveDeliveryAvailable = Boolean(
        deliveryTypes &&
            deliveryTypes.find(
                (delivery) => delivery.name === FlowerDeliveryType.GRAVE,
            ),
    );

    const privateAddressDeliveryAvailable = Boolean(
        deliveryTypes &&
            deliveryTypes.find(
                (delivery) =>
                    delivery.name === FlowerDeliveryType.PRIVATE_ADDRESS,
            ),
    );

    useEffect(() => {
        if (
            deliveryTypes &&
            deliveryInStore &&
            deliveryTypes.find((delivery) => delivery.name === deliveryInStore)
        ) {
            setActiveDelivery(deliveryInStore);
        } else if (deliveryTypes && ceremonyDeliveryAvailable) {
            setActiveDelivery(FlowerDeliveryType.CEREMONY);
        } else if (deliveryTypes) {
            setActiveDelivery(deliveryTypes[0]?.name);
        }
    }, [deliveryTypes, deliveryInStore, ceremonyDeliveryAvailable]);

    const isDeliveryRecipientDataRequired = checkIfDeliveryRecipientIsRequired(
        deliveryTypes,
        activeDelivery,
    );

    const isDeliveryAddressDataRequired = checkIfDeliveryAddressIsRequired(
        deliveryTypes,
        activeDelivery,
    );

    return {
        activeDelivery,
        deliveryTypes,
        setActiveDelivery,
        ceremonyDeliveryAvailable,
        graveDeliveryAvailable,
        privateAddressDeliveryAvailable,
        deliveryInStore,
        isDeliveryRecipientDataRequired,
        isDeliveryAddressDataRequired,
    };
};
