import React from 'react';
import Container from 'react-images/lib/components/Container';

import styles from '../../../../gallery/gallery-display/gallery-display.module.scss';
import { ImageCarouselGalleryItem } from '../image-carousel-gallery-item/image-carousel-gallery-item';
import { ImageCarouselGalleryExpander } from '../image-carousel-gallery-expander/image-carousel-gallery-expander';

import { useImageCarouselGalleryWrapper } from './use-image-carousel-gallery-wrapper';
import { ImageCarouselGalleryWrapperProps } from './image-carousel-gallery-wrapper.types';

export const ImageCarouselGalleryWrapper = React.memo(
    (props: ImageCarouselGalleryWrapperProps) => {
        const {
            isFullscreen,
            filteredPhotos,
            handleGalleryItemClick,
            handleExpandGallery,
            showExpander,
            expanderPhotosCount,
            expanderBackgroundUrl,
        } = useImageCarouselGalleryWrapper(props);

        return (
            <Container {...props}>
                {isFullscreen ? (
                    props.children
                ) : (
                    <div className={styles.Gallery}>
                        {filteredPhotos.map((photo, index) => {
                            const handleClick = () => {
                                handleGalleryItemClick(index);
                            };
                            return (
                                <ImageCarouselGalleryItem
                                    key={photo.id}
                                    image={photo}
                                    onClick={handleClick}
                                />
                            );
                        })}
                        {showExpander && (
                            <ImageCarouselGalleryExpander
                                backgroundUrl={expanderBackgroundUrl}
                                onClick={handleExpandGallery}
                                photosCount={expanderPhotosCount}
                            />
                        )}
                    </div>
                )}
            </Container>
        );
    },
);
