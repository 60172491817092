import { call, put, select } from '@redux-saga/core/effects';

import { volunteerTaskApiActions } from '../tasks-api.actions';
import { volunteerTask } from '../http/volunteer-task';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { TaskVolunteerStatusCode } from '../../../interfaces/task-volunteer-status';

export function* volunteerTaskSaga(
    action: ReturnType<typeof volunteerTaskApiActions.request>,
) {
    const { taskId, email } = action.payload;
    const slug = yield select(getPageSlug);

    const response = yield call(volunteerTask, {
        slug,
        data: { taskId, email },
    });

    if (!response.errors) {
        yield put(
            volunteerTaskApiActions.success({
                taskId,
                status: TaskVolunteerStatusCode.SUCCESS,
            }),
        );
    } else {
        yield put(
            volunteerTaskApiActions.failure({
                taskId,
                status:
                    response.errors[0].code === 'is_taken'
                        ? TaskVolunteerStatusCode.EMAIL_ALREADY_USED
                        : TaskVolunteerStatusCode.ERROR,
            }),
        );
    }
}
