import * as yup from 'yup';

const MAX_CONDOLENCE_LENGTH = 3000;

export const condolenceFormValidation = () =>
    yup.object().shape(
        {
            displayName: yup.string().required(),
            content: yup.string().when(['candle', 'heart'], {
                is: (candle: boolean, heart: boolean) => {
                    return !candle && !heart;
                },
                then: yup
                    .string()
                    .max(
                        MAX_CONDOLENCE_LENGTH,
                        'condolenceForm.tooLongCondolence',
                    )
                    .required(),
            }),
            candle: yup.boolean().oneOf([true, false]),
            heart: yup.boolean().oneOf([true, false]),
        },
        [
            ['content', 'candle'],
            ['content', 'heart'],
        ],
    );
