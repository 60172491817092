import { put } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { tasksDataReceived } from '../tasks.actions';
import { ITask } from '../../../interfaces/task';

export function* getTasksDataFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const memorialPageData = action.payload;

    try {
        const tasks: ITask[] = memorialPageData.included
            .filter((inclusion) => inclusion.type === 'task')
            .map((data: any) => ({
                description: data.attributes.description,
                expirationDate: data.attributes.deadlineTimestamp,
                id: data.id,
                status: data.attributes.status,
                task: data.attributes.title,
            }));

        yield put(
            tasksDataReceived({
                tasks,
            }),
        );
    } catch (e) {
        /**
         * TODO: Handle missing data and assume privacy mode is set
         */
        console.log('Error getting tasks from memorial page');
        console.error(e);
    }
}
