import { INavRoute, NavigationLinkIds } from '../interfaces/nav-route';
import { ReactComponent as DetailsIcon } from '../assets/icons/details-icon.svg';
import { ReactComponent as SendFlowersIcon } from '../assets/icons/send-flowers-icon.svg';
import { ReactComponent as SendCondolenceIcon } from '../assets/icons/edit-icon.svg';
import { ReactComponent as GalleryIcon } from '../assets/icons/gallery-icon.svg';
import { ReactComponent as AccountIcon } from '../assets/icons/account.svg';
import { DonationIcon } from '../assets/icons/donation-icon';

import { RoutePaths } from './route-paths';

export const NavigationLinks: INavRoute[] = [
    {
        id: NavigationLinkIds.DETAILS,
        href: RoutePaths.HOME,
        icon: DetailsIcon,
    },
    {
        id: NavigationLinkIds.SEND_FLOWERS,
        href: RoutePaths.FLOWERS_ROOT,
        icon: SendFlowersIcon,
        promoted: true,
    },
    {
        id: NavigationLinkIds.SEND_DONATION,
        href: RoutePaths.DONATION_ROOT,
        icon: DonationIcon,
    },
    {
        id: NavigationLinkIds.SEND_CONDOLENCE,
        href: RoutePaths.HOME,
        state: { scrollToCondolence: true },
        icon: SendCondolenceIcon,
        isButton: true,
    },
    {
        id: NavigationLinkIds.GALLERY,
        href: RoutePaths.GALLERY,
        icon: GalleryIcon,
    },
    {
        id: NavigationLinkIds.SHARE,
        href: RoutePaths.SHARE,
        icon: AccountIcon,
    },
];

export const RequestAccessLink: INavRoute = {
    id: NavigationLinkIds.REQUEST_ACCESS,
    href: RoutePaths.REQUEST_ACCESS,
    icon: AccountIcon,
};
