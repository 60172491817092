import React from 'react';
import cx from 'classnames';

import { Button } from '../button/button';

import styles from './primary-button.module.scss';

export type PrimaryButtonProps = React.HTMLProps<HTMLButtonElement> & {
    icon?:
        | React.FC<React.SVGProps<SVGSVGElement>>
        | React.FC<React.HTMLAttributes<HTMLDivElement>>;
    iconBoxClassName?: string;
    iconClassName?: string;
    type?: 'button' | 'submit' | 'reset';
};

export const PrimaryButton = ({
    children,
    className,
    iconBoxClassName,
    iconClassName,
    disabled,
    ...props
}: PrimaryButtonProps) => {
    return (
        <Button
            className={cx(styles.PrimaryButton, className, {
                [styles.ButtonDisabled]: disabled,
            })}
            iconBoxClassName={cx(styles.PrimaryButtonIconBox, iconBoxClassName)}
            iconClassName={iconClassName}
            {...props}
        >
            {children}
        </Button>
    );
};
