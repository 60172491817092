import { put } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { languageChangeRequested } from '../configuration.actions';
import { getLanguageFromStorage } from '../../../utils/language-storage-utils';

export function* getLanguageFromMemorialPage(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const lang = action.payload.data.attributes.locale;
    const languageFromStorage = getLanguageFromStorage();
    const code = languageFromStorage && languageFromStorage.code;

    yield put(
        languageChangeRequested({
            language: {
                code: code || lang,
            },
        }),
    );
}
