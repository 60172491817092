import React, { ChangeEvent, HTMLAttributes, InputHTMLAttributes } from 'react';
import cx from 'classnames';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { useSelector } from 'react-redux';

import { getLanguage } from '../../model/configuration/selectors/get-language.selector';
import { convertLangCode } from '../../utils/convert-lang-code';

import styles from './phone-input.module.scss';

const INPUT_HEIGHT = 50;

type Country = {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
};

interface IProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
    name: string;
    value?: any;
    onChange(event: ChangeEvent<HTMLInputElement> | string): unknown;
    inputProps?: Omit<
        InputHTMLAttributes<HTMLInputElement>,
        'name' | 'value' | 'onChange'
    >;
    dropdownToTop?: boolean;
    error?: boolean;
}

export const PhoneInput = ({
    className,
    value,
    onChange,
    placeholder,
    name,
    inputProps,
    dropdownToTop,
    error,
    ...props
}: IProps) => {
    const prefix = '+';
    const language = useSelector(getLanguage);
    const code = convertLangCode(language.code);

    const currentDialCode = React.useRef<string>('');

    const handleOnChange = (newValue: string, data: Country) => {
        currentDialCode.current = data.dialCode;

        const isEmpty = !newValue || newValue === data.dialCode;

        if (isEmpty) {
            onChange('');
        } else {
            onChange(prefix + newValue);
        }
    };

    const nonNullishValue =
        value && value !== '' ? value : `${prefix}${currentDialCode.current}`;

    return (
        <div className={cx(className, styles.Wrapper)} {...props}>
            <div className={styles.Container}>
                <ReactPhoneInput
                    prefix={prefix}
                    inputClass={cx(
                        styles.TextField,
                        styles.PhoneInput,
                        styles.open,
                        { [styles.Error]: error },
                    )}
                    country={code}
                    buttonClass={styles.Button}
                    value={nonNullishValue}
                    onChange={handleOnChange}
                    placeholder={placeholder}
                    inputProps={inputProps}
                    dropdownStyle={
                        dropdownToTop ? { bottom: INPUT_HEIGHT } : {}
                    }
                />
            </div>
        </div>
    );
};
