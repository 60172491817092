export enum LoginErrorCode {
    INVALID_CREDENTIALS = 'invalid_credentials',
    USER_DISABLED = 'user_disabled',
    UNKNOWN = 'unknown',
}

export type LoginErrorsDto = Array<{
    code: LoginErrorCode;
    title: string;
}>;
