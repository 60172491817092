import { useSelector } from 'react-redux';

import { getDeceasedAvatarImage } from '../selectors/get-deceased-avatar-image';
import { getDeceasedBackgroundImage } from '../selectors/get-deceased-background-image';

export const useDeceasedDisplayImages = () => {
    const avatar = useSelector(getDeceasedAvatarImage);
    const background = useSelector(getDeceasedBackgroundImage);

    return {
        avatar,
        background,
    };
};
