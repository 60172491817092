import { apiService } from '../../api.service';
import { IImageUploadSessionResponseDto } from '../../DTOs/image-upload-session-response.dto';

export const getImageUploadSession = ({
    file,
}: {
    file: File;
}): Promise<IImageUploadSessionResponseDto> => {
    const { name, type } = file;

    return apiService
        .get(`/media/presign?filename=${name}&type=${type}`, {
            headers: {
                'Key-Inflection': 'snake',
            },
        })
        .then((resp) => resp.json());
};
