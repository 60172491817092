import { spawn } from 'redux-saga/effects';

import { flowerShopApiSaga } from './flower-shop/saga/flower-shop-api.saga';
import { flowersDeliveryApiSaga } from './delivery/saga/flowers-delivery-api.saga';
import { flowersCartApiSaga } from './cart/saga/flowers-cart-api.saga';

export function* flowersApiSaga() {
    yield spawn(flowersDeliveryApiSaga);
    yield spawn(flowersCartApiSaga);
    yield spawn(flowerShopApiSaga);
}
