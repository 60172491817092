import { put, take } from '@redux-saga/core/effects';

import { downloadGalleryApiActions } from '../../../api/gallery/gallery-api.actions';
import { setDownloadingGalleryInProgress } from '../gallery.actions';

export function* handleDownloadGallerySaga() {
    yield put(downloadGalleryApiActions.request());
    yield put(setDownloadingGalleryInProgress(true));

    const resp: ReturnType<typeof downloadGalleryApiActions.success> = yield take(
        downloadGalleryApiActions.success,
    );

    yield put(setDownloadingGalleryInProgress(false));

    // eslint-disable-next-line
    location.replace(resp.payload.url);
}
