import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../components/text-field/text-field';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import { FieldErrorMessage } from '../../components/error-message/error-message';
import { ISetNewPasswordFormValues } from '../set-new-password-form-values';
import { setNewPasswordFormValidation } from '../set-new-password-form-validation';

import styles from './set-new-password-form.module.scss';

type OuterProps = {
    onSubmit(values: ISetNewPasswordFormValues): unknown;
} & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

type Props = FormikProps<ISetNewPasswordFormValues> & OuterProps;

export const SetNewPasswordForm = ({
    className,
    handleChange,
    handleSubmit,
    values,
    errors,
    handleBlur,
    dirty,
    isValid,
}: Props) => {
    const { t } = useTranslation();
    const canSave = dirty && isValid;
    return (
        <form className={cx(className)} onSubmit={handleSubmit}>
            <p className={styles.HintText}>{t('setNewPassword.hintText')}</p>
            <div className={styles.InputsContainer}>
                <div className={styles.Control}>
                    <TextField
                        autoFocus
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('setNewPassword.password')}
                        value={values.password}
                        error={Boolean(errors.password)}
                        type="password"
                    />
                    <FieldErrorMessage name="password" />
                </div>
                <div className={styles.Control}>
                    <TextField
                        name="repeatPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('setNewPassword.repeatPassword')}
                        value={values.repeatPassword}
                        error={Boolean(errors.repeatPassword)}
                        type="password"
                    />
                    <FieldErrorMessage name="repeatPassword" />
                </div>
            </div>
            <PrimaryButton
                className={styles.SubmitButton}
                icon={ArrowIcon}
                type="submit"
                disabled={!canSave}
            >
                {t('setNewPassword.submitButton')}
            </PrimaryButton>
        </form>
    );
};

export const ConnectedSetNewPasswordForm = compose<Props, OuterProps>(
    withFormik<OuterProps, ISetNewPasswordFormValues>({
        mapPropsToValues() {
            return {
                password: '',
                repeatPassword: '',
            };
        },
        handleSubmit(values, formikBag) {
            formikBag.props.onSubmit(values);
        },
        validationSchema: setNewPasswordFormValidation,
    }),
)(SetNewPasswordForm);
