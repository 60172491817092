import { createAction } from 'typesafe-actions';

import { IAccessRequestFormValues } from './access-request-form-values';
import { AccessRequestStatus } from './access-request-status';

export const accessRequestFormSubmitted = createAction(
    'access-request/FORM_SUBMITTED',
    (payload: IAccessRequestFormValues) => payload,
)();

export const setAccessRequestStatus = createAction(
    'access-request/SET_ACCESS_REQUEST_STATUS',
    (payload: AccessRequestStatus | null) => payload,
)();
