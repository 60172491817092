import { createReducer } from 'typesafe-actions';

import { FlowerDeliveryType } from '../../interfaces/flower-delivery-type';
import { RootActions } from '../../store/actions';
import {
    createOrderApiActions,
    createPaymentApiActions,
    fetchCartCalculationApiActions,
} from '../../api/flowers/cart/cart-api.actions';
import { IFlowerSummaryCost } from '../../interfaces/flower-summary-cost';
import { ICondolenceFormValues } from '../../condolences/condolence-form-values';
import { ICreateOrderRequestDto } from '../../api/DTOs/create-order-request.dto';
import { ICreateOrderResponseDto } from '../../api/DTOs/create-order-response.dto';
import { fetchFlowerShopApiActions } from '../../api/flowers/flower-shop/flower-shop-api.actions';
import { PaymentMethod } from '../../api/payment/payment.types';

import { FlowersFlowActions } from './flowers.actions';
import { FlowersOrderStatus } from './flowers-order-status';

export enum FlowerAddon {
    BANDS_AND_CARD = 'BANDS_AND_CARD',
    CARD = 'CARD',
}

export interface IFlowerInOrder {
    /**
     * Unique ID only for frontend needs. It will distinguish same flowers with different
     * configurations
     */
    id: string;
    /**
     * ID corresponding ID with flower ID in the backend
     */
    productID: string;
    leftBandText: string | null;
    rightBandText: string | null;
    cardText: string | null;
    quantity: number;
    variantID: string;
    addon: FlowerAddon;
}

export interface IUserOrderStore {
    cartFlowersByID: {
        [flowerLocalID: string]: IFlowerInOrder;
    };
    deliveryType: FlowerDeliveryType | null;
    deliveryAddress: {
        address: string;
        city: string;
        postalCode: string;
    } | null;
    currentProcessedFlower: Partial<IFlowerInOrder>;
    contactData: null | {
        name?: string;
        email: string;
        phone: string;
    };
    recipientData: null | {
        name: string;
        email: string;
        phone?: string;
    };
    /**
     * Store band price to calculate product.
     * TODO: This should be rather part of cart calculation, so its "temporary" here
     */
    bandPrice: number;
    summary: IFlowerSummaryCost | null;
    flowerOrderCondolence: ICondolenceFormValues | null;

    loading: boolean;
    flowersOrderStatus: FlowersOrderStatus | null;
    result: {
        errorCode: null | string; // TODO: Enum
        status: 'success' | 'error' | 'cancel' | undefined;
    };

    receivedPaymentSessionId: string | null;
    submittedOrderDto:
        | (ICreateOrderRequestDto & ICreateOrderResponseDto)
        | null;
    paymentMethod: PaymentMethod;
}

const initialState: IUserOrderStore = {
    deliveryAddress: null,
    deliveryType: null,
    cartFlowersByID: {},
    currentProcessedFlower: {},
    contactData: null,
    recipientData: null,
    summary: null,
    bandPrice: 0,
    flowerOrderCondolence: null,
    loading: false,
    flowersOrderStatus: null,
    result: {
        errorCode: null,
        status: undefined,
    },
    receivedPaymentSessionId: null,
    submittedOrderDto: null,
    paymentMethod: PaymentMethod.CREDIT_CARD,
};

const {
    deliveryAddress,
    recipientData,
    contactData,
    ...paymentSucceed
}: Partial<IUserOrderStore> = initialState;

export const createUserOrderReducer = (
    injectedState: IUserOrderStore = initialState,
) =>
    createReducer<IUserOrderStore, RootActions>(injectedState)
        .handleAction(FlowersFlowActions.categoryChosen, (state, action) => {
            return {
                ...state,
                /**
                 * Going to category choosing means flow is reset
                 */
                currentProcessedFlower: {},
            };
        })
        .handleAction(
            FlowersFlowActions.localProductCreated,
            (
                state,
                { payload: { variantID, productID, localID } },
            ): IUserOrderStore => {
                return {
                    ...state,
                    /**
                     * Going to category choosing means flow is reset
                     */
                    currentProcessedFlower: {
                        ...state.currentProcessedFlower,
                        productID,
                        variantID,
                        id: localID,
                        quantity: 1,
                        addon: FlowerAddon.BANDS_AND_CARD,
                    },
                };
            },
        )
        .handleAction(
            FlowersFlowActions.detailsSet,
            (
                state,
                { payload: { card, leftBand, rightBand, selectedAddon } },
            ): IUserOrderStore => {
                const flower = {
                    ...state.currentProcessedFlower,
                    cardText: card || null,
                    leftBandText: leftBand || null,
                    rightBandText: rightBand || null,
                    addon: selectedAddon,
                };

                return {
                    ...state,
                    /**
                     * At this stage flower is added to cart, so its no longer edited
                     */
                    currentProcessedFlower: {},
                    cartFlowersByID: {
                        ...state.cartFlowersByID,
                        [flower.id as string]: flower as IFlowerInOrder,
                    },
                };
            },
        )
        .handleAction(
            FlowersFlowActions.variantChanged,
            (state, { payload: { localID, newVariant } }) => {
                if (state.currentProcessedFlower.id === localID) {
                    return {
                        ...state,
                        currentProcessedFlower: {
                            ...state.currentProcessedFlower,
                            variantID: newVariant,
                        },
                    };
                }

                const newState = { ...state };
                newState.cartFlowersByID[localID].variantID = newVariant;

                return newState;
            },
        )
        .handleAction(
            FlowersFlowActions.quantityChanged,
            (state, { payload: { localID, newQuantity } }) => {
                if (state.currentProcessedFlower.id === localID) {
                    return {
                        ...state,
                        currentProcessedFlower: {
                            ...state.currentProcessedFlower,
                            quantity: newQuantity,
                        },
                    };
                }

                const newState = { ...state };
                newState.cartFlowersByID[localID].quantity = newQuantity;

                return newState;
            },
        )
        .handleAction(
            FlowersFlowActions.productEditPrepared,
            (state, { payload }) => {
                return {
                    ...state,
                    currentProcessedFlower: payload,
                };
            },
        )
        .handleAction(
            FlowersFlowActions.productDeleteRequested,
            (state, { payload: { localID } }) => {
                const newState = { ...state };

                delete newState.cartFlowersByID[localID];

                return newState;
            },
        )
        .handleAction(
            FlowersFlowActions.lastProductInCartRemoved,
            (state, action) => ({
                ...state,
                summary: null,
            }),
        )
        .handleAction(FlowersFlowActions.setPaymentMethod, (state, action) => ({
            ...state,
            paymentMethod: action.payload,
        }))
        .handleAction(FlowersFlowActions.deliveryChosen, (state, action) => {
            return {
                ...state,
                deliveryType: action.payload.payload.deliveryType,
                deliveryAddress: action.payload.payload.address || null,
                contactData: action.payload.payload.contactData || null,
                recipientData: action.payload.payload.recipientData || null,
            };
        })
        .handleAction(
            fetchCartCalculationApiActions.success,
            (state, action) => ({
                ...state,
                summary: action.payload,
                bandPrice: action.payload.bandPriceGross,
            }),
        )
        .handleAction(FlowersFlowActions.orderSubmitted, (state, action) => ({
            ...state,
            loading: true,
            flowerOrderCondolence: action.payload || null,
        }))
        .handleAction(
            [
                fetchFlowerShopApiActions.failure,
                FlowersFlowActions.resetLoading,
            ],
            (state, action) => ({
                ...state,
                loading: false,
            }),
        )
        .handleAction(createOrderApiActions.request, (state, action) => ({
            ...state,
            loading: true,
            submittedOrderDto: null,
        }))
        .handleAction(createOrderApiActions.success, (state, action) => ({
            ...state,
            submittedOrderDto: action.payload,
        }))
        .handleAction(createOrderApiActions.failure, (state, action) => ({
            ...state,
            loading: false,
            submittedOrderDto: null,
        }))
        .handleAction(
            FlowersFlowActions.setFlowersOrderStatus,
            (state, action) => ({
                ...state,
                flowersOrderStatus: action.payload,
                loading: false,
            }),
        )
        .handleAction(createPaymentApiActions.request, (state, action) => ({
            ...state,
            receivedPaymentSessionId: null,
            loading: true,
        }))
        .handleAction(createPaymentApiActions.success, (state, action) => ({
            ...state,
            receivedPaymentSessionId: action.payload.sessionId,
            loading: false,
        }))
        .handleAction(createPaymentApiActions.failure, (state, action) => ({
            ...state,
            receivedPaymentSessionId: null,
            loading: false,
        }))
        .handleAction(FlowersFlowActions.paymentFailed, (state, action) => ({
            ...state,
            loading: false,
            result: {
                status: 'error',
                errorCode: action.payload.errorCode,
            },
        }))
        .handleAction(FlowersFlowActions.paymentCancelled, (state, action) => ({
            ...state,
            loading: false,
            result: {
                status: 'cancel',
                errorCode: null,
            },
        }))
        .handleAction(FlowersFlowActions.paymentSucceeded, (state, action) => ({
            ...state,
            loading: false,
            result: {
                status: 'success',
                errorCode: null,
            },
        }))
        .handleAction(FlowersFlowActions.orderConfirmationClosed, (state) => {
            return {
                ...state,
                ...paymentSucceed,
            };
        });
