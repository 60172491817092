import React from 'react';
import cx from 'classnames';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

import styles from '../image-container-mask.module.scss';
import { IImageContainerMaskProps } from '../image-container-mask.types';

export const ImageContainerErrorMask = ({
    useLightMode,
}: Pick<IImageContainerMaskProps, 'useLightMode'>) => {
    return (
        <div
            className={cx(styles.MaskContainer, styles.ErrorMaskContainer, {
                [styles.LightMode]: useLightMode,
            })}
        >
            <div className={styles.MaskInnerContainer}>
                <div className={styles.MaskIcon}>
                    <ImageNotSupportedOutlinedIcon />
                </div>
            </div>
        </div>
    );
};
