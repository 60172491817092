import { useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';

import { getGalleryPhotos } from '../../model/gallery/selectors/get-gallery-photos.selector';
import { getMilestones } from '../../model/milestones/selectors/get-milestones.selector';
import { getDeceasedTributeText } from '../../model/deceased-person/selectors/get-deceased-tribute.selector';
import { getDeathNotices } from '../../model/death-notice/selectors/get-death-notices.selector';
import { getLeaflets } from '../../model/leaflets/selectors/get-leaflets.selector';
import { IGalleryPhoto } from '../../interfaces/gallery-photo';
import { getCondolencesMediaFiles } from '../../model/condolences/selectors/get-condolences-media-files.selector';

export const useGalleryPage = () => {
    const photos = useSelector(getGalleryPhotos);
    const milestones = useSelector(getMilestones);
    const tribute = useSelector(getDeceasedTributeText);
    const condolencesMediaFiles = useSelector(getCondolencesMediaFiles);
    const deathNotices = useSelector(getDeathNotices);
    const leaflets = useSelector(getLeaflets);

    const condolencesPhotos: IGalleryPhoto[] = useMemo(
        () =>
            condolencesMediaFiles.map((mediaFile) => ({
                id: mediaFile.id,
                originalUrl: mediaFile.sourceUrl,
                previewUrl: mediaFile.previewUrl,
            })),
        [condolencesMediaFiles],
    );

    const downloads = deathNotices &&
        leaflets && [...deathNotices, ...leaflets];

    const downloadsRef = useRef<HTMLDivElement>(null);
    const milestonesRef = useRef<HTMLDivElement>(null);

    const scrollToRef = (ref: any) => {
        if (ref && ref.current) {
            window.scrollTo(0, ref.current.offsetTop);
        }
    };

    const scrollToDownloads = () => scrollToRef(downloadsRef);
    const scrollToMilestones = () => scrollToRef(milestonesRef);

    return {
        tribute,
        downloads,
        downloadsRef,
        milestones,
        milestonesRef,
        photos,
        condolencesPhotos,
        scrollToDownloads,
        scrollToMilestones,
    };
};
