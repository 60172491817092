import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import StringToHtmlParser from '../../components/string-to-html-parser/string-to-html-parser';
import { Section } from '../../components/section/section';
import { THEME } from '../../utils/get-theme';
import { ButtonLink } from '../../components/button-link/button-link';
import { useIsMobileBreakpoint } from '../../../shared/hooks/useIsViewPortSmallerThan';

import styles from './obituary-text.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & { content: string };

const LINE_HEIGHT = 29;
const VG_LINE_HEIGHT = 33;
const MAX_NUMBER_OF_LINES = 8;

export const ObituaryText = ({ content, className }: Props) => {
    const { t } = useTranslation();
    const theme = useSelector(getTheme);
    const isMobile = useIsMobileBreakpoint();

    const contentRef = useRef<HTMLDivElement | null>(null);
    const contentTextRef = useRef<HTMLDivElement | null>(null);

    const [expanded, setExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const lineHeight =
        !isMobile && theme === THEME.VG ? VG_LINE_HEIGHT : LINE_HEIGHT;
    const initialContentHeight = lineHeight * MAX_NUMBER_OF_LINES;

    useEffect(() => {
        if (contentTextRef && contentTextRef.current) {
            const numberOfLines =
                contentTextRef.current.clientHeight / lineHeight;

            if (numberOfLines > MAX_NUMBER_OF_LINES) {
                setShowButton(true);
            }
        }
    }, [contentTextRef, theme, lineHeight]);

    const collapseSection = () => {
        if (contentRef.current) {
            const sectionHeight = contentRef.current.scrollHeight;
            const actualTransition = contentRef.current.style.transition;
            contentRef.current.style.transition = '';
            requestAnimationFrame(() => {
                if (contentRef.current) {
                    contentRef.current.style.height = `${sectionHeight}px`;
                    contentRef.current.style.transition = actualTransition;
                }

                requestAnimationFrame(() => {
                    if (contentRef.current) {
                        contentRef.current.style.height = `${initialContentHeight}px`;
                    }
                });
            });
        }
    };

    const expandSection = () => {
        if (contentRef.current) {
            const sectionHeight = contentRef.current.scrollHeight;
            contentRef.current.style.height = `${sectionHeight}px`;
            contentRef.current.style.height = 'none';
        }
    };

    const sectionExpandHandler = () => {
        if (expanded) {
            collapseSection();
        } else {
            expandSection();
        }
        setExpanded(!expanded);
    };

    const setContentAutoHeight = () => {
        if (contentRef.current) {
            contentRef.current.style.height = 'auto';
        }
    };

    useEffect(() => {
        if (expanded) {
            window.addEventListener('orientationchange', setContentAutoHeight);
        } else {
            window.removeEventListener(
                'orientationchange',
                setContentAutoHeight,
            );
        }

        return () =>
            window.removeEventListener(
                'orientationchange',
                setContentAutoHeight,
            );
    }, [expanded]);

    return (
        <Section
            className={styles.Container}
            headerText={t('obituary.title')}
            headerClassName={styles.SectionHeader}
        >
            <div className={cx(className, styles.InnerContainer)}>
                <div
                    ref={contentRef}
                    className={cx(styles.Content, styles[theme], {
                        [styles.Expanded]: expanded || !showButton,
                        [styles.NoShowButton]: !showButton,
                    })}
                    style={{ height: `${initialContentHeight}px` }}
                >
                    <div ref={contentTextRef}>
                        <StringToHtmlParser stringToParse={content} />
                    </div>
                </div>
            </div>
            {showButton && (
                <ButtonLink
                    className={cx(styles.Button, styles[theme])}
                    onClick={sectionExpandHandler}
                >
                    {expanded
                        ? t('downloads.showLess')
                        : t('downloads.showAll')}
                </ButtonLink>
            )}
        </Section>
    );
};
