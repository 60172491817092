import React from 'react';
import Rollbar from 'rollbar';

import { RollbarContext } from './rollbar-context';

export const withRollbarSetup = () => <Props extends {}>(
    Component: React.ComponentType<Props>,
) =>
    class WithRollbar extends React.Component<Props> {
        rollbar = new Rollbar({
            accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
            captureUncaught: true,
            captureUnhandledRejections: true,
            enabled: process.env.NODE_ENV === 'production',
            environment: process.env.REACT_APP_ENVIRONMENT,
        });

        componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
            this.rollbar.error({
                error,
                errorInfo,
            });
        }

        render() {
            return (
                <RollbarContext.Provider value={this.rollbar}>
                    <Component {...this.props} />
                </RollbarContext.Provider>
            );
        }
    };
