import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { getTheme } from '../../model/configuration/selectors/get-theme.selector';

import styles from './button.module.scss';

type Props = React.HTMLProps<HTMLButtonElement> & {
    icon?:
        | React.FC<React.SVGProps<SVGSVGElement>>
        | React.FC<React.HTMLAttributes<HTMLDivElement>>;
    iconBoxClassName?: string;
    iconClassName?: string;
    type?: 'button' | 'submit' | 'reset';
};

export const Button = ({
    children,
    className,
    icon,
    iconBoxClassName,
    iconClassName,
    type = 'button',
    ...props
}: Props) => {
    const theme = useSelector(getTheme);

    const Icon:
        | React.FC<React.SVGProps<SVGSVGElement>>
        | React.FC<React.HTMLAttributes<HTMLDivElement>>
        | undefined = icon;

    const buttonClass = cx(
        styles.Button,
        styles[theme],
        { [styles.ButtonNoIcon]: !Icon },
        className,
    );

    return (
        <button className={buttonClass} type={type} {...props}>
            {children}
            {Icon && (
                <span className={cx(styles.ButtonIconBox, iconBoxClassName)}>
                    <Icon className={iconClassName} />
                </span>
            )}
        </button>
    );
};
