import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { compose, mapProps } from 'recompose';
import { ErrorMessage as FormikErrorMessage, ErrorMessageProps } from 'formik';
import {
    WithTranslation,
    withTranslation,
    useTranslation,
} from 'react-i18next';

import styles from './error-message.module.scss';

type Props = HTMLAttributes<HTMLSpanElement>;

export const ErrorMessage = ({ className, children }: Props) => {
    const { t } = useTranslation();

    return (
        <span className={cx(styles.Message, className)}>
            {Boolean(children) && t(children!.toString())}
        </span>
    );
};

export const FieldErrorMessage = compose<ErrorMessageProps, ErrorMessageProps>(
    withTranslation(),
    mapProps<ErrorMessageProps, ErrorMessageProps & WithTranslation>(
        ({ t, ...props }) => ({
            children: (message: string) => {
                return <ErrorMessage>{t(message)}</ErrorMessage>;
            },
            ...props,
        }),
    ),
)(FormikErrorMessage);
