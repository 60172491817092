import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { facebookSharingRequested } from '../sharing.actions';

import { facebookSharingSaga } from './facebook-sharing.saga';

export function* sharingSaga() {
    yield takeEvery(getType(facebookSharingRequested), facebookSharingSaga);
}
