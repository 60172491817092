import { IUpdateCondolenceDto } from '../../DTOs/update-condolence.dto';
import { ICreatedCondolence } from '../../../interfaces/condolence';
import { mapIImageUploadItemToMediaFileAttribute } from '../../../../shared/helpers/mapIImageUploadItemToMediaFileAttribute';

export const mapCondolenceToUpdateCondolenceDto = (
    condolence: ICreatedCondolence,
    uploadedImages?: string[],
): IUpdateCondolenceDto => {
    const condolenceDto: IUpdateCondolenceDto = {
        id: condolence.id,
        candle: condolence.candle,
        heart: condolence.heart,
        createdAt: condolence.createdAt,
        displayName: condolence.displayName,
        isOwner: condolence.isOwner,
        content: null,
        mediaFilesAttributes: mapIImageUploadItemToMediaFileAttribute(
            condolence.images,
            uploadedImages,
        ),
    };

    if (condolence.content) {
        condolenceDto.content = condolence.content;
    }

    return condolenceDto;
};
