import { call, put } from '@redux-saga/core/effects';

import { resetAccountPassword } from '../http/reset-account-password';
import { ForgottenPasswordActions } from '../forgotten-password-api.actions';
import { ITokensResponseDto } from '../../DTOs/tokens-response.dto';
import { saveTokens } from '../../auth-token-storage';

export function* resetPasswordApiSaga(
    action: ReturnType<typeof ForgottenPasswordActions.setNew.request>,
) {
    try {
        const response: ITokensResponseDto = yield call(resetAccountPassword, {
            token: action.payload.token,
            password: action.payload.password,
        });

        yield saveTokens(response.accessToken, response.refreshToken);

        yield put(ForgottenPasswordActions.setNew.success(response));
    } catch (e) {
        yield put(ForgottenPasswordActions.setNew.failure(e.statusText));
    }
}
