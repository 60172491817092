import { apiService } from '../../api.service';
import { ISendDonationRequestDto } from '../../DTOs/send-donation-request.dto';

export const sendDonation = ({
    slug,
    dto,
    charityId,
}: {
    slug: string;
    dto: ISendDonationRequestDto;
    charityId: string;
}): Promise<void> =>
    apiService
        .post(`/public/memorial_pages/${slug}/donations`, {
            json: {
                ...dto,
                charityId,
            },
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
