import { call, select, put } from '@redux-saga/core/effects';

import {
    addCondolenceCommentApiActions,
    fetchCondolencesApiActions,
} from '../condolences-api.actions';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { addCondolenceComment } from '../http/add-condolence-comment';

export function* addCondolenceCommentApiSaga(
    action: ReturnType<typeof addCondolenceCommentApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    const { condolenceId, comment } = action.payload;

    const response = yield call(addCondolenceComment, {
        slug,
        condolenceId,
        comment,
    });

    if (!response.errors) {
        yield put(addCondolenceCommentApiActions.success());
        yield put(fetchCondolencesApiActions.request());
    } else {
        yield put(
            addCondolenceCommentApiActions.failure({
                status: response.errors[0].title,
            }),
        );
    }
}
