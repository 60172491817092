import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { INavRoute, NavigationLinkIds } from '../../app/interfaces/nav-route';
import { getFlowerShopEnabled } from '../../app/model/configuration/selectors/get-flower-shop-enabled.selector';
import { getDonationEnabled } from '../../app/model/configuration/selectors/get-donation-enabled.selector';
import { getCondolencesEnabled } from '../../app/model/configuration/selectors/get-condolences-enabled.selector';
import {
    NavigationLinks,
    RequestAccessLink,
} from '../../app/routing/navigation-links';
import { getPagePrivacyMode } from '../../app/model/configuration/selectors/get-page-privacy-mode.selector';
import { getHasUserAccess } from '../../app/model/user/selectors/get-has-user-access.selector';
import { PrivacyMode } from '../../app/interfaces/privacy-mode';

export const useNavigationLinks = (
    navigationLinks: INavRoute[] = NavigationLinks,
): INavRoute[] => {
    const hasAccessToPage = useSelector(getHasUserAccess);
    const privacyMode = useSelector(getPagePrivacyMode);
    const flowerShopEnabled = useSelector(getFlowerShopEnabled);
    const donationEnabled = useSelector(getDonationEnabled);
    const condolencesEnabled = useSelector(getCondolencesEnabled);

    return useMemo(() => {
        if (!hasAccessToPage) {
            if (privacyMode === PrivacyMode.PRIVATE) {
                return [];
            }
            return [RequestAccessLink];
        }

        return navigationLinks.filter((link) => {
            if (link.id === NavigationLinkIds.SEND_FLOWERS) {
                return flowerShopEnabled;
            }

            if (link.id === NavigationLinkIds.SEND_DONATION) {
                return donationEnabled;
            }

            if (link.id === NavigationLinkIds.SEND_CONDOLENCE) {
                return condolencesEnabled;
            }

            return true;
        });
    }, [
        navigationLinks,
        hasAccessToPage,
        privacyMode,
        flowerShopEnabled,
        donationEnabled,
        condolencesEnabled,
    ]);
};
