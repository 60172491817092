import { IMediaFilesPostObject } from '../../app/interfaces/media-files';

export const mapS3ResponseToMediaFileAttributeDto = (
    s3Response?: string[],
): IMediaFilesPostObject[] | undefined => {
    return s3Response?.map((image) => {
        return {
            file: image,
        };
    });
};
