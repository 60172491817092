import React from 'react';
import { compose } from 'recompose';

import { withRollbarSetup } from '../setup/with-rollbar-setup';
import { withSessionRestoring } from '../shared/hoc/with-session-restoring';
import { withPageFetch } from '../shared/hoc/with-page-fetch';

import { Routing } from './routing/routing';
import { withRoutingSetup } from './routing/with-routing-setup';
import { withStoreProvider } from './store/with-store-provider';
import { store } from './store/store';
import { Layout } from './components/layout/layout';

export const App: React.FC = () => {
    return (
        <Layout>
            <Routing />
        </Layout>
    );
};

export const ComposedApp = compose(
    withRollbarSetup(),
    withStoreProvider(store),
    withRoutingSetup(),
    withSessionRestoring(),
    withPageFetch(),
)(App);
