import { apiService } from '../../api.service';
import { IViolationReportRequestDto } from '../../DTOs/violation-report-request.dto';

export const sendViolationReports = ({
    dto,
}: {
    dto: IViolationReportRequestDto;
}) => {
    return apiService
        .post(`/violation_reports`, {
            json: {
                data: {
                    attributes: {
                        description: dto.description,
                        violation_reportable_id: dto.violationReportableId,
                        violation_reportable_type: dto.violationReportableType,
                    },
                    type: 'violation_report',
                },
            },
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
};
