import { apiService } from '../../api.service';
import { IInvitationCheckResponseDto } from '../../DTOs/invitation-check-response.dto';

export const getSessionFromInvitationToken = (
    token: string,
): Promise<IInvitationCheckResponseDto> => {
    return apiService
        .get(`/auth/invitations/${token}`)
        .then((r) => r.json())
        .then((resp) => resp.data.attributes);
};
