import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { sendVideoStreamApiActions } from '../video-stream-api.actions';

import { sendVideoStreamApiSaga } from './send-video-stream-api.saga';

export function* videoStreamApiSaga() {
    yield takeEvery(
        getType(sendVideoStreamApiActions.request),
        sendVideoStreamApiSaga,
    );
}
