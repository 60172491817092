import React from 'react';
import cx from 'classnames';

import { IFlowerCategory } from '../../../interfaces/flower-category';
import { roundPriceToString } from '../../../utils/set-price';
import { useFlowerPhoto } from '../../hooks/use-flower-photo';

import styles from './flowers-category-item.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    item: IFlowerCategory;
};

export const FlowersCategoryItem = ({ item, ...props }: Props) => {
    const minPrice = roundPriceToString(item.priceRange.minPrice);
    const maxPrice = roundPriceToString(item.priceRange.maxPrice);
    const { imageUrl, isPlaceholderPhoto } = useFlowerPhoto({ product: item });
    const minMaxPrice =
        minPrice === maxPrice ? minPrice : `${minPrice} - ${maxPrice}`;
    return (
        <div className={styles.FlowersCategoryItem} {...props}>
            <div className={styles.FlowersCategoryImageContainer}>
                {!!imageUrl && (
                    <img
                        alt={item.name}
                        className={cx(
                            styles.FlowersCategoryItemImage,
                            isPlaceholderPhoto && styles.PlaceholderPhoto,
                        )}
                        src={imageUrl}
                    />
                )}
                {!imageUrl && (
                    <span className={styles.FlowersCategoryItemImage} />
                )}
            </div>
            <>
                <span className={styles.FlowersCategoryItemName}>
                    {item.name}
                </span>
                <span className={styles.FlowersCategoryItemPrice}>
                    {`${item.currency} ${minMaxPrice}`}
                </span>
            </>
        </div>
    );
};
