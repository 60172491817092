import {
    IImageCarouselCustomViewProps,
    IImageCarouselProps,
} from './image-carousel.types';

export const checkIfURLIsVideo = (url: string) => {
    return url.includes('.mp4');
};

export const checkIfImageCarouselPropsAreEqual = (
    prevProps: IImageCarouselProps,
    nextProps: IImageCarouselProps,
) =>
    prevProps.currentIndex === nextProps.currentIndex &&
    JSON.stringify(prevProps.mediaFiles) ===
        JSON.stringify(nextProps.mediaFiles);

export const checkIfImageCarouselCustomViewPropsAreEqual = (
    prevProps: IImageCarouselCustomViewProps,
    nextProps: IImageCarouselCustomViewProps,
) =>
    prevProps.data?.source === nextProps.data?.source &&
    prevProps.currentIndex === nextProps.currentIndex;
