import React from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { PhoneInput } from '../../components/phone-input/phone-input';
import { TextField } from '../../components/text-field/text-field';
import { ErrorMessage } from '../../components/error-message/error-message';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';
import { IContactSuggestionFormValues } from '../contact-suggestion-form-values';

import { contactSuggestionFormValidation } from './contact-suggestion-form-validation';
import styles from './suggest-contact-form.module.scss';

type OuterProps = {
    onSubmit(values: IContactSuggestionFormValues): unknown;
    contactSuggestionMessage: string | null;
};

type Props = FormikProps<IContactSuggestionFormValues> & OuterProps;

export const ContactSuggestionForm = ({
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    contactSuggestionMessage,
    setFieldValue,
    dirty,
    isValid,
}: Props) => {
    const { t } = useTranslation();

    const buttonContainerClass = cx(styles.ButtonContainer, {
        [styles.ButtonContainerWithMessage]: contactSuggestionMessage,
    });

    const buttonClass = cx(styles.Button, {
        [styles.ButtonWithMessage]: contactSuggestionMessage,
    });

    const canSave = dirty && isValid;

    return (
        <form onSubmit={handleSubmit} className={styles.Form}>
            <span className={styles.Subheader}>{t('suggestGuest.title')}</span>
            <div className={styles.FieldsContainer}>
                <div className={styles.TextFieldContainer}>
                    <TextField
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('suggestGuest.namePlaceholder')}
                        value={values.name}
                    />
                    {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                </div>
                <div className={styles.TextFieldContainer}>
                    <TextField
                        className={styles.TextField}
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('suggestGuest.emailPlaceholder')}
                        value={values.email}
                    />
                    {errors.email && (
                        <ErrorMessage>{errors.email}</ErrorMessage>
                    )}
                </div>
                <div className={styles.TextFieldContainer}>
                    <PhoneInput
                        className={styles.TextField}
                        name="phone"
                        onChange={(phone) => setFieldValue('phone', phone)}
                        inputProps={{
                            onBlur: handleBlur,
                            placeholder: t('suggestGuest.phonePlaceholder'),
                        }}
                        value={values.phone}
                        onBlur={(event) => {
                            handleBlur(event);

                            contactSuggestionFormValidation()
                                .validateAt('phone', values)
                                .then((phoneValue: any) => {
                                    if (!phoneValue.length) {
                                        setFieldValue('phone', '');
                                    }
                                });
                        }}
                    />
                    {errors.phone && (
                        <ErrorMessage>{errors.phone}</ErrorMessage>
                    )}
                </div>
            </div>
            <div className={buttonContainerClass}>
                {contactSuggestionMessage && (
                    <span className={styles.InfoMessage}>
                        {contactSuggestionMessage}
                    </span>
                )}
                <PrimaryButton
                    className={buttonClass}
                    icon={PlusIcon}
                    type="submit"
                    disabled={!canSave}
                >
                    {t('suggestGuest.addSuggestionButton')}
                </PrimaryButton>
            </div>
        </form>
    );
};

export const ConnectedContactSuggestionForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IContactSuggestionFormValues>({
        mapPropsToValues() {
            return {
                name: '',
                email: '',
                phone: '',
            };
        },
        handleSubmit(values, { props }) {
            props.onSubmit(values);
        },
        validationSchema: contactSuggestionFormValidation,
    }),
)(ContactSuggestionForm);
