import React from 'react';
import cx from 'classnames';

import { IImageCarouselCustomViewProps } from '../../image-carousel.types';
import styles from '../../image-carousel.module.scss';
import { VideoPlayer } from '../../../video-player/video-player';
import { checkIfImageCarouselCustomViewPropsAreEqual } from '../../image-carousel.helpers';
import { ImageContainer } from '../../../image-container/image-container';

import { useCustomView } from './use-custom-view';

export const CustomView = React.memo((props: IImageCarouselCustomViewProps) => {
    const {
        videoPlayerRef,
        videoPlayerOptions,
        videoPlayerControlsOptions,
        data,
        source,
        isFullscreen,
    } = useCustomView(props);

    return (
        <div
            className={cx(styles.CustomView, {
                [styles.CustomViewFullscreen]: isFullscreen,
            })}
        >
            {data?.isVideo ? (
                <VideoPlayer
                    ref={videoPlayerRef}
                    src={source}
                    playerOptions={videoPlayerOptions}
                    controlsOptions={videoPlayerControlsOptions}
                />
            ) : (
                <div className={styles.CustomViewImage}>
                    <ImageContainer
                        src={source}
                        withBlurredBackground={!isFullscreen}
                    />
                </div>
            )}
        </div>
    );
}, checkIfImageCarouselCustomViewPropsAreEqual);
