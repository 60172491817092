import { LanguageCode } from './get-language-codes';
import { convertLangCode } from './convert-lang-code';

export const getGoogleDomain = (locale: LanguageCode) => {
    let domain = 'com';

    if ([LanguageCode.PL, LanguageCode.DE, LanguageCode.ES].includes(locale)) {
        return locale;
    }
    if (locale === LanguageCode.NO) {
        return convertLangCode(locale);
    }
    if (locale === LanguageCode.EN) {
        domain = 'co.uk';
    }

    return domain;
};
