import React, { useState } from 'react';

import ImageCarousel from '../image-carousel/image-carousel';

import { ImageCarouselGalleryWrapper } from './components/image-carousel-gallery-wrapper/image-carousel-gallery-wrapper';
import { checkIfImageCarouselGalleryPropsAreEqual } from './image-carousel-gallery.helpers';
import { ImageCarouselGalleryProps } from './image-carousel-gallery.types';

export const ImageCarouselGallery = React.memo(
    ({ photos }: ImageCarouselGalleryProps) => {
        const [imageIndex, setImageIndex] = useState<number>(0);
        const [expanded, setExpanded] = useState<boolean>(false);

        const mediaFiles = React.useMemo(
            () =>
                photos.map((photo) => ({
                    sourceUrl: photo.originalUrl,
                    previewUrl: photo.originalUrl,
                    id: photo.id,
                })),
            [photos],
        );
        return (
            <ImageCarousel
                mediaFiles={mediaFiles}
                currentIndex={imageIndex}
                wrapperElement={
                    <ImageCarouselGalleryWrapper
                        photos={photos}
                        setImageIndex={setImageIndex}
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                }
            />
        );
    },
    checkIfImageCarouselGalleryPropsAreEqual,
);
