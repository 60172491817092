import { createAsyncAction } from 'typesafe-actions';

import { IEventAttendanceData } from '../../interfaces/event-attendance-data';
import { IEventAttendanceStatus } from '../../interfaces/event-attendance-status';

export const attendEventApiActions = createAsyncAction(
    'api/events/ATTEND_EVENT_REQUESTED',
    'api/events/ATTEND_EVENT_SUCCEEDED',
    'api/events/ATTEND_EVENT_FAILED',
)<IEventAttendanceData, IEventAttendanceStatus, IEventAttendanceStatus>();
