import { IFlowerPhoto } from '../../interfaces/flower-photo';
import { FLOWER_PHOTO_PLACEHOLDER } from '../../../shared/constants/common';

export type IWithFlowerPhoto = { photo?: IFlowerPhoto } | null | undefined;
export interface IUseFlowerPhotoProps {
    product?: IWithFlowerPhoto;
    selectedVariant?: IWithFlowerPhoto;
    hoveredVariant?: IWithFlowerPhoto;
}
export const useFlowerPhoto = ({
    product,
    hoveredVariant,
    selectedVariant,
}: IUseFlowerPhotoProps) => {
    const productPhoto = product?.photo?.original;
    const hoveredVariantPhoto = hoveredVariant
        ? hoveredVariant?.photo?.original ?? productPhoto
        : null;
    const selectedVariantPhoto = selectedVariant?.photo?.original;
    const imageUrl =
        hoveredVariantPhoto ??
        selectedVariantPhoto ??
        productPhoto ??
        FLOWER_PHOTO_PLACEHOLDER;
    const isPlaceholderPhoto = imageUrl === FLOWER_PHOTO_PLACEHOLDER;
    return {
        imageUrl,
        isPlaceholderPhoto,
    };
};
