import { ICondolenceResponseDto } from '../../DTOs/condolence-response.dto';
import { ICreatedCondolence } from '../../../interfaces/condolence';

export const mapCondolenceDtoToCondolence = (
    dto: ICondolenceResponseDto,
): ICreatedCondolence => {
    const condolence: ICreatedCondolence = {
        id: dto.id,
        displayName: dto.attributes.displayName,
        createdAt: dto.attributes.createdAt,
        candle: dto.attributes.candle,
        heart: dto.attributes.heart,
        isOwner: dto.attributes.isOwner,
        orderSent: dto.attributes.orderSent,
        donationSent: dto.attributes.donationSent,
        iconProductUrl: dto.attributes.iconProductUrl,
        commentsCount: dto.attributes.commentsCount,
        likesCount: dto.attributes.likesCount,
        currentUserHasLiked: dto.attributes.currentUserHasLiked,
        mediaFilesCount: dto.attributes.mediaFilesCount,
        comments: [],
    };

    if (dto.attributes.content) {
        condolence.content = dto.attributes.content;
    }

    return condolence;
};
