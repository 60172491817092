import { put } from 'redux-saga/effects';

import { violationReportFormSubmitted } from '../violation-report.actions';
import { sendViolationReportApiActions } from '../../../api/violation-report/violation-reports-api.actions';

export function* handleViolationReportFormSubmittedSaga(
    action: ReturnType<typeof violationReportFormSubmitted>,
) {
    yield put(
        sendViolationReportApiActions.request({
            description: action.payload.description,
            violationReportableId: action.payload.violationReportableId,
            violationReportableType: action.payload.violationReportableType,
        }),
    );
}
