import * as yup from 'yup';

export const eventAttendanceValidation = () =>
    yup.object().shape({
        email: yup.string().email(),
        phone: yup.string().required(),
        fullName: yup.string().required(),
        foodComment: yup.string(),
        memcareNewsAccepted: yup.boolean(),
        personalInformationProcessingAccepted: yup
            .boolean()
            .oneOf([true])
            .required(),
        sharingPersonalInfoWithBereavedAccepted: yup.boolean(),
    });
