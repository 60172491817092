import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { Section } from '../../components/section/section';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as DetailsIcon } from '../../assets/icons/details-icon.svg';
import { resolveDeliveryLabel } from '../delivery/resolve-delivery-label';
import { RoutePaths } from '../../routing/route-paths';
import { FlowersFlowActions } from '../../model/flowers/flowers.actions';
import { FlowersSelectors } from '../../model/flowers/flowers.selector';

import styles from './flowers-confirmation.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement>;

export const FlowersConfirmation = ({ className, ...props }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const receivedPaymentSessionIdStore = useSelector(
        FlowersSelectors.getReceivedPaymentSessionId,
    );
    const [receivedPaymentSessionIdState] = useState(
        receivedPaymentSessionIdStore,
    );
    const submittedOrderDtoStore = useSelector(
        FlowersSelectors.getSubmittedOrderDto,
    );
    const [submittedOrderDtoState] = useState(submittedOrderDtoStore);

    const redirectToHomePage = () => {
        history.push(RoutePaths.HOME);
    };

    const redirectToPaymentFailedPage = () => {
        history.push(RoutePaths.FLOWERS_PAYMENT_FAILED);
    };

    useEffect(() => {
        const error = new URLSearchParams(location.search).get('error');

        if (error) {
            redirectToPaymentFailedPage();
        } else {
            if (!receivedPaymentSessionIdState || !submittedOrderDtoState) {
                redirectToHomePage();
            }
            dispatch(FlowersFlowActions.orderConfirmationClosed());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    if (!submittedOrderDtoState) {
        return null;
    }

    return (
        <Section className={className} {...props}>
            <div className={styles.Container}>
                <h1 className={styles.Headline}>
                    {t('flowers.confirmation.headline')}
                </h1>
                <h2 className={styles.SuccessMessage}>
                    {t('flowers.confirmation.successMessage')}
                </h2>
                <div className={styles.DataSet}>
                    <span className={styles.DataSetLabel}>
                        {t('flowers.confirmation.deliveryTo')}
                    </span>
                    <div className={styles.DataSetContainer}>
                        <div className={styles.OrderDeliveryContainer}>
                            <span className={styles.OrderDeliveryPlace}>
                                {t(
                                    resolveDeliveryLabel(
                                        submittedOrderDtoState.delivery
                                            .deliveryTypeName,
                                    ),
                                )}
                            </span>
                            {submittedOrderDtoState.location && (
                                <div>
                                    {submittedOrderDtoState.location.street ??
                                        submittedOrderDtoState.location.name}
                                    <br />
                                    {
                                        submittedOrderDtoState.location.zipCode
                                    }{' '}
                                    {submittedOrderDtoState.location.city}
                                </div>
                            )}
                            {submittedOrderDtoState.location?.street &&
                                submittedOrderDtoState.location.name && (
                                    <div>
                                        {submittedOrderDtoState.location.name}
                                    </div>
                                )}
                            {submittedOrderDtoState.recipient && (
                                <div>
                                    {submittedOrderDtoState.recipient.fullName}
                                    <br />
                                    {submittedOrderDtoState.recipient.phone}
                                    <br />
                                    {submittedOrderDtoState.recipient.email}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.DataSet}>
                    <span className={styles.DataSetLabel}>
                        {t('flowers.confirmation.orderNo')}
                    </span>
                    <div className={styles.DataSetContainer}>
                        <span className={styles.DataSetInfo}>
                            {submittedOrderDtoState.uuid}
                        </span>
                    </div>
                </div>
                <div className={styles.DataSet}>
                    <span
                        className={cx(
                            styles.DataSetLabel,
                            styles.DetailsSentTo,
                        )}
                    >
                        {t('flowers.confirmation.detailsSentTo')}
                    </span>
                    <div className={styles.DataSetContainer}>
                        <span className={styles.DataSetInfo}>
                            {submittedOrderDtoState.profile.email}
                        </span>
                    </div>
                </div>
                <PrimaryButton
                    className={styles.HomeButton}
                    icon={DetailsIcon}
                    onClick={redirectToHomePage}
                >
                    {t('flowers.confirmation.goToHomepageButton')}
                </PrimaryButton>
            </div>
        </Section>
    );
};
