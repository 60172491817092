import { apiService } from '../../api.service';

export const deleteCondolence = ({
    slug,
    id,
}: {
    slug: string;
    id: string;
}): Promise<Response> => {
    return apiService.delete(
        `/public/memorial_pages/${slug}/condolences/${id}`,
    );
};
