import { combineReducers } from 'redux';

import { createFlowersDeliveryReducer } from './flowers-delivery.store';
import { createUserOrderReducer } from './flowers-order.store';

export const flowerReducers = {
    delivery: createFlowersDeliveryReducer(),
    order: createUserOrderReducer(),
};

export const flowersCombinedReducer = combineReducers(flowerReducers);
