import { useRef } from 'react';

import { IVideoPlayerControlsProps } from './video-player-controls.types';
import {
    PLAYER_WIDTH_NEEDED_TO_FIT_TIME_PROGRESS,
    PLAYER_WIDTH_NEEDED_TO_FIT_VOLUME_CONTROL,
} from './video-player-controls.constants';

export const useVideoPlayerControls = ({
    hideWhenPaused = true,
    hideOnMouseLeave = true,
    waitForPlaybackStart = true,
    showFullscreenButton = true,
    showSpinner = false,
}: IVideoPlayerControlsProps) => {
    const controlsRef = useRef<HTMLVmControlsElement>(null);
    const controlsRefCurrent = controlsRef.current;

    const controlsWidth = controlsRefCurrent?.clientWidth || 0;
    const isEnoughSpaceForTimeProgress =
        controlsWidth > PLAYER_WIDTH_NEEDED_TO_FIT_TIME_PROGRESS;
    const isEnoughSpaceForVolumeControl =
        controlsWidth > PLAYER_WIDTH_NEEDED_TO_FIT_VOLUME_CONTROL;

    return {
        controlsRef,
        isEnoughSpaceForTimeProgress,
        isEnoughSpaceForVolumeControl,
        hideWhenPaused,
        hideOnMouseLeave,
        waitForPlaybackStart,
        showFullscreenButton,
        showSpinner,
    };
};
