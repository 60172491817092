import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';

import { setCookiesAccepted } from './cookies-accepted.actions';

export interface ICookiesAcceptedStore {
    cookiesAccepted: boolean;
}

const initialState: ICookiesAcceptedStore = {
    cookiesAccepted: false,
};

export const cookiesAcceptedReducer = createReducer<
    ICookiesAcceptedStore,
    RootActions
>(initialState).handleAction(setCookiesAccepted, (state) => ({
    ...state,
    cookiesAccepted: true,
}));
