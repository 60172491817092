import { createAsyncAction } from 'typesafe-actions';

import { ITokensResponseDto } from '../DTOs/tokens-response.dto';

const resetPasswordActions = createAsyncAction(
    'api/forgotten-password/RESET_REQUESTED',
    'api/forgotten-password/RESET_SUCCEEDED',
    'api/forgotten-password/RESET_FAILED',
)<{ email: string }, ITokensResponseDto, string>();

const setNewPasswordActions = createAsyncAction(
    'api/forgotten-password/NEW_PASSWORD_SET_REQUESTED',
    'api/forgotten-password/NEW_PASSWORD_SET_SUCCEEDED',
    'api/forgotten-password/NEW_PASSWORD_SET_FAILED',
)<{ password: string; token: string }, ITokensResponseDto, string>();

export const ForgottenPasswordActions = {
    resetEmail: resetPasswordActions,
    setNew: setNewPasswordActions,
};
