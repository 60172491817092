import { createSelector } from 'reselect';

import { AccessRequestStatus } from '../../access-request-status';
import i18n from '../../../../setup/i18n';

import { getAccessRequestStatus } from './get-access-request-status.selector';

export const getAccessRequestStatusMessage = createSelector(
    getAccessRequestStatus,
    (status) => {
        const AccessRequestStatusMessage = {
            [AccessRequestStatus.SUCCESS]: i18n.t(
                'accessRequestStatus.success',
            ),
            [AccessRequestStatus.EMAIL_TAKEN]: i18n.t(
                'accessRequestStatus.emailTaken',
            ),
            [AccessRequestStatus.ERROR]: i18n.t('accessRequestStatus.error'),
        };

        return status !== null ? AccessRequestStatusMessage[status] : null;
    },
);
