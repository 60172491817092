import { delay, put, select, take } from 'redux-saga/effects';

import {
    accessRequestFormSubmitted,
    setAccessRequestStatus,
} from '../../access-request.actions';
import { sendAccessRequestApiActions } from '../../../api/access-request/request-access-api.actions';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';

export function* handleAccessRequestFormSubmitted(
    action: ReturnType<typeof accessRequestFormSubmitted>,
) {
    const { name, email, phone } = action.payload;

    const slug = yield select(getPageSlug);

    yield put(
        sendAccessRequestApiActions.request({
            email: email.length ? email : undefined,
            phone: phone.length ? phone : undefined,
            name,
            slug,
        }),
    );

    const resp = yield take([
        sendAccessRequestApiActions.success,
        sendAccessRequestApiActions.failure,
    ]);

    yield put(setAccessRequestStatus(resp.payload));
    yield delay(3000);
    yield put(setAccessRequestStatus(null));
}
