import React from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextArea } from '../../components/text-area/text-area';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ErrorMessage } from '../../components/error-message/error-message';
import { ReactComponent as PublishIcon } from '../../assets/icons/publish-icon.svg';
import { IViolationReportFormValues } from '../violation-report-form-values';
import { ViolationReportableType } from '../../interfaces/violation-reportable-type';
import { IViolationReportRequestDto } from '../../api/DTOs/violation-report-request.dto';

import { violationReportFormValidation } from './violation-report-form-validation';
import styles from './violation-report-form.module.scss';

type OuterProps = {
    violationReportableType: ViolationReportableType;
    violationId: string;
    onCreate(values: IViolationReportRequestDto): unknown;
};

type Props = FormikProps<IViolationReportFormValues> & OuterProps;

export const ViolationReportForm = ({
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    dirty,
    isValid,
}: Props) => {
    const { t } = useTranslation();
    const canSave = dirty && isValid;
    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.FieldWrapper}>
                <TextArea
                    autoFocus
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('violationReport.description')}
                    rows={10}
                    value={values.description}
                />
                {errors.description && (
                    <ErrorMessage>{errors.description}</ErrorMessage>
                )}
            </div>
            <div className={styles.ButtonContainer}>
                <PrimaryButton
                    className={styles.PublishButton}
                    icon={PublishIcon}
                    type="submit"
                    disabled={!canSave}
                >
                    {t('violationReport.publishButton')}
                </PrimaryButton>
            </div>
        </form>
    );
};

export const ConnectedViolationReportForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IViolationReportFormValues>({
        handleSubmit(values, { props }) {
            props.onCreate({
                description: values.description,
                violationReportableId: props.violationId,
                violationReportableType: props.violationReportableType,
            });
        },
        mapPropsToValues() {
            return {
                description: '',
            };
        },
        validationSchema: violationReportFormValidation,
        enableReinitialize: true,
    }),
)(ViolationReportForm);
