import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Section } from '../../components/section/section';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { RoutePaths } from '../../routing/route-paths';
import { DonationIcon } from '../../assets/icons/donation-icon';
import { getSubmittedDonationDto } from '../../model/donation/selectors/get-submitted-donation-dto.selector';
import { sendDonationActions } from '../../model/donation/donation.actions';
import { getDonationLoading } from '../../model/donation/selectors/get-donation-loading.selector';
import { Spinner } from '../../components/spinner/spinner';

import styles from './donation-payment-failed.module.scss';

export const DonationPaymentFailed = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const donationLoading = useSelector(getDonationLoading);
    const submittedDonationDtoStore = useSelector(getSubmittedDonationDto);
    const [submittedDonationDtoState] = useState(submittedDonationDtoStore);

    const redirectToHomePage = () => {
        history.push(RoutePaths.HOME);
    };

    const tryAgain = () => {
        if (submittedDonationDtoState) {
            dispatch(
                sendDonationActions.request({ dto: submittedDonationDtoState }),
            );
        } else {
            redirectToHomePage();
        }
    };

    useEffect(() => {
        if (!submittedDonationDtoStore) {
            redirectToHomePage();
        }
        return () => {
            dispatch(sendDonationActions.cancel());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <>
            <Prompt
                when={!!submittedDonationDtoState}
                message={t('donation.paymentFailed.exitConfirmation')}
            />
            <Section className={styles.Section}>
                <div className={styles.Container}>
                    <h1 className={styles.Headline}>
                        {t('donation.paymentFailed.headline')}
                    </h1>
                    <h2 className={styles.Message}>
                        <span className={styles.DetailsSentTo}>
                            {t('donation.paymentFailed.detailsSentTo')}
                        </span>
                        {submittedDonationDtoState?.giverEmail}
                    </h2>
                    <PrimaryButton
                        className={styles.Button}
                        icon={donationLoading ? Spinner : DonationIcon}
                        onClick={tryAgain}
                        disabled={donationLoading}
                    >
                        {t('donation.paymentFailed.actionButton')}
                    </PrimaryButton>
                </div>
            </Section>
        </>
    );
};
