import React from 'react';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { SmallGrayButton } from '../../../components/small-gray-button/small-gray-button';

import { EmailInputValidation } from './email-input-validation';
import styles from './email-input.module.scss';

type OuterProps = Omit<React.HTMLAttributes<HTMLFormElement>, 'onSubmit'> & {
    buttonLabel: string;
    onSubmit(email: string): unknown;
    placeholder?: string;
};

type Props = FormikProps<{ email: string }> & OuterProps;

export const Form = ({
    buttonLabel,
    className,
    placeholder = '',
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
}: Props) => {
    const { t } = useTranslation();

    return (
        <form
            className={cx(styles.Container, className)}
            onSubmit={handleSubmit}
        >
            <div className={styles.InputBox}>
                <input
                    className={styles.Input}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={placeholder}
                    type="text"
                    value={values.email}
                />
                <SmallGrayButton
                    className={styles.Button}
                    disabled={!values.email}
                    type="submit"
                >
                    {buttonLabel}
                </SmallGrayButton>
            </div>
            {errors.email && touched.email && (
                <span className={styles.Error}>{t('error.invalidEmail')}</span>
            )}
        </form>
    );
};

export const EmailInput = compose<Props, OuterProps>(
    withFormik<OuterProps, { email: string }>({
        handleSubmit({ email }, { props }) {
            props.onSubmit(email);
        },
        validationSchema: () => EmailInputValidation(),
    }),
)(Form);
