import React from 'react';
import { useSelector } from 'react-redux';

import { Container } from '../../../components/container/container';
import { DeceasedDisplay } from '../../../deceased-person/deceased-display/deceased-display';
import { DonationPaymentFailed } from '../../../donation/payment-failed/donation-payment-failed';
import { TributeText } from '../../../deceased-person/tribute-text/tribute-text';
import { getDeceasedTributeText } from '../../../model/deceased-person/selectors/get-deceased-tribute.selector';

export const DonationPaymentFailedPage = () => {
    const tribute = useSelector(getDeceasedTributeText);

    return (
        <>
            <DeceasedDisplay />
            <Container>
                {tribute && <TributeText content={tribute} />}
                <DonationPaymentFailed />
            </Container>
        </>
    );
};
