import { ICondolenceFormValues } from '../../../condolences/condolence-form-values';
import { ICreateCondolenceDto } from '../../../api/DTOs/create-condolence.dto';
import { mapS3ResponseToMediaFileAttributeDto } from '../../../../shared/helpers/mapS3ResponseToMediaFileAttributeDto';

export const mapCondolenceFormValuesToDto = (
    condolence: ICondolenceFormValues,
    uploadedImages: string[],
): ICreateCondolenceDto => {
    return {
        displayName: condolence.displayName,
        candle: condolence.candle,
        heart: condolence.heart,
        content: condolence.content,
        mediaFilesAttributes: mapS3ResponseToMediaFileAttributeDto(
            uploadedImages,
        ),
    };
};
