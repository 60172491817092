export const convertLangCode = (code: string) => {
    switch (code) {
        case 'en':
            return 'gb';
        case 'nb':
            return 'no';
        case 'sv':
            return 'se';
        case 'ar':
            return 'dz';
        default:
            return code;
    }
};
