import { ILocation } from '../../interfaces/location';

export const getEncodedGoogleMapsQueryParams = (location: ILocation) => {
    if (location.pinLatitude && location.pinLongitude) {
        return encodeURIComponent(
            `${location.pinLatitude},${location.pinLongitude}`,
        );
    }

    if (location.latitude && location.longitude) {
        return encodeURIComponent(`${location.latitude},${location.longitude}`);
    }

    if (location.formattedAddress) {
        return encodeURIComponent(location.formattedAddress);
    }

    if (location.name) {
        return encodeURIComponent(location.name);
    }

    return '';
};
