import React from 'react';
import cx from 'classnames';

import styles from './company-logo.module.scss';

type Props = React.HTMLAttributes<HTMLAnchorElement> & {
    url: string;
    href?: string | null;
};

export const CompanyLogo = ({ className, url, href, ...props }: Props) => {
    return (
        <>
            {href && (
                <a
                    className={styles.CompanyLogo}
                    href={href}
                    style={{ cursor: 'pointer' }}
                    {...props}
                >
                    <img
                        className={cx(styles.Logo, className)}
                        src={url}
                        alt="company logo"
                    />
                </a>
            )}
            {!href && (
                <a className={styles.CompanyLogo} {...props}>
                    <img
                        className={cx(styles.Logo, className)}
                        src={url}
                        alt="company logo"
                    />
                </a>
            )}
        </>
    );
};
