import { apiService } from '../../../api.service';
import { IFetchFlowersCartCalculationRequestDto } from '../../../DTOs/fetch-flowers-cart-calculation-request.dto';
import { IFetchFlowersCartCalculationResponseDto } from '../../../DTOs/fetch-flowers-cart-calculation-response.dto';

export const fetchCartCalculation = ({
    slug,
    dto,
}: {
    slug: string;
    dto: IFetchFlowersCartCalculationRequestDto;
}): Promise<IFetchFlowersCartCalculationResponseDto> =>
    apiService
        .post(`/public/memorial_pages/${slug}/orders/summary`, {
            json: dto,
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes);
