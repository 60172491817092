import React, {useEffect, useMemo} from 'react';
import {FormikProps, withFormik} from 'formik';
import {compose} from 'recompose';
import {useTranslation} from 'react-i18next';
import {Tooltip} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import {Prompt} from 'react-router';
import {Validator} from 'stdnum/lib/cjs/types';

import {ReactComponent as ArrowIcon} from '../../../assets/icons/icon-arrow-right.svg';
import {ReactComponent as MastercardIcon} from '../../../assets/icons/mastercard-icon.svg';
import {ReactComponent as VisaIcon} from '../../../assets/icons/visa-icon.svg';
import {TextField} from '../../../components/text-field/text-field';
import {PhoneInput} from '../../../components/phone-input/phone-input';
import {ErrorMessage} from '../../../components/error-message/error-message';
import {Checkbox} from '../../../components/checkbox/checkbox';
import {PrimaryButton} from '../../../components/primary-button/primary-button';
import {ICondolenceFormValues} from '../../../condolences/condolence-form-values';
import {OrangeHeadline} from '../../../components/orange-headline/orange-headline';
import {useBooleanState} from '../../../../shared/hooks/useBooleanState';
import {ICreatedCondolence} from '../../../interfaces/condolence';
import {roundPrice, roundPriceToString} from '../../../utils/set-price';
import {getDonationCurrency} from '../../../model/configuration/selectors/get-donation-currency.selector';
import {
    getDonationOrganizationName
} from '../../../model/configuration/selectors/get-donation-organization-name.selector';
import {
    getDonationOrganizationLogo
} from '../../../model/configuration/selectors/get-donation-organization-logo.selector';
import {DonationIcon} from '../../../assets/icons/donation-icon';
import {getDonationLoading} from '../../../model/donation/selectors/get-donation-loading.selector';
import {getDonationError} from '../../../model/donation/selectors/get-donation-error.selector';
import {Spinner} from '../../../components/spinner/spinner';
import {getSubmittedDonationDto} from '../../../model/donation/selectors/get-submitted-donation-dto.selector';
import {sendDonationActions} from '../../../model/donation/donation.actions';
import {
    getDonationOrganizationDescription
} from '../../../model/configuration/selectors/get-donation-organization-description.selector';

import {AddCondolenceModal} from './condolence/add-condolence-modal';
import {donationDetailsFormValidation} from './donation-details-form.validation';
import styles from './donation-details-form.module.scss';
import {getDonationFee} from "../../../model/configuration/selectors/get-donation-fee.selector";

export interface IDonationDetailsFormValues {
    addCondolence: boolean;
    condolence: ICondolenceFormValues | undefined;
    amount: number | undefined;
    name: string;
    email: string;
    phone: string;
    share: boolean;
    personalId: string;
}

export interface IOuterProps {
    onSubmit: (values: IDonationDetailsFormValues) => void;
    userEmail?: string;
    personalIdValidator: Validator | null;
}

interface IProps extends IOuterProps, FormikProps<IDonationDetailsFormValues> {
}

const DonationDetailsForm = ({
                                 handleBlur,
                                 handleSubmit,
                                 handleChange,
                                 values,
                                 errors,
                                 dirty,
                                 isValid,
                                 setFieldValue,
                                 personalIdValidator,
                             }: IProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const addCondolenceModal = useBooleanState(false);

    const donationFee = useSelector(getDonationFee);
    const donationCurrency = useSelector(getDonationCurrency);
    const donationOrganizationName = useSelector(getDonationOrganizationName);
    const donationOrganizationDescription = useSelector(
        getDonationOrganizationDescription,
    );
    const donationOrganizationLogo = useSelector(getDonationOrganizationLogo);
    const donationLoading = useSelector(getDonationLoading);
    const submittedDonationDto = useSelector(getSubmittedDonationDto);
    const donationError = useSelector(getDonationError);
    const donationErrorText = donationError ? t('donation.error') : null;

    useEffect(() => {
        if (donationLoading && !submittedDonationDto) {
            dispatch(sendDonationActions.cancel());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handleSendDonation = (condolence?: ICondolenceFormValues) => {
        setFieldValue('condolence', condolence || undefined);
        handleSubmit();
    };

    const handleSubmitButtonClick = () => {
        if (values.addCondolence) {
            addCondolenceModal.handleSetAsTrue();
        } else {
            handleSendDonation();
        }
    };

    const getTotalAmountToPay = () => {
        return (values.amount || 0) + (donationFee || 0);
    }

    const getAmountWithCurrency = () =>
        values.amount && donationCurrency && !errors.amount
            ? `${donationCurrency} ${roundPriceToString(getTotalAmountToPay())}`
            : '';

    const sendDonationButtonTitle = `${t(
        'donation.form.submit',
    )} ${getAmountWithCurrency()}`;

    const handleRoundAmount = () => {
        if (values.amount) {
            setFieldValue('amount', roundPrice(values.amount));
        }
    };

    const updatePhoneNumber = (phone: string) => {
        setFieldValue('phone', phone);
    };

    const canSave = dirty && isValid && !donationLoading;
    const initialCondolenceValues: Partial<ICreatedCondolence> = {
        displayName: values.name,
    };

    const submitButtonIcon = useMemo(() => {
        if (donationLoading) {
            return Spinner;
        }
        if (values.addCondolence && !addCondolenceModal.state) {
            return ArrowIcon;
        }
        return DonationIcon;
    }, [values.addCondolence, addCondolenceModal.state, donationLoading]);

    return (
        <>
            <Prompt
                when={dirty}
                message={t('donation.form.exitConfirmation')}
            />
            <form className={styles.Form}>
                <OrangeHeadline className={styles.Headline} marginBottom>
                    {t('donation.headline')}
                </OrangeHeadline>
                <div className={styles.OrganizationContainer}>
                    <div className={styles.OrganizationHeader}>
                        <div className={styles.OrganizationTitle}>
                            {t('donation.info')}
                            <span className={styles.OrganizationName}>
                                {donationOrganizationName}
                            </span>
                        </div>
                        {donationOrganizationLogo && (
                            <img
                                className={styles.OrganizationLogo}
                                src={donationOrganizationLogo}
                                alt={donationOrganizationName || ''}
                            />
                        )}
                    </div>
                    {donationOrganizationDescription && (
                        <div className={styles.OrganizationBody}>
                            {donationOrganizationDescription}
                        </div>
                    )}
                </div>
                <OrangeHeadline className={styles.Headline}>
                    {t('donation.form.headline.details')}
                </OrangeHeadline>
                <div className={styles.FieldsColumn}>
                    <div className={styles.Field}>
                        <TextField
                            placeholder={t('donation.form.name')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            error={Boolean(errors.name)}
                            name="name"
                        />
                        <ErrorMessage className={styles.ErrorMessage}>
                            {errors.name}
                        </ErrorMessage>
                    </div>
                    <div className={styles.FieldsRow}>
                        <div className={styles.Field}>
                            <TextField
                                placeholder={t('donation.form.email')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                error={Boolean(errors.email)}
                                name="email"
                            />
                            <ErrorMessage className={styles.ErrorMessage}>
                                {errors.email}
                            </ErrorMessage>
                        </div>
                        <div className={styles.Field}>
                            <PhoneInput
                                onBlur={handleBlur}
                                inputProps={{
                                    placeholder: t('donation.form.phone'),
                                }}
                                onChange={updatePhoneNumber}
                                value={values.phone}
                                error={Boolean(errors.phone)}
                                name="phone"
                            />
                            <ErrorMessage className={styles.ErrorMessage}>
                                {errors.phone}
                            </ErrorMessage>
                        </div>
                    </div>
                    {personalIdValidator && (
                        <div className={styles.Field}>
                            <TextField
                                placeholder={t('donation.form.personalId')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.personalId}
                                error={Boolean(errors.personalId)}
                                name="personalId"
                            />
                            <ErrorMessage className={styles.ErrorMessage}>
                                {errors.personalId &&
                                    t('errors.personalId', {
                                        personalId:
                                        personalIdValidator?.localName,
                                    })}
                            </ErrorMessage>
                            <div className={styles.PersonalIdDescription}>
                                {t('donation.form.personalIdDescription')}
                            </div>
                        </div>
                    )}
                </div>
                <OrangeHeadline
                    className={cx(styles.Headline, styles.NoMarginTop)}
                >
                    {t('donation.form.headline.amount')}
                </OrangeHeadline>
                <div className={styles.FieldsColumn}>
                    <div className={styles.Field}>
                        <TextField
                            placeholder={t('donation.form.amount')}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amount}
                            name="amount"
                            type="number"
                            error={Boolean(errors.amount)}
                            startAdornment={donationCurrency}
                            onBlurCapture={handleRoundAmount}
                        />
                        <ErrorMessage className={styles.ErrorMessage}>
                            {errors.amount}
                        </ErrorMessage>
                        {!!(donationFee && donationFee > 0) && (
                            <div className={styles.AmountDescription}>
                                {t('donation.form.AmountDescription')} {`${donationCurrency} ${roundPriceToString(donationFee)}`}
                            </div>
                        )}
                    </div>
                </div>
                <Tooltip
                    placement="bottom-start"
                    title={t('donation.form.tooltip.share').toString()}
                    classes={{
                        tooltip: styles.Tooltip,
                    }}
                >
                    <div>
                        <Checkbox
                            checked={values.share}
                            className={styles.Checkbox}
                            label={t('donation.form.share')}
                            name="share"
                            onChange={handleChange}
                        />
                    </div>
                </Tooltip>
                <OrangeHeadline className={styles.Headline} marginBottom>
                    {t('donation.form.condolence.headline')}
                </OrangeHeadline>
                <Checkbox
                    checked={values.addCondolence}
                    className={styles.Checkbox}
                    label={t('donation.form.condolence.checkbox')}
                    name="addCondolence"
                    onChange={handleChange}
                />
                <OrangeHeadline className={styles.Headline} marginBottom>
                    {t('donation.form.paymentInfo.headline')}
                </OrangeHeadline>
                <div className={styles.PaymentInfoContainer}>
                    <div className={styles.PaymentInfoDescription}>
                        {t('donation.form.paymentInfo.description', {
                            donate: t('donation.form.submit'),
                        })}
                    </div>
                    <div className={styles.PaymentInfoIcons}>
                        <MastercardIcon/>
                        <VisaIcon/>
                    </div>
                </div>
                {donationErrorText && (
                    <ErrorMessage
                        className={cx(
                            styles.ErrorMessage,
                            styles.FormErrorMessage,
                        )}
                    >
                        {donationErrorText}
                    </ErrorMessage>
                )}
                <PrimaryButton
                    className={styles.SubmitButton}
                    disabled={!canSave}
                    onClick={handleSubmitButtonClick}
                    icon={submitButtonIcon}
                >
                    {values.addCondolence
                        ? t('donation.form.continue')
                        : sendDonationButtonTitle}
                </PrimaryButton>
                <AddCondolenceModal
                    isOpened={addCondolenceModal.state}
                    handleClose={addCondolenceModal.handleSetAsFalse}
                    handleAdd={handleSendDonation}
                    initialCondolenceValues={initialCondolenceValues}
                    customButtonText={sendDonationButtonTitle}
                    customButtonIcon={DonationIcon}
                    donationLoading={donationLoading}
                    donationError={donationErrorText}
                />
            </form>
        </>
    );
};

export const ConnectedDonationDetailsForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IDonationDetailsFormValues>({
        mapPropsToValues({userEmail}) {
            return {
                amount: undefined,
                fee: 0,
                name: '',
                phone: '',
                email: userEmail || '',
                share: true,
                addCondolence: false,
                condolence: undefined,
                personalId: '',
            };
        },
        handleSubmit(values, {props}) {
            props.onSubmit(values);
        },
        validationSchema: donationDetailsFormValidation,
        enableReinitialize: true,
    }),
)(DonationDetailsForm);
