import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { accessRequestFormSubmitted } from '../../access-request.actions';

import { handleAccessRequestFormSubmitted } from './handle-access-request-form-submitted.saga';

export function* accessRequestSaga() {
    yield takeEvery(
        getType(accessRequestFormSubmitted),
        handleAccessRequestFormSubmitted,
    );
}
