import { apiService } from '../../../api.service';
import { ICreateOrderRequestDto } from '../../../DTOs/create-order-request.dto';
import { ICreateOrderResponseDto } from '../../../DTOs/create-order-response.dto';

export const createOrder = ({
    slug,
    dto,
}: {
    slug: string;
    dto: ICreateOrderRequestDto;
}): Promise<ICreateOrderResponseDto> =>
    apiService
        .post(`/public/memorial_pages/${slug}/orders`, {
            json: dto,
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
