import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ILanguage } from '../../app/interfaces/language';
import { getLanguage } from '../../app/model/configuration/selectors/get-language.selector';
import { getLanguagesWithFlags } from '../../app/model/configuration/selectors/get-languages-with-flags.selector';
import { languageChangeRequested } from '../../app/model/configuration/configuration.actions';

export const withLanguageSelect = () => <
    Props extends {
        language: ILanguage;
        languages: ILanguage[];
        onChange(lang: ILanguage): unknown;
    }
>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    const dispatch = useDispatch();
    const language = useSelector(getLanguage);
    const languages = useSelector(getLanguagesWithFlags);

    const onChange = (lang: ILanguage) => {
        dispatch(languageChangeRequested({ language: lang }));
    };

    return (
        <Component
            {...props}
            selectedLanguage={language}
            languages={languages}
            onChange={onChange}
        />
    );
};
