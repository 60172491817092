import React, { HTMLProps } from 'react';
import cx from 'classnames';

import styles from './radio.module.scss';

interface IProps extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
    label?: React.ReactNode;
}

export const Radio = ({
    className,
    label,
    type,
    checked,
    ...props
}: IProps) => {
    return (
        <label className={cx(className, styles.Label)}>
            <div className={styles.Wrapper}>
                <input type="radio" {...props} hidden checked={checked} />
                <div
                    className={cx(styles.Radio, {
                        [styles.Active]: checked,
                    })}
                />
                {label}
            </div>
        </label>
    );
};
