import { ILanguage } from '../../interfaces/language';

export const availableLanguages: ILanguage[] = [
    { code: 'en' },
    { code: 'pl' },
    { code: 'nb' },
    { code: 'de' },
    { code: 'fr' },
    { code: 'sv' },
    { code: 'nl' },
    { code: 'ar' },
];
