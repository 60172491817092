import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { FlowersSummaryItem } from '../flowers-summary-item/flowers-summary-item';
import { IFlowerSummaryEntry } from '../../../interfaces/flower-summary-entry';

import styles from './flowers-summary-listing.module.scss';

export const FlowersSummaryListing = ({
    items,
}: {
    items: IFlowerSummaryEntry[];
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.FlowersSummaryListing}>
            <div className={styles.Columns}>
                <span className={cx(styles.Column, styles.ColumnLeftAligned)} />
                <span className={cx(styles.Column, styles.ColumnLeftAligned)}>
                    {t('flowers.summary.column11')}
                </span>
                <span className={styles.Column}>
                    {t('flowers.summary.column2')}
                </span>
                <span className={styles.Column}>
                    {t('flowers.summary.column21')}
                </span>
                <span className={styles.Column}>
                    {t('flowers.summary.column3')}
                </span>
                <span className={styles.Column}>
                    {t('flowers.summary.column4')}
                </span>
                <span className={styles.Column}>
                    {t('flowers.summary.column5')}
                </span>
            </div>
            {items.map((item) => (
                <FlowersSummaryItem
                    key={item.id}
                    item={item}
                    data-testid={`flower-summary-item-id-${item.id}`}
                />
            ))}
        </div>
    );
};
