import { createAsyncAction } from 'typesafe-actions';

import { IGalleryPhotoDto } from '../DTOs/gallery-photo.dto';
import { IGalleryPhotoWithMetaData } from '../../interfaces/gallery-photo';

export const uploadGalleryPhotoApiActions = createAsyncAction(
    'api/gallery/UPLOAD_GALLERY_PHOTO_REQUESTED',
    'api/gallery/UPLOAD_GALLERY_PHOTO_SUCCEEDED',
    'api/gallery/UPLOAD_GALLERY_PHOTO_FAILED',
)<IGalleryPhotoWithMetaData, IGalleryPhotoDto, string>();

export const downloadGalleryApiActions = createAsyncAction(
    'api/gallery/DOWNLOAD_GALLERY_REQUESTED',
    'api/gallery/DOWNLOAD_GALLERY_SUCCEEDED',
    'api/gallery/DOWNLOAD_GALLERY_FAILED',
)<undefined, { url: string }, string>();
