import { createSelector } from 'reselect';

import { UploadGalleryPhotoStatus } from '../gallery.store';
import i18n from '../../../../setup/i18n';

import { getUploadPhotoStatus } from './get-upload-photo-status.selector';

export const getUploadPhotoStatusMessage = createSelector(
    getUploadPhotoStatus,
    (status) => {
        const uploadGalleryPhotoStatusMessages = {
            [UploadGalleryPhotoStatus.SUCCESS]: i18n.t(
                'uploadGalleryPhotoStatusMessages.success',
            ),
            [UploadGalleryPhotoStatus.ERROR]: i18n.t(
                'uploadGalleryPhotoStatusMessages.error',
            ),
        };

        return status && uploadGalleryPhotoStatusMessages[status];
    },
);
