import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';

import {
    eventAttendanceFormSubmitted,
    resetEventAttendanceState,
    setEventAttendanceError,
    setEventAttendanceStatus,
} from './event-attendance.actions';

export type EventAttendanceStatus = 'SUCCESS';

interface IEventAttendanceStore {
    error: string | null;
    loading: boolean;
    status: EventAttendanceStatus | null;
}

const initialState: IEventAttendanceStore = {
    error: null,
    status: null,
    loading: false,
};

export const eventAttendancePageReducer = createReducer<
    IEventAttendanceStore,
    RootActions
>(initialState)
    .handleAction(setEventAttendanceError, (state, action) => ({
        ...state,
        error: action.payload,
    }))
    .handleAction(eventAttendanceFormSubmitted, (state, action) => ({
        ...state,
        loading: true,
    }))
    .handleAction(setEventAttendanceStatus, (state, action) => ({
        ...state,
        status: action.payload,
        loading: false,
    }))
    .handleAction(resetEventAttendanceState, () => ({
        ...initialState,
    }));
