/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { OrangeHeadline } from '../../components/orange-headline/orange-headline';
import { Radio } from '../../components/radio/radio';
import { FlowerDeliveryType } from '../../interfaces/flower-delivery-type';
import { FlowersFlowActions } from '../../model/flowers/flowers.actions';
import { FlowersSelectors } from '../../model/flowers/flowers.selector';
import { getEvents } from '../../model/events/selectors/get-events.selector';
import { EventType } from '../../interfaces/event-type';
import { ReactComponent as PinIcon } from '../../assets/icons/icon-pin.svg';
import { ReactComponent as DateIcon } from '../../assets/icons/icon-time.svg';
import { useDateFormatter } from '../../utils/use-date-formatter';
import { getUserEmail } from '../../model/user/selectors/get-user-email';

import { ContactDetailsForm } from './contact-details-form/contact-details-form';
import { useFormController } from './use-form-controller';
import { IDeliveryRecipientFormValues } from './delivery-recipient-form/delivery-recipient-form-values';
import { useFlowerDeliveryState } from './use-flower-delivery-state';
import { DeliveryAddressForm } from './delivery-address-form/delivery-address-form';
import { IDeliveryAddressFormValues } from './delivery-address-form/delivery-address-form-values';
import { resolveDeliveryLabel } from './resolve-delivery-label';
import { DeliveryRecipientForm } from './delivery-recipient-form/delivery-recipient-form';
import { IContactDetailsFormValues } from './contact-details-form/contact-details-form-values';
import styles from './flowers-delivery.module.scss';

const makeRenderDeliveryRadio = (
    activeDeliveryType: FlowerDeliveryType | null,
    onChange: (newType: FlowerDeliveryType) => unknown,
    t: (k: string) => string,
    className: string,
) => (boundType: FlowerDeliveryType) => (
    <Radio
        className={className}
        checked={boundType === activeDeliveryType}
        onChange={() => onChange(boundType)}
        label={<span>{t(resolveDeliveryLabel(boundType))}</span>}
    />
);

export type FlowersDeliveryProps = {
    onChange: (isFormValid: boolean) => void;
};

export const FlowersDelivery = ({ onChange }: FlowersDeliveryProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const events = useSelector(getEvents);
    const ceremony = events.find((event) => event.type === EventType.CEREMONY);
    const { getTextDate } = useDateFormatter();
    const userEmail = useSelector(getUserEmail);

    const addressDataInStore = useSelector(FlowersSelectors.getAddressData);
    const recipientDataInStore = useSelector(FlowersSelectors.getRecipientData);
    const contactDataInStore = useSelector(FlowersSelectors.getContactData);

    const {
        deliveryTypes,
        activeDelivery,
        setActiveDelivery,
        ceremonyDeliveryAvailable,
        graveDeliveryAvailable,
        privateAddressDeliveryAvailable,
        isDeliveryRecipientDataRequired,
        isDeliveryAddressDataRequired,
    } = useFlowerDeliveryState();

    const recipientFormController = useFormController<
        IDeliveryRecipientFormValues
    >(recipientDataInStore);
    const addressFormController = useFormController<IDeliveryAddressFormValues>(
        addressDataInStore,
    );
    const contactDetailsFormController = useFormController<
        IContactDetailsFormValues
    >(contactDataInStore);

    const addressDataPrefilledValues = useMemo(
        () => addressFormController.data,
        [activeDelivery],
    );
    const recipientDataPrefilledValues = useMemo(
        () => recipientFormController.data,
        [activeDelivery],
    );
    const contactDataPrefilledValues = useMemo(
        () => contactDetailsFormController.data,
        [activeDelivery],
    );

    const { state } = useLocation<{
        isEdited: boolean;
    }>();

    const renderDeliveryRadio = makeRenderDeliveryRadio(
        activeDelivery,
        setActiveDelivery,
        t,
        styles.DeliveryTypeItem,
    );

    const isRecipientDataValid =
        recipientFormController.valid || !isDeliveryRecipientDataRequired;
    const isAddressDataValid =
        addressFormController.valid || !isDeliveryAddressDataRequired;

    const isContactDataValid = !!contactDetailsFormController.valid;

    const isFormValid =
        isContactDataValid && isRecipientDataValid && isAddressDataValid;

    const handleUpdateDelivery = () => {
        onChange(isFormValid);
        dispatch(
            FlowersFlowActions.deliveryChosen(
                {
                    deliveryType: activeDelivery!,
                    address: addressFormController.data || undefined,
                    recipientData: recipientFormController.data || undefined,
                    contactData: contactDetailsFormController.data || undefined,
                },
                {
                    isEdited: state && state.isEdited,
                    isFilledInSummary: true,
                },
            ),
        );
    };

    useEffect(() => {
        handleUpdateDelivery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isFormValid,
        isDeliveryRecipientDataRequired,
        isDeliveryAddressDataRequired,
        addressFormController.data,
        recipientFormController.data,
        contactDetailsFormController.data,
    ]);

    return (
        <div>
            <OrangeHeadline marginBottom>
                {t('flowers.delivery.subHeadline')}
            </OrangeHeadline>
            <p>{t('flowers.delivery.hintText')}</p>
            {deliveryTypes && deliveryTypes.length > 0 && (
                <div className={styles.DeliveryTypeContainer}>
                    {ceremonyDeliveryAvailable &&
                        renderDeliveryRadio(FlowerDeliveryType.CEREMONY)}
                    {graveDeliveryAvailable &&
                        renderDeliveryRadio(FlowerDeliveryType.GRAVE)}
                    {privateAddressDeliveryAvailable &&
                        renderDeliveryRadio(FlowerDeliveryType.PRIVATE_ADDRESS)}
                </div>
            )}
            {isDeliveryAddressDataRequired && (
                <DeliveryAddressForm
                    preselectedValues={addressDataPrefilledValues}
                    className={styles.ExtraDataForm}
                    onChange={addressFormController.onChange}
                />
            )}
            {isDeliveryRecipientDataRequired && (
                <DeliveryRecipientForm
                    className={styles.ExtraDataForm}
                    preselectedValues={recipientDataPrefilledValues}
                    onChange={recipientFormController.onChange}
                />
            )}
            {ceremony && activeDelivery === FlowerDeliveryType.CEREMONY && (
                <div className={styles.CeremonyAddress}>
                    {ceremony.title && (
                        <div className={styles.ListRow}>{ceremony.title}</div>
                    )}
                    {ceremony.date && (
                        <div className={styles.ListRow}>
                            <div className={styles.IconContainer}>
                                <DateIcon className={styles.Icon} />
                            </div>
                            <div className={styles.RowContent}>
                                {getTextDate(ceremony.date)}
                            </div>
                        </div>
                    )}
                    {ceremony.location && ceremony.location.name && (
                        <div className={styles.ListRow}>
                            <div className={styles.IconContainer}>
                                <PinIcon className={styles.Icon} />
                            </div>
                            <div className={styles.RowContent}>
                                {`${ceremony.location.name}`}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <OrangeHeadline marginBottom>
                {t('flowers.delivery.contactDetailSubHeadline')}
            </OrangeHeadline>
            <p>{t('flowers.delivery.contactDetailsHintText')}</p>
            <ContactDetailsForm
                onChange={contactDetailsFormController.onChange}
                prefilledValues={contactDataPrefilledValues}
                userEmail={userEmail}
            />
        </div>
    );
};
