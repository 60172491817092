import React from 'react';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import cx from 'classnames';

import styles from '../image-carousel.module.scss';
import { useImageCarouselFullscreenContext } from '../contexts/fullscreen-context/use-fullscreen-context';

export const Footer = React.memo(() => {
    const {
        isFullscreen,
        enterFullscreen,
    } = useImageCarouselFullscreenContext();
    return (
        <div className={styles.Footer}>
            {!isFullscreen && (
                <div
                    className={cx(styles.Icon, styles.FullscreenIcon)}
                    onClick={enterFullscreen}
                >
                    <FullscreenRoundedIcon />
                </div>
            )}
        </div>
    );
});
