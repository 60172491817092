import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { ForgottenPasswordActions } from '../forgotten-password-api.actions';
import { setNewPasswordFormSubmitted } from '../../../forgotten-password/model/forgotten-password.actions';
import { handleSetNewPasswordFormSubmitSaga } from '../../../forgotten-password/saga/handle-set-new-password-form-submit.saga';

import { resetPasswordApiSaga } from './reset-password-api.saga';
import { sendPasswordResetEmailApiSaga } from './send-password-reset-email.saga';

export function* forgottenPasswordApiSaga() {
    yield takeEvery(
        getType(ForgottenPasswordActions.resetEmail.request),
        sendPasswordResetEmailApiSaga,
    );

    yield takeEvery(
        getType(ForgottenPasswordActions.setNew.request),
        resetPasswordApiSaga,
    );

    yield takeEvery(
        getType(setNewPasswordFormSubmitted),
        handleSetNewPasswordFormSubmitSaga,
    );
}
