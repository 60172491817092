import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { contactSuggestionFormSubmitted } from '../contact-suggestion.actions';

import { handleContactSuggestionFormSubmittedSaga } from './handle-contact-suggestion-form-submitted.saga';

export function* contactSuggestionSaga() {
    yield takeEvery(
        getType(contactSuggestionFormSubmitted),
        handleContactSuggestionFormSubmittedSaga,
    );
}
