import { INavRoute, NavigationLinkIds } from '../interfaces/nav-route';

import { RoutePaths } from './route-paths';

export const FooterLinks: INavRoute[] = [
    {
        id: NavigationLinkIds.DETAILS,
        href: RoutePaths.HOME,
    },
    {
        id: NavigationLinkIds.SEND_FLOWERS,
        href: RoutePaths.FLOWERS_ROOT,
    },
    {
        id: NavigationLinkIds.SEND_DONATION,
        href: RoutePaths.DONATION_ROOT,
    },
    {
        id: NavigationLinkIds.GALLERY,
        href: RoutePaths.GALLERY,
    },
    {
        id: NavigationLinkIds.SUGGEST_GUEST,
        href: RoutePaths.SHARE,
    },
];
