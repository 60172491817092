import * as i18next from 'i18next';

import { LanguageCode } from '../../app/utils/get-language-codes';

export const getShorterTranslationIfExists = (
    i18n: i18next.i18n,
    translationKey: string,
): string => {
    return i18n.t([`${translationKey}Short`, translationKey], {
        fallbackLng: [i18n.language, LanguageCode.EN],
    });
};
