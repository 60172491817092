import { useState } from 'react';

import { useImageCarouselFullscreenContext } from '../../../image-carousel/contexts/fullscreen-context/use-fullscreen-context';
import { getInitialPhotosCount } from '../../image-carousel-gallery.helpers';

import { ImageCarouselGalleryWrapperProps } from './image-carousel-gallery-wrapper.types';

export const useImageCarouselGalleryWrapper = ({
    photos,
    setImageIndex,
    ...props
}: ImageCarouselGalleryWrapperProps) => {
    const { isFullscreen } = useImageCarouselFullscreenContext();
    const [expanded, setExpanded] = useState<boolean>(props.expanded);

    let initialPhotosCount = getInitialPhotosCount();

    if (photos.length - initialPhotosCount === 1) {
        initialPhotosCount = photos.length;
    }

    const showExpander = !expanded && photos.length > initialPhotosCount;
    const expanderPhotosCount = photos.length - initialPhotosCount;
    const firstExpanderPhoto = photos[initialPhotosCount];
    const expanderBackgroundUrl = showExpander
        ? firstExpanderPhoto.previewUrl || firstExpanderPhoto.originalUrl
        : '';

    const filteredPhotos = expanded
        ? photos
        : photos.slice(0, initialPhotosCount);

    const handleExpandGallery = () => {
        setExpanded(true);
        props.setExpanded(true);
    };

    const handleGalleryItemClick = (index) => {
        setImageIndex(index);
    };

    return {
        isFullscreen,
        filteredPhotos,
        handleGalleryItemClick,
        handleExpandGallery,
        showExpander,
        expanderPhotosCount,
        expanderBackgroundUrl,
    };
};
