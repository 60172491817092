import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';
import { handleEventPreventDefault } from '../../../shared/helpers/handleEventPreventDefault';
import { useDisableScrolling } from '../../../shared/hooks/useDisableScrolling';

import styles from './modal.module.scss';
import { IModalProps, ModalSize } from './modal-types';

export const Modal: React.FC<IModalProps> = ({
    children,
    onClose,
    open,
    title,
    classname,
    overlayClassname,
    size,
    shouldCloseOnOverlayClick,
}) => {
    const { enableScrolling, disableScrolling } = useDisableScrolling();

    useEffect(() => {
        if (open) {
            disableScrolling();
        } else {
            enableScrolling();
        }
    }, [open, enableScrolling, disableScrolling]);

    return (
        <ReactModal
            ariaHideApp={false}
            className={cx(
                styles.ModalContent,
                classname,
                styles[`ModalSize${size}`],
            )}
            isOpen={open}
            onRequestClose={onClose}
            overlayClassName={cx(styles.ModalOverlay, overlayClassname)}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
            <PlusIcon
                className={styles.ModalCloseIcon}
                onClick={onClose}
                onMouseDown={handleEventPreventDefault}
            />
            {title && <span className={styles.ModalTitle}>{title}</span>}
            {children}
        </ReactModal>
    );
};

Modal.defaultProps = {
    size: ModalSize.Regular,
};
