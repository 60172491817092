import * as yup from 'yup';
import parsePhoneNumberFromString from 'libphonenumber-js/max';

import { IOuterProps } from './donation-details-form';

export const donationDetailsFormValidation = ({
    personalIdValidator,
}: IOuterProps) =>
    yup.object().shape({
        amount: yup
            .number()
            .positive('error.invalidField')
            .required('error.requiredField'),
        name: yup.string().required(),
        email: yup
            .string()
            .email()
            .required(),
        share: yup.boolean().required(),
        phone: yup
            .string()
            .required()
            .test({
                name: 'isValid',
                message: 'error.invalidPhone',
                test: (value: string | undefined) => {
                    if (!value) {
                        return false;
                    }
                    const parsedPhone = parsePhoneNumberFromString(value);

                    return parsedPhone?.isValid() || false;
                },
            }),
        personalId: yup.string().test({
            name: 'isValid',
            test: (value: string | undefined) => {
                if (!value || !personalIdValidator) {
                    return true;
                }
                return personalIdValidator.validate(value).isValid;
            },
        }),
    });
