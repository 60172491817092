import * as yup from 'yup';

const MIN_PHONE_LENGTH = 4;

export const contactSuggestionFormValidation = () =>
    yup.object().shape(
        {
            name: yup.string().required(),
            email: yup
                .string()
                .email()
                .when(['phone'], {
                    is: (phone: boolean) => !phone,
                    then: yup
                        .string()
                        .email()
                        .required(),
                }),
            phone: yup
                .string()
                .transform((value: string) => {
                    /**
                     * Trim "+" or "+1" or "+48" etc
                     */
                    if (
                        value.indexOf('+') === 0 &&
                        value.length <= MIN_PHONE_LENGTH
                    ) {
                        return '';
                    }

                    return value;
                })
                .when(['email'], {
                    is: (email: boolean) => !email,
                    then: yup.string(),
                }),
        },
        [['email', 'phone']],
    );
