import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import { sendViolationReportApiActions } from '../violation-reports-api.actions';

import { sendViolationReportsApiSaga } from './send-violation-reports-api.saga';

export function* violationReportApiSaga() {
    yield takeEvery(
        getType(sendViolationReportApiActions.request),
        sendViolationReportsApiSaga,
    );
}
