import { useRef, useState } from 'react';
import { FormikErrors } from 'formik';

export interface ICanBeControlled<Values extends {}> {
    onChange(
        values: Values,
        validateForm: () => Promise<FormikErrors<Values>>,
        valid: boolean,
    ): unknown;
}

/**
 * Hook allowing control of form in case of form not having a submit functionality
 * TODO: Make this reusable util and replace in app
 */
export const useFormController = <FormValues extends {}>(
    initialData: FormValues | null = null,
) => {
    const [data, setData] = useState<FormValues | null>(initialData || null);
    const [valid, setValid] = useState<boolean>();
    const validateRef = useRef<
        null | (() => Promise<FormikErrors<FormValues>>)
    >(null);

    return {
        onChange(
            values: FormValues,
            validateForm: () => Promise<FormikErrors<FormValues>>,
            isValid: boolean,
        ) {
            setData(values);
            setValid(isValid);
            validateRef.current = validateForm;
        },
        validate: async () => {
            try {
                return validateRef.current!().then((errors) => {
                    return Boolean(Object.keys(errors).length);
                });
            } catch (e) {
                return false;
            }
        },
        data,
        valid,
    };
};
