import React, { HTMLAttributes, useRef } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import StringToHtmlParser from '../../components/string-to-html-parser/string-to-html-parser';

import styles from './tribute-text.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & { content: string };

export const TributeText = ({ content, className }: Props) => {
    const theme = useSelector(getTheme);
    const containerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className={cx(className, styles.Container)}>
            <div
                className={cx(styles.Content, styles[theme], {
                    [styles.Expanded]: true,
                })}
            >
                <div ref={containerRef}>
                    <StringToHtmlParser stringToParse={content} />
                </div>
            </div>
        </div>
    );
};
