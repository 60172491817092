import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './image-carousel-gallery-expander.module.scss';

type Props = React.HTMLAttributes<HTMLImageElement> & {
    backgroundUrl: string;
    photosCount: number;
    onClick(): unknown;
};

export const ImageCarouselGalleryExpander = ({
    backgroundUrl,
    onClick,
    photosCount,
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className={styles.GalleryExpander} onClick={onClick}>
            <img
                alt=""
                className={styles.GalleryBackground}
                src={backgroundUrl}
            />
            <div className={styles.Overlay} />
            <span className={styles.Label}>
                +{photosCount} {t('gallery.photos')}
            </span>
            <span className={styles.LabelOnHover}>{t('gallery.showAll')}</span>
        </div>
    );
};
