import { createAsyncAction } from 'typesafe-actions';

import { IDonationDetailsFormValues } from '../../donation/details/form/donation-details-form';
import { ISendDonationRequestDto } from '../../api/DTOs/send-donation-request.dto';

export const sendDonationActions = createAsyncAction(
    'model/donation/SEND_DONATION_REQUESTED',
    'model/donation/SEND_DONATION_SUCCEEDED',
    'model/donation/SEND_DONATION_FAILED',
    'model/donation/SEND_DONATION_CANCEL',
)<
    { formValues?: IDonationDetailsFormValues; dto?: ISendDonationRequestDto },
    ISendDonationRequestDto,
    boolean,
    undefined
>();
