import { isIOS } from 'react-device-detect';

import { MOBILE_SAFARI_BOTTOM_BAR_HEIGHT } from '../../../../../shared/constants/layout';
import { VIDEO_PLAYER_DEFAULT_OPTIONS } from '../../../video-player/video-player.constants';

export const getVideoPlayerOptions = (isFullscreen: boolean) => {
    return isFullscreen
        ? {
              minHeight: isIOS
                  ? `${window.innerHeight - MOBILE_SAFARI_BOTTOM_BAR_HEIGHT}px`
                  : '100vh',
              minWidth: `100vw`,
          }
        : VIDEO_PLAYER_DEFAULT_OPTIONS;
};
