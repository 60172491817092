import { createAction } from 'typesafe-actions';

import { ITask } from '../../interfaces/task';
import { ITaskVolunteerData } from '../../interfaces/task-volunteer-data';
import { ITaskVolunteerStatus } from '../../interfaces/task-volunteer-status';

export const tasksDataReceived = createAction(
    'model/tasks/DATA_RECEIVED',
    (payload: { tasks: ITask[] }) => payload,
)();

export const volunteerTaskRequested = createAction(
    'model/tasks/VOLUNTEER_TASK_REQUESTED',
    (payload: ITaskVolunteerData) => payload,
)();

export const setTaskVolunteerStatus = createAction(
    'model/tasks/SET_TASK_VOLUNTEER_STATUS',
    (payload: ITaskVolunteerStatus | null) => payload,
)();
