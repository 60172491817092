import {
    FlowerDeliveryType,
    IFlowerDeliveryType,
} from '../../app/interfaces/flower-delivery-type';

export const checkIfDeliveryAddressIsRequired = (
    deliveryTypes: IFlowerDeliveryType[] | null,
    selectedDeliveryType: FlowerDeliveryType | null,
) => {
    if (deliveryTypes === null || selectedDeliveryType === null) {
        return false;
    }

    return !!deliveryTypes.find(
        (delivery) => delivery.name === selectedDeliveryType,
    )?.locationRequired;
};
