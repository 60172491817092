import { useDispatch, useSelector } from 'react-redux';

import { attendEventRequested } from '../../model/events/events.actions';
import { getEventAttendanceStatus } from '../../model/events/selectors/get-event-attendance-status.selector';
import { getEventAttendanceStatusMessage } from '../../model/events/selectors/get-event-attendance-status-message.selector';
import { IEventAttendanceData } from '../../interfaces/event-attendance-data';

export const useAttendEvent = () => {
    const dispatch = useDispatch();
    const attendanceStatus = useSelector(getEventAttendanceStatus);
    const attendanceStatusMessage = useSelector(
        getEventAttendanceStatusMessage,
    );

    return {
        attendanceStatus,
        attendanceStatusMessage,
        attendEventRequested(payload: IEventAttendanceData) {
            dispatch(attendEventRequested(payload));
        },
    };
};
