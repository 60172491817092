import { createSelector } from 'reselect';

import { getCondolencesLikesStore } from './get-condolences-likes-store.selector';

export const getCondolenceCommentLikeTokenByCondolenceCommentId = (
    id: string,
) =>
    createSelector(
        getCondolencesLikesStore,
        (store) =>
            store.condolencesCommentsLikes.find(
                (condolenceCommentLike) => condolenceCommentLike.id === id,
            )?.likeToken,
    );
