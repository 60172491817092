import { createBrowserHistory } from 'history';

import {
    getBaseUrlFromLocation,
    getSlugFromLocation,
} from '../utils/parse-location';

const slug = getSlugFromLocation(window.location);
const baseUrl = getBaseUrlFromLocation(window.location);

/**
 * From ENV (which is without slug) get everything between host and slug
 */
const getHostSegments = () => {
    let segments = baseUrl
        .replace(window.location.host, '')
        .replace('http://', '')
        .replace('https://', '');

    if (segments[0] === '/') {
        segments = segments.replace('/', '');
    }

    if (segments[segments.length - 1] === '/') {
        segments = segments.slice(0, -1);
    }

    if (segments.length) {
        return `/${segments}/`;
    }
    return '';
};

export const browserHistory = createBrowserHistory({
    basename: `${getHostSegments()}${slug}`,
});
