import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';
import { IMemorialEvent } from '../../interfaces/memorial-event';
import { IEventAttendanceStatus } from '../../interfaces/event-attendance-status';

import { eventsDataReceived, setEventAttendanceStatus } from './events.actions';

export interface IEventsStore {
    eventAttendanceStatus: IEventAttendanceStatus | null;
    events: IMemorialEvent[];
}

const initialState: IEventsStore = {
    eventAttendanceStatus: null,
    events: [],
};

export const eventsReducer = createReducer<IEventsStore, RootActions>(
    initialState,
)
    .handleAction(eventsDataReceived, (state, action) => ({
        ...state,
        events: action.payload.events,
    }))
    .handleAction(setEventAttendanceStatus, (state, action) => ({
        ...state,
        eventAttendanceStatus: action.payload,
    }));
