import { createAction } from 'typesafe-actions';

import { IForgottenPasswordFormValues } from '../forgotten-password-form-values';

export const forgottenPasswordFormSubmitted = createAction(
    'auth/FORGOTTEN_PASSWORD_FORM_SUBMITTED',
    (payload: IForgottenPasswordFormValues) => payload,
)();

export const setNewPasswordFormSubmitted = createAction(
    'auth/SET_NEW_PASSWORD_FORM_SUBMITTED',
    (payload: { password: string; token: string }) => payload,
)();
