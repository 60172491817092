import { put } from '@redux-saga/core/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { userAccessToMemorialDetected } from '../user.actions';

/**
 * Resolve memorial data and figure out incomplete response, to detect no access
 */
export function* getMemorialAccessFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const memorialPageData = action.payload;

    const hasAccess = memorialPageData.data.attributes.authorized;

    yield put(userAccessToMemorialDetected({ hasAccess }));
}
