export const VIDEO_PLAYER_THEME: { [key: string]: string } = {
    '--vm-spinner-width': '40px',
    '--vm-spinner-height': '40px',
};

export const VIDEO_PLAYER_CURRENT_TIME_PRECISION = 0.05;
export const VIDEO_PLAYER_DEFAULT_OPTIONS = {
    minWidth: 'unset',
    minHeight: '300px',
};
