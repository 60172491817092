import ky, { Input, Options } from 'ky-universal';

import { attachAuthHeader } from './attach-auth-header';

export interface IApiServiceOptions {
    host: string;
    mapHeaders(headers: Headers): Headers;
    mapOptions(options: Options): Options;
}

/**
 * Simple proxy on HTTP library
 */
export class ApiService {
    private readonly host: string = '';

    private readonly options: IApiServiceOptions = {
        host: '',
        mapHeaders(headers: Headers) {
            return headers;
        },
        mapOptions(options: Options) {
            return options;
        },
    };

    constructor(options: Partial<IApiServiceOptions>) {
        this.options = { ...this.options, ...options };
        this.host = options.host || '';
        this.options.mapOptions = options.mapOptions || this.options.mapOptions;
    }

    get(input: Input, options?: Options) {
        return ky.get(this.host + input, this.mapOptions(options));
    }

    post(input: Input, options?: Options) {
        return ky.post(this.host + input, this.mapOptions(options));
    }

    put(input: Input, options?: Options) {
        return ky.put(this.host + input, this.mapOptions(options));
    }

    patch(input: Input, options?: Options) {
        return ky.patch(this.host + input, this.mapOptions(options));
    }

    delete(input: Input, options?: Options) {
        return ky.delete(this.host + input, this.mapOptions(options));
    }

    private mapOptions(options?: Options) {
        const newOptions: Options = options || {};
        let clonedHeaders: Headers;

        if (newOptions.headers) {
            clonedHeaders = new Headers(newOptions.headers);
        } else {
            clonedHeaders = new Headers();
        }

        clonedHeaders = this.options.mapHeaders(clonedHeaders);

        const mappedOptions = {
            ...newOptions,
            headers: clonedHeaders,
        };

        return this.options.mapOptions(mappedOptions);
    }
}

export const apiService = new ApiService({
    host: process.env.REACT_APP_API_HOST as string,
    mapHeaders: attachAuthHeader,
    mapOptions: (options) => {
        return {
            ...options,
            retry: {
                statusCodes: [403],
                methods: ['patch', 'get', 'delete', 'post'],
            },
        };
    },
});
