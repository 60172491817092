import React, { useState } from 'react';
import cx from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { isEqual } from 'lodash';
import { compose } from 'recompose';

import { ILanguageWithFlag, ILanguage } from '../../interfaces/language';
import { withLanguageSelect } from '../../../shared/hoc/with-language-select';
import { mapLanguageWithFlag } from '../../model/configuration/map-languages-to-flags';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down.svg';

import styles from './language-selector.module.scss';

type Props = {
    selectedLanguage: ILanguage;
    languages: ILanguageWithFlag[];
    onChange(language: ILanguage): unknown;
};

type OuterProps = {
    mobile?: boolean;
    vg?: boolean;
};

export const LanguageSelector = ({
    selectedLanguage,
    languages,
    mobile,
    onChange,
    vg,
}: Props & OuterProps) => {
    const [expanded, setExpanded] = useState(false);
    const languageSelectorRef = React.useRef(null);

    const language = mapLanguageWithFlag(selectedLanguage);

    useOnClickOutside(languageSelectorRef, () => {
        if (expanded) {
            setExpanded(false);
        }
    });

    const SelectedLanguageIcon = language.icon;

    const filteredLanguages = languages.filter((l) => !isEqual(l, language));

    const containerClass = mobile
        ? styles.MobileLanguageSelector
        : cx(styles.LanguageSelector, {
              [styles.ExpandedLanguageSelector]: expanded,
          });

    const innerContainerClass = cx(styles.SelectedLanguage, {
        [styles.SelectedLanguageExpanded]: expanded,
        [styles.vg]: vg,
    });

    return (
        <div
            className={containerClass}
            onClick={() => setExpanded(!expanded)}
            ref={languageSelectorRef}
        >
            <div className={innerContainerClass}>
                <SelectedLanguageIcon className={styles.LanguageIcon} />
                {expanded ? (
                    <span className={styles.LanguageName}>{language.code}</span>
                ) : (
                    <ArrowDown
                        className={cx({
                            [styles.Arrow]: true,
                            [styles.MobileArrow]: mobile,
                        })}
                    />
                )}
            </div>
            {expanded && (
                <div
                    className={cx(styles.LanguageSelectorDropdown, {
                        [styles.VGModifier]: !mobile && vg,
                    })}
                >
                    {filteredLanguages.map((lang) => {
                        const Icon = lang.icon;

                        return (
                            <div
                                className={styles.Language}
                                key={lang.code}
                                onClick={() => {
                                    onChange({ code: lang.code });
                                }}
                            >
                                <Icon className={styles.LanguageIcon} />
                                <span className={styles.LanguageName}>
                                    {lang.code}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export const ComposedLanguageSelector = compose<Props, OuterProps>(
    withLanguageSelect(),
)(LanguageSelector);
