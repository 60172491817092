import { apiService } from '../../api.service';
import { IEventAttendanceRequestDto } from '../../DTOs/event-attendance-request.dto';

export const sendEventAttendanceForm = ({
    slug,
    dto,
}: {
    slug: string;
    dto: IEventAttendanceRequestDto;
}): Promise<Response> =>
    apiService
        .post(`/public/memorial_pages/${slug}/attendance_registrations`, {
            json: {
                attendance_registration: {
                    attendable_id: dto.schedulable.id,
                    attendable_type: dto.schedulable.type,
                    full_name: dto.fullName,
                    phone: dto.phone,
                    email: dto.email,
                    food_comment: dto.foodComment,
                    memcare_news_accepted: dto.memcareNewsAccepted,
                    personal_information_processing_accepted:
                        dto.personalInformationProcessingAccepted,
                    sharing_personal_info_with_bereaved_accepted:
                        dto.sharingPersonalInfoWithBereavedAccepted,
                },
            },
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
