import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { sendDonationActions } from '../donation.actions';

import { handleSendDonationRequestSaga } from './handle-send-donation-request.saga';

export function* donationSaga() {
    yield takeEvery(
        getType(sendDonationActions.request),
        handleSendDonationRequestSaga,
    );
}
