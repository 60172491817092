import { useEffect, useRef, useState } from 'react';
import { PlayerProps } from '@vime/react/dist/types/components/Player';

import {
    IVideoPlayerProps,
    IPlayerProps,
    IVideoPlayerState,
} from './video-player.types';
import { getFormattedCurrentTime } from './video-player.helpers';
import { VIDEO_PLAYER_DEFAULT_OPTIONS } from './video-player.constants';

export const useVideoPlayerHandlers = ({
    src,
    playerOptions = VIDEO_PLAYER_DEFAULT_OPTIONS,
}: IVideoPlayerProps) => {
    const playerRef = useRef<IPlayerProps>(null);
    const playerRefCurrent = playerRef.current;
    const playerContainer = playerRefCurrent?.shadowRoot?.querySelector(
        '.video.player',
    );
    const playerContainerStyles = `
          min-width: ${playerOptions.minWidth};
          min-height: ${playerOptions.minHeight};
          border-radius: unset;
    `;

    const [isReady, setIsReady] = useState<boolean>(false);
    const [isPlaybackStarted, setIsPlaybackStarted] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<string | null>('');
    const [isPaused, setIsPaused] = useState<boolean>(false);
    const [isControlsActive, setIsControlsActive] = useState<boolean>(false);
    const [isFullscreenActive, setIsFullscreenActive] = useState<boolean>(
        false,
    );

    useEffect(() => {
        if (playerContainer) {
            playerContainer.setAttribute('style', playerContainerStyles);
        }
    }, [playerContainer, playerContainerStyles]);

    const handleChangeCurrentTime = () => {
        const playerState = playerRefCurrent?.getPlayerState();
        const formattedCurrentTime = getFormattedCurrentTime(
            playerState?.currentTime,
            playerState?.duration,
        );
        setCurrentTime(formattedCurrentTime);
    };

    const handleOnVmReady = ({ detail }) => {
        setIsReady(Boolean(detail));
    };

    const handleOnVmPlaybackStarted = () => {
        setIsPlaybackStarted(true);
    };

    const handleOnVmPlayingChange = ({ detail }) => {
        const playing = Boolean(detail);
        if (!playing) {
            handleChangeCurrentTime();
        }
        setIsPlaying(playing);
    };

    const handleOnVmSeeked = () => {
        handleChangeCurrentTime();
    };

    const handleOnVmPausedChange = ({ detail }) => {
        const paused = Boolean(detail);
        if (paused) {
            handleChangeCurrentTime();
        }
        setIsPaused(paused);
    };

    const handleOnVmControlsChange = ({ detail }) => {
        setIsControlsActive(Boolean(detail));
    };

    const handleOnVmFullscreenChange = ({ detail }) => {
        setIsFullscreenActive(Boolean(detail));
    };

    const playerHandlers: PlayerProps = {
        onVmReady: handleOnVmReady,
        onVmPlaybackStarted: handleOnVmPlaybackStarted,
        onVmPlayingChange: handleOnVmPlayingChange,
        onVmSeeked: handleOnVmSeeked,
        onVmPausedChange: handleOnVmPausedChange,
        onVmControlsChange: handleOnVmControlsChange,
        onVmFullscreenChange: handleOnVmFullscreenChange,
    };

    const playerState: IVideoPlayerState = {
        isPlaybackStarted,
        isPlaying,
        isControlsActive,
        isReady,
        isPaused,
        isFullscreenActive,
        currentTime,
        src,
    };

    return {
        playerRef,
        playerHandlers,
        playerState,
    };
};
