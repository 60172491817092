import { apiService } from '../../api.service';
import { ICreateCondolenceDto } from '../../DTOs/create-condolence.dto';
import { ICondolenceResponseDto } from '../../DTOs/condolence-response.dto';

export const createCondolence = ({
    slug,
    dto,
}: {
    slug: string;
    dto: ICreateCondolenceDto;
}): Promise<ICondolenceResponseDto> => {
    return apiService
        .post(`/public/memorial_pages/${slug}/condolences`, {
            json: {
                condolence: dto,
            },
            throwHttpErrors: false,
            timeout: 1600 * 1000, // should be able to upload a 100MB (BE limit) file on a 0.5mbps upload connection
        })
        .then((resp) => resp.json());
};
