import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalSize } from '../../../../components/modal/modal-types';
import { ConnectedCondolenceForm } from '../../../../condolences/condolence-form/condolence-form';
import { Modal } from '../../../../components/modal/modal';
import { PrimaryButtonProps } from '../../../../components/primary-button/primary-button';
import { ICreatedCondolence } from '../../../../interfaces/condolence';
import { ICondolenceFormValues } from '../../../../condolences/condolence-form-values';

import styles from './add-condolence-modal.module.scss';

interface IAddCondolenceModalProps {
    isOpened: boolean;
    handleClose: () => void;
    handleAdd: (condolence: ICondolenceFormValues) => void;
    initialCondolenceValues: Partial<ICreatedCondolence>;
    customButtonText?: string | null;
    customButtonIcon?: PrimaryButtonProps['icon'] | null;
    donationLoading: boolean;
    donationError: string | null;
    canSaveWithoutEdits?: boolean;
}
export const AddCondolenceModal = ({
    isOpened,
    handleClose,
    handleAdd,
    initialCondolenceValues,
    customButtonText,
    customButtonIcon,
    donationLoading,
    donationError,
    canSaveWithoutEdits,
}: IAddCondolenceModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            onClose={handleClose}
            open={isOpened}
            title={t('donation.form.condolence.modal.title')}
            overlayClassname={styles.ModalOverlay}
            size={ModalSize.Wide}
            classname={styles.Modal}
            shouldCloseOnOverlayClick={false}
        >
            <ConnectedCondolenceForm
                initialValues={initialCondolenceValues}
                onCreate={handleAdd}
                customButtonText={customButtonText}
                customButtonIcon={customButtonIcon}
                customLoading={donationLoading}
                customError={donationError}
                canSaveWithoutEdits={canSaveWithoutEdits}
            />
        </Modal>
    );
};
