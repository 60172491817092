import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { camelCase, isEqual } from 'lodash';

import { OrangeHeadline } from '../../components/orange-headline/orange-headline';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { SecondaryButton } from '../../components/secondary-button/secondary-button';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus-icon.svg';
import { ReactComponent as StripeIcon } from '../../assets/icons/stripe-icon.svg';
import { ReactComponent as DinteroIcon } from '../../assets/icons/dintero-icon.svg';
import { ReactComponent as VippsIcon } from '../../assets/icons/vipps-icon.svg';
// import { ReactComponent as BlikIcon } from '../../assets/icons/blik-icon.svg';
import { ReactComponent as MastercardIcon } from '../../assets/icons/mastercard-icon.svg';
import { ReactComponent as VisaIcon } from '../../assets/icons/visa-icon.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet-icon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import { RoutePaths } from '../../routing/route-paths';
import { FlowersSelectors } from '../../model/flowers/flowers.selector';
import { FlowersFlowActions } from '../../model/flowers/flowers.actions';
import { IFlowerSummaryEntry } from '../../interfaces/flower-summary-entry';
import { Spinner } from '../../components/spinner/spinner';
import { FlowersDelivery } from '../delivery/flowers-delivery';
import { useFlowerDeliveryState } from '../delivery/use-flower-delivery-state';
import { getFlowersOrderStatusMessage } from '../../model/flowers/selectors/get-flowers-order-status-message.selector';
import { ErrorMessage } from '../../components/error-message/error-message';
import { roundPriceToString } from '../../utils/set-price';
import { ICreatedCondolence } from '../../interfaces/condolence';
import { Checkbox } from '../../components/checkbox/checkbox';
import { useBooleanState } from '../../../shared/hooks/useBooleanState';
import { AddCondolenceModal } from '../../donation/details/form/condolence/add-condolence-modal';
import { ICondolenceFormValues } from '../../condolences/condolence-form-values';
import { Radio } from '../../components/radio/radio';
import { PaymentMethod } from '../../api/payment/payment.types';
import { getDinteroPaymentEnabledSelector } from '../../model/configuration/selectors/get-dintero-payment-enabled.selector';

import { FlowersSummaryListingMobile } from './flowers-summary-listing-mobile/flowers-summary-listing-mobile';
import { FlowersSummaryListing } from './flowers-summary-listing/flowers-summary-listing';
import styles from './flowers-summary.module.scss';

/**
 * TODO: Dont render mobile list if desktop and the opposite.
 * TODO: Some loader when prices are updating
 */
export const FlowersSummary = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const orderSummaryData = useSelector(FlowersSelectors.getCartSummary);
    const items = useSelector(FlowersSelectors.getProductsInSummary);
    const contactDataInStore = useSelector(FlowersSelectors.getContactData);
    const flowersOrderLoading = useSelector(
        FlowersSelectors.getFlowersOrderLoading,
    );
    // const paymentMethod = useSelector(FlowersSelectors.getPaymentMethod);
    const { deliveryInStore } = useFlowerDeliveryState();

    const flowersOrderStatus = useSelector(getFlowersOrderStatusMessage);

    const dinteroPaymentEnabled = useSelector(getDinteroPaymentEnabledSelector);
    const dinteroPaymentMethods = [
        {
            method: PaymentMethod.VIPPS,
            icons: [DinteroIcon, MastercardIcon, VisaIcon],
        }
    ];

    const addCondolenceCheckbox = useBooleanState(true);
    const addCondolenceModal = useBooleanState(false);

    const itemsRef = useRef<IFlowerSummaryEntry[] | null>(null);

    const [isFlowersDeliveryFormValid, setFlowersDeliveryFormValid] = useState<
        boolean
    >(false);

    const orderButtonDisabled =
        flowersOrderLoading ||
        !deliveryInStore ||
        !contactDataInStore ||
        !isFlowersDeliveryFormValid;

    const condolenceText = items.reduce(
        (text: string, item: IFlowerSummaryEntry) =>
            `${[text, item.cardText, item.leftBandText, item.rightBandText]
                .filter(Boolean)
                .join(', ')}`,
        '',
    );

    const initialCondolenceValues: Partial<ICreatedCondolence> = {
        content: condolenceText,
        displayName: contactDataInStore?.name,
    };

    useEffect(() => {
        if (!isEqual(items, itemsRef.current)) {
            dispatch(FlowersFlowActions.cartCalculationRequested());
        }
        itemsRef.current = items;
    }, [dispatch, items]);

    const getPriceWithCurrency = (price: number) =>
        `${orderSummaryData!.currency} ${roundPriceToString(price)}`;

    const submitButtonIcon = useMemo(() => {
        if (flowersOrderLoading) {
            return Spinner;
        }

        if (addCondolenceCheckbox.state && !addCondolenceModal.state) {
            return ArrowIcon;
        }

        return WalletIcon;
    }, [
        flowersOrderLoading,
        addCondolenceCheckbox.state,
        addCondolenceModal.state,
    ]);

    const handleSubmitOrderWithCondolence = (
        condolence: ICondolenceFormValues,
    ) => {
        if(dinteroPaymentEnabled){
            dispatch(FlowersFlowActions.setPaymentMethod(dinteroPaymentMethods[0].method));
        }
        dispatch(FlowersFlowActions.orderSubmitted(condolence));
    };

    const handleSubmitButtonClick = () => {
        if (addCondolenceCheckbox.state) {
            addCondolenceModal.handleSetAsTrue();
        } else {
            if(dinteroPaymentEnabled){
                dispatch(FlowersFlowActions.setPaymentMethod(dinteroPaymentMethods[0].method));
            }
            dispatch(FlowersFlowActions.orderSubmitted());
        }
    };

    const handleSelectPaymentMethod = (
        selectedPaymentMethod: PaymentMethod,
    ) => () => {
        dispatch(FlowersFlowActions.setPaymentMethod(selectedPaymentMethod));
    };

    // const paymentMethods = Object.values(PaymentMethod).map((method) => ({
    //     method,
    //     icons: [DinteroIcon, MastercardIcon, VisaIcon],
    // }));



    const getPaymentMethodIcons = (method: PaymentMethod) => {
        switch (method) {
            case PaymentMethod.CREDIT_CARD:
                return [<StripeIcon />, <MastercardIcon />, <VisaIcon />];
            // case PaymentMethod.BLIK:
            //     return [<StripeIcon />, <BlikIcon />];
            case PaymentMethod.VIPPS:
                return [<DinteroIcon />, <VippsIcon />, <MastercardIcon />, <VisaIcon />];
            default:
                return [<StripeIcon />];
        }
    };

    return (
        <div>
            <OrangeHeadline marginBottom>
                {t('flowers.summary.headline')}
            </OrangeHeadline>
            <p className={styles.FlowersSummaryDescription}>
                {t('flowers.summary.description')}
            </p>
            <FlowersSummaryListing items={items} />
            <FlowersSummaryListingMobile items={items} />
            <div className={styles.AddFlowersContainer}>
                <SecondaryButton
                    icon={PlusIcon}
                    onClick={() => history.push(RoutePaths.FLOWERS_CATEGORY)}
                >
                    {t('flowers.summary.addFlowersButton')}
                </SecondaryButton>
            </div>
            {orderSummaryData && (
                <div className={styles.TotalContainer}>
                    <div className={styles.TotalRow}>
                        <span className={styles.TotalLabel}>
                            {t('flowers.summary.orderSumLabel')}
                        </span>
                        <span className={styles.TotalLabel}>
                            {getPriceWithCurrency(
                                orderSummaryData.itemsPriceGross,
                            )}
                        </span>
                    </div>
                    <div className={styles.TotalRow}>
                        <span className={styles.TotalLabel}>
                            {t('flowers.summary.deliveryLabel')}
                        </span>

                        {orderSummaryData.deliveryPriceGross > 0 ? (
                            <span className={styles.TotalLabel}>
                                {getPriceWithCurrency(
                                    orderSummaryData.deliveryPriceGross,
                                )}
                            </span>
                        ) : (
                            <span className={styles.TotalLabel}>
                                {t('flowers.summary.deliveryIncluded')}
                            </span>
                        )}
                    </div>
                    <div className={styles.TotalSeparator} />
                    <div className={styles.TotalRow}>
                        <span
                            className={cx(
                                styles.TotalLabel,
                                styles.TotalLabelBold,
                            )}
                        >
                            {t('flowers.summary.totalLabel')}
                        </span>
                        <span
                            data-testid="flowers-summary-total-price"
                            className={cx(
                                styles.TotalLabel,
                                styles.TotalLabelBold,
                            )}
                        >
                            {getPriceWithCurrency(
                                orderSummaryData.totalOrderPriceGross,
                            )}
                        </span>
                    </div>
                </div>
            )}
            {dinteroPaymentEnabled && (
                <>
                    <OrangeHeadline
                        className={styles.PaymentMethodHeadline}
                        marginBottom
                    >
                        {t('flowers.summary.paymentMethodHeadlineSingle')}
                    </OrangeHeadline>
                    <p className={styles.PaymentMethodDescription}>
                        {t('flowers.summary.creditCardSectionDescription')}
                    </p>
                    <div className={styles.PaymentMethodSelectorContainer}>
                        {dinteroPaymentMethods.map(({ method, icons }) => (
                            <Radio
                                key={method}
                                checked={true}
                                label={
                                    <div
                                        className={
                                            styles.PaymentMethodSelectorItem
                                        }
                                    >
                                        <span>
                                            {t(
                                                `payment.method.${camelCase(
                                                    method,
                                                )}`,
                                            )}
                                        </span>
                                        {icons && (
                                            <div
                                                className={
                                                    styles.PaymentMethodSelectorIcons
                                                }
                                            >
                                                {getPaymentMethodIcons(method)}
                                            </div>
                                        )}
                                    </div>
                                }
                                onChange={handleSelectPaymentMethod(method)}
                            />
                        ))}
                    </div>
                </>
            )}
            {!dinteroPaymentEnabled && (
                <>
                    <OrangeHeadline
                        className={styles.CreditCardHeadline}
                        marginBottom
                    >
                        {t('flowers.summary.paymentMethodHeadline')}
                    </OrangeHeadline>
                    <div className={styles.CreditCardContainer}>
                        <div>
                            <span className={styles.CreditCardTitle}>
                                {t('flowers.summary.creditCardSectionTitle')}
                            </span>
                            <p className={styles.CreditCardDescription}>
                                {t(
                                    'flowers.summary.creditCardSectionDescription',
                                )}
                            </p>
                        </div>
                        <div className={styles.CreditCardIcons}>
                            <StripeIcon className={styles.CreditCardIcon} />
                            <MastercardIcon className={styles.CreditCardIcon} />
                            <VisaIcon className={styles.CreditCardIcon} />
                        </div>
                    </div>
                </>
            )}
            <div className={styles.Delivery}>
                <FlowersDelivery onChange={setFlowersDeliveryFormValid} />
            </div>
            <OrangeHeadline className={styles.CondolenceHeadline} marginBottom>
                {t('flowers.summary.condolenceHeadline')}
            </OrangeHeadline>
            <Checkbox
                checked={addCondolenceCheckbox.state}
                className={styles.Checkbox}
                label={t('flowers.summary.condolenceCheckbox')}
                name="addCondolenceCheckbox"
                onChange={addCondolenceCheckbox.handleToggle}
            />
            <AddCondolenceModal
                canSaveWithoutEdits
                isOpened={addCondolenceModal.state}
                handleClose={addCondolenceModal.handleSetAsFalse}
                handleAdd={handleSubmitOrderWithCondolence}
                initialCondolenceValues={initialCondolenceValues}
                customButtonText={
                    orderSummaryData &&
                    `${t('flowers.summary.payButton')} ${getPriceWithCurrency(
                        orderSummaryData?.totalOrderPriceGross,
                    )}`
                }
                customButtonIcon={WalletIcon}
                donationLoading={flowersOrderLoading}
                donationError={flowersOrderStatus}
            />
            {orderSummaryData && (
                <div className={styles.PayButtonContainer}>
                    <PrimaryButton
                        className={styles.PayButton}
                        icon={submitButtonIcon}
                        disabled={orderButtonDisabled}
                        onClick={handleSubmitButtonClick}
                    >
                        {addCondolenceCheckbox.state
                            ? t('flowers.summary.continue')
                            : `${t(
                                  'flowers.summary.payButton',
                              )} ${getPriceWithCurrency(
                                  orderSummaryData.totalOrderPriceGross,
                              )}`}
                    </PrimaryButton>
                </div>
            )}
            <div
                className={cx(styles.ErrorMessage, {
                    [styles.Error]: flowersOrderStatus,
                })}
            >
                {flowersOrderStatus && (
                    <ErrorMessage>{flowersOrderStatus}</ErrorMessage>
                )}
            </div>
        </div>
    );
};
