import React from 'react';
import cx from 'classnames';
import { useInView } from 'react-intersection-observer';

import { IImageContainerProps } from './image-container.types';
import { ImageContainerMask } from './components/image-container-mask/image-container-mask';
import { useImageContainer } from './useImageContainer';
import styles from './image-container.module.scss';

export const ImageContainer = (props: IImageContainerProps) => {
    const {
        imageContainerState,
        imageContainerHandlers,
        ...otherProps
    } = useImageContainer(props);

    const [ref, inView] = useInView({
        triggerOnce: true,
        fallbackInView: true,
    });

    const commonImageClassnames = [
        styles.Image,
        props.imageClassName,
        imageContainerState.isHidden && styles.ImageHidden,
    ];

    const withBlurredBackground =
        otherProps.withBlurredBackground && !imageContainerState.isHidden;

    const containerDivProps = imageContainerState.isHidden
        ? {}
        : otherProps.containerDivProps;
    return (
        <div
            ref={ref}
            className={cx(
                props.containerClassName,
                styles.ImageContainer,
                withBlurredBackground &&
                    styles.ImageContainerWithBlurredBackground,
            )}
            {...containerDivProps}
        >
            <ImageContainerMask
                imageContainerState={imageContainerState}
                useLightMode={otherProps.useLightMode}
            />
            {inView && withBlurredBackground && (
                <img
                    alt=""
                    src={imageContainerState.src}
                    className={cx(
                        ...commonImageClassnames,
                        styles.ImageWithBlurredBackgroundBlurred,
                    )}
                />
            )}
            {inView && (
                <img
                    alt=""
                    src={imageContainerState.src}
                    className={cx(
                        ...commonImageClassnames,
                        withBlurredBackground &&
                            styles.ImageWithBlurredBackgroundFocused,
                    )}
                    {...imageContainerHandlers}
                />
            )}
        </div>
    );
};
