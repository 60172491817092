import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, RootStore, store } from './store';
import { RootActions } from './actions';

export const withStoreProvider = (
    appStore: Store<RootStore, RootActions> = store,
) => <Props extends {}>(Component: React.ComponentType<Props>) => (
    props: Props,
) => (
    <Provider store={appStore}>
        <PersistGate loading={null} persistor={persistor}>
            <Component {...props} />
        </PersistGate>
    </Provider>
);
