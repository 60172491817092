import React from 'react';
import cx from 'classnames';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import styles from '../video-player-mask.module.scss';

export const VideoPlayerPlayMask = () => {
    return (
        <div className={cx(styles.MaskContainer, styles.PausedMaskContainer)}>
            <div className={styles.MaskInnerContainer}>
                <div className={styles.MaskIcon}>
                    <PlayCircleOutlineIcon />
                </div>
            </div>
        </div>
    );
};
