import { createReducer } from 'typesafe-actions';

import { ICreatedCondolence } from '../../interfaces/condolence';
import { RootActions } from '../../store/actions';
import {
    addCondolenceCommentApiActions,
    createCondolenceApiActions,
    updateCondolenceApiActions,
} from '../../api/condolences/condolences-api.actions';

import {
    condolenceCreated,
    condolencesDataReceived,
    condolenceDeletionSucceeded,
    condolenceEditRequested,
    setCondolenceStatus,
    setCondolenceError,
    likeCondolenceSucceeded,
    unlikeCondolenceSucceeded,
    likeCondolenceCommentSucceeded,
    unlikeCondolenceCommentSucceeded,
    condolenceEditClear,
} from './condolences.actions';

export enum CondolenceStatus {
    INAPPROPRIATE_WORDS = 'INAPPROPRIATE_WORDS',
    MEDIA_FILE_UPLOAD_ERROR = 'MEDIA_FILE_UPLOAD_ERROR',
}

export interface ICondolencesStore {
    condolences: ICreatedCondolence[];
    editedCondolence: ICreatedCondolence | null;
    condolenceStatus: CondolenceStatus | null;
    error: boolean;
    loading: boolean;
}

const initialState: ICondolencesStore = {
    condolences: [],
    editedCondolence: null,
    condolenceStatus: null,
    error: false,
    loading: false,
};

export const condolencesReducer = createReducer<ICondolencesStore, RootActions>(
    initialState,
)
    .handleAction(createCondolenceApiActions.request, (state) => ({
        ...state,
        loading: true,
    }))
    .handleAction(addCondolenceCommentApiActions.request, (state) => ({
        ...state,
        loading: true,
    }))
    .handleAction(updateCondolenceApiActions.request, (state) => ({
        ...state,
        loading: true,
    }))
    .handleAction(setCondolenceError, (state, action) => ({
        ...state,
        error: action.payload,
        loading: false,
    }))
    .handleAction(condolenceCreated, (state, action) => ({
        ...state,
        condolences: [action.payload, ...state.condolences],
    }))
    .handleAction(condolencesDataReceived, (state, action) => ({
        ...state,
        condolences: action.payload.condolences,
    }))
    .handleAction(condolenceDeletionSucceeded, (state, action) => ({
        ...state,
        condolences: state.condolences.filter(
            (condolence) => condolence.id !== action.payload.id,
        ),
    }))
    .handleAction(condolenceEditRequested, (state, action) => ({
        ...state,
        editedCondolence: action.payload,
    }))
    .handleAction(condolenceEditClear, (state) => ({
        ...state,
        editedCondolence: null,
    }))
    .handleAction(setCondolenceStatus, (state, action) => ({
        ...state,
        condolenceStatus: action.payload,
    }))
    .handleAction(likeCondolenceSucceeded, (state, action) => ({
        ...state,
        condolences: state.condolences.map((condolence: ICreatedCondolence) => {
            if (condolence.id === action.payload.id) {
                return {
                    ...condolence,
                    currentUserHasLiked: true,
                    likesCount: condolence.likesCount
                        ? condolence.likesCount + 1
                        : 1,
                };
            }

            return condolence;
        }),
    }))
    .handleAction(unlikeCondolenceSucceeded, (state, action) => ({
        ...state,
        condolences: state.condolences.map((condolence: ICreatedCondolence) => {
            if (condolence.id === action.payload.id) {
                return {
                    ...condolence,
                    currentUserHasLiked: false,
                    likesCount: condolence.likesCount
                        ? condolence.likesCount - 1
                        : condolence.likesCount,
                };
            }

            return condolence;
        }),
    }))
    .handleAction(likeCondolenceCommentSucceeded, (state, action) => ({
        ...state,
        condolences: state.condolences.map((condolence: ICreatedCondolence) => {
            return {
                ...condolence,
                comments: condolence.comments.map((comment) => {
                    if (comment.id === action.payload.id) {
                        return {
                            ...comment,
                            currentUserHasLiked: true,
                            likesCount: comment.likesCount
                                ? comment.likesCount + 1
                                : 1,
                        };
                    }
                    return comment;
                }),
            };
        }),
    }))
    .handleAction(unlikeCondolenceCommentSucceeded, (state, action) => ({
        ...state,
        condolences: state.condolences.map((condolence: ICreatedCondolence) => {
            return {
                ...condolence,
                comments: condolence.comments.map((comment) => {
                    if (comment.id === action.payload.id) {
                        return {
                            ...comment,
                            currentUserHasLiked: false,
                            likesCount: comment.likesCount
                                ? comment.likesCount - 1
                                : comment.likesCount,
                        };
                    }
                    return comment;
                }),
            };
        }),
    }));
