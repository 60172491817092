import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { sendAccessRequestApiActions } from '../request-access-api.actions';

import { submitAccessRequestApiSaga } from './submit-access-request-api.saga';

export function* accessRequestApiSaga() {
    yield takeEvery(
        getType(sendAccessRequestApiActions.request),
        submitAccessRequestApiSaga,
    );
}
