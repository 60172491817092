export enum RoutePaths {
    HOME = '/',
    GALLERY = '/gallery',
    VIDEO_STREAM = '/video-stream/:eventId',
    SHARE = '/share',
    LOGIN = '/auth',
    FORGOT_PASSWORD = '/auth/forgot-password',
    SET_NEW_PASSWORD = '/auth/set-password',
    REQUEST_ACCESS = '/request-access',
    INVITATION = '/invite',
    FLOWERS_ROOT = '/flowers',
    FLOWERS_DELIVERY = '/flowers/delivery',
    FLOWERS_CATEGORY = '/flowers/category',
    FLOWERS_TYPE = '/flowers/type',
    FLOWERS_DETAILS = '/flowers/details',
    FLOWERS_SUMMARY = '/flowers/summary',
    FLOWERS_CONFIRMATION = '/flowers/confirmation',
    FLOWERS_PAYMENT_FAILED = '/flowers/payment-failed',
    DONATION_ROOT = '/donation',
    DONATION_DETAILS = '/donation/details',
    DONATION_CONFIRMATION = '/donation/confirmation',
    DONATION_PAYMENT_FAILED = '/donation/payment-failed',
    EVENT_ATTENDANCE = '/event-attendance',
}
