import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { loginApiActions } from '../login-api.actions';

import { loginUserToApiSaga } from './login-user-to-api.saga';

export function* loginApiSaga() {
    yield takeEvery(getType(loginApiActions.request), loginUserToApiSaga);
}
