import { put } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { mapMemorialPageToMilestones } from '../mappers/map-memorial-page-to-milestones';
import { milestonesDataReceived } from '../milestones.actions';

export function* getMilestonesDataFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const memorialPageData = action.payload;

    try {
        const milestones = mapMemorialPageToMilestones(memorialPageData);

        yield put(
            milestonesDataReceived({
                milestones,
            }),
        );
    } catch (e) {
        /**
         * TODO: Handle missing data and assume privacy mode is set
         */
        console.log('Error getting milestones from memorial page');
        console.error(e);
    }
}
