import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { GApageView } from '../../setup/ga-init';
import { getTheme } from '../model/configuration/selectors/get-theme.selector';
import { getThemeReceived } from '../model/configuration/selectors/get-theme-received.selector';
import { getHasUserAccess } from '../model/user/selectors/get-has-user-access.selector';
import { getPagePrivacyMode } from '../model/configuration/selectors/get-page-privacy-mode.selector';
import { PrivacyMode } from '../interfaces/privacy-mode';

import { RoutePaths } from './route-paths';
import { useRoutesConfigs } from './use-routes-configs';
import { APPLICATION_ROUTES } from './routes';

const checkIfPathIsPublic = (pathname: string) => {
    return APPLICATION_ROUTES.OPEN.some((route) => {
        if (Array.isArray(route.path)) {
            return route.path.some((path) => pathname.includes(path));
        }
        return pathname.includes(route.path as string);
    });
};

const checkIfUserHasAccessToThePage = (
    hasAccessToThisMemorial: boolean = true,
    pathname: string,
) => {
    if (hasAccessToThisMemorial) {
        return true;
    }

    return checkIfPathIsPublic(pathname);
};
export const useRouting = () => {
    const history = useHistory();
    const location = useLocation();

    const theme = useSelector(getTheme);
    const themeReceived = useSelector(getThemeReceived);
    const hasAccessToThisMemorial = useSelector(getHasUserAccess);
    const privacyMode = useSelector(getPagePrivacyMode);

    const routesConfig = useRoutesConfigs();

    useEffect(() => {
        if (themeReceived) {
            GApageView(window.location.href, theme);
        }
    }, [location.pathname, themeReceived, theme]);

    // react router v4 workaround
    useEffect(() => {
        if (!location.pathname.startsWith(RoutePaths.FLOWERS_ROOT)) {
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);

    const isWaitingForTheme =
        !themeReceived && privacyMode !== PrivacyMode.PRIVATE;

    const hasAccessToThePage = useMemo(() => {
        return checkIfUserHasAccessToThePage(
            hasAccessToThisMemorial,
            location.pathname,
        );
    }, [hasAccessToThisMemorial, location.pathname]);

    return {
        history,
        location,
        isWaitingForTheme,
        hasAccessToThePage,
        routesConfig,
    };
};
