export enum TaskVolunteerStatusCode {
    SUCCESS = 'success',
    EMAIL_ALREADY_USED = 'email_already_used',
    ERROR = 'error',
}

export interface ITaskVolunteerStatus {
    taskId: string;
    status: TaskVolunteerStatusCode;
}
