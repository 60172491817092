import { Deserializer } from 'jsonapi-serializer';
import { call } from '@redux-saga/core/effects';

const deserializer = new Deserializer({
    keyForAttribute: 'camelCase',
});

export function* deserializeApiResponseSaga(response) {
    if (response) {
        return yield call(deserializer.deserialize, response);
    }

    return null;
}
