export interface IIconProps {
    type: IIconType;
}

export enum IIconType {
    Like = 'like',
    Share = 'share',
    Comment = 'comment',
    Trash = 'trash',
    Movie = 'movie',
}
