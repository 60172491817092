import { apiService } from '../../api.service';
import {
    ICondolenceComment,
    ICreatedCondolenceComment,
} from '../../../interfaces/condolence';

export const addCondolenceComment = async ({
    slug,
    condolenceId,
    comment,
}: {
    slug: string;
    condolenceId: string;
    comment: ICondolenceComment;
}): Promise<ICreatedCondolenceComment> => {
    return apiService
        .post(
            `/public/memorial_pages/${slug}/comments/condolence/${condolenceId}`,
            {
                json: {
                    ...comment,
                },
                throwHttpErrors: false,
            },
        )
        .then((resp) => resp.json());
};
