import * as yup from 'yup';

const MIN_PHONE_LENGTH = 4;

export const accessRequestFormValidation = () =>
    yup.object().shape({
        email: yup.string().email(),
        phone: yup.string().transform((value: string) => {
            /**
             * Trim "+" or "+1" or "+48" etc
             */
            if (value.indexOf('+') === 0 && value.length <= MIN_PHONE_LENGTH) {
                return '';
            }

            return value;
        }),
        name: yup.string().required(),
        /**
         * @see https://github.com/jquense/yup/issues/176
         */
        phoneOrEmail: yup.bool().when(['email', 'phone'], {
            is: (email: boolean, phone: boolean) => !email && !phone,
            then: yup.bool().required('error.emailOrPhone'),
            otherwise: yup.bool(),
        }),
    });
