import { createAction } from 'typesafe-actions';

import { IEventAttendanceRequestDto } from '../../api/DTOs/event-attendance-request.dto';

import { EventAttendanceStatus } from './event-attendance-page.store';

export const eventAttendanceFormSubmitted = createAction(
    'model/event_attendance/EVENT_ATTENDANCE_FORM_SUBMITTED',
    (payload: IEventAttendanceRequestDto) => payload,
)();

export const setEventAttendanceError = createAction(
    'model/event_attendance/SET_EVENT_ATTENDANCE_ERROR',
    (payload: string | null) => payload,
)();

export const setEventAttendanceStatus = createAction(
    'model/event_attendance/SET_EVENT_ATTENDANCE_STATUS',
    (payload: EventAttendanceStatus | null) => payload,
)();

export const resetEventAttendanceState = createAction(
    'model/event_attendance/RESET_EVENT_ATTENDANCE_STATE',
)();
