import { put, take, delay } from '@redux-saga/core/effects';
import { isActionOf } from 'typesafe-actions';
import { all, call } from 'redux-saga/effects';

import { updateCondolenceApiActions } from '../../../api/condolences/condolences-api.actions';
import {
    condolenceUpdateRequested,
    setCondolenceStatus,
    setCondolenceError,
} from '../condolences.actions';
import { imageUploadToS3Saga } from '../../../api/image-upload/saga/image-upload-to-s3.saga';
import { mapCondolenceToUpdateCondolenceDto } from '../../../api/condolences/mappers/map-condolence-to-update-condolence-dto';

export function* handleCondolenceUpdateSaga(
    action: ReturnType<typeof condolenceUpdateRequested>,
) {
    const imagesToUpload =
        action.payload.images?.filter((image) => {
            return image.isNew;
        }) ?? [];

    const uploadedImages = yield all(
        imagesToUpload.map((image) => {
            return call(imageUploadToS3Saga, image.file as File);
        }),
    );

    yield put(
        updateCondolenceApiActions.request(
            mapCondolenceToUpdateCondolenceDto(action.payload, uploadedImages),
        ),
    );

    const response = yield take([
        updateCondolenceApiActions.success,
        updateCondolenceApiActions.failure,
    ]);

    if (isActionOf(updateCondolenceApiActions.success, response)) {
        yield put(setCondolenceError(false));
    } else {
        yield put(setCondolenceError(true));
        yield put(setCondolenceStatus(response.payload.status));
        yield delay(3000);
        yield put(setCondolenceStatus(null));
    }
}
