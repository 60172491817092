import React from 'react';
import cx from 'classnames';

import { ReactComponent as IncrementIcon } from '../../../assets/icons/plus-icon.svg';
import { ReactComponent as DecrementIcon } from '../../../assets/icons/minus-icon.svg';

import styles from './counter.module.scss';

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
    onChange(count: number): void;
    count?: number;
    minCount?: number;
    maxCount?: number;
};

export const Counter = ({
    className,
    count = 0,
    onChange,
    minCount = 1,
    maxCount,
    ...props
}: Props) => {
    const increment = () => {
        onChange(count + 1);
    };

    const decrement = () => {
        if (count > 0) {
            onChange(count - 1);
        }
    };

    return (
        <div className={cx(styles.Counter, className)} {...props}>
            <button
                className={cx(styles.IconWrapper, {
                    [styles.Disabled]: count === minCount,
                })}
                disabled={count === minCount}
                onClick={decrement}
                data-testid="counter-decrement"
            >
                <DecrementIcon className={styles.Icon} />
            </button>
            <span className={styles.Label} data-testid="counter-count">
                {count}
            </span>
            <button
                className={cx(styles.IconWrapper, {
                    [styles.Disabled]: count === maxCount,
                })}
                onClick={increment}
                disabled={count === maxCount}
                data-testid="counter-increment"
            >
                <IncrementIcon className={styles.Icon} />
            </button>
        </div>
    );
};
