import { put, all, call, take, delay } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';

import { imageUploadToS3Saga } from '../../../api/image-upload/saga/image-upload-to-s3.saga';
import { sendDonationActions } from '../donation.actions';
import { sendDonationApiActions } from '../../../api/donation/donation-api.actions';
import { mapDonationDetailsFormValuesToDto } from '../mappers/map-donation-details-form-values-to-dto';

export function* handleSendDonationRequestSaga(
    action: ReturnType<typeof sendDonationActions.request>,
) {
    if (!action.payload.formValues && !action.payload.dto) {
        return;
    }

    let donationRequestDto = action.payload.dto;

    if (action.payload.formValues) {
        const imagesToUpload =
            action.payload.formValues.condolence?.images?.filter((image) => {
                return image.isNew;
            }) ?? [];

        const uploadedImages = yield all(
            imagesToUpload.map((image) => {
                return call(imageUploadToS3Saga, image.file as File);
            }),
        );

        donationRequestDto = mapDonationDetailsFormValuesToDto(
            action.payload.formValues,
            uploadedImages,
        );
    }

    yield put(sendDonationApiActions.request(donationRequestDto!));

    const response = yield take([
        sendDonationApiActions.success,
        sendDonationApiActions.failure,
    ]);

    if (isActionOf(sendDonationApiActions.success, response)) {
        yield put(sendDonationActions.success(response.payload.dto));
        window.location.replace(response.payload.paymentUrl);
    } else {
        yield put(sendDonationActions.failure(true));
        yield delay(3000);
        yield put(sendDonationActions.failure(false));
    }
}
