import { delay, put, take } from '@redux-saga/core/effects';
import { isActionOf } from 'typesafe-actions';

import { uploadGalleryPhotoApiActions } from '../../../api/gallery/gallery-api.actions';
import {
    galleryPhotoUploadFormSubmitted,
    setUploadPhotoStatus,
    setUploadPhotoInProgress,
} from '../gallery.actions';
import { UploadGalleryPhotoStatus } from '../gallery.store';

export function* handlePhotoUploadFormSubmittedSaga(
    action: ReturnType<typeof galleryPhotoUploadFormSubmitted>,
) {
    yield put(setUploadPhotoInProgress(true));
    yield put(uploadGalleryPhotoApiActions.request(action.payload));

    const result = yield take([
        uploadGalleryPhotoApiActions.success,
        uploadGalleryPhotoApiActions.failure,
    ]);

    yield put(setUploadPhotoInProgress(false));

    if (isActionOf(uploadGalleryPhotoApiActions.success, result)) {
        yield put(setUploadPhotoStatus(UploadGalleryPhotoStatus.SUCCESS));
        yield delay(5000);
        yield put(setUploadPhotoStatus(null));
    } else if (isActionOf(uploadGalleryPhotoApiActions.failure, result)) {
        yield put(setUploadPhotoStatus(UploadGalleryPhotoStatus.ERROR));
        yield delay(5000);
        yield put(setUploadPhotoStatus(null));
    }
}
