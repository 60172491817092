import { put } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { mapMemorialPageToGalleryPhotos } from '../mappers/map-memorial-page-to-gallery-photos';
import { galleryDataReceived } from '../gallery.actions';

export function* getGalleryPhotosDataFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const memorialPageData = action.payload;

    try {
        const photos = mapMemorialPageToGalleryPhotos(memorialPageData);

        yield put(
            galleryDataReceived({
                photos,
            }),
        );
    } catch (e) {
        /**
         * TODO: Handle missing data and assume privacy mode is set
         */
        console.log('Error getting gallery photos from memorial page');
        console.error(e);
    }
}
