import { createReducer } from 'typesafe-actions';

import { IFlowerDeliveryType } from '../../interfaces/flower-delivery-type';
import { RootActions } from '../../store/actions';
import { getFlowerDeliveryTypesApiActions } from '../../api/flowers/delivery/flowers-delivery-api.actions';

interface IFlowersDeliveryStore {
    deliveryTypes: IFlowerDeliveryType[] | null;
}

const initialState: IFlowersDeliveryStore = {
    deliveryTypes: null,
};

export const createFlowersDeliveryReducer = (
    injectedState: IFlowersDeliveryStore = initialState,
) =>
    createReducer<IFlowersDeliveryStore, RootActions>(
        injectedState,
    ).handleAction(
        getFlowerDeliveryTypesApiActions.success,
        (state, action) => ({
            ...state,
            deliveryTypes: action.payload,
        }),
    );
