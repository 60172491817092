import { createAsyncAction } from 'typesafe-actions';

import { ISendDonationRequestDto } from '../DTOs/send-donation-request.dto';

export const sendDonationApiActions = createAsyncAction(
    'api/donation/SEND_DONATION_REQUESTED',
    'api/donation/SEND_DONATION_SUCCEEDED',
    'api/donation/SEND_DONATION_FAILED',
)<
    ISendDonationRequestDto,
    { dto: ISendDonationRequestDto; paymentUrl: string },
    undefined
>();
