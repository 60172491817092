import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencil-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash-icon.svg';
import { getIsUserAdmin } from '../../../../model/user/selectors/get-is-user-admin';
import { getUserLoggedStatus } from '../../../../model/user/selectors/get-user-logged-status.selector';
import { getCondolenceLikeTokenByCondolenceId } from '../../../../model/condolences/selectors/get-condolence-like-token-by-condolence-id.selector';

import { ICondolencesListItemProps } from './condolences-list-types';

export const useCondolencesListItem = ({
    condolence,
    onOpenViolationModal,
    onOpenDeleteCondolenceModal,
    onOpenCondolenceModal,
    onEdit,
    onLikeCondolence,
    onUnlikeCondolence,
    onLikeCondolenceComment,
    onUnlikeCondolenceComment,
}: ICondolencesListItemProps) => {
    const { t } = useTranslation();
    const isAdmin = useSelector(getIsUserAdmin);
    const logged = useSelector(getUserLoggedStatus);
    const condolenceLikeToken = useSelector(
        getCondolenceLikeTokenByCondolenceId(condolence.id),
    );

    const [likeRequestLoading, setLikeRequestLoading] = useState(false);

    useEffect(() => {
        setLikeRequestLoading(false);
    }, [condolence.likesCount]);

    const isLikedByCurrentUser = logged
        ? condolence.currentUserHasLiked
        : !!condolenceLikeToken;

    const handleLikeOrUnlikeCondolence = () => {
        if (!likeRequestLoading) {
            if (isLikedByCurrentUser) {
                onUnlikeCondolence(condolence);
            } else {
                onLikeCondolence(condolence);
            }
        }
        setLikeRequestLoading(true);
    };

    const handleOpenCondolenceModal = () => {
        onOpenCondolenceModal(condolence.id);
    };

    const condolenceMenuItems = [
        {
            label: t('condolences.violationReport'),
            icon: PencilIcon,
            action() {
                onOpenViolationModal(condolence.id);
            },
        },
    ];

    if (condolence.isOwner || isAdmin) {
        condolenceMenuItems.unshift({
            label: t('condolences.delete'),
            icon: DeleteIcon,
            action() {
                onOpenDeleteCondolenceModal(condolence.id);
            },
        });
    }

    if (condolence.isOwner) {
        condolenceMenuItems.unshift({
            label: t('condolences.edit'),
            icon: EditIcon,
            action() {
                onEdit(condolence);
            },
        });
    }

    return {
        condolence,
        condolenceMenuItems,
        isLikedByCurrentUser,
        handleLikeOrUnlikeCondolence,
        handleOpenCondolenceModal,
        onLikeCondolenceComment,
        onUnlikeCondolenceComment,
    };
};
