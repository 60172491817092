import { call, put } from '@redux-saga/core/effects';
import { get } from 'lodash';

import { refreshSessionActions } from '../refresh-session.actions';
import { getTokens, saveTokens } from '../../auth-token-storage';
import { ITokensResponseDto } from '../../DTOs/tokens-response.dto';
import { refreshTokens } from '../http/refresh-tokens';
import {
    userEmailReceived,
    userLoggedStatusReceived,
} from '../../../model/user/user.actions';
import { getEmailFromStorage } from '../../../model/user/email-storage';

/**
 * TODO: It both checks session and handle routing
 *   Probably there should be another saga to get failures and handle them.
 *   However currently only failure result is login, so lets keep it simple.
 *
 * TODO: Maybe handle redirecting from login page if this succeeds, so user dont
 *   have to login accidentaly again
 */
export function* getNewTokensSaga() {
    const tokens = yield getTokens();

    const refreshToken = get(tokens, 'refreshToken');

    if (!refreshToken) {
        yield put(refreshSessionActions.failure('NO_TOKENS_SAVED'));

        return;
    }

    try {
        const newTokens: ITokensResponseDto = yield call(
            refreshTokens,
            tokens.refreshToken,
        );

        yield saveTokens(newTokens.accessToken, newTokens.refreshToken);
        yield put(refreshSessionActions.success(newTokens));
        yield put(userLoggedStatusReceived({ logged: true }));

        const userEmail = getEmailFromStorage();

        if (userEmail) {
            yield put(
                userEmailReceived({
                    email: userEmail,
                }),
            );
        }
    } catch (e) {
        console.log(e);
        yield put(refreshSessionActions.failure('Tokens fetch failed'));
    }
}
