import { call, put, take, select } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';

import { fetchFlowerShopApiActions } from '../flower-shop-api.actions';
import { IFlowerShopResponseDto } from '../../../DTOs/fetch-flower-shop-response.dto';
import { fetchFlowerShop } from '../http/fetch-flower-shop';
import { mapFlowerShopDtoToFlowerShop } from '../mappers/map-flower-shop-dto-to-flower-shop';
import { getPageSlug } from '../../../../model/configuration/selectors/get-page-slug.selector';
import { getFlowerDeliveryTypesApiActions } from '../../delivery/flowers-delivery-api.actions';

export function* getFlowerShopApiSaga() {
    const slug = yield select(getPageSlug);

    try {
        yield put(getFlowerDeliveryTypesApiActions.request());

        const deliveryTypesResponse = yield take([
            getFlowerDeliveryTypesApiActions.success,
            getFlowerDeliveryTypesApiActions.failure,
        ]);

        if (
            isActionOf(
                getFlowerDeliveryTypesApiActions.failure,
                deliveryTypesResponse,
            )
        ) {
            throw new Error('');
        }

        const response: IFlowerShopResponseDto = yield call(fetchFlowerShop, {
            slug,
        });
        yield put(
            fetchFlowerShopApiActions.success(
                mapFlowerShopDtoToFlowerShop(response),
            ),
        );
    } catch (e) {
        yield put(fetchFlowerShopApiActions.failure(e.statusText));
    }
}
