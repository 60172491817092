import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    uploadGalleryPhotoApiActions,
    downloadGalleryApiActions,
} from '../gallery-api.actions';

import { uploadGalleryPhotoSaga } from './upload-gallery-photo.saga';
import { downloadGallerySaga } from './download-gallery.saga';

export function* galleryApiSaga() {
    yield takeEvery(
        getType(uploadGalleryPhotoApiActions.request),
        uploadGalleryPhotoSaga,
    );
    yield takeEvery(
        getType(downloadGalleryApiActions.request),
        downloadGallerySaga,
    );
}
