import { createAsyncAction } from 'typesafe-actions';

import { ITokensResponseDto } from '../DTOs/tokens-response.dto';
import { LoginErrorCode } from '../DTOs/login-errors.dto';

export const loginApiActions = createAsyncAction(
    'api/login/LOGIN_REQUESTED',
    'api/login/LOGIN_SUCCEEDED',
    'api/login/LOGIN_FAILED',
)<{ email: string; password: string }, ITokensResponseDto, LoginErrorCode>();
