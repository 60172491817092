import { delay, put, take } from '@redux-saga/core/effects';

import { attendEventApiActions } from '../../../api/events/events-api.actions';
import {
    attendEventRequested,
    setEventAttendanceStatus,
} from '../events.actions';

export function* attendEventSaga(
    action: ReturnType<typeof attendEventRequested>,
) {
    yield put(attendEventApiActions.request(action.payload));

    const result = yield take([
        attendEventApiActions.success,
        attendEventApiActions.failure,
    ]);

    yield put(setEventAttendanceStatus(result.payload));
    yield delay(3000);
    yield put(setEventAttendanceStatus(null));
}
