import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';

import { getDeathNoticesDataFromMemorialPageFetchSaga } from './get-death-notices-data-from-memorial-page-fetch.saga';

export function* deathNoticesSaga() {
    yield takeEvery(
        getType(fetchPageActions.success),
        getDeathNoticesDataFromMemorialPageFetchSaga,
    );
}
