import React, { useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../../components/modal/modal';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-icon.svg';
import { ReactComponent as PublishIcon } from '../../assets/icons/publish-icon.svg';
import { CancelButton } from '../../components/cancel-button/cancel-button';

import styles from './delete-condolence-modal.module.scss';
import { IDeleteCondolenceModalRefProps } from './delete-condolence-modal-types';
import { useDeleteCondolenceModal } from './use-delete-condolence-modal';

export const DeleteCondolenceModal = React.forwardRef<
    IDeleteCondolenceModalRefProps
>((props, ref) => {
    const { t } = useTranslation();

    const {
        condolenceId,
        handleClose,
        handleConfirm,
        setCondolenceId,
    } = useDeleteCondolenceModal();

    useImperativeHandle(ref, () => {
        return {
            openFor: setCondolenceId,
        };
    });

    return (
        <Modal
            onClose={handleClose}
            open={Boolean(condolenceId)}
            title={t('deleteCondolenceModal.modalTitle')}
            classname={styles.Modal}
            overlayClassname={styles.Overlay}
        >
            <span className={styles.RemovalLabel}>
                {t('deleteCondolenceModal.modalLabel')}
            </span>
            <div className={styles.ButtonsContainer}>
                <CancelButton onClick={handleClose} icon={PublishIcon} />
                <PrimaryButton icon={TrashIcon} onClick={handleConfirm}>
                    {t('deleteCondolenceModal.delete')}
                </PrimaryButton>
            </div>
        </Modal>
    );
});
