import React, { useCallback, useState, useEffect } from 'react';

import { ErrorMessage } from '../components/error-message/error-message';

import styles from './video-stream.module.scss';

type Props = {
    url: string;
};

export const VideoStream: React.FC<Props> = (props) => {
    const { url } = props;
    const [hasError, setHasError] = useState(false);
    const [parsedSrc, setParsedSrc] = useState<string>();

    const onError = useCallback(() => {
        setHasError(true);
    }, []);

    useEffect(() => {
        if (url.includes('vimeo')) {
            const encodedURL = encodeURIComponent(url);
            fetch(`https://vimeo.com/api/oembed.json?url=${encodedURL}`)
                .then((resp) => resp.json())
                .then((resp) => {
                    const regex = /<iframe.*?src="(.*?)"/;
                    const src = resp.html ? regex.exec(resp.html)?.[1] : url;
                    setParsedSrc(src || '');
                });
        }
    }, [url]);

    return (
        <div className={styles.videoStream}>
            <div className={styles.iframeWrapper}>
                {!hasError && (
                    <iframe
                        className={styles.iframe}
                        src={parsedSrc || url}
                        allowFullScreen
                        allowTransparency
                        frameBorder="0"
                        title="video-stream"
                        onError={onError}
                    />
                )}
                {hasError && (
                    <ErrorMessage>videoStream.iframeOnError</ErrorMessage>
                )}
            </div>
        </div>
    );
};
