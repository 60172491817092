import React, { useEffect, useState } from 'react';
import { CarouselProps, Components, TrackProps } from 'react-images';

import { usePrevious } from '../../../shared/hooks/usePrevious';

import { IImageCarouselProps, IViewType } from './image-carousel.types';
import { checkIfURLIsVideo } from './image-carousel.helpers';
import { CustomView } from './components/custom-view/custom-view';
import { NavigationPrev } from './components/navigation-prev';
import { NavigationNext } from './components/navigation-next';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { useImageCarouselFullscreen } from './use-image-carousel-fullscreen';

export const useImageCarousel = ({
    mediaFiles,
    currentIndex,
    wrapperElement,
}: IImageCarouselProps) => {
    const {
        nativeFullscreenHandle,
        fullscreenContext,
    } = useImageCarouselFullscreen();

    const currentStringifiedMediaFiles = JSON.stringify(mediaFiles);
    const previousStringifiedMediaFiles = usePrevious(
        currentStringifiedMediaFiles,
    );
    const mediaFilesChanged =
        !!previousStringifiedMediaFiles &&
        previousStringifiedMediaFiles.length !==
            currentStringifiedMediaFiles.length;

    const [carouselKey, setCarouselKey] = useState<string>(
        currentStringifiedMediaFiles,
    );
    useEffect(() => {
        if (mediaFilesChanged) {
            setCarouselKey(currentStringifiedMediaFiles);
        }
    }, [mediaFilesChanged, currentStringifiedMediaFiles]);

    const [components, setComponents] = useState<Components | undefined>(
        undefined,
    );
    const isReady = !!components;

    const views: IViewType[] = mediaFiles.map((item) => ({
        source: item.sourceUrl,
        isVideo: checkIfURLIsVideo(item.sourceUrl),
    }));

    useEffect(() => {
        if (!isReady) {
            const carouselComponents: Components = {
                View: CustomView,
                NavigationPrev,
                NavigationNext,
                Header,
                Footer,
            };

            if (wrapperElement) {
                carouselComponents.Container = (props) =>
                    React.cloneElement(wrapperElement, props);
            }

            setComponents(carouselComponents);
        }
    }, [isReady, wrapperElement]);

    const trackProps: TrackProps = {
        swipe: false,
    };

    const carouselProps: CarouselProps & {
        showNavigationOnTouchDevice: boolean;
        key: string;
    } = {
        key: carouselKey,
        views,
        components,
        trackProps,
        showNavigationOnTouchDevice: true,
        currentIndex,
    };

    return {
        isReady: !!components,
        carouselProps,
        nativeFullscreenHandle,
        fullscreenContext,
    };
};
