import { createAction } from 'typesafe-actions';

export const userLoggedStatusReceived = createAction(
    'model/user/LOGGED_STATUS_RECEIVED',
    (payload: { logged: boolean }) => payload,
)();

export const userAccessToMemorialDetected = createAction(
    'model/user/ACCESS_TO_MEMORIAL_DETECTED',
    (payload: { hasAccess: boolean }) => payload,
)();

export const userEmailReceived = createAction(
    'model/user/EMAIL_RECEIVED',
    (payload: { email: string }) => payload,
)();

export const userMPAStatusReceived = createAction(
    'model/user/MPA_STATUS_RECEIVED',
    (payload: { isAdmin: boolean }) => payload,
)();

export const userLogoutRequested = createAction(
    'model/user/LOGOUT_REQUESTED',
)();
