import { stdnum } from 'stdnum';
import { Validator } from 'stdnum/lib/cjs/types';

import { LanguageCode } from '../../../utils/get-language-codes';

export const getPersonalIdValidatorByLocale = (
    locale: LanguageCode | null,
): Validator | null => {
    switch (locale) {
        case LanguageCode.NO:
            return stdnum.NO.fodselsnummer;
        case LanguageCode.PL:
            return stdnum.PL.pesel;
        case LanguageCode.DE:
            return stdnum.DE.personalausweis;
        default:
            return null;
    }
};
