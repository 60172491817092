import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { get } from 'lodash';

import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { Container } from '../../components/container/container';
import { ConnectedLoginForm } from '../../login/login-form/login-form';
import { ILoginFormValues } from '../../login/login-form-values';
import { loginFormSubmitted } from '../../login/model/login-page.actions';
import { Section } from '../../components/section/section';
import { getLoginPageGlobalError } from '../../login/model/selectors/get-login-page-global-error.selector';
import { getLoginApiErrorMessage } from '../../login/get-login-api-error-message';
import { ButtonLink } from '../../components/button-link/button-link';
import { RoutePaths } from '../../routing/route-paths';
import { ConnectedForgottenPasswordForm } from '../../forgotten-password/forgotten-password-form/forgotten-password-form';
import { IForgottenPasswordFormValues } from '../../forgotten-password/forgotten-password-form-values';
import { forgottenPasswordFormSubmitted } from '../../forgotten-password/model/forgotten-password.actions';
import { getHasUserAccess } from '../../model/user/selectors/get-has-user-access.selector';
import { getUserLoggedStatus } from '../../model/user/selectors/get-user-logged-status.selector';
import { getPagePrivacyMode } from '../../model/configuration/selectors/get-page-privacy-mode.selector';
import { PrivacyMode } from '../../interfaces/privacy-mode';

import styles from './login-page.module.scss';

export const LoginPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation<{ email?: string }>();
    const history = useHistory();
    const match = useRouteMatch();
    const globalErrorCode = useSelector(getLoginPageGlobalError);
    const [cachedEmail, setCachedEmail] = useState('');
    const hasAccessToThisMemorial = useSelector(getHasUserAccess);
    const logged = useSelector(getUserLoggedStatus);
    const privacyMode = useSelector(getPagePrivacyMode);

    useEffect(() => {
        if (hasAccessToThisMemorial && logged) {
            history.push(RoutePaths.HOME);
        }
    }, [history, hasAccessToThisMemorial, logged]);

    const onLoginSubmit = (values: ILoginFormValues) => {
        setCachedEmail(values.email);
        dispatch(loginFormSubmitted(values));
    };

    const onForgottenPasswordFormSubmit = (
        values: IForgottenPasswordFormValues,
    ) => {
        dispatch(forgottenPasswordFormSubmitted(values));
    };

    // eslint-disable-next-line consistent-return
    const getGlobalError = (): string | undefined => {
        if (globalErrorCode) {
            return getLoginApiErrorMessage(globalErrorCode);
        }
    };

    const isLogin = match && match.path === RoutePaths.LOGIN;

    const isForgottenPassword =
        match && match.path === RoutePaths.FORGOT_PASSWORD;

    const renderNavigationLink = () =>
        isLogin ? (
            <ButtonLink
                onClick={() => history.push(RoutePaths.FORGOT_PASSWORD)}
                className={styles.ForgotPasswordButton}
            >
                {t('login.goToForgotPassword')}
            </ButtonLink>
        ) : (
            <ButtonLink
                onClick={() => history.push(RoutePaths.LOGIN)}
                className={styles.ForgotPasswordButton}
            >
                {t('login.goToLogin')}
            </ButtonLink>
        );

    const shouldShowDeceasedData = (): boolean => {
        /**
         * Backend already verified that user is logged and has access
         */
        if (hasAccessToThisMemorial) {
            return true;
        }

        /**
         * If somehow page was detected as private on frontend
         */
        if (privacyMode === PrivacyMode.PRIVATE) {
            return false;
        }

        /**
         * Handle not loaded data and NULL which means page was not loaded, because backend
         * returns 404 if page is private
         */
        if (!privacyMode) {
            return false;
        }

        return true;
    };

    return (
        <>
            {shouldShowDeceasedData() && <DeceasedDisplay />}
            <Container>
                <Section
                    centerHeader
                    headerText={
                        isLogin
                            ? t('login.headline')
                            : t('forgottenPassword.headline')
                    }
                    className={styles.LoginForm}
                >
                    {isLogin && (
                        <ConnectedLoginForm
                            prefillEmail={
                                get(location, 'state.email') || cachedEmail
                            }
                            onSubmit={onLoginSubmit}
                            globalError={getGlobalError()}
                        />
                    )}
                    {isForgottenPassword && (
                        <ConnectedForgottenPasswordForm
                            prefillEmail={cachedEmail}
                            onSubmit={onForgottenPasswordFormSubmit}
                        />
                    )}
                    {renderNavigationLink()}
                </Section>
            </Container>
        </>
    );
};
