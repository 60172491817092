import React from 'react';
import { useSelector } from 'react-redux';

import { Container } from '../../components/container/container';
import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { TributeText } from '../../deceased-person/tribute-text/tribute-text';
import { FlowersConfirmation } from '../../flowers/confirmation/flowers-confirmation';
import { getDeceasedTributeText } from '../../model/deceased-person/selectors/get-deceased-tribute.selector';

import styles from './flowers-confirmation-page.module.scss';

export const FlowersConfirmationPage = () => {
    const tribute = useSelector(getDeceasedTributeText);

    return (
        <>
            <DeceasedDisplay />
            <Container>
                {tribute && <TributeText content={tribute} />}
                <FlowersConfirmation
                    className={styles.FlowersConfirmationSection}
                />
            </Container>
        </>
    );
};
