import { call, put, select } from '@redux-saga/core/effects';

import { uploadGalleryPhotoApiActions } from '../gallery-api.actions';
import { IGalleryPhotoDto } from '../../DTOs/gallery-photo.dto';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { uploadPhoto } from '../http/upload-photo';
import { imageUploadToS3Saga } from '../../image-upload/saga/image-upload-to-s3.saga';

export function* uploadGalleryPhotoSaga(
    action: ReturnType<typeof uploadGalleryPhotoApiActions.request>,
) {
    const { file, meta } = action.payload;

    const slug = yield select(getPageSlug);

    try {
        const s3StringifyImageResponse = yield call(imageUploadToS3Saga, file);

        const photo: IGalleryPhotoDto = yield call(uploadPhoto, {
            slug,
            meta,
            s3StringifyImageResponse,
        });

        yield put(uploadGalleryPhotoApiActions.success(photo));
    } catch (err) {
        yield put(uploadGalleryPhotoApiActions.failure(err.statusText));
    }
}
