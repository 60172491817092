import { IImageContainerMaskProps } from './image-container-mask.types';

export const useImageContainerMask = ({
    imageContainerState,
    useLightMode,
}: IImageContainerMaskProps) => {
    const { isLoading, isError } = imageContainerState;

    const isLoadingMaskVisible = isLoading;
    const isErrorMaskVisible = isError;

    return {
        isLoadingMaskVisible,
        isErrorMaskVisible,
        useLightMode,
    };
};
