import { apiService } from '../../api.service';
import { ITokensResponseDto } from '../../DTOs/tokens-response.dto';

export const createUser = (dto: {
    password: string;
    token: string;
    email: string;
}): Promise<ITokensResponseDto> =>
    apiService
        .post(`/auth/invitations/${dto.token}/users`, {
            json: {
                user: {
                    password: dto.password,
                    email: dto.email,
                    terms_and_conditions: true,
                },
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes);
