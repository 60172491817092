import { call, put, select } from '@redux-saga/core/effects';

import { unlikeCondolenceCommentApiActions } from '../condolences-api.actions';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { unlikeCondolenceComment } from '../http/unlike-condolence-comment';
import { getUserLoggedStatus } from '../../../model/user/selectors/get-user-logged-status.selector';
import { getCondolenceCommentLikeTokenByCondolenceCommentId } from '../../../model/condolences/selectors/get-condolence-comment-like-token-by-condolence-comment-id.selector';

export function* unlikeCondolenceCommentApiSaga(
    action: ReturnType<typeof unlikeCondolenceCommentApiActions.request>,
) {
    const { id } = action.payload;

    const slug = yield select(getPageSlug);
    const logged = yield select(getUserLoggedStatus);
    const condolenceCommentLikeToken = yield select(
        getCondolenceCommentLikeTokenByCondolenceCommentId(id),
    );

    const likeToken = !logged && condolenceCommentLikeToken;

    try {
        const response = yield call(unlikeCondolenceComment, {
            slug,
            id,
            likeToken,
        });
        if (response.ok) {
            yield put(unlikeCondolenceCommentApiActions.success({ likeToken }));
        } else {
            yield put(unlikeCondolenceCommentApiActions.failure());
        }
    } catch (err) {
        yield put(unlikeCondolenceCommentApiActions.failure());
    }
}
