import { call, put, select } from '@redux-saga/core/effects';

import { attendEventApiActions } from '../events-api.actions';
import { attendEvent } from '../http/attend-event';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { EventAttendanceStatusCode } from '../../../interfaces/event-attendance-status';

export function* attendEventSaga(
    action: ReturnType<typeof attendEventApiActions.request>,
) {
    const { eventId, email } = action.payload;
    const slug = yield select(getPageSlug);

    const response = yield call(attendEvent, {
        slug,
        data: { eventId, email },
    });

    if (!response.errors) {
        yield put(
            attendEventApiActions.success({
                eventId,
                status: EventAttendanceStatusCode.SUCCESS,
            }),
        );
    } else {
        yield put(
            attendEventApiActions.failure({
                eventId,
                status:
                    response.errors[0].code === 'is_taken'
                        ? EventAttendanceStatusCode.EMAIL_ALREADY_USED
                        : EventAttendanceStatusCode.ERROR,
            }),
        );
    }
}
