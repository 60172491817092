import { apiService } from '../../api.service';

export const downloadGallery = ({
    slug,
}: {
    slug: string;
}): Promise<Response> => {
    return apiService
        .get(`/public/memorial_pages/${slug}/gallery_photos/download`)
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes.url);
};
