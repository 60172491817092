import { createSelector } from 'reselect';

import i18n from '../../../../setup/i18n';
import { CreateContactSuggestionResult } from '../contact-suggestion.store';

import { getContactSuggestionStatus } from './get-contact-suggestion-status.selector';

export const getContactSuggestionMessage = createSelector(
    getContactSuggestionStatus,
    (status) => {
        const CreateContactSuggestionResultMessages = {
            [CreateContactSuggestionResult.SUCCESS]: i18n.t(
                'createContactSuggestionResult.success',
            ),
            [CreateContactSuggestionResult.ERROR]: i18n.t(
                'createContactSuggestionResult.error',
            ),
        };

        return status !== null
            ? CreateContactSuggestionResultMessages[status]
            : '';
    },
);
