import { put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { forgottenPasswordFormSubmitted } from '../model/forgotten-password.actions';
import { ForgottenPasswordActions } from '../../api/forgotten-password/forgotten-password-api.actions';
import { RoutePaths } from '../../routing/route-paths';

export function* handleForgottenPasswordFormSubmitSaga(
    action: ReturnType<typeof forgottenPasswordFormSubmitted>,
) {
    yield put(
        ForgottenPasswordActions.resetEmail.request({
            email: action.payload.email,
        }),
    );

    yield take(ForgottenPasswordActions.resetEmail.success);

    yield put(push(RoutePaths.LOGIN));

    /**
     * TODO: Handle error
     */
}
