import { apiService } from '../../api.service';
import { ICondolenceResponseDto } from '../../DTOs/condolence-response.dto';

export const getCondolences = async ({
    slug,
}: {
    slug: string;
}): Promise<ICondolenceResponseDto> => {
    const requestUrl = `/public/memorial_pages/${slug}/condolences`;

    return apiService
        .get(requestUrl, {
            throwHttpErrors: false,
        })
        .then((resp) => resp.json());
};
