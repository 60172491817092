import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qr-svg';
import { useHistory, useLocation } from 'react-router';

import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { Container } from '../../components/container/container';
import { Section } from '../../components/section/section';
import { IEventAttendanceFormValues } from '../../event-attendance/event-attendance-form-values';
import { ConnectedEventAttendanceForm } from '../../event-attendance/event-attendance-form/event-attendance-form';
import {
    eventAttendanceFormSubmitted,
    resetEventAttendanceState,
} from '../../event-attendance/model/event-attendance.actions';
import { getEventAttendanceStatus } from '../../event-attendance/model/selectors/get-event-attendance-status.selector';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import { ReactComponent as DetailsIcon } from '../../assets/icons/details-icon.svg';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { useFlowerDeliveryState } from '../../flowers/delivery/use-flower-delivery-state';
import { useDateFormatter } from '../../utils/use-date-formatter';
import { FlowerDeadlineType } from '../../interfaces/flower-deadline';
import { getFlowerShopDeadlineSelector } from '../../model/flower-shop/selectors/get-flower-shop-deadline.selector';
import { getEvents } from '../../model/events/selectors/get-events.selector';
import { RoutePaths } from '../../routing/route-paths';
import { getEventAttendanceLoading } from '../../event-attendance/model/selectors/get-event-attendance-loading.selector';

import styles from './event-attendance.module.scss';

export const EventAttendancePage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const status = useSelector(getEventAttendanceStatus);
    const loading = useSelector(getEventAttendanceLoading);

    const { ceremonyDeliveryAvailable } = useFlowerDeliveryState();
    const deadline = useSelector(getFlowerShopDeadlineSelector);
    const { getTextDate, checkIfDateIsAfterNow } = useDateFormatter();

    const handleResetEventAttendanceState = useCallback(() => {
        dispatch(resetEventAttendanceState());
    }, [dispatch]);

    useEffect(() => {
        handleResetEventAttendanceState();
    }, [handleResetEventAttendanceState]);

    const events = useSelector(getEvents);
    const location = useLocation<{ eventId: string }>();
    const history = useHistory();

    const event = location.state?.eventId
        ? events.find((e) => e.id === location.state.eventId)
        : null;

    const isAttendanceRegistrationExpired = event?.date
        ? checkIfDateIsAfterNow(event.date)
        : false;

    const isAttendanceRegistrationLimitReached = event
        ? !isAttendanceRegistrationExpired &&
          event.attendanceRegistrationLimitEnabled &&
          !!event.attendanceRegistrationLimit &&
          event.attendanceRegistrationLimit <= event.attendanceRegistrationCount
        : false;

    const redirectToHomePage = () => {
        history.push(RoutePaths.HOME);
    };

    if (!event) {
        redirectToHomePage();
        return null;
    }

    const onEventAttendanceSubmit = (values: IEventAttendanceFormValues) => {
        dispatch(
            eventAttendanceFormSubmitted({
                schedulable: event.schedulable,
                ...values,
            }),
        );
    };

    const showCeremonyDeliveryData =
        ceremonyDeliveryAvailable &&
        deadline !== FlowerDeadlineType.PASSED &&
        deadline !== FlowerDeadlineType.NONE;

    return (
        <>
            <DeceasedDisplay />
            <Container>
                <Section
                    centerHeader
                    headerText={t('eventAttendance.formTitle')}
                    className={styles.EventAttendanceForm}
                >
                    <>
                        {status ? (
                            <div className={styles.RegistrationCompleted}>
                                <p className={styles.RegistrationCompletedRow}>
                                    {t('eventAttendance.registrationCompleted')}
                                </p>
                                {showCeremonyDeliveryData && (
                                    <p
                                        className={
                                            styles.RegistrationCompletedRow
                                        }
                                    >
                                        {t('eventAttendance.flowersOrdering', {
                                            deadline:
                                                deadline &&
                                                getTextDate(deadline),
                                        })}
                                    </p>
                                )}
                                {isAttendanceRegistrationLimitReached ? (
                                    <PrimaryButton
                                        icon={DetailsIcon}
                                        onClick={redirectToHomePage}
                                    >
                                        {t('navigation.goToHomepageButton')}
                                    </PrimaryButton>
                                ) : (
                                    <PrimaryButton
                                        icon={ArrowIcon}
                                        type="submit"
                                        onClick={
                                            handleResetEventAttendanceState
                                        }
                                    >
                                        {t(
                                            'eventAttendance.backToRegistration',
                                        )}
                                    </PrimaryButton>
                                )}
                            </div>
                        ) : (
                            <>
                                <div className={styles.PageText}>
                                    {event.attendanceRegistrationPageText ??
                                        t(
                                            'eventAttendance.registartionPageDescription',
                                        )}
                                </div>
                                <ConnectedEventAttendanceForm
                                    loading={loading}
                                    onSubmit={onEventAttendanceSubmit}
                                    attendanceRegistrationRetentionPeriodInDays={
                                        event.attendanceRegistrationRetentionPeriodInDays
                                    }
                                    foodCommentEnabled={
                                        event.foodCommentEnabled
                                    }
                                />
                            </>
                        )}
                    </>
                    <QRCode
                        value={window.location.href}
                        style={{
                            display: 'block',
                            maxWidth: 200,
                            margin: '30px auto',
                        }}
                    />
                </Section>
            </Container>
        </>
    );
};
