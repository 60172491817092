import React from 'react';
import cx from 'classnames';

import styles from './text-area.module.scss';

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    name: string;
    rows?: number;
    value?: string;
    maxLength?: number;
};

export const TextArea = ({ className, ...props }: Props) => {
    return <textarea className={cx(styles.TextArea, className)} {...props} />;
};
