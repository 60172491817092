import React, { useEffect } from 'react';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../../components/text-field/text-field';
import { ErrorMessage } from '../../../components/error-message/error-message';
import { ICanBeControlled } from '../use-form-controller';
import { PhoneInput } from '../../../components/phone-input/phone-input';

import { IContactDetailsFormValues } from './contact-details-form-values';
import { ContactDetailsFormValidation } from './contact-details-form-validation';
import styles from './contact-details-form.module.scss';

interface IOuterProps
    extends Omit<React.HTMLAttributes<HTMLFormElement>, 'onChange'>,
        ICanBeControlled<IContactDetailsFormValues> {
    prefilledValues?: IContactDetailsFormValues | null;
    userEmail?: string;
}

interface IProps extends IOuterProps, FormikProps<IContactDetailsFormValues> {}

const Form = ({
    handleBlur,
    handleSubmit,
    handleChange,
    onChange,
    values,
    errors,
    validateForm,
    className,
    isValid,
    setFieldValue,
}: IProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        onChange(values, validateForm, isValid);
    }, [onChange, values, isValid, validateForm]);

    return (
        <form onSubmit={handleSubmit} className={cx(styles.Form, className)}>
            <div className={styles.FieldWrapper}>
                <TextField
                    placeholder={t(
                        'flowers.delivery.contactDetailsForm.nameLabel',
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    name="name"
                    data-testid="flowers-delivery-contact-name"
                />
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </div>
            <div className={styles.FieldWrapper}>
                <TextField
                    placeholder={t(
                        'flowers.delivery.contactDetailsForm.emailLabel',
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    data-testid="flowers-delivery-contact-email"
                />
                {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </div>
            <div className={styles.FieldWrapper}>
                <PhoneInput
                    onBlur={handleBlur}
                    inputProps={{
                        placeholder: t(
                            'flowers.delivery.contactDetailsForm.phoneLabel',
                        ),
                    }}
                    onChange={(phone) => setFieldValue('phone', phone)}
                    value={values.phone}
                    name="phone"
                    data-testid="flowers-delivery-contact-phone"
                    dropdownToTop
                />
                {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
            </div>
        </form>
    );
};

export const ContactDetailsForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IContactDetailsFormValues>({
        mapPropsToValues({ prefilledValues, userEmail }) {
            if (prefilledValues) {
                return prefilledValues;
            }

            return {
                name: '',
                email: userEmail || '',
                phone: '',
            };
        },
        validationSchema: ContactDetailsFormValidation,
        validateOnMount: true,
        handleSubmit() {},
    }),
)(Form);
