import { createAsyncAction } from 'typesafe-actions';

import { ISendAccessRequestDto } from '../DTOs/send-access-request.dto';
import { AccessRequestStatus } from '../../access-request/access-request-status';

export const sendAccessRequestApiActions = createAsyncAction(
    'api/request-access/ACCESS_REQUEST_REQUESTED',
    'api/request-access/ACCESS_REQUEST_SUCCEEDED',
    'api/request-access/ACCESS_REQUEST_FAILED',
)<
    ISendAccessRequestDto & { slug: string },
    AccessRequestStatus,
    AccessRequestStatus
>();
