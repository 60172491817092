export const initFB = () => {
    try {
        FB.init({
            appId: process.env.REACT_APP_FACEBOOK_APP_ID!,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v4.0',
        });
    } catch (e) {
        console.log('FB SDK couldnt be initialized');
    }
};
