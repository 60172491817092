import { useCallback, useEffect, useRef } from 'react';

import { usePrevious } from '../../../../../shared/hooks/usePrevious';
import { IVideoPlayerRef } from '../../../video-player/video-player.types';
import { IImageCarouselCustomViewProps } from '../../image-carousel.types';
import { useImageCarouselFullscreenContext } from '../../contexts/fullscreen-context/use-fullscreen-context';
import { IVideoPlayerControlsProps } from '../../../video-player/components/video-player-controls/video-player-controls.types';

import { getVideoPlayerOptions } from './custom-view.helpers';

export const useCustomView = ({
    data,
    currentIndex,
}: IImageCarouselCustomViewProps) => {
    const {
        isNativeFullscreen,
        isFullscreen,
    } = useImageCarouselFullscreenContext();

    const previousIndex = usePrevious(currentIndex);
    const videoPlayerRef = useRef<IVideoPlayerRef>(null);
    const videoPlayerRefCurrent = videoPlayerRef.current;

    const source = data?.source as string;

    const videoPlayerOptions = getVideoPlayerOptions(isFullscreen);
    const videoPlayerControlsOptions: IVideoPlayerControlsProps = {
        showFullscreenButton: isFullscreen && !isNativeFullscreen,
    };

    const handleVideoPlayerPause = useCallback(() => {
        if (videoPlayerRefCurrent && videoPlayerRefCurrent.pause) {
            videoPlayerRefCurrent.pause();
        }
    }, [videoPlayerRefCurrent]);

    useEffect(() => {
        return () => {
            if (currentIndex !== previousIndex) {
                handleVideoPlayerPause();
            }
        };
    }, [currentIndex, previousIndex, handleVideoPlayerPause]);

    return {
        videoPlayerRef,
        data,
        isFullscreen,
        source,
        videoPlayerOptions,
        videoPlayerControlsOptions,
    };
};
