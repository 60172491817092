import React, { useRef, useState } from 'react';
import cx from 'classnames';
import useOnClickOutside from 'use-onclickoutside';

import { ReactComponent as ContextIcon } from '../../assets/icons/context-icon.svg';

import styles from './context-menu.module.scss';

interface IMenuItemProps {
    label: string;
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    action(): void;
}

type IProps = React.HTMLAttributes<HTMLDivElement> & {
    items: IMenuItemProps[];
};

export const ContextMenu = ({ className, items, ...props }: IProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const contextMenuRef = useRef(null);

    useOnClickOutside(contextMenuRef, () => {
        if (open) {
            setOpen(false);
        }
    });

    const contextMenuClass = cx(
        styles.ContextMenu,
        { [styles.ContextMenuOpen]: open },
        className,
    );

    return (
        <div
            className={contextMenuClass}
            onClick={() => setOpen(true)}
            ref={contextMenuRef}
            {...props}
        >
            <ContextIcon className={styles.ContextIcon} />
            {open && (
                <div className={styles.ContextMenuItems}>
                    {items.map((item) => {
                        const Icon: React.FC<React.SVGProps<SVGSVGElement>> =
                            item.icon;

                        return (
                            <div
                                className={styles.ContextMenuItem}
                                key={item.label}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(false);
                                    item.action();
                                }}
                            >
                                <Icon className={styles.ContextMenuItemIcon} />
                                <span className={styles.ContextMenuItemLabel}>
                                    {item.label}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
