import { useTranslation } from 'react-i18next';

import { getInitiallySelectedVariant } from '../../helpers/flower-helpers';

import { VariantPickerProps } from './variant-picker';

export const useVariantPicker = ({
    rowsLimit = 4,
    columnsLimit = 3,
    oneColumnLayout = true,
    itemsLimit,
    ...props
}: VariantPickerProps) => {
    const { t } = useTranslation();
    let { items } = props;

    if (itemsLimit && items.length > itemsLimit) {
        const numberOfItemsToShow = itemsLimit - 1 || 1;
        items = [
            ...items.slice(0, numberOfItemsToShow),
            {
                id: getInitiallySelectedVariant(items).id,
                label: t('flowers.type.andMore', {
                    count: items.length - numberOfItemsToShow,
                }),
                grossPrice: 0,
                currency: '',
                isMoreButton: true,
            },
        ];
    }

    const containerStyles =
        items.length > rowsLimit
            ? {
                  gridTemplateRows: `repeat(${
                      items.length % columnsLimit === 0
                          ? items.length / columnsLimit
                          : Math.ceil(items.length / columnsLimit)
                  },1fr)`,
              }
            : {
                  gridAutoFlow: 'row',
                  gridTemplateColumns: '1fr',
                  gridTemplateRows: 'unset',
              };

    return {
        ...props,
        containerStyles,
        oneColumnLayout,
        items,
    };
};
