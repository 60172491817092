import { put, take } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';

import { invitationFormSubmitted } from '../invitation.actions';
import { createUserApiActions } from '../../../api/create-user/create-user.actions';
import { saveTokens } from '../../../api/auth-token-storage';
import { RoutePaths } from '../../../routing/route-paths';
import {
    userLoggedStatusReceived,
    userAccessToMemorialDetected,
} from '../../../model/user/user.actions';
import { fetchPageActions } from '../../../api/page/page-api.actions';

export function* handleInvitationFormSubmission(
    action: ReturnType<typeof invitationFormSubmitted>,
) {
    const { email, password } = action.payload;

    yield put(
        createUserApiActions.request({
            email,
            password,
        }),
    );

    const result: ReturnType<typeof createUserApiActions.success> = yield take(
        createUserApiActions.success,
    );

    const tokens = result.payload;

    yield saveTokens(tokens.accessToken, tokens.refreshToken);

    yield put(userLoggedStatusReceived({ logged: true }));
    yield put(userAccessToMemorialDetected({ hasAccess: true }));

    /**
     * After user is logged, fetch page again with possibly more data
     */
    yield put(fetchPageActions.request());
    yield take(fetchPageActions.success);

    yield put(push(RoutePaths.HOME));

    // TODO: Handle errors
}
