import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { videoStreamFormSubmitted } from '../video-stream.actions';

import { handleVideoStreamFormSubmittedSaga } from './handle-video-stream-form-submitted.saga';

export function* videoStreamSaga() {
    yield takeEvery(
        getType(videoStreamFormSubmitted),
        handleVideoStreamFormSubmittedSaga,
    );
}
