import { put, take } from 'redux-saga/effects';
// import { push } from 'connected-react-router';

import {
    eventAttendanceFormSubmitted,
    setEventAttendanceStatus,
} from '../event-attendance.actions';
import { sendEventAttendanceApiActions } from '../../../api/event-attendance/event-attendance-api.actions';
// import { RoutePaths } from '../../../routing/route-paths';

export function* eventAttendanceFormSubmittedSaga(
    action: ReturnType<typeof eventAttendanceFormSubmitted>,
) {
    const data = action.payload;

    yield put(sendEventAttendanceApiActions.request(data));

    yield take(sendEventAttendanceApiActions.success);
    yield put(setEventAttendanceStatus('SUCCESS'));
    // yield put(push(RoutePaths.HOME));
}
