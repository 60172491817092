import { createSelector } from 'reselect';

import { IFlowerShop } from '../../../interfaces/flower-shop';

import { getFlowerShopProductsSelector } from './get-flower-shop-products.selector';

export const getFlowerShopPopularProductsSelector = createSelector(
    getFlowerShopProductsSelector,
    (products): IFlowerShop['products'] => {
        return products.filter((product) =>
            product.variants.some((variant) => variant.isPopular),
        );
    },
);
