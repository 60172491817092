import { createSelector } from 'reselect';

import { getLanguage } from '../../configuration/selectors/get-language.selector';
import { sortAscByKey } from '../../../../shared/helpers/sortAscByKey';
import { IMemorialEvent } from '../../../interfaces/memorial-event';

import { getEventsStore } from './get-events-store.selector';

export const getEvents = createSelector(getEventsStore, getLanguage, (store) =>
    store.events.sort(sortAscByKey<IMemorialEvent>('date')),
);
