import { createSelector } from 'reselect';

import { FlowersOrderStatusMessage } from '../flowers-order-status';

import { getFlowersOrderStatus } from './get-flowers-order-status.selector';

export const getFlowersOrderStatusMessage = createSelector(
    getFlowersOrderStatus,
    (status) => {
        const flowersOrderStatusMessage = FlowersOrderStatusMessage;
        return status ? flowersOrderStatusMessage[status] : null;
    },
);
