import { put, race, take } from '@redux-saga/core/effects';

import { unlikeCondolenceApiActions } from '../../../api/condolences/condolences-api.actions';
import {
    unlikeCondolenceRequested,
    unlikeCondolenceSucceeded,
} from '../condolences.actions';

export function* handleUnlikeCondolenceSaga(
    action: ReturnType<typeof unlikeCondolenceRequested>,
) {
    const { id } = action.payload;

    yield put(unlikeCondolenceApiActions.request({ id }));

    const { success } = yield race({
        success: take(unlikeCondolenceApiActions.success),
        failure: take(unlikeCondolenceApiActions.failure),
    });

    if (success) {
        const { likeToken } = success.payload;
        yield put(unlikeCondolenceSucceeded({ id, likeToken }));
    }
}
