import { put, delay } from 'redux-saga/effects';

import { sendEventAttendanceApiActions } from '../../../api/event-attendance/event-attendance-api.actions';
import {
    setEventAttendanceError,
    setEventAttendanceStatus,
} from '../event-attendance.actions';

export function* handleEventAttendanceApiErrorSaga(
    action: ReturnType<typeof sendEventAttendanceApiActions.failure>,
) {
    yield put(setEventAttendanceStatus(null));
    yield put(setEventAttendanceError(action.payload));
    yield delay(3000);
    yield put(setEventAttendanceError(null));
}
