import React from 'react';
import cx from 'classnames';
import { Presets } from 'react-component-transition';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import styles from '../image-carousel.module.scss';
import { IImageCarouselHeaderProps } from '../image-carousel.types';
import { useImageCarouselFullscreenContext } from '../contexts/fullscreen-context/use-fullscreen-context';

export const Header = React.memo(
    ({ views, currentIndex, interactionIsIdle }: IImageCarouselHeaderProps) => {
        const {
            isFullscreen,
            exitFullscreen,
        } = useImageCarouselFullscreenContext();
        const isCountIconVisible =
            Number(views?.length) > 1 && (!isFullscreen || !interactionIsIdle);
        const isCloseIconVisible = !interactionIsIdle && isFullscreen;
        return (
            <div className={styles.Header}>
                <Presets.TransitionFade>
                    {isCountIconVisible && (
                        <div className={cx(styles.Icon, styles.CountIcon)}>
                            {(currentIndex || 0) + 1} / {views?.length || 0}
                        </div>
                    )}
                    {isCloseIconVisible && (
                        <div
                            className={cx(styles.Icon, styles.CloseIcon)}
                            onClick={exitFullscreen}
                        >
                            <CloseRoundedIcon />
                        </div>
                    )}
                </Presets.TransitionFade>
            </div>
        );
    },
);
