import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchFlowerShopApiActions } from '../flower-shop-api.actions';

import { getFlowerShopApiSaga } from './get-flower-shop-api.saga';

export function* flowerShopApiSaga() {
    yield takeEvery(
        getType(fetchFlowerShopApiActions.request),
        getFlowerShopApiSaga,
    );
}
