import { apiService } from '../../api.service';

export const unlikeCondolenceComment = ({
    slug,
    id,
    likeToken = '',
}: {
    slug: string;
    id: string;
    likeToken?: string;
}): Promise<Response> => {
    return apiService.delete(
        `/public/memorial_pages/${slug}/comments/${id}/like`,
        {
            throwHttpErrors: false,
            json: { token: likeToken },
        },
    );
};
