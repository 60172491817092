import { createSelector } from 'reselect';

import { IFlowerCategory } from '../../../interfaces/flower-category';

import { getFlowerShopProductsSelector } from './get-flower-shop-products.selector';

export const getFlowerShopCategoriesSelector = createSelector(
    getFlowerShopProductsSelector,
    (products): IFlowerCategory[] => {
        const categories: IFlowerCategory[] = [];
        products.forEach((product) => {
            const isCategoryAlreadyAdded = categories.some(
                (category) => category.id === product.category.id,
            );
            if (!isCategoryAlreadyAdded) {
                categories.push(product.category);
            }
        });
        return categories;
    },
);
