import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchPageActions } from '../../app/api/page/page-api.actions';

export const withPageFetch = () => <Props extends {}>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPageActions.request());
    }, [dispatch]);

    return <Component {...props} />;
};
