import { put } from '@redux-saga/core/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { userMPAStatusReceived } from '../user.actions';

/**
 * Resolve memorial data and figure out incomplete response, to detect no access
 */
export function* getAdminStatusFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const memorialPageData = action.payload;

    const { isAdmin } = memorialPageData.data.attributes;

    yield put(userMPAStatusReceived({ isAdmin }));
}
