import { put } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { mapMemorialPageToEvents } from '../mappers/map-memorial-page-to-events';
import { eventsDataReceived } from '../events.actions';

export function* getEventsDataFromMemorialPageFetchSaga(
    action: ReturnType<typeof fetchPageActions.success>,
) {
    const memorialPageData = action.payload;

    try {
        const events = mapMemorialPageToEvents(memorialPageData);

        yield put(
            eventsDataReceived({
                events,
            }),
        );
    } catch (e) {
        /**
         * TODO: Handle missing data and assume privacy mode is set
         */
        console.log('Error getting events from memorial page');
        console.error(e);
    }
}
