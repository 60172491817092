import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useDateFormatter } from '../../../../utils/use-date-formatter';
import { ReactComponent as CandleRoundedIcon } from '../../../../assets/icons/candle-rounded-icon.svg';
import { ReactComponent as HeartRoundedIcon } from '../../../../assets/icons/heart-rounded-icon.svg';
import { ReactComponent as FlowersRoundedIcon } from '../../../../assets/icons/flower-rounded-icon.svg';
import { ReactComponent as DonationRoundedIcon } from '../../../../assets/icons/donation-rounded-icon.svg';
import { ContextMenu } from '../../../../components/context-menu/context-menu';
import { IIconType } from '../../../../components/icon/icon-types';
import ImageCarousel from '../../../../components/image-carousel/image-carousel';
import {
    decodeText,
    getDecodedText,
} from '../../../../../shared/helpers/getDecodedText';

import styles from './condolences-list-item.module.scss';
import { ICondolencesListItemProps } from './condolences-list-types';
import { useCondolencesListItem } from './use-condolences-list-item';
import { SocialItem } from './components/social-item';
import { CondolenceCommentsList } from './components/condolence-comments-list';

export const CondolencesListItem: React.FC<ICondolencesListItemProps> = (
    props,
) => {
    const { getDaysAgo } = useDateFormatter();
    const { t } = useTranslation();

    const {
        condolence,
        condolenceMenuItems,
        isLikedByCurrentUser,
        handleOpenCondolenceModal,
        handleLikeOrUnlikeCondolence,
        onLikeCondolenceComment,
        onUnlikeCondolenceComment,
    } = useCondolencesListItem(props);

    const withBadge =
        condolence.candle ||
        condolence.heart ||
        condolence.orderSent ||
        condolence.donationSent;

    return (
        <div className={styles.Container}>
            <div className={styles.InnerContainer}>
                <ContextMenu
                    className={styles.ContextMenu}
                    items={condolenceMenuItems}
                />
                <div className={styles.AuthorBar}>
                    <span className={styles.Author}>
                        {decodeText(condolence.displayName)}
                    </span>
                    <span className={styles.CondolenceDate}>
                        {getDaysAgo(condolence.createdAt)}
                    </span>
                </div>
                <div className={styles.Badges}>
                    {condolence.candle && (
                        <span className={styles.CondolenceBadge}>
                            <CandleRoundedIcon
                                className={styles.CondolenceBadgeIcon}
                            />
                            {t('condolences.candleActionText')}
                        </span>
                    )}
                    {condolence.heart && (
                        <span className={styles.CondolenceBadge}>
                            <HeartRoundedIcon
                                className={styles.CondolenceBadgeIcon}
                            />
                            {t('condolences.heartActionText')}
                        </span>
                    )}
                    {condolence.orderSent && (
                        <span className={styles.CondolenceBadge}>
                            <FlowersRoundedIcon
                                className={styles.CondolenceBadgeIcon}
                            />
                            {t('condolences.flowersActionText')}
                        </span>
                    )}
                    {condolence.donationSent && (
                        <span className={styles.CondolenceBadge}>
                            <DonationRoundedIcon
                                className={styles.CondolenceBadgeIcon}
                            />
                            {t('condolences.donationActionText')}
                        </span>
                    )}
                </div>
                {!!condolence.mediaFiles?.length && (
                    <div
                        className={cx(
                            styles.GalleryContainer,
                            withBadge && styles.WithBadge,
                        )}
                    >
                        <div className={styles.GalleryInnerContainer}>
                            <ImageCarousel mediaFiles={condolence.mediaFiles} />
                        </div>
                    </div>
                )}
                {condolence.content && (
                    <div className={styles.Content}>
                        {getDecodedText(condolence.content)}
                    </div>
                )}
                <div className={styles.SocialBar}>
                    <SocialItem
                        like
                        label={t('condolences.like')}
                        selected={isLikedByCurrentUser}
                        icon={IIconType.Like}
                        count={condolence.likesCount}
                        onClick={handleLikeOrUnlikeCondolence}
                    />
                    <SocialItem
                        label={t('condolences.comment')}
                        icon={IIconType.Comment}
                        count={condolence.commentsCount}
                        onClick={handleOpenCondolenceModal}
                    />
                </div>
                <CondolenceCommentsList
                    condolence={condolence}
                    onLikeCondolenceComment={onLikeCondolenceComment}
                    onUnlikeCondolenceComment={onUnlikeCondolenceComment}
                />
            </div>
        </div>
    );
};
