import React, { HTMLAttributes, useEffect } from 'react';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';

import { ExamplesPicker } from '../../components/examples-picker/examples-picker';
import { TextArea } from '../../../components/text-area/text-area';

import { IBandsFormValues } from './bands-form-values';
import styles from './bands-form.module.scss';

interface IOuterProps {
    bandExamples: string[];
    prefilledValues?: Partial<IBandsFormValues>;
    onChange(values: IBandsFormValues): unknown;
}

interface IProps
    extends FormikProps<IBandsFormValues>,
        IOuterProps,
        Omit<HTMLAttributes<HTMLFormElement>, 'onChange'> {}

const Form = ({
    bandExamples,
    className,
    handleBlur,
    handleChange,
    handleSubmit,
    onChange,
    setFieldValue,
    values,
}: IProps) => {
    useEffect(() => {
        onChange(values);
    }, [values, onChange]);

    const { t } = useTranslation();

    const currentExample = find(bandExamples, values.leftBand);

    return (
        <form className={cx(className, styles.Form)} onSubmit={handleSubmit}>
            <TextArea
                name="leftBand"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('flowers.details.leftBandLabel')}
                rows={2}
                value={values.leftBand}
            />
            <TextArea
                name="rightBand"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('flowers.details.rightBandLabel')}
                rows={2}
                value={values.rightBand}
            />
            {bandExamples.length > 0 && (
                <div className={styles.FullRow}>
                    {t('flowers.details.leftBandHelperText')}
                </div>
            )}
            <ExamplesPicker
                className={styles.BandExamples}
                examples={bandExamples}
                onChange={(band: string) => {
                    setFieldValue('leftBand', band);
                }}
                selectedExample={currentExample}
            />
        </form>
    );
};

export const BandsForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IBandsFormValues>({
        handleSubmit() {},
        mapPropsToValues({ prefilledValues = {} }) {
            return {
                rightBand: prefilledValues.rightBand || '',
                leftBand: prefilledValues.leftBand || '',
            };
        },
    }),
)(Form);
