import { DinteroCheckoutOptions, redirect } from '@dintero/checkout-web-sdk';

import { IPaymentService, PaymentProvider } from '../payment.types';

export class DinteroService extends IPaymentService {
    constructor() {
        super(PaymentProvider.DINTERO);
    }

    redirectToCheckout(paymentSessionId: DinteroCheckoutOptions['sid']) {
        return redirect({ sid: paymentSessionId });
    }
}

export const dinteroService = new DinteroService();
