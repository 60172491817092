import { apiService } from '../../../api.service';
import { IFlowerShopResponseDto } from '../../../DTOs/fetch-flower-shop-response.dto';

export const fetchFlowerShop = ({
    slug,
}: {
    slug: string;
}): Promise<IFlowerShopResponseDto> =>
    apiService
        .get(`/public/memorial_pages/${slug}/flower_shops`)
        .then((resp) => resp.json());
