import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { Presets } from 'react-component-transition';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow-box.svg';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';

import styles from './collapse.module.scss';

type ToggleProps = {
    open: boolean;
    onToggle(): unknown;
};

type CollapseProps = {
    headerText: string;
    divider?: boolean;
    disableArrow?: boolean;
};

type Props = HTMLAttributes<HTMLDivElement> & CollapseProps;

export const Collapse = ({
    open,
    onToggle,
    headerText,
    children,
    className,
    divider = false,
    disableArrow = false,
}: Props & ToggleProps) => {
    const theme = useSelector(getTheme);

    return (
        <div
            className={cx(styles.Collapse, className, styles[theme], {
                [styles.Divider]: divider,
            })}
        >
            <div
                onClick={onToggle}
                className={cx(styles.Header, {
                    [styles.Hoverable]: !disableArrow && !open,
                })}
            >
                <span className={styles.HeaderText}>{headerText}</span>
                {!disableArrow && (
                    <ArrowIcon
                        className={cx(styles.Icon, {
                            [styles.IconOpen]: open,
                        })}
                    />
                )}
            </div>
            <Presets.TransitionExpandVertical>
                {open && <div className={styles.Content}>{children}</div>}
            </Presets.TransitionExpandVertical>
        </div>
    );
};
