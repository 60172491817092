import { IMilestoneResponseDto } from '../../../api/DTOs/milestone-response.dto';
import { IMilestone } from '../../../interfaces/milestone';
import { IMemorialPageDataPublicResponseDto } from '../../../api/DTOs/memorial-page-data-public-response.dto';

export const mapMemorialPageToMilestones = (
    memorialPageData: IMemorialPageDataPublicResponseDto,
): IMilestone[] => {
    const milestones = memorialPageData.included.filter(
        (inclusion) => inclusion.type === 'milestone',
    ) as IMilestoneResponseDto[];

    return milestones.map((milestone) => ({
        id: milestone.id,
        title: milestone.attributes.title,
        year: milestone.attributes.year,
        month: milestone.attributes.month,
        description: milestone.attributes.description,
    }));
};
