import * as yup from 'yup';
import parsePhoneNumberFromString from 'libphonenumber-js/max';

export const DeliveryRecipientFormValidation = () =>
    yup.object().shape({
        name: yup.string().required(),
        email: yup
            .string()
            .email()
            .required(),
        phone: yup
            .string()
            .required()
            .test({
                name: 'isValid',
                message: 'error.invalidPhone',
                test: (value: string | undefined) => {
                    if (!value) {
                        return false;
                    }
                    const parsedPhone = parsePhoneNumberFromString(value);
                    return parsedPhone?.isValid() || false;
                },
            }),
    });
