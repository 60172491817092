import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Section } from '../../../components/section/section';
import { SectionHeader } from '../../../components/section-header/section-header';
import { FlowerRow } from '../flower-row/flower-row';
import { featuredFlowerBuyRequested } from '../../../model/flowers/flowers.actions';
import { useFlowerDeliveryState } from '../../delivery/use-flower-delivery-state';
import { useDateFormatter } from '../../../utils/use-date-formatter';
import { RoutePaths } from '../../../routing/route-paths';
import { FlowerDeadlineType } from '../../../interfaces/flower-deadline';
import { ButtonLink } from '../../../components/button-link/button-link';
import { getPopularProductsDescription } from '../../../model/configuration/selectors/get-popular-products-description.selector';
import { getFlowerShopDeadlineSelector } from '../../../model/flower-shop/selectors/get-flower-shop-deadline.selector';
import { getFlowerShopPopularProductsSelector } from '../../../model/flower-shop/selectors/get-flower-shop-popular-products.selector';

import styles from './flowers-shortcut-display.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {};

export const FlowersShortcutDisplay = ({ className, ...props }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const products = useSelector(getFlowerShopPopularProductsSelector);
    const { ceremonyDeliveryAvailable } = useFlowerDeliveryState();
    const deadline = useSelector(getFlowerShopDeadlineSelector);
    const { getTextDateWithOffset } = useDateFormatter();
    const popularProductsDescription = useSelector(
        getPopularProductsDescription,
    );

    const showCeremonyDeliveryData =
        ceremonyDeliveryAvailable &&
        deadline !== FlowerDeadlineType.PASSED &&
        deadline !== FlowerDeadlineType.NONE;

    const onProductClicked = (id: string, categoryId: string) => {
        dispatch(
            featuredFlowerBuyRequested({
                id,
                categoryId,
            }),
        );
    };

    if (products.length === 0) {
        return null;
    }

    return (
        <Section
            className={className}
            headerText={showCeremonyDeliveryData ? '' : t('sendFlowers.title')}
            {...props}
        >
            {showCeremonyDeliveryData && (
                <div
                    className={styles.ToCeremonyContainer}
                    onClick={() => {
                        history.push(RoutePaths.FLOWERS_CATEGORY);
                    }}
                >
                    <SectionHeader>{t('sendFlowers.title')}</SectionHeader>
                    <div className={styles.ToCeremonyText}>
                        {t('sendFlowers.toCeremonyText', {
                            deadline:
                                deadline && getTextDateWithOffset(deadline),
                        })}
                        {!!popularProductsDescription &&
                            ` ${popularProductsDescription}`}
                    </div>
                </div>
            )}
            {products.map((flower) => (
                <FlowerRow
                    key={flower.id}
                    flower={flower}
                    onButtonClick={() => {
                        onProductClicked(flower.id, flower.categoryID);
                    }}
                />
            ))}
            <ButtonLink
                className={styles.ButtonLink}
                onClick={() => {
                    return history.push(RoutePaths.FLOWERS_CATEGORY);
                }}
            >
                {t('sendFlowers.moreFlowers')}
            </ButtonLink>
        </Section>
    );
};
