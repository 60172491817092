export enum FileMimeFormatForType {
    Image = 'image/png,image/jpeg,image/jpg',
    Video = 'video/mp4',
    PDF = 'application/pdf',
    ODT = 'application/vnd.oasis.opendocument.text',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    DOC = 'application/msword',
}

export interface IFileUploaderProps {
    fileType: FileMimeFormatForType[];
    label?: string;
    onChange(payload: File[]): void;
    maxFiles: number;
    onClick?: () => void;
    withPreview?: boolean;
    className?: string;
    openOnRender?: boolean;
    displayPreview?: boolean;
}
