import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Presets } from 'react-component-transition';

import { Section } from '../../components/section/section';
import { CondolencesList } from '../condolences-list/condolences-list';
import { ConnectedCondolenceForm } from '../condolence-form/condolence-form';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-icon.svg';
import { ReactComponent as CandleIcon } from '../../assets/icons/candle-icon.svg';
import { ReactComponent as PhotoIcon } from '../../assets/icons/photo-icon.svg';
import { useDeceasedPersonData } from '../../model/deceased-person/hooks/use-deceased-person-data';

import { useCondolencesDisplay } from './use-condolences-display';
import styles from './condolences-display.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {};

export const CondolencesDisplay = ({ className, ...props }: Props) => {
    const { t } = useTranslation();
    const { name } = useDeceasedPersonData();

    const {
        condolences,
        condolenceFormRef,
        editedCondolence,
        imagesEnabled,
        onSubmit,
        onUpdate,
        condolenceStatus,
        onEdit,
        onLikeCondolence,
        onUnlikeCondolence,
        onLikeCondolenceComment,
        onUnlikeCondolenceComment,
        condolenceFormState,
        handleCloseCondolencesForm,
        handleOpenCondolencesForm,
        handleOpenCondolencesFormWithLightedCandle,
        handleOpenCondolencesFormWithImageUploaderOpened,
    } = useCondolencesDisplay();

    const sectionClass = cx(
        styles.Section,
        { [styles.SectionEmpty]: condolences.length === 0 },
        className,
    );

    return (
        <Section
            className={sectionClass}
            headerClassName={styles.SectionHeader}
            headerText={t('condolences.title')}
            ref={condolenceFormRef}
            {...props}
        >
            <div className={styles.SubHeader}>
                {`${t('condolences.formSubHeader')} `}{' '}
                <span className={styles.DeceasedName}>{name}</span>
            </div>
            <Presets.TransitionFade>
                {condolenceFormState.isOpened ? (
                    <div
                        className={styles.CondolenceFormOverTheList}
                        key="condolences-form-container"
                    >
                        <ConnectedCondolenceForm
                            edit={Boolean(editedCondolence)}
                            editedCondolence={editedCondolence}
                            initialValues={editedCondolence}
                            onCreate={onSubmit}
                            onUpdate={onUpdate}
                            customError={condolenceStatus}
                            candle={condolenceFormState.withLightedCandle}
                            cancelForm={handleCloseCondolencesForm}
                            openImageUploaderOnRender={
                                condolenceFormState.withOpenedFileUploader
                            }
                        />
                    </div>
                ) : (
                    <div
                        className={styles.ButtonBox}
                        key="condolences-for-buttons"
                    >
                        <PrimaryButton
                            className={styles.CondolencesButton}
                            icon={EditIcon}
                            onClick={handleOpenCondolencesForm}
                        >
                            {t('condolences.openCondolencesFormButton')}
                        </PrimaryButton>
                        <PrimaryButton
                            className={styles.CondolencesButton}
                            icon={CandleIcon}
                            onClick={handleOpenCondolencesFormWithLightedCandle}
                        >
                            {t('condolenceForm.candleCheckboxLabel')}
                        </PrimaryButton>
                        {imagesEnabled && (
                            <PrimaryButton
                                className={styles.CondolencesButton}
                                icon={PhotoIcon}
                                onClick={
                                    handleOpenCondolencesFormWithImageUploaderOpened
                                }
                            >
                                {t('condolenceForm.uploadPhotoOrVideo')}
                            </PrimaryButton>
                        )}
                    </div>
                )}
            </Presets.TransitionFade>
            <CondolencesList
                onEdit={onEdit}
                onLikeCondolence={onLikeCondolence}
                onUnlikeCondolence={onUnlikeCondolence}
                onLikeCondolenceComment={onLikeCondolenceComment}
                onUnlikeCondolenceComment={onUnlikeCondolenceComment}
            />
        </Section>
    );
};
