import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';

import {
    invitationSessionReceived,
    invitationTokenDetected,
} from './invitation.actions';

export interface IInvitationStore {
    /**
     * If invitation was sent to an email, it should be available for us
     */
    invitationEmail: string | null;
    invitationToken: string | null;
}

const initialState: IInvitationStore = {
    invitationEmail: null,
    invitationToken: null,
};

export const invitationReducer = createReducer<IInvitationStore, RootActions>(
    initialState,
)
    .handleAction(invitationSessionReceived, (state, action) => ({
        ...state,
        invitationEmail: action.payload.email,
    }))
    .handleAction(invitationTokenDetected, (state, action) => ({
        ...state,
        invitationToken: action.payload.token,
    }));
