import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { ReactComponent as ShareButtonIcon } from '../../../assets/icons/share-arrow-icon.svg';
import { getTheme } from '../../../model/configuration/selectors/get-theme.selector';

import styles from './share-button.module.scss';

type Props = React.HTMLAttributes<HTMLButtonElement> & {};

export const ShareButton = ({ children, className, ...props }: Props) => {
    const theme = useSelector(getTheme);

    return (
        <button
            className={cx(styles.ShareButton, styles[theme], className)}
            {...props}
        >
            <ShareButtonIcon className={styles.Icon} />
            <span className={styles.SmallFacebookText}>{children}</span>
        </button>
    );
};
