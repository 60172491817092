export enum PaymentProvider {
    STRIPE = 'stripe',
    DINTERO = 'dintero',
}

export enum PaymentMethod {
    CREDIT_CARD = 'credit_card',
    // BLIK = 'blik',
    VIPPS = 'vipps',
    // OTHER = 'other',
}

export abstract class IPaymentService {
    protected constructor(readonly PROVIDER_NAME: PaymentProvider) {}

    abstract redirectToCheckout(paymentSessionId: string): void;
}
