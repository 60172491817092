import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import { handleEventPreventDefault } from '../../../shared/helpers/handleEventPreventDefault';

import styles from './button-link.module.scss';

type Props = HTMLAttributes<HTMLButtonElement> & { disabled?: boolean };

export const ButtonLink = ({
    className,
    disabled = false,
    ...props
}: Props) => {
    const theme = useSelector(getTheme);

    return (
        <button
            className={cx(className, styles.ButtonLink, styles[theme])}
            disabled={disabled}
            onMouseDown={handleEventPreventDefault}
            {...props}
        />
    );
};
