import { IViolationReportRequestDto } from '../../DTOs/violation-report-request.dto';

export const mapViolationsReportDtoToViolationReport = (
    dto: IViolationReportRequestDto,
) => {
    const violationReport: IViolationReportRequestDto = {
        violationReportableId: dto.violationReportableId,
        description: dto.description,
        violationReportableType: dto.violationReportableType,
    };

    return violationReport;
};
