import React from 'react';
import { useSelector } from 'react-redux';

import { Container } from '../../components/container/container';
import { ShareSection } from '../../sharing/share-section/share-section';
import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { TributeText } from '../../deceased-person/tribute-text/tribute-text';
import { getDeceasedTributeText } from '../../model/deceased-person/selectors/get-deceased-tribute.selector';

import styles from './share-page.module.scss';

export const SharePage = () => {
    const tribute = useSelector(getDeceasedTributeText);

    return (
        <>
            <DeceasedDisplay />
            <Container>
                {tribute && <TributeText content={tribute} />}
                <ShareSection className={styles.ShareUrlSection} />
            </Container>
        </>
    );
};
