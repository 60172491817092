import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { attendEventRequested } from '../events.actions';

import { getEventsDataFromMemorialPageFetchSaga } from './get-events-data-from-memorial-page-fetch.saga';
import { attendEventSaga } from './attend-event.saga';

export function* eventsSaga() {
    yield takeEvery(
        getType(fetchPageActions.success),
        getEventsDataFromMemorialPageFetchSaga,
    );
    yield takeEvery(getType(attendEventRequested), attendEventSaga);
}
