import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { CondolencesDisplay } from '../../condolences/condolences-display/condolences-display';
import { Container } from '../../components/container/container';
import { TributeText } from '../../deceased-person/tribute-text/tribute-text';
import { getDeceasedTributeText } from '../../model/deceased-person/selectors/get-deceased-tribute.selector';
import { Section } from '../../components/section/section';
import { EventsDisplay } from '../../events/events-display/events-display';
import { getEvents } from '../../model/events/selectors/get-events.selector';
import { FlowersShortcutDisplay } from '../../flowers/flowers-shortcut/flowers-shortcut-display/flowers-shortcut-display';
import { getHasUserAccess } from '../../model/user/selectors/get-has-user-access.selector';
import { getFlowerShopEnabled } from '../../model/configuration/selectors/get-flower-shop-enabled.selector';
import { TasksDisplay } from '../../tasks/tasks-display/tasks-display';
import { TasksSelectors } from '../../model/tasks/tasks.selector';
import { getCondolencesEnabled } from '../../model/configuration/selectors/get-condolences-enabled.selector';
import { getDeathNotices } from '../../model/death-notice/selectors/get-death-notices.selector';
import { getLeaflets } from '../../model/leaflets/selectors/get-leaflets.selector';
import { getCeremonyData } from '../../model/configuration/selectors/get-ceremony-data.selector';
import { getUserEmail } from '../../model/user/selectors/get-user-email';
import { getIsCeremonyEvent } from '../../model/events/selectors/get-is-ceremony-event.selector';
import { ObituaryText } from '../../deceased-person/obituary-text/obituary-text';
import { getDeceasedObituaryText } from '../../model/deceased-person/selectors/get-deceased-obituary.selector';
import { DonationShortcut } from '../../donation/shortcut/donation-shortcut';
import { getDonationEnabled } from '../../model/configuration/selectors/get-donation-enabled.selector';

import styles from './main-page.module.scss';

export const MainPage = () => {
    const tribute = useSelector(getDeceasedTributeText);
    const obituary = useSelector(getDeceasedObituaryText);
    const events = useSelector(getEvents);
    const hasAccessToThisMemorial = useSelector(getHasUserAccess);
    const tasks = useSelector(TasksSelectors.getTasks);
    const flowerShopEnabled = useSelector(getFlowerShopEnabled);
    const donationEnabled = useSelector(getDonationEnabled);
    const condolencesEnabled = useSelector(getCondolencesEnabled);
    const leaflets = useSelector(getLeaflets);
    const deathNotices = useSelector(getDeathNotices);
    const publicMessageForPrivateCeremony = useSelector(getCeremonyData);
    const ceremonyCreated = useSelector(getIsCeremonyEvent);
    const userEmail = useSelector(getUserEmail);

    if (!hasAccessToThisMemorial) {
        return null;
    }

    const isEventsSectionVisible =
        events.length > 0 ||
        deathNotices.length > 0 ||
        (publicMessageForPrivateCeremony &&
            publicMessageForPrivateCeremony.length > 0);
    const isTasksSectionVisible = tasks.length > 0;
    const isFlowersSectionVisible = !isTasksSectionVisible && flowerShopEnabled;
    const isDonationSectionVisible = donationEnabled;

    const isLeftSectionVisible = isEventsSectionVisible;
    const isRightSectionVisible =
        isTasksSectionVisible ||
        isFlowersSectionVisible ||
        isDonationSectionVisible;

    const isMainSectionVisible = isLeftSectionVisible || isRightSectionVisible;

    return (
        <>
            <DeceasedDisplay />
            <Container>
                {tribute && <TributeText content={tribute} />}
                {isMainSectionVisible && (
                    <div
                        className={cx(styles.Sections, {
                            [styles.LastSection]:
                                !obituary && !condolencesEnabled,
                            [styles.FirstSection]: !tribute,
                            [styles.OneSection]:
                                !isLeftSectionVisible || !isRightSectionVisible,
                        })}
                    >
                        {isLeftSectionVisible && (
                            <Section
                                className={cx(styles.LeftSection, {
                                    [styles.FullWidth]: !isRightSectionVisible,
                                })}
                            >
                                <EventsDisplay
                                    events={events}
                                    leaflets={leaflets}
                                    deathNotices={deathNotices}
                                    publicMessageForPrivateCeremony={
                                        publicMessageForPrivateCeremony
                                    }
                                    isUserLoggedIn={
                                        (userEmail && userEmail.length > 0) ||
                                        false
                                    }
                                    ceremonyCreated={ceremonyCreated}
                                    fullWidth={!isRightSectionVisible}
                                />
                            </Section>
                        )}
                        {isRightSectionVisible && (
                            <div
                                className={cx(styles.RightSection, {
                                    [styles.FullWidth]: !isLeftSectionVisible,
                                })}
                            >
                                {isTasksSectionVisible && (
                                    <TasksDisplay tasks={tasks} />
                                )}
                                {isFlowersSectionVisible && (
                                    <FlowersShortcutDisplay />
                                )}
                                {isDonationSectionVisible && (
                                    <DonationShortcut />
                                )}
                            </div>
                        )}
                    </div>
                )}
                {obituary && <ObituaryText content={obituary} />}
                {condolencesEnabled && (
                    <CondolencesDisplay className={styles.CondolencesDisplay} />
                )}
            </Container>
        </>
    );
};
