import { call, put, select } from '@redux-saga/core/effects';

import { downloadGalleryApiActions } from '../gallery-api.actions';
import { downloadGallery } from '../http/download-gallery';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';

export function* downloadGallerySaga() {
    const slug = yield select(getPageSlug);

    try {
        const url = yield call(downloadGallery, { slug });

        yield put(downloadGalleryApiActions.success({ url }));
    } catch (err) {
        yield put(downloadGalleryApiActions.failure(err.statusText));
    }
}
