import { put, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { setNewPasswordFormSubmitted } from '../model/forgotten-password.actions';
import { ForgottenPasswordActions } from '../../api/forgotten-password/forgotten-password-api.actions';
import { RoutePaths } from '../../routing/route-paths';

export function* handleSetNewPasswordFormSubmitSaga(
    action: ReturnType<typeof setNewPasswordFormSubmitted>,
) {
    yield put(
        ForgottenPasswordActions.setNew.request({
            token: action.payload.token,
            password: action.payload.password,
        }),
    );

    yield take(ForgottenPasswordActions.setNew.success);

    yield put(push(RoutePaths.HOME));

    /**
     * TODO: Handle error
     */
}
