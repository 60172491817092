import React from 'react';
import cx from 'classnames';

import styles from './section-header.module.scss';

type Props = React.HTMLAttributes<HTMLHeadingElement> & {};

export const SectionHeader = ({ children, className, ...props }: Props) => {
    return (
        <h2 className={cx(styles.SectionHeader, className)} {...props}>
            {children}
        </h2>
    );
};
