import { apiService } from '../../api.service';
import { IEventAttendanceData } from '../../../interfaces/event-attendance-data';

export const attendEvent = ({
    slug,
    data,
}: {
    slug: string;
    data: IEventAttendanceData;
}): Promise<Response> =>
    apiService
        .post(
            `/public/memorial_pages/${slug}/events/${data.eventId}/attendees`,
            {
                json: data.email && { email: data.email },
                throwHttpErrors: false,
            },
        )
        .then((resp) => resp.json());
