import { IVideoPlayerMaskProps } from './video-player-mask.types';

export const useVideoPlayerMask = ({ playerState }: IVideoPlayerMaskProps) => {
    const {
        isReady,
        isPlaybackStarted,
        isPlaying,
        isPaused,
        currentTime,
        isControlsActive,
        isFullscreenActive,
    } = playerState;

    const isLoading = isReady && isPlaybackStarted && !isPlaying && !isPaused;
    const isReadyToPlay = !isLoading && isReady && !isPlaying;
    const canBeReplayed = isReadyToPlay && currentTime === null;
    const canBePlayed = isReadyToPlay && currentTime !== null;

    const isPlayMaskVisible =
        canBePlayed && !isControlsActive && !isFullscreenActive;
    const isPausedMaskVisible =
        isPaused && !isControlsActive && !isFullscreenActive;
    const pausedMaskText = currentTime;
    const isReplayMaskVisible = canBeReplayed;

    return {
        isPlayMaskVisible,
        isReplayMaskVisible,
        isPausedMaskVisible,
        pausedMaskText,
    };
};
