import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

import styles from './fading-line.module.scss';

interface IProps extends HTMLAttributes<HTMLHRElement> {}

export const FadingLine = ({ className, ...props }: IProps) => (
    <hr className={cx(styles.Line, className)} {...props} />
);
