import { call, select, put } from '@redux-saga/core/effects';

import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { getCondolences } from '../http/get-condolences';
import { fetchCondolencesApiActions } from '../condolences-api.actions';
import { deserializeApiResponseSaga } from '../../json-api/saga/deserializeApiResponse.saga';
import { ICreatedCondolence } from '../../../interfaces/condolence';
import { condolencesDataReceived } from '../../../model/condolences/condolences.actions';

export function* getCondolencesApiSaga() {
    const slug = yield select(getPageSlug);

    const response = yield call(getCondolences, {
        slug,
    });

    if (!response.errors) {
        const deserializedBody: ICreatedCondolence[] = yield call(
            deserializeApiResponseSaga,
            response,
        );

        yield put(
            condolencesDataReceived({
                condolences: deserializedBody ?? [],
            }),
        );

        yield put(fetchCondolencesApiActions.success(response));
    } else {
        yield put(
            fetchCondolencesApiActions.failure({
                status: response.errors[0].title,
            }),
        );
    }
}
