import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import {
    Route,
    Switch,
    useRouteMatch,
    useLocation,
    useHistory,
} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { differenceInSeconds } from 'date-fns';

import { Container } from '../../components/container/container';
import { DeceasedDisplay } from '../../deceased-person/deceased-display/deceased-display';
import { Section } from '../../components/section/section';
import { RoutePaths } from '../../routing/route-paths';
import { FlowersCategory } from '../../flowers/category/flowers-category';
import { FlowersType } from '../../flowers/type/flowers-type';
import { FlowerDetails } from '../../flowers/details/flower-details';
import { FlowersSummary } from '../../flowers/summary/flowers-summary';
import { TributeText } from '../../deceased-person/tribute-text/tribute-text';
import { getDeceasedTributeText } from '../../model/deceased-person/selectors/get-deceased-tribute.selector';
import { ProgressSteps } from '../../components/progress-steps/progress-steps';
import { SectionHeader } from '../../components/section-header/section-header';
import { useRollbar } from '../../use-rollbar';
import { THEME } from '../../utils/get-theme';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import { DeadlineBar } from '../../flowers/components/deadline-bar/deadline-bar';
import { useFlowerDeliveryState } from '../../flowers/delivery/use-flower-delivery-state';
import { FlowerDeadlineType } from '../../interfaces/flower-deadline';
import { FlowersSelectors } from '../../model/flowers/flowers.selector';
import { NAV_HEIGHT, VG_NAV_HEIGHT } from '../../../shared/constants/layout';
import { getFlowerShopDeadlineSelector } from '../../model/flower-shop/selectors/get-flower-shop-deadline.selector';
import { FlowersFlowActions } from '../../model/flowers/flowers.actions';

import styles from './flowers-page.module.scss';

const ScrollToSection = ({
    refContainerY,
    children,
}: {
    refContainerY: number;
    children: React.ReactElement;
}) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, refContainerY);
    }, [location.pathname, refContainerY]);

    return children;
};

const resolveStepFromRoute = (route: RoutePaths) => {
    switch (route) {
        case RoutePaths.FLOWERS_CATEGORY:
            return 0;
        case RoutePaths.FLOWERS_TYPE:
            return 1;
        case RoutePaths.FLOWERS_DETAILS:
            return 2;
        case RoutePaths.FLOWERS_SUMMARY:
            return 3;
        default:
            return 0;
    }
};

const resolveHeadlines = (route: RoutePaths) => {
    switch (route) {
        case RoutePaths.FLOWERS_CATEGORY:
            return 'flowers.category.headline';
        case RoutePaths.FLOWERS_TYPE:
            return 'flowers.type.headline';
        case RoutePaths.FLOWERS_DETAILS:
            return 'flowers.details.headline';
        case RoutePaths.FLOWERS_SUMMARY:
            return 'flowers.summary.headline';
        default:
            return '';
    }
};

export const FlowersPage = () => {
    const tribute = useSelector(getDeceasedTributeText);
    const match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { rollbar } = useRollbar();
    const theme = useSelector(getTheme);
    const refContainer = useRef<HTMLDivElement>(null);
    const [refContainerY, setRefContainerY] = useState(0);
    const deadline = useSelector(getFlowerShopDeadlineSelector);
    const { ceremonyDeliveryAvailable } = useFlowerDeliveryState();
    const cartProducts = useSelector(FlowersSelectors.getCartProducts);

    const getSecondsToDeadline = () => {
        const diffTime = deadline
            ? differenceInSeconds(new Date(deadline), new Date())
            : 0;

        return diffTime;
    };

    const displayDeadlineBar =
        getSecondsToDeadline() <= 1800 &&
        ceremonyDeliveryAvailable &&
        deadline &&
        deadline !== FlowerDeadlineType.PASSED &&
        deadline !== FlowerDeadlineType.NONE;

    const step = resolveStepFromRoute(match!.path as RoutePaths);

    useEffect(() => {
        rollbar.info('User opens Flowershop');
    }, [rollbar]);

    /**
     * TODO: NAV_HEIGHT shouldn't be subtracted in mobile view
     */
    useLayoutEffect(() => {
        if (refContainer.current) {
            if (theme === THEME.VG) {
                setRefContainerY(
                    refContainer!.current!.offsetTop - VG_NAV_HEIGHT,
                );
            } else {
                setRefContainerY(refContainer!.current!.offsetTop - NAV_HEIGHT);
            }
        }
    }, [refContainer, theme]);

    const receivedPaymentSessionId = useSelector(
        FlowersSelectors.getReceivedPaymentSessionId,
    );
    const submittedOrderDto = useSelector(
        FlowersSelectors.getSubmittedOrderDto,
    );
    const flowersOrderLoading = useSelector(
        FlowersSelectors.getFlowersOrderLoading,
    );

    useEffect(() => {
        if (receivedPaymentSessionId || submittedOrderDto) {
            history.push(RoutePaths.FLOWERS_PAYMENT_FAILED);
        }
        if (flowersOrderLoading) {
            dispatch(FlowersFlowActions.paymentCancelled());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <>
            <DeceasedDisplay />
            <Container>
                {tribute && <TributeText content={tribute} />}
                <Section className={styles.FlowersContent} ref={refContainer}>
                    {displayDeadlineBar && (
                        <DeadlineBar startTime={getSecondsToDeadline()} />
                    )}
                    <span className={styles.StepHeadline}>
                        {t('flowerShop.step')} {step + 1}
                    </span>
                    <SectionHeader className={styles.Headline}>
                        {t(resolveHeadlines(match!.path as RoutePaths))}
                    </SectionHeader>
                    <ProgressSteps
                        className={styles.Progress}
                        activeStep={step}
                        stepsNo={4}
                        productsInBasketNo={cartProducts.length}
                        onSummaryStepClick={() => {
                            history.push(RoutePaths.FLOWERS_SUMMARY);
                        }}
                    />
                    <>
                        <ScrollToSection refContainerY={refContainerY}>
                            <Switch>
                                <Route
                                    component={FlowersCategory}
                                    path={RoutePaths.FLOWERS_CATEGORY}
                                />
                                <Route
                                    component={FlowersType}
                                    path={RoutePaths.FLOWERS_TYPE}
                                />
                                <Route
                                    component={FlowerDetails}
                                    path={RoutePaths.FLOWERS_DETAILS}
                                />
                                <Route
                                    component={FlowersSummary}
                                    path={RoutePaths.FLOWERS_SUMMARY}
                                />
                            </Switch>
                        </ScrollToSection>
                    </>
                </Section>
            </Container>
        </>
    );
};
