import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';
import { ITask } from '../../interfaces/task';
import {
    ITaskVolunteerStatus,
    TaskVolunteerStatusCode,
} from '../../interfaces/task-volunteer-status';
import i18n from '../../../setup/i18n';

import { tasksDataReceived, setTaskVolunteerStatus } from './tasks.actions';

export const TaskVolunteerStatusMessages = {
    [TaskVolunteerStatusCode.SUCCESS]: i18n.t(
        'eventAttendanceStatusMessages.success',
    ),
    [TaskVolunteerStatusCode.EMAIL_ALREADY_USED]: i18n.t(
        'eventAttendanceStatusMessages.emailAlreadyUsed',
    ),
    [TaskVolunteerStatusCode.ERROR]: i18n.t(
        'eventAttendanceStatusMessages.error',
    ),
};

export interface ITasksStore {
    taskVolunteerStatus: ITaskVolunteerStatus | null;
    tasks: ITask[];
}

const initialState: ITasksStore = {
    taskVolunteerStatus: null,
    tasks: [],
};

export const tasksReducer = createReducer<ITasksStore, RootActions>(
    initialState,
)
    .handleAction(tasksDataReceived, (state, action) => ({
        ...state,
        tasks: action.payload.tasks,
    }))
    .handleAction(setTaskVolunteerStatus, (state, action) => ({
        ...state,
        taskVolunteerStatus: action.payload,
    }));
