import { createReducer } from 'typesafe-actions';

import { RootActions } from '../../store/actions';
import { ISendDonationRequestDto } from '../../api/DTOs/send-donation-request.dto';

import { sendDonationActions } from './donation.actions';

export interface IDonationStore {
    loading: boolean;
    error: boolean;
    submittedDonationDto?: ISendDonationRequestDto | null;
}

const initialState: IDonationStore = {
    loading: false,
    error: false,
    submittedDonationDto: null,
};

export const donationReducer = createReducer<IDonationStore, RootActions>(
    initialState,
)
    .handleAction(sendDonationActions.request, (state, action) => {
        return {
            loading: true,
            error: false,
            submittedDonationDto: null,
        };
    })
    .handleAction(sendDonationActions.failure, (state, action) => {
        return {
            loading: false,
            error: action.payload,
            submittedDonationDto: null,
        };
    })
    .handleAction(sendDonationActions.success, (state, action) => {
        return {
            loading: false,
            error: false,
            submittedDonationDto: action.payload,
        };
    })
    .handleAction(sendDonationActions.cancel, () => {
        return initialState;
    });
