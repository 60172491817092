import { flatten, last } from 'lodash';

import { APPLICATION_ROUTES } from '../routing/routes';

interface IParsedLocation {
    slug: string;
    baseUrl: string;
}
const parseLocation = (windowLocation: Location): IParsedLocation => {
    const allApplicationRoutes = new Set(
        flatten(APPLICATION_ROUTES.ALL.map((route) => route.path))
            .map((route) => route && route.split('/').filter(Boolean)[0])
            .filter(Boolean)
            .map((route) => `/${route}`),
    );

    const urlWithoutParams = windowLocation
        .toString()
        .replace(windowLocation.search, '');

    let urlWithoutApplicationRoute = urlWithoutParams;
    allApplicationRoutes.forEach((route) => {
        const routeIndexInUrl = urlWithoutApplicationRoute.indexOf(route);
        if (routeIndexInUrl > -1) {
            urlWithoutApplicationRoute = urlWithoutParams.slice(
                0,
                routeIndexInUrl,
            );
        }
    });

    const slug = last(
        urlWithoutApplicationRoute.split('/').filter(Boolean),
    ) as string;

    const slugIndexInUrl = urlWithoutApplicationRoute.indexOf(slug);
    const baseUrl = urlWithoutApplicationRoute.slice(0, slugIndexInUrl);

    return {
        baseUrl,
        slug,
    };
};

export const getBaseUrlFromLocation = (windowLocation: Location): string => {
    const { baseUrl } = parseLocation(windowLocation);
    return baseUrl;
};

export const getSlugFromLocation = (windowLocation: Location): string => {
    const { slug } = parseLocation(windowLocation);
    return slug;
};
