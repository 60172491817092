import React from 'react';
import cx from 'classnames';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import styles from '../video-player-mask.module.scss';
import { IVideoPlayerCommonMaskProps } from '../video-player-mask.types';

export const VideoPlayerPausedMask = ({
    maskText,
}: IVideoPlayerCommonMaskProps) => {
    return (
        <div className={cx(styles.MaskContainer, styles.ReplayMaskContainer)}>
            <div className={styles.MaskInnerContainer}>
                <div className={styles.MaskIcon}>
                    <PlayCircleOutlineIcon />
                </div>
                {maskText && <div className={styles.MaskText}>{maskText}</div>}
            </div>
        </div>
    );
};
