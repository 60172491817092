import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';

import { getMilestonesDataFromMemorialPageFetchSaga } from './get-milestones-data-from-memorial-page-fetch.saga';

export function* milestonesSaga() {
    yield takeEvery(
        getType(fetchPageActions.success),
        getMilestonesDataFromMemorialPageFetchSaga,
    );
}
