import React from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../components/text-field/text-field';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { ErrorMessage } from '../../components/error-message/error-message';
import { ReactComponent as PublishIcon } from '../../assets/icons/publish-icon.svg';
import { IVideoStreamFormValues } from '../video-stream-form-values';
import { IVideoStreamRequestDto } from '../../api/DTOs/video-stream-request.dto';

import { videoStreamFormValidation } from './video-stream-form-validation';
import styles from './video-stream-form.module.scss';

type OuterProps = {
    onSend(values: IVideoStreamRequestDto): unknown;
};

type Props = FormikProps<IVideoStreamFormValues> & OuterProps;

export const VideoStreamForm = ({
    errors,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
}: Props) => {
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.FieldWrapper}>
                <TextField
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('videoStream.password')}
                    value={values.password}
                    type="password"
                />
                {errors.password && (
                    <ErrorMessage>{errors.password}</ErrorMessage>
                )}
            </div>
            <div className={styles.ButtonContainer}>
                <PrimaryButton
                    className={styles.PublishButton}
                    icon={PublishIcon}
                    type="submit"
                >
                    {t('videoStream.sendButton')}
                </PrimaryButton>
            </div>
        </form>
    );
};

export const ConnectedVideoStreamForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IVideoStreamFormValues>({
        handleSubmit(values, { props }) {
            props.onSend({
                password: values.password,
            });
        },
        mapPropsToValues() {
            return {
                password: '',
            };
        },
        validationSchema: videoStreamFormValidation,
        enableReinitialize: true,
    }),
)(VideoStreamForm);
