import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { getUserLoggedStatus } from '../../../../../../../model/user/selectors/get-user-logged-status.selector';
import { getCondolenceCommentLikeTokenByCondolenceCommentId } from '../../../../../../../model/condolences/selectors/get-condolence-comment-like-token-by-condolence-comment-id.selector';

import { ICondolenceCommentProps } from './condolence-comments-item-types';

export const useCondolenceCommentsItem = ({
    comment,
    onLikeCondolenceComment,
    onUnlikeCondolenceComment,
}: ICondolenceCommentProps) => {
    const logged = useSelector(getUserLoggedStatus);
    const condolenceCommentLikeToken = useSelector(
        getCondolenceCommentLikeTokenByCondolenceCommentId(comment.id),
    );

    const [likeRequestLoading, setLikeRequestLoading] = useState(false);

    useEffect(() => {
        setLikeRequestLoading(false);
    }, [comment.likesCount]);

    const isLikedByCurrentUser = logged
        ? comment.currentUserHasLiked
        : !!condolenceCommentLikeToken;

    const handleLikeOrUnlikeCondolenceComment = () => {
        if (!likeRequestLoading) {
            if (isLikedByCurrentUser) {
                onUnlikeCondolenceComment(comment);
            } else {
                onLikeCondolenceComment(comment);
            }
        }
        setLikeRequestLoading(true);
    };

    return {
        comment,
        isLikedByCurrentUser,
        handleLikeOrUnlikeCondolenceComment,
    };
};
