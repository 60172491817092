import React, { HTMLAttributes, useEffect } from 'react';
import cx from 'classnames';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../../components/text-field/text-field';
import { ErrorMessage } from '../../../components/error-message/error-message';
import { ICanBeControlled } from '../use-form-controller';

import { IDeliveryAddressFormValues } from './delivery-address-form-values';
import { DeliveryAddressFormValidation } from './delivery-address-form-validation';
import styles from './delivery-address-form.module.scss';

interface IOuterProps
    extends Omit<HTMLAttributes<HTMLFormElement>, 'onChange'>,
        ICanBeControlled<IDeliveryAddressFormValues> {
    preselectedValues?: IDeliveryAddressFormValues | null;
}

interface IProps extends IOuterProps, FormikProps<IDeliveryAddressFormValues> {}

const Form = ({
    handleBlur,
    handleSubmit,
    handleChange,
    values,
    onChange,
    errors,
    className,
    validateForm,
    isValid,
}: IProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        onChange(values, validateForm, isValid);
    }, [isValid, onChange, validateForm, values]);

    return (
        <form onSubmit={handleSubmit} className={cx(styles.Form, className)}>
            <div>
                <TextField
                    placeholder={t('flowers.delivery.addressForm.addressLabel')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    name="address"
                    data-testid="delivery-address-street"
                />
                {errors.address && (
                    <ErrorMessage>{errors.address}</ErrorMessage>
                )}
            </div>
            <div>
                <TextField
                    placeholder={t('flowers.delivery.addressForm.cityLabel')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city}
                    name="city"
                    data-testid="delivery-address-city"
                />
                {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
            </div>
            <div>
                <TextField
                    placeholder={t(
                        'flowers.delivery.addressForm.postalCodeLabel',
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.postalCode}
                    name="postalCode"
                    data-testid="delivery-address-postal"
                />
                {errors.postalCode && (
                    <ErrorMessage>{errors.postalCode}</ErrorMessage>
                )}
            </div>
        </form>
    );
};

export const DeliveryAddressForm = compose<IProps, IOuterProps>(
    withFormik<IOuterProps, IDeliveryAddressFormValues>({
        mapPropsToValues({ preselectedValues }) {
            if (preselectedValues) {
                return preselectedValues;
            }

            return { city: '', address: '', postalCode: '' };
        },
        validationSchema: DeliveryAddressFormValidation,
        validateOnMount: true,
        handleSubmit(values, bag) {},
    }),
)(Form);
