import { createAction } from 'typesafe-actions';

import { ILanguage } from '../../interfaces/language';
import { PrivacyMode } from '../../interfaces/privacy-mode';

export const languageChangeRequested = createAction(
    'model/configuration/LANGUAGE_CHANGE_REQUESTED',
    (payload: { language: ILanguage }) => payload,
)();

export const languageChanged = createAction(
    'model/configuration/LANGUAGE_CHANGED',
    (payload: { language: ILanguage }) => payload,
)();

export const privacyModeDetected = createAction(
    'model/configuration/PRIVACY_MODE_DETECTED',
    (payload: { privacy: PrivacyMode }) => payload,
)();
