/* eslint-disable no-unused-expressions */

import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';

import { getCondolences } from '../../model/condolences/selectors/get-condolences.selector';
import { IViolationReportRequestDto } from '../../api/DTOs/violation-report-request.dto';
import { violationReportFormSubmitted } from '../../model/violation-report/violation-report.actions';
import { IViolationReportModalModalRefProps } from '../../violation-reports/violation-report-modal';
import { IDeleteCondolenceModalRefProps } from '../delete-condolence-modal';
import { ICommentCondolenceModalRefProps } from '../comment-condolence-modal';

export const useCondolencesList = () => {
    const dispatch = useDispatch();

    const violationReportModalRef = useRef<IViolationReportModalModalRefProps>(
        null,
    );
    const deleteCondolenceModalRef = useRef<IDeleteCondolenceModalRefProps>(
        null,
    );
    const addCommendModalRef = useRef<ICommentCondolenceModalRefProps>(null);

    const condolences = useSelector(getCondolences);

    const handleSubmitViolationReport = (
        values: IViolationReportRequestDto,
    ) => {
        dispatch(violationReportFormSubmitted(values));
    };

    const handleOpenViolationModal = (violationId: string) => {
        if (violationReportModalRef.current) {
            violationReportModalRef.current?.openFor(violationId);
        }
    };

    const handleOpenDeleteCondolenceModal = (condolenceId: string) => {
        if (deleteCondolenceModalRef.current) {
            deleteCondolenceModalRef.current?.openFor(condolenceId);
        }
    };

    const handleOpenCommentCondolenceModal = (condolenceId: string) => {
        if (addCommendModalRef.current) {
            addCommendModalRef.current?.openFor(condolenceId);
        }
    };

    return {
        condolences,
        handleSubmitViolationReport,
        handleOpenViolationModal,
        handleOpenDeleteCondolenceModal,
        handleOpenCommentCondolenceModal,
        violationReportModalRef,
        deleteCondolenceModalRef,
        addCommendModalRef,
    };
};
