import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Section } from '../../components/section/section';
import { RoutePaths } from '../../routing/route-paths';
import { getDonationOrganizationName } from '../../model/configuration/selectors/get-donation-organization-name.selector';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { getDonationOrganizationLogo } from '../../model/configuration/selectors/get-donation-organization-logo.selector';
import { DonationIcon } from '../../assets/icons/donation-icon';
import { getDonationOrganizationDescription } from '../../model/configuration/selectors/get-donation-organization-description.selector';

import styles from './donation-shortcut.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {};

export const DonationShortcut = ({ className, ...props }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    const donationOrganizationName = useSelector(getDonationOrganizationName);
    const donationOrganizationDescription = useSelector(
        getDonationOrganizationDescription,
    );
    const donationOrganizationLogo = useSelector(getDonationOrganizationLogo);

    const redirectToDonationPage = () => {
        history.push(RoutePaths.DONATION_ROOT);
    };

    return (
        <Section
            className={className}
            headerText={t('donation.sendDonation')}
            {...props}
        >
            <div className={styles.Container}>
                <div className={styles.Header}>
                    <div>
                        <span className={styles.Info}>
                            {t('donation.info')}
                        </span>
                        <span className={styles.OrganizationName}>
                            {donationOrganizationName}
                        </span>
                    </div>
                    {donationOrganizationLogo && (
                        <img
                            className={styles.OrganizationLogo}
                            src={donationOrganizationLogo}
                            alt={donationOrganizationName || ''}
                        />
                    )}
                </div>
                {donationOrganizationDescription && (
                    <div className={styles.Body}>
                        {donationOrganizationDescription}
                    </div>
                )}
                <PrimaryButton
                    className={styles.Button}
                    icon={DonationIcon}
                    onClick={redirectToDonationPage}
                >
                    {t('donation.sendDonation')}
                </PrimaryButton>
            </div>
        </Section>
    );
};
