import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '../button/button';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-icon.svg';

import styles from './cancel-button.module.scss';

type Props = React.HTMLProps<HTMLButtonElement> & {
    icon?:
        | React.FC<React.SVGProps<SVGSVGElement>>
        | React.FC<React.HTMLAttributes<HTMLDivElement>>;
};

export const CancelButton = ({
    className,
    disabled,
    type,
    icon = TrashIcon,
    ...props
}: Props) => {
    const { t } = useTranslation();

    return (
        <Button
            className={cx(styles.PrimaryButton, className, {
                [styles.ButtonDisabled]: disabled,
            })}
            iconBoxClassName={cx(styles.PrimaryButtonIconBox)}
            icon={icon}
            {...props}
        >
            {t('common.cancel')}
        </Button>
    );
};
