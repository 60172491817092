import cx from 'classnames';
import React, {HTMLAttributes} from 'react';
import {FormikProps, withFormik} from 'formik';
import {compose} from 'recompose';
import {useTranslation} from 'react-i18next';

import {TextField} from '../../components/text-field/text-field';
import {PrimaryButton} from '../../components/primary-button/primary-button';
import {ReactComponent as ArrowIcon} from '../../assets/icons/icon-arrow-right.svg';
import {
    ErrorMessage,
    FieldErrorMessage,
} from '../../components/error-message/error-message';
import {IEventAttendanceFormValues} from '../event-attendance-form-values';
import {eventAttendanceValidation} from '../event-attendance-form-validation';
import {PhoneInput} from '../../components/phone-input/phone-input';
import {Checkbox} from '../../components/checkbox/checkbox';
import {Radio} from '../../components/radio/radio';
import {Spinner} from '../../components/spinner/spinner';

import styles from './event-attendance-form.module.scss';
import {TextArea} from "../../components/text-area/text-area";

type OuterProps = {
    onSubmit(values: IEventAttendanceFormValues): unknown;
    globalError?: string;
    loading: boolean;
    attendanceRegistrationRetentionPeriodInDays: number;
    foodCommentEnabled: boolean;
} & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

type Props = FormikProps<IEventAttendanceFormValues> & OuterProps;

export const EventAttendanceForm = ({
                                        className,
                                        handleChange,
                                        handleSubmit,
                                        values,
                                        errors,
                                        handleBlur,
                                        globalError,
                                        setFieldValue,
                                        dirty,
                                        isValid,
                                        loading,
                                        attendanceRegistrationRetentionPeriodInDays,
                                        foodCommentEnabled,
                                    }: Props) => {
    const {t} = useTranslation();
    const canSave = dirty && isValid && !loading;
    return (
        <form className={cx(className)} onSubmit={handleSubmit}>
            <div className={styles.InputsContainer}>
                <div className={styles.Control}>
                    <TextField
                        autoFocus
                        name="fullName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('eventAttendance.fullNameLabel')}
                        value={values.fullName}
                        error={Boolean(errors.fullName)}
                    />
                    <FieldErrorMessage name="fullName"/>
                </div>
                <div className={styles.Control}>
                    <TextField
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('eventAttendance.emailLabel')}
                        value={values.email}
                        error={Boolean(errors.email)}
                    />
                    <FieldErrorMessage name="email"/>
                </div>
                <div className={styles.Control}>
                    <PhoneInput
                        onBlur={handleBlur}
                        inputProps={{
                            placeholder: t('eventAttendance.phoneLabel'),
                        }}
                        onChange={(phone: string) => {
                            setFieldValue('phone', phone);
                        }}
                        value={values.phone}
                        name="phone"
                    />
                    {errors.phone && (
                        <ErrorMessage>{t('error.requiredField')}</ErrorMessage>
                    )}
                </div>
                {
                    foodCommentEnabled && <div className={styles.Control}>
                        <TextArea
                            name="foodComment"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('eventAttendance.foodCommentLabel')}
                            value={values.foodComment}
                        />
                    </div>
                }
                <div className={styles.Control}>
                    <div className={styles.RadioGroup}>
                        <div>
                            {t(
                                'eventAttendance.sharingPersonalInfoWithBereavedAcceptedLabel',
                            )}
                        </div>
                        <Radio
                            checked={
                                values.sharingPersonalInfoWithBereavedAccepted ===
                                true
                            }
                            className={styles.Radio}
                            label={t(
                                'eventAttendance.sharingPersonalInfoWithBereavedAcceptedYes',
                            )}
                            name="sharingPersonalInfoWithBereavedAccepted"
                            onChange={() => {
                                setFieldValue(
                                    'sharingPersonalInfoWithBereavedAccepted',
                                    true,
                                );
                            }}
                        />
                        <Radio
                            checked={
                                values.sharingPersonalInfoWithBereavedAccepted ===
                                false
                            }
                            className={styles.Radio}
                            label={t(
                                'eventAttendance.sharingPersonalInfoWithBereavedAcceptedNo',
                            )}
                            name="sharingPersonalInfoWithBereavedAccepted"
                            onChange={() => {
                                setFieldValue(
                                    'sharingPersonalInfoWithBereavedAccepted',
                                    false,
                                );
                            }}
                        />
                    </div>
                    {errors.sharingPersonalInfoWithBereavedAccepted &&
                        values.sharingPersonalInfoWithBereavedAccepted ===
                        null && (
                            <ErrorMessage>
                                {t('error.requiredField')}
                            </ErrorMessage>
                        )}
                </div>
                <div className={styles.Control}>
                    <Checkbox
                        checked={values.memcareNewsAccepted}
                        className={styles.Checkbox}
                        label={t('eventAttendance.memcareNewsAcceptedLabel')}
                        name="memcareNewsAccepted"
                        onChange={handleChange}
                    />
                </div>
                <div className={styles.Control}>
                    <Checkbox
                        checked={values.personalInformationProcessingAccepted}
                        className={styles.Checkbox}
                        label={t(
                            'eventAttendance.personalInformationProcessingAcceptedLabel',
                            {
                                days: attendanceRegistrationRetentionPeriodInDays,
                            },
                        )}
                        name="personalInformationProcessingAccepted"
                        onChange={handleChange}
                    />
                    {errors.personalInformationProcessingAccepted && (
                        <ErrorMessage>{t('error.requiredField')}</ErrorMessage>
                    )}
                </div>
                {globalError && <ErrorMessage>{globalError}</ErrorMessage>}
            </div>
            <PrimaryButton
                className={styles.SubmitButton}
                icon={loading ? Spinner : ArrowIcon}
                type="submit"
                disabled={!canSave}
            >
                {t('eventAttendance.submitButton')}
            </PrimaryButton>
        </form>
    );
};

export const ConnectedEventAttendanceForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IEventAttendanceFormValues>({
        mapPropsToValues() {
            return {
                email: '',
                fullName: '',
                phone: '',
                foodComment: '',
                sharingPersonalInfoWithBereavedAccepted: null,
                memcareNewsAccepted: false,
                personalInformationProcessingAccepted: false,
            };
        },
        handleSubmit(values, {props}) {
            props.onSubmit(values);
        },
        validationSchema: eventAttendanceValidation,
        enableReinitialize: true,
    }),
)(EventAttendanceForm);
