import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { userLogoutRequested } from '../user.actions';

import { getMemorialAccessFromMemorialPageFetchSaga } from './get-memorial-access-from-memorial-page-fetch.saga';
import { handleLogoutRequestedSaga } from './handle-logout-requested.saga';
import { getAdminStatusFromMemorialPageFetchSaga } from './get-admin-status-from-memorial-page-fetch.saga';

export function* userSaga() {
    yield takeEvery(
        getType(fetchPageActions.success),
        getMemorialAccessFromMemorialPageFetchSaga,
    );
    yield takeEvery(
        getType(fetchPageActions.success),
        getAdminStatusFromMemorialPageFetchSaga,
    );
    yield takeEvery(getType(userLogoutRequested), handleLogoutRequestedSaga);
}
