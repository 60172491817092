import { apiService } from '../../api.service';
import { ITaskVolunteerData } from '../../../interfaces/task-volunteer-data';

export const volunteerTask = ({
    slug,
    data,
}: {
    slug: string;
    data: ITaskVolunteerData;
}): Promise<Response> =>
    apiService
        .post(
            `/public/memorial_pages/${slug}/tasks/${data.taskId}/volunteers`,
            {
                json: data.email && { email: data.email },
                throwHttpErrors: false,
            },
        )
        .then((resp) => resp.json());
