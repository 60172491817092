import { delay, put, take } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';

import { contactSuggestionApiActions } from '../../../api/contact-suggestion/contact-suggestion.actions';
import {
    contactSuggestionFormSubmitted,
    contactSuggestionStatusChanged,
    contactSuggestionReset,
} from '../contact-suggestion.actions';
import { mapContactSuggestionFormToDto } from '../mappers/map-contact-suggestion-form-values-to-dto';
import { CreateContactSuggestionResult } from '../contact-suggestion.store';

export function* handleContactSuggestionFormSubmittedSaga(
    action: ReturnType<typeof contactSuggestionFormSubmitted>,
) {
    yield put(
        contactSuggestionApiActions.request(
            mapContactSuggestionFormToDto(action.payload),
        ),
    );

    const result = yield take([
        contactSuggestionApiActions.success,
        contactSuggestionApiActions.failure,
    ]);

    if (isActionOf(contactSuggestionApiActions.success, result)) {
        yield put(
            contactSuggestionStatusChanged(
                CreateContactSuggestionResult.SUCCESS,
            ),
        );

        yield delay(5000);

        yield put(contactSuggestionReset());
    } else if (isActionOf(contactSuggestionApiActions.failure, result)) {
        yield put(
            contactSuggestionStatusChanged(CreateContactSuggestionResult.ERROR),
        );

        yield delay(5000);

        yield put(contactSuggestionReset());
    }
}
