import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    invitationFormSubmitted,
    invitationTokenDetected,
    invitationTokenNotFound,
} from '../invitation.actions';
import { invitationCheckApiActions } from '../../../api/invitation-check/invitation-check-api.actions';

import { handleMissingInvitationTokenSaga } from './handle-missing-invitation-token.saga';
import { handleDetectedInvitationTokenSaga } from './handle-detected-invitation-token.saga';
import { handleInvitationValidateFailedSaga } from './handle-invitation-validate-failed.saga';
import { handleInvitationFormSubmission } from './handle-invitation-form-submission.saga';

export function* invitationSaga() {
    yield takeEvery(
        getType(invitationTokenNotFound),
        handleMissingInvitationTokenSaga,
    );

    yield takeEvery(
        getType(invitationTokenDetected),
        handleDetectedInvitationTokenSaga,
    );

    yield takeEvery(
        getType(invitationCheckApiActions.failure),
        handleInvitationValidateFailedSaga,
    );

    yield takeEvery(
        getType(invitationFormSubmitted),
        handleInvitationFormSubmission,
    );
}
