import React from 'react';
import cx from 'classnames';
import { uniqueId } from 'lodash';

import { IFlowerVariant } from '../../../interfaces/flower-variant';
import { roundPriceToString } from '../../../utils/set-price';

import styles from './variant-picker.module.scss';
import { useVariantPicker } from './use-variant-picker';

export interface IVariantPickerFlowerVariant extends IFlowerVariant {
    isMoreButton?: boolean;
}

export type VariantPickerProps = React.HTMLAttributes<HTMLDivElement> & {
    items: IVariantPickerFlowerVariant[];
    selected?: string;
    onSelectItem?(variant: IVariantPickerFlowerVariant): void;
    onHover?(variant: IVariantPickerFlowerVariant | null): void;
    oneColumnLayout?: boolean;
    rowsLimit?: number;
    columnsLimit?: number;
    itemsLimit?: number;
};

export const VariantPicker = (props: VariantPickerProps) => {
    const {
        items,
        containerStyles,
        onSelectItem,
        selected,
        onHover,
        oneColumnLayout,
    } = useVariantPicker(props);

    return (
        <div
            className={cx(
                styles.VariantPickerContainer,
                props.className,
                oneColumnLayout && styles.VariantPickerContainerOneColumnLayout,
            )}
            style={containerStyles}
        >
            {items.map((item) => (
                <div
                    className={cx(styles.VariantPickerItem, {
                        [styles.Selected]:
                            selected === item.id || item.isMoreButton,
                    })}
                    key={uniqueId('variant-picker')}
                    onClick={() => {
                        if (onSelectItem) {
                            onSelectItem(item);
                            if (onHover) {
                                onHover(null);
                            }
                        }
                    }}
                    onMouseEnter={() => {
                        if (onHover) {
                            onHover(item);
                        }
                    }}
                    onMouseLeave={() => {
                        if (onHover) {
                            onHover(null);
                        }
                    }}
                >
                    <span
                        className={cx(
                            styles.VariantPickerItemLabel,
                            styles.VariantPickerName,
                        )}
                    >
                        {item.label}
                    </span>
                    {!item.isMoreButton && (
                        <span className={styles.VariantPickerItemLabel}>
                            {`${roundPriceToString(item.grossPrice)} ${
                                item.currency
                            }`}
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
};
