import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DeceasedName } from '../deceased-name/deceased-name';
import { DeceasedAvatar } from '../deceased-avatar/deceased-avatar';
import { FadingLine } from '../../components/fading-line/fading-line';
import { Container } from '../../components/container/container';
import { useDeceasedPersonData } from '../../model/deceased-person/hooks/use-deceased-person-data';
import { useDeceasedDisplayImages } from '../../model/deceased-person/hooks/use-deceased-display-images';
import { DeceasedDateAndPlace } from '../deceased-date-and-place/deceased-date-and-place';
import { ComposedLanguageSelector } from '../../components/language-selector/language-selector';
import { ReactComponent as CandleBigIcon } from '../../assets/icons/candle-big-icon.svg';
import { getIsUserAdmin } from '../../model/user/selectors/get-is-user-admin';
import { getPageSlug } from '../../model/configuration/selectors/get-page-slug.selector';
import { getNotifyEditorUrl } from '../../utils/get-notify-editor-url';
import { getCompanyLogoOriginal } from '../../model/configuration/selectors/get-company-original-logo.selector';
import { getCompanyLogoThumb } from '../../model/configuration/selectors/get-company-thumb-logo.selector';
import { CompanyLogo } from '../../components/company-logo/company-logo';
import { getCompanyWebsiteUrl } from '../../model/configuration/selectors/get-company-website-url.selector';
import { checkAbsoluteUrl } from '../../utils/check-absolute-url';
import { getTheme } from '../../model/configuration/selectors/get-theme.selector';
import { getPublicUrl } from '../../utils/url-resolvers';
import { THEME, THEME_DETAILS } from '../../utils/get-theme';
import { ShareButtons } from '../../sharing/components/share-buttons/share-buttons';
import { getFuneralHomeBranchLogoOriginal } from '../../model/configuration/selectors/get-funeral-home-branch-original-logo.selector';
import { getFuneralHomeBranchLogoThumb } from '../../model/configuration/selectors/get-funeral-home-branch-thumb-logo.selector';
import { getFuneralHomeBranchWebsiteUrl } from '../../model/configuration/selectors/get-funeral-home-branch-website-url.selector';
import { LoginButton } from '../../components/login-button/login-button';

import styles from './deceased-display.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export const DeceasedDisplay = ({ className, ...props }: IProps) => {
    const { t } = useTranslation();
    const isAdmin = useSelector(getIsUserAdmin);
    const slug = useSelector(getPageSlug);
    const companyLogoOriginal = useSelector(getCompanyLogoOriginal);
    const companyLogoThumb = useSelector(getCompanyLogoThumb);
    const funeralHomeBranchLogoOriginal = useSelector(
        getFuneralHomeBranchLogoOriginal,
    );
    const funeralHomeBranchLogoThumb = useSelector(
        getFuneralHomeBranchLogoThumb,
    );
    const theme = useSelector(getTheme);
    let companyWebsiteUrl = useSelector(getCompanyWebsiteUrl);
    companyWebsiteUrl = companyWebsiteUrl
        ? checkAbsoluteUrl(companyWebsiteUrl)
        : companyWebsiteUrl;

    let funeralHomeBranchWebsiteUrl = useSelector(
        getFuneralHomeBranchWebsiteUrl,
    );
    funeralHomeBranchWebsiteUrl = funeralHomeBranchWebsiteUrl
        ? checkAbsoluteUrl(funeralHomeBranchWebsiteUrl)
        : funeralHomeBranchWebsiteUrl;

    const companyLogo = companyLogoOriginal || companyLogoThumb;
    const funeralHomeBranchLogo =
        funeralHomeBranchLogoOriginal || funeralHomeBranchLogoThumb;
    const usedCompanyLogo = funeralHomeBranchLogo ?? companyLogo;
    const usedCompanyWebsiteUrl = funeralHomeBranchLogo
        ? funeralHomeBranchWebsiteUrl
        : companyWebsiteUrl;
    const {
        name,
        deathDate,
        birthDate,
        deathPlace,
        birthPlace,
        birthPlaceVisible,
        deathPlaceVisible,
    } = useDeceasedPersonData();

    const { avatar, background } = useDeceasedDisplayImages();

    const renderContent = () => (
        <>
            {theme === THEME.VG && (
                <div className={styles.VGLogoContainer}>
                    <CompanyLogo
                        url={`${getPublicUrl(window.location.toString())}/${
                            THEME_DETAILS[theme as keyof typeof THEME_DETAILS]
                                .logo
                        }`}
                        href={
                            THEME_DETAILS[theme as keyof typeof THEME_DETAILS]
                                .link
                        }
                        className={styles.VGLogo}
                    />
                </div>
            )}
            <div
                className={cx(styles.ButtonsLanguageContainer, styles[theme], {
                    [styles.NoAdmin]: !isAdmin,
                })}
            >
                {isAdmin && (
                    <a
                        className={styles.EditMPButton}
                        href={getNotifyEditorUrl(slug)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className={styles.Desktop}>
                            {t('deceased.adminEditPage')}
                        </button>
                        <button className={styles.Mobile}>
                            {t('deceased.adminEditPageShort')}
                        </button>
                    </a>
                )}
                <div className={styles.ButtonsContainer}>
                    <LoginButton />
                    <ShareButtons />
                    <ComposedLanguageSelector mobile vg={theme === THEME.VG} />
                </div>
            </div>
            {usedCompanyLogo && (
                <div
                    className={cx({
                        [styles.CompanyContainer]: true,
                        [styles.Admin]: isAdmin,
                    })}
                >
                    <CompanyLogo
                        url={usedCompanyLogo}
                        href={usedCompanyWebsiteUrl}
                    />
                </div>
            )}
        </>
    );

    return (
        <div
            className={cx(styles.Container, className, styles[theme])}
            {...props}
        >
            <div className={styles.ContentContainer}>{renderContent()}</div>
            <div className={styles.ContentContainerMobile}>
                {renderContent()}
            </div>
            <div className={cx(styles.Background, styles[theme])}>
                <img alt="" src={background} />
            </div>
            <Container className={styles.Wrapper}>
                <div className={cx(styles.InnerContainer, styles[theme])}>
                    <FadingLine className={styles.TopLine} />
                    {avatar ? (
                        <DeceasedAvatar
                            className={styles.Avatar}
                            avatarSrc={avatar}
                        />
                    ) : (
                        <div
                            className={cx(
                                styles.Avatar,
                                styles.AvatarPlaceholder,
                            )}
                        >
                            <CandleBigIcon
                                className={styles.AvatarPlaceholderIcon}
                            />
                        </div>
                    )}
                    <DeceasedDateAndPlace
                        label={t('deceasedDisplay.birth')}
                        isoDate={birthDate}
                        place={birthPlace}
                        placeVisible={birthPlaceVisible}
                    />
                    <DeceasedDateAndPlace
                        label={t('deceasedDisplay.death')}
                        isoDate={deathDate}
                        place={deathPlace}
                        placeVisible={deathPlaceVisible}
                        death
                    />

                    <DeceasedName
                        name={name}
                        className={cx(styles.Name, styles[theme])}
                    />
                    <FadingLine className={styles.BottomLine} />
                </div>
            </Container>
        </div>
    );
};
