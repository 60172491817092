type Props = {
    url: string;
};

export const webApiSharing = async ({ url }: Props) => {
    const shareData = {
        url,
    };
    let error = '';
    try {
        await (window.navigator as any).share(shareData);
    } catch (err) {
        error = `Error: ${err}`;
    }

    return error;
};
