import { useSelector } from 'react-redux';
import { RouteProps } from 'react-router';
import { useMemo } from 'react';

import { getFlowerShopEnabled } from '../model/configuration/selectors/get-flower-shop-enabled.selector';
import { getDonationEnabled } from '../model/configuration/selectors/get-donation-enabled.selector';

import { RoutePaths } from './route-paths';
import { APPLICATION_ROUTES } from './routes';

interface IRoutesConfig {
    id: string;
    accessible: boolean;
    redirectToPathIfNotAccessible: string;
    rootPath?: string;
    defaultPath?: string;
    routes: RouteProps[];
}

export const useRoutesConfigs = () => {
    const flowerShopEnabled = useSelector(getFlowerShopEnabled);
    const donationEnabled = useSelector(getDonationEnabled);

    const commonRoutesConfig: IRoutesConfig = useMemo(
        () => ({
            id: 'common',
            accessible: true,
            redirectToPathIfNotAccessible: RoutePaths.HOME,
            routes: APPLICATION_ROUTES.COMMON,
        }),
        [],
    );

    const flowersRoutesConfig: IRoutesConfig = useMemo(
        () => ({
            id: 'flowers',
            accessible: flowerShopEnabled,
            redirectToPathIfNotAccessible: RoutePaths.HOME,
            rootPath: RoutePaths.FLOWERS_ROOT,
            defaultPath: RoutePaths.FLOWERS_CATEGORY,
            routes: APPLICATION_ROUTES.FLOWERS,
        }),
        [flowerShopEnabled],
    );

    const donationsRoutesConfig: IRoutesConfig = useMemo(
        () => ({
            id: 'model',
            accessible: donationEnabled,
            redirectToPathIfNotAccessible: RoutePaths.HOME,
            rootPath: RoutePaths.DONATION_ROOT,
            defaultPath: RoutePaths.DONATION_DETAILS,
            routes: APPLICATION_ROUTES.DONATIONS,
        }),
        [donationEnabled],
    );

    return useMemo(
        () => [flowersRoutesConfig, donationsRoutesConfig, commonRoutesConfig],
        [flowersRoutesConfig, donationsRoutesConfig, commonRoutesConfig],
    );
};
