import { call, put, select } from 'redux-saga/effects';
import { find } from 'lodash';

import { getPageSlug } from '../../../../model/configuration/selectors/get-page-slug.selector';
import { createOrderApiActions } from '../cart-api.actions';
import { createOrder } from '../http/create-order';
import { FlowersOrderStatus } from '../../../../model/flowers/flowers-order-status';

export function* createOrderApiSaga(
    action: ReturnType<typeof createOrderApiActions.request>,
) {
    const slug = yield select(getPageSlug);
    const resp = yield call(createOrder, {
        slug,
        dto: action.payload,
    });

    if (!resp.errors) {
        yield put(
            createOrderApiActions.success({
                ...action.payload,
                uuid: resp.data.attributes.uuid,
            }),
        );
    } else if (find(resp.errors, { code: 'invalid' })) {
        yield put(
            createOrderApiActions.failure({
                status: FlowersOrderStatus.INVALID_PHONE,
            }),
        );
    } else {
        yield put(
            createOrderApiActions.failure({
                status: resp.errors[0].title,
            }),
        );
    }
}
