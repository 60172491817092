import { call, put, select } from 'redux-saga/effects';

import { getPageSlug } from '../../../../model/configuration/selectors/get-page-slug.selector';
import { fetchCartCalculation } from '../http/fetch-cart-calculation';
import { fetchCartCalculationApiActions } from '../cart-api.actions';
import { IFetchFlowersCartCalculationResponseDto } from '../../../DTOs/fetch-flowers-cart-calculation-response.dto';

export function* fetchCartCalculationSaga(
    action: ReturnType<typeof fetchCartCalculationApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const resp: IFetchFlowersCartCalculationResponseDto = yield call(
            fetchCartCalculation,
            {
                slug,
                dto: action.payload,
            },
        );

        yield put(
            fetchCartCalculationApiActions.success({
                ...resp,
            }),
        );
    } catch (e) {
        yield put(fetchCartCalculationApiActions.failure(e.statusText));
    }
}
