import { put, take, delay, all, call } from 'redux-saga/effects';
import { isActionOf } from 'typesafe-actions';

import {
    condolenceFormSubmitted,
    condolenceCreated,
    setCondolenceStatus,
    setCondolenceError,
    condolenceEditClear,
} from '../condolences.actions';
import { imageUploadToS3Saga } from '../../../api/image-upload/saga/image-upload-to-s3.saga';
import { createCondolenceApiActions } from '../../../api/condolences/condolences-api.actions';
import { mapCondolenceFormValuesToDto } from '../mappers/map-condolence-form-values-to-dto';

export function* handleCondolenceFormSubmittedSaga(
    action: ReturnType<typeof condolenceFormSubmitted>,
) {
    const imagesToUpload =
        action.payload.images?.filter((image) => {
            return image.isNew;
        }) ?? [];

    const uploadedImages = yield all(
        imagesToUpload.map((image) => {
            return call(imageUploadToS3Saga, image.file as File);
        }),
    );

    yield put(
        createCondolenceApiActions.request(
            mapCondolenceFormValuesToDto(action.payload, uploadedImages),
        ),
    );

    const response = yield take([
        createCondolenceApiActions.success,
        createCondolenceApiActions.failure,
    ]);

    if (isActionOf(createCondolenceApiActions.success, response)) {
        yield put(condolenceCreated(response.payload));
        yield put(setCondolenceError(false));
        yield put(condolenceEditClear());
    } else {
        yield put(setCondolenceError(true));
        yield put(setCondolenceStatus(response.payload.status));
        yield delay(3000);
        yield put(setCondolenceStatus(null));
    }
}
