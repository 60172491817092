import { call, put, select } from 'redux-saga/effects';

import { getPageSlug } from '../../../../model/configuration/selectors/get-page-slug.selector';
import { createPaymentApiActions } from '../cart-api.actions';
import { createPayment } from '../http/create-payment';
import { ICreatePaymentResponseDto } from '../../../DTOs/create-payment-response.dto';

export function* createPaymentApiSaga(
    action: ReturnType<typeof createPaymentApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    try {
        const resp: ICreatePaymentResponseDto = yield call(createPayment, {
            slug,
            dto: action.payload,
        });

        yield put(createPaymentApiActions.success(resp));
    } catch (e) {
        yield put(createPaymentApiActions.failure(e.statusText));
    }
}
