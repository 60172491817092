import { IVideoPlayerProps } from './video-player.types';
import { useVideoPlayerHandlers } from './use-video-player-handlers';

export const useVideoPlayer = ({ playerOptions, src }: IVideoPlayerProps) => {
    const { playerRef, playerHandlers, playerState } = useVideoPlayerHandlers({
        playerOptions,
        src,
    });

    return {
        playerRef,
        playerState,
        playerHandlers,
    };
};
