import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { RoutePaths } from '../../routing/route-paths';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet-icon.svg';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { Section } from '../../components/section/section';
import { FlowersSelectors } from '../../model/flowers/flowers.selector';
import { FlowersFlowActions } from '../../model/flowers/flowers.actions';
import { Spinner } from '../../components/spinner/spinner';
import { PaymentService } from '../../api/payment';

import styles from './payment-failed.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {};

/**
 * TODO: Get real errors from stripe or backend and map them to proper translations
 */
export const PaymentFailed = ({ className, ...props }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const flowerOrderLoading = useSelector(
        FlowersSelectors.getFlowersOrderLoading,
    );
    const receivedPaymentSessionIdStore = useSelector(
        FlowersSelectors.getReceivedPaymentSessionId,
    );
    const [receivedPaymentSessionIdState] = useState(
        receivedPaymentSessionIdStore,
    );

    const submittedOrderDtoStore = useSelector(
        FlowersSelectors.getSubmittedOrderDto,
    );
    const [submittedOrderDtoState] = useState(submittedOrderDtoStore);

    const redirectToHomePage = () => {
        history.push(RoutePaths.HOME);
    };

    const tryAgain = () => {
        if (receivedPaymentSessionIdState) {
            const paymentService = PaymentService.initByPaymentMethod(
                submittedOrderDtoState?.paymentMethod,
            );
            paymentService.redirectToCheckout(receivedPaymentSessionIdState);
        } else {
            redirectToHomePage();
        }
    };

    useEffect(() => {
        if (!receivedPaymentSessionIdState) {
            redirectToHomePage();
        }
        return () => {
            dispatch(FlowersFlowActions.orderConfirmationClosed());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <>
            <Prompt
                when={!!receivedPaymentSessionIdState}
                message={t('donation.paymentFailed.exitConfirmation')}
            />
            <Section className={className} {...props}>
                <div className={styles.Container}>
                    <h1 className={styles.Headline}>
                        {t('flowers.paymentFailed.headline')}
                    </h1>
                    <h2 className={styles.ErrorMessage}>
                        {t('flowers.paymentFailed.errorMessage')}
                    </h2>
                    <PrimaryButton
                        className={styles.Button}
                        icon={flowerOrderLoading ? Spinner : WalletIcon}
                        onClick={tryAgain}
                        disabled={flowerOrderLoading}
                    >
                        {t('flowers.paymentFailed.actionButton')}
                    </PrimaryButton>
                </div>
            </Section>
        </>
    );
};
