import {
    IFlowerShopProductDataDto,
    IFlowerShopResponseDto,
} from '../../../DTOs/fetch-flower-shop-response.dto';
import { IFlowerShop } from '../../../../interfaces/flower-shop';
import { IFlowerCategory } from '../../../../interfaces/flower-category';

export const mapFlowerShopDtoToFlowerShop = (
    dto: IFlowerShopResponseDto,
): IFlowerShop => {
    const parsedFlowerShopProductsData: IFlowerShopProductDataDto = JSON.parse(
        dto.data.attributes.productsData,
    );
    const {
        gross_band_price: grossBandPrice,
        gross_card_price: grossCardPrice,
        product_data: productsData,
        currency,
    } = parsedFlowerShopProductsData;

    const categories: IFlowerCategory[] = dto.included
        .filter((included) => included.type === 'category')
        .map((category) => {
            const allProductsInCategory = productsData.filter(
                (productData) =>
                    String(productData.category_id) === String(category.id),
            );
            const firstProductWithPhotoInCategory = allProductsInCategory.find(
                (productData) => !!productData.photo,
            );
            const allProductPrices = allProductsInCategory
                .map((product) =>
                    product.variants.map((variant) => variant.gross_price),
                )
                .flat();
            const priceRange: IFlowerCategory['priceRange'] = {
                minPrice: Math.min(...allProductPrices),
                maxPrice: Math.max(...allProductPrices),
            };
            return {
                id: String(category.id),
                name: category.attributes.name,
                priceRange,
                currency,
                photo: firstProductWithPhotoInCategory?.photo,
            };
        });

    const products: IFlowerShop['products'] = productsData.map(
        (productData) => ({
            id: productData.id,
            name: productData.name,
            description:
                productData.flower_shop_description ||
                productData.description ||
                null,
            categoryID: String(productData.category_id),
            category: categories.find(
                (category) =>
                    String(category.id) === String(productData.category_id),
            )!,
            grossBandPrice,
            grossCardPrice,
            photo: productData.photo,
            variants: productData.variants
                .map((variant) => ({
                    id: String(variant.id),
                    label: variant.name,
                    grossPrice: variant.gross_price,
                    isPopular: variant.is_popular,
                    currency,
                    minimumDeliveryLeadTimeInHours:
                        variant.minimum_delivery_lead_time_in_hours,
                    photo: variant.photo,
                }))
                .sort((a, b) => a.grossPrice - b.grossPrice),
        }),
    );

    const flowerShop: IFlowerShop = {
        products,
        deadline: dto.data.attributes.deadline,
        customDeadline: dto.data.attributes.customDeadline,
        bandExamples: dto.data.attributes.exampleBandsTexts,
        categories,
    };

    return flowerShop;
};
