import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { getFlowerDeliveryTypesApiActions } from '../flowers-delivery-api.actions';

import { getFlowersDeliveryTypesApiSaga } from './get-flowers-delivery-types-api.saga';

export function* flowersDeliveryApiSaga() {
    yield takeEvery(
        getType(getFlowerDeliveryTypesApiActions.request),
        getFlowersDeliveryTypesApiSaga,
    );
}
