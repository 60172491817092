import { mapS3ResponseToMediaFileAttributeDto } from '../../../../shared/helpers/mapS3ResponseToMediaFileAttributeDto';
import { IDonationDetailsFormValues } from '../../../donation/details/form/donation-details-form';
import { ISendDonationRequestDto } from '../../../api/DTOs/send-donation-request.dto';

export const mapDonationDetailsFormValuesToDto = (
    formValues: IDonationDetailsFormValues,
    uploadedImages?: string[],
): ISendDonationRequestDto => {
    const condolence: ISendDonationRequestDto['condolence'] = formValues.condolence
        ? {
              displayName: formValues.condolence.displayName,
              candle: formValues.condolence.candle,
              heart: formValues.condolence.heart,
              content: formValues.condolence.content,
              mediaFilesAttributes: mapS3ResponseToMediaFileAttributeDto(
                  uploadedImages,
              ),
          }
        : undefined;

    return {
        giverName: formValues.name,
        giverPhone: formValues.phone,
        giverEmail: formValues.email,
        amount: formValues.amount!,
        shareWithFh: formValues.share,
        personalId: formValues.personalId || undefined,
        condolence,
    };
};
