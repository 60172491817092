import React from 'react';
import { useTranslation } from 'react-i18next';

import { Section } from '../../components/section/section';
import { TimelineEvent } from '../timeline-event/timeline-event';
import { IMilestone } from '../../interfaces/milestone';

import styles from './timeline-display.module.scss';

type Props = React.HTMLAttributes<HTMLDivElement> & {
    events: IMilestone[];
};

export const TimelineDisplay = React.forwardRef(
    (
        { className, events, ...props }: Props,
        ref: React.Ref<HTMLDivElement>,
    ) => {
        const { t } = useTranslation();

        return (
            <Section
                className={className}
                headerText={t('timeline.title')}
                ref={ref}
                {...props}
            >
                <div className={styles.Timeline}>
                    {events.map((event, index) => (
                        <TimelineEvent
                            key={event.id}
                            event={event}
                            last={events.length === index + 1}
                        />
                    ))}
                </div>
            </Section>
        );
    },
);
