import { call, put, select } from '@redux-saga/core/effects';

import { deleteCondolenceApiActions } from '../condolences-api.actions';
import { deleteCondolence } from '../http/delete-condolence';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';

export function* deleteCondolenceApiSaga(
    action: ReturnType<typeof deleteCondolenceApiActions.request>,
) {
    const { id } = action.payload;
    const slug = yield select(getPageSlug);

    try {
        yield call(deleteCondolence, { id, slug });

        yield put(deleteCondolenceApiActions.success());
    } catch (err) {
        yield put(deleteCondolenceApiActions.failure(err.statusText));
    }
}
