import { call, put, select } from '@redux-saga/core/effects';

import { sendResetPasswordMail } from '../http/send-password-reset-mail';
import { ForgottenPasswordActions } from '../forgotten-password-api.actions';
import { ITokensResponseDto } from '../../DTOs/tokens-response.dto';
import { RoutePaths } from '../../../routing/route-paths';
import { getMemorialPageUrl } from '../../../model/configuration/selectors/get-memorial-page-url.selector';

export function* sendPasswordResetEmailApiSaga(
    action: ReturnType<typeof ForgottenPasswordActions.resetEmail.request>,
) {
    const memorialPageUrl = yield select(getMemorialPageUrl);

    const redirectUrl = `${memorialPageUrl}${RoutePaths.SET_NEW_PASSWORD}?token={{token}}`;

    try {
        const resp: ITokensResponseDto = yield call(sendResetPasswordMail, {
            email: action.payload.email,
            redirectUrl,
        });

        yield put(ForgottenPasswordActions.resetEmail.success(resp));
    } catch (e) {
        yield put(ForgottenPasswordActions.resetEmail.failure(e.statusText));
    }
}
