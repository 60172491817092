import { SagaMiddleware } from 'redux-saga';

import { configurationSaga } from '../model/configuration/saga/configuration.saga';
import { deceasedPersonSaga } from '../model/deceased-person/saga/deceased-person.saga';
import { milestonesSaga } from '../model/milestones/saga/milestones.saga';
import { eventsSaga } from '../model/events/saga/events.saga';
import { gallerySaga } from '../model/gallery/saga/gallery.saga';
import { sharingSaga } from '../sharing/model/saga/sharing.saga';
import { apiSaga } from '../api/api.saga';
import { loginSaga } from '../login/model/saga/login.saga';
import { invitationSaga } from '../invitation/model/saga/invitation.saga';
import { invitationCheckApiSaga } from '../api/invitation-check/saga/invitation-check.saga';
import { condolencesSaga } from '../model/condolences/saga/condolences.saga';
import { forgottenPasswordSaga } from '../forgotten-password/saga/forgotten-password.saga';
import { contactSuggestionSaga } from '../contact-suggestion/model/saga/contact-suggestion.saga';
import { userSaga } from '../model/user/saga/user.saga';
import { accessRequestSaga } from '../access-request/model/saga/access-request.saga';
import { tasksSaga } from '../model/tasks/saga/tasks.saga';
import { flowersFlowSaga } from '../model/flowers/saga/flowers-flow.saga';
import { deathNoticesSaga } from '../model/death-notice/saga/death-notices.saga';
import { leafletsSaga } from '../model/leaflets/saga/leaflets.saga';
import { violationReportSaga } from '../model/violation-report/saga/violation-report.saga';
import { eventAttendanceSaga } from '../event-attendance/model/saga/event-attendance.saga';
import { videoStreamSaga } from '../model/video-stream/saga/video-stream.saga';
import { donationSaga } from '../model/donation/saga/donation.saga';

export const runSagas = (middleware: SagaMiddleware) => {
    middleware.run(configurationSaga);
    middleware.run(deceasedPersonSaga);
    middleware.run(eventsSaga);
    middleware.run(milestonesSaga);
    middleware.run(gallerySaga);
    middleware.run(sharingSaga);
    middleware.run(apiSaga);
    middleware.run(loginSaga);
    middleware.run(invitationSaga);
    middleware.run(invitationCheckApiSaga);
    middleware.run(condolencesSaga);
    middleware.run(donationSaga);
    middleware.run(forgottenPasswordSaga);
    middleware.run(contactSuggestionSaga);
    middleware.run(userSaga);
    middleware.run(accessRequestSaga);
    middleware.run(tasksSaga);
    middleware.run(flowersFlowSaga);
    middleware.run(deathNoticesSaga);
    middleware.run(leafletsSaga);
    middleware.run(violationReportSaga);
    middleware.run(eventAttendanceSaga);
    middleware.run(videoStreamSaga);
};
