import React from 'react';

import { useImageContainerMask } from './use-image-container-mask';
import { IImageContainerMaskProps } from './image-container-mask.types';
import { ImageContainerErrorMask } from './components/image-container-error-mask';
import { ImageContainerLoadingMask } from './components/image-container-loading-mask';

export const ImageContainerMask = (props: IImageContainerMaskProps) => {
    const {
        isErrorMaskVisible,
        isLoadingMaskVisible,
        useLightMode,
    } = useImageContainerMask(props);

    if (isErrorMaskVisible) {
        return <ImageContainerErrorMask useLightMode={useLightMode} />;
    }
    if (isLoadingMaskVisible) {
        return <ImageContainerLoadingMask useLightMode={useLightMode} />;
    }

    return null;
};
