import { createSelector } from 'reselect';

import { RootStore } from '../../store/store';
import { IFlowerSummaryEntry } from '../../interfaces/flower-summary-entry';
import {
    FlowerDeliveryType,
    IFlowerDeliveryType,
} from '../../interfaces/flower-delivery-type';
import { getUserLoggedStatus } from '../user/selectors/get-user-logged-status.selector';
import { getFlowerShopProductsSelector } from '../flower-shop/selectors/get-flower-shop-products.selector';
import { getInitiallySelectedVariant } from '../../flowers/helpers/flower-helpers';
import { ICreateCondolenceDto } from '../../api/DTOs/create-condolence.dto';
import { PaymentMethod } from '../../api/payment/payment.types';

import { IFlowerInOrder, IUserOrderStore } from './flowers-order.store';

const getOrderStore = (state: RootStore) => state.flowers.order;
const getFlowerDeliveryStore = (state: RootStore) => state.flowers.delivery;

const getFlowerDeliveryTypes = createSelector(
    getFlowerDeliveryStore,
    (store) => store.deliveryTypes,
);

const getSelectedDeliveryType = createSelector(
    getOrderStore,
    (store: IUserOrderStore): FlowerDeliveryType | null => store.deliveryType,
);

const getPaymentMethod = createSelector(
    getOrderStore,
    (store: IUserOrderStore): PaymentMethod => store.paymentMethod,
);

const getProcessedProduct = createSelector(
    getOrderStore,
    (store) => store.currentProcessedFlower,
);

const getCartProducts = createSelector(
    getOrderStore,
    (store: IUserOrderStore) => Object.values(store.cartFlowersByID),
);

const getCartSummary = createSelector(
    getOrderStore,
    (store: IUserOrderStore) => store.summary,
);

const getFlowerOrderCondolence = createSelector(
    getOrderStore,
    (store: IUserOrderStore): ICreateCondolenceDto | null =>
        store.flowerOrderCondolence,
);

const getProductsInSummary = createSelector(
    getCartProducts,
    getFlowerShopProductsSelector,
    (cartProducts, flowerShopProducts): IFlowerSummaryEntry[] => {
        const productsInSummary: IFlowerSummaryEntry[] = [];
        cartProducts.forEach((item: IFlowerInOrder) => {
            const {
                id,
                rightBandText,
                leftBandText,
                cardText,
                quantity,
                variantID,
                productID,
                addon,
            } = item;
            const product = flowerShopProducts.find(
                (flowerShopProduct) => flowerShopProduct.id === productID,
            );

            if (product) {
                const {
                    variants,
                    name,
                    grossCardPrice,
                    grossBandPrice,
                    photo,
                } = product;

                const selectedVariant =
                    variants.find((v) => v.id === variantID) ||
                    getInitiallySelectedVariant(variants);

                productsInSummary.push({
                    id,
                    name,
                    variants,
                    cardText,
                    leftBandText,
                    rightBandText,
                    selectedVariant,
                    quantity,
                    selectedAddon: addon,
                    grossCardPrice,
                    grossBandPrice,
                    photo,
                });
            }
        });
        return productsInSummary;
    },
);

const makeGetProductFromCart = (localID: string) =>
    createSelector(
        getOrderStore,
        (store: IUserOrderStore) => store.cartFlowersByID[localID],
    );

const getAddressData = createSelector(
    getOrderStore,
    (order: IUserOrderStore) => order.deliveryAddress,
);

const getContactData = createSelector(
    getOrderStore,
    (order: IUserOrderStore) => order.contactData,
);

const getRecipientData = createSelector(
    getOrderStore,
    (order: IUserOrderStore) => order.recipientData,
);

const getFlowersOrderLoading = createSelector(
    getOrderStore,
    (order: IUserOrderStore) => order.loading,
);

const getReceivedPaymentSessionId = createSelector(
    getOrderStore,
    (order: IUserOrderStore) => order.receivedPaymentSessionId,
);

const getSubmittedOrderDto = createSelector(
    getOrderStore,
    (order: IUserOrderStore) => order.submittedOrderDto,
);

/**
 * Check if all conditions are passing because its possible to access cart summary
 * without filling delivery.
 * By checking this we can redirect to delivery step to fill it
 */
const getIsDeliveryDataFilled = createSelector(
    getFlowerDeliveryTypes,
    getSelectedDeliveryType,
    getAddressData,
    getContactData,
    getRecipientData,
    getUserLoggedStatus,
    (deliveries, delivery, addressData, contactData, recipientData, logged) => {
        if (!logged && !contactData) {
            return false;
        }

        if (!delivery) {
            return false;
        }

        const selectedDelivery: IFlowerDeliveryType = deliveries!.find(
            (d: IFlowerDeliveryType) => d.name === delivery,
        )!;

        const { recipientRequired, locationRequired } = selectedDelivery;

        if (recipientRequired && !recipientData) {
            return false;
        }

        if (locationRequired && !addressData) {
            return false;
        }

        return true;
    },
);

const getBandPrice = createSelector(
    getOrderStore,
    (order: IUserOrderStore): number => order.bandPrice,
);

export const FlowersSelectors = {
    getFlowerDeliveryTypes,
    makeGetProductFromCart,
    getProcessedProduct,
    getProductsInSummary,
    getAddressData,
    getCartProducts,
    getSelectedDeliveryType,
    getCartSummary,
    getFlowerOrderCondolence,
    getContactData,
    getRecipientData,
    getFlowersOrderLoading,
    getIsDeliveryDataFilled,
    getBandPrice,
    getReceivedPaymentSessionId,
    getSubmittedOrderDto,
    getPaymentMethod,
};
