import { call, put } from 'redux-saga/effects';
import { find } from 'lodash';

import { sendAccessRequestApiActions } from '../request-access-api.actions';
import { submitAccessRequest } from '../http/request-access';
import { AccessRequestStatus } from '../../../access-request/access-request-status';

export function* submitAccessRequestApiSaga(
    action: ReturnType<typeof sendAccessRequestApiActions.request>,
) {
    const resp = yield call(submitAccessRequest, action.payload);

    if (!resp.errors) {
        yield put(
            sendAccessRequestApiActions.success(AccessRequestStatus.SUCCESS),
        );
    } else if (find(resp.errors, { code: 'is_taken' })) {
        yield put(
            sendAccessRequestApiActions.failure(
                AccessRequestStatus.EMAIL_TAKEN,
            ),
        );
    } else {
        yield put(
            sendAccessRequestApiActions.failure(AccessRequestStatus.ERROR),
        );
    }
}
