import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

import { Icon } from '../icon/icon';

import { IIconButtonWithTooltipProps } from './icon-button-with-tooltip.types';

export const IconButtonWithTooltip: React.FC<IIconButtonWithTooltipProps> = ({
    onClick,
    tooltipText,
    iconName,
}) => {
    return (
        <Tooltip title={tooltipText}>
            <IconButton onClick={onClick} size="small">
                <Icon type={iconName} />
            </IconButton>
        </Tooltip>
    );
};
