import { createAsyncAction } from 'typesafe-actions';

import { IEventAttendanceRequestDto } from '../DTOs/event-attendance-request.dto';
import { IEventAttendanceResponseDto } from '../DTOs/event-attendance-response.dto';

export const sendEventAttendanceApiActions = createAsyncAction(
    'api/event-attendance/REQUESTED',
    'api/event-attendance/SUCCEEDED',
    'api/event-attendance/FAILED',
)<IEventAttendanceRequestDto, IEventAttendanceResponseDto, string>();
