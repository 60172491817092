import { getType } from 'typesafe-actions';
import { takeEvery } from '@redux-saga/core/effects';

import {
    createCondolenceApiActions,
    updateCondolenceApiActions,
    deleteCondolenceApiActions,
    fetchCondolencesApiActions,
    addCondolenceCommentApiActions,
    likeCondolenceApiActions,
    unlikeCondolenceApiActions,
    likeCondolenceCommentApiActions,
    unlikeCondolenceCommentApiActions,
} from '../condolences-api.actions';

import { createCondolenceApiSaga } from './create-condolence-api.saga';
import { updateCondolenceApiSaga } from './update-condolence-api.saga';
import { deleteCondolenceApiSaga } from './delete-condolence-api.saga';
import { addCondolenceCommentApiSaga } from './add-condolence-comment-api.saga';
import { getCondolencesApiSaga } from './get-condolences-api.saga';
import { likeCondolenceApiSaga } from './like-condolence-api.saga';
import { unlikeCondolenceApiSaga } from './unlike-condolence-api.saga';
import { likeCondolenceCommentApiSaga } from './like-condolence-comment-api.saga';
import { unlikeCondolenceCommentApiSaga } from './unlike-condolence-comment-api.saga';

export function* condolencesApiSaga() {
    yield takeEvery(
        getType(createCondolenceApiActions.request),
        createCondolenceApiSaga,
    );
    yield takeEvery(
        getType(updateCondolenceApiActions.request),
        updateCondolenceApiSaga,
    );
    yield takeEvery(
        getType(deleteCondolenceApiActions.request),
        deleteCondolenceApiSaga,
    );
    yield takeEvery(
        getType(fetchCondolencesApiActions.request),
        getCondolencesApiSaga,
    );
    yield takeEvery(
        getType(addCondolenceCommentApiActions.request),
        addCondolenceCommentApiSaga,
    );
    yield takeEvery(
        getType(updateCondolenceApiActions.success),
        getCondolencesApiSaga,
    );
    yield takeEvery(
        getType(likeCondolenceApiActions.request),
        likeCondolenceApiSaga,
    );
    yield takeEvery(
        getType(unlikeCondolenceApiActions.request),
        unlikeCondolenceApiSaga,
    );
    yield takeEvery(
        getType(likeCondolenceCommentApiActions.request),
        likeCondolenceCommentApiSaga,
    );
    yield takeEvery(
        getType(unlikeCondolenceCommentApiActions.request),
        unlikeCondolenceCommentApiSaga,
    );
}
