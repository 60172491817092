import { useSelector } from 'react-redux';
import { format, formatDistance } from 'date-fns';

import { getLanguage } from '../model/configuration/selectors/get-language.selector';
import { getDateLocale } from '../../setup/get-date-locale';

import { LanguageCode } from './get-language-codes';
import { getTimeLanguagePrefix } from './get-time-language-prefix';

export const useDateFormatter = () => {
    /**
     * Use locale selector to listen on change and rerender
     */
    const lang = useSelector(getLanguage);

    const removeOffsetFromDate = (date: string) => {
        return date.split('+')[0].trim();
    };

    const parseDateString = (date: string) => {
        const [year, month, day] = date.split('-');
        const now = new Date();
        now.setFullYear(
            parseInt(year, 10),
            parseInt(month, 10) - 1,
            parseInt(day, 10),
        );
        return now;
    };

    return {
        getLongDate(date: string) {
            let formatPattern = 'H:mm, dd.LL.yyyy';

            if (lang.code === LanguageCode.EN) {
                formatPattern = 'h:mm a, dd.LL.yyyy';
            }

            return format(new Date(removeOffsetFromDate(date)), formatPattern, {
                locale: getDateLocale(lang.code),
            });
        },
        getShortDate(date: string) {
            const dateObj = parseDateString(date);

            return format(dateObj, 'd.LL.yyyy', {
                locale: getDateLocale(lang.code),
            });
        },
        getDaysAgo(date: string) {
            return formatDistance(new Date(date), new Date(), {
                addSuffix: true,
                locale: getDateLocale(lang.code),
            });
        },
        getShortDateNo(date: string) {
            const dateObj = parseDateString(date);

            return format(dateObj, 'd. MMM yyyy', {
                locale: getDateLocale(lang.code),
            });
        },
        getLongDateNo(date: string) {
            const dateObj = parseDateString(date);

            return format(dateObj, 'd. MMMM yyyy', {
                locale: getDateLocale(lang.code),
            });
        },
        getTextDate(date: string) {
            let formatDatePattern = 'EEEE d. MMMM';
            let formatHourPattern = 'H:mm';

            if (lang.code === LanguageCode.EN) {
                formatDatePattern = 'EEEE d. MMMM';
                formatHourPattern = 'h:mm a';
            }

            return `${format(
                new Date(removeOffsetFromDate(date)),
                formatDatePattern,
                {
                    locale: getDateLocale(lang.code),
                },
            )}${getTimeLanguagePrefix(lang.code)}${format(
                new Date(removeOffsetFromDate(date)),
                formatHourPattern,
                {
                    locale: getDateLocale(lang.code),
                },
            )}`;
        },
        getTextDateWithOffset(date: string) {
            let formatDatePattern = 'EEEE d. MMMM';
            let formatHourPattern = 'H:mm';

            if (lang.code === LanguageCode.EN) {
                formatDatePattern = 'EEEE d. MMMM';
                formatHourPattern = 'h:mm a';
            }

            return `${format(new Date(date), formatDatePattern, {
                locale: getDateLocale(lang.code),
            })}${getTimeLanguagePrefix(lang.code)}${format(
                new Date(date),
                formatHourPattern,
                {
                    locale: getDateLocale(lang.code),
                },
            )}`;
        },
        getMonthYearDate(year: number, month: number | null) {
            const dateObj = new Date();
            if (month) {
                dateObj.setFullYear(year, month - 1);
            } else {
                dateObj.setFullYear(year);
            }

            if (month) {
                return format(dateObj, 'LL.yyyy', {
                    locale: getDateLocale(lang.code),
                });
            }
            return format(dateObj, 'yyyy', {
                locale: getDateLocale(lang.code),
            });
        },
        checkIfDateIsAfterNow(date: string) {
            return Date.now() > new Date(date).valueOf();
        },
    };
};
