import { LoginErrorCode } from '../api/DTOs/login-errors.dto';
import i18n from '../../setup/i18n';

export const getLoginApiErrorMessage = (code: LoginErrorCode) => {
    switch (code) {
        case LoginErrorCode.INVALID_CREDENTIALS:
            return i18n.t('loginError.invalidCredentials');
        case LoginErrorCode.USER_DISABLED:
            return i18n.t('loginError.userDisabled');
        default:
        case LoginErrorCode.UNKNOWN:
            return i18n.t('loginError.unknown');
    }
};
