import { call, put, select } from '@redux-saga/core/effects';

import { sendVideoStreamApiActions } from '../video-stream-api.actions';
import { sendVideoStream } from '../http/send-video-stream';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { VideoStreamError } from '../../../model/video-stream/video-stream.store';

export function* sendVideoStreamApiSaga(
    action: ReturnType<typeof sendVideoStreamApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    const response = yield call(sendVideoStream, {
        slug,
        dto: action.payload,
    });

    if (!response.errors) {
        yield put(sendVideoStreamApiActions.success(response.data));
    } else {
        yield put(
            sendVideoStreamApiActions.failure(
                VideoStreamError.INVALID_PASSWORD,
            ),
        );
    }
}
