import React from 'react';
import { useSelector } from 'react-redux';

import { getDeceasedTributeText } from '../../../model/deceased-person/selectors/get-deceased-tribute.selector';
import { DeceasedDisplay } from '../../../deceased-person/deceased-display/deceased-display';
import { TributeText } from '../../../deceased-person/tribute-text/tribute-text';
import { DonationConfirmation } from '../../../donation/confirmation/donation-confirmation';
import { Container } from '../../../components/container/container';

export const DonationConfirmationPage = () => {
    const tribute = useSelector(getDeceasedTributeText);

    return (
        <>
            <DeceasedDisplay />
            <Container>
                {tribute && <TributeText content={tribute} />}
                <DonationConfirmation />
            </Container>
        </>
    );
};
