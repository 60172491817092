import { getType, isActionOf } from 'typesafe-actions';
import {
    all,
    call,
    delay,
    put,
    select,
    take,
    takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ShortUniqueId from 'short-unique-id';

import {
    featuredFlowerBuyRequested,
    FlowersFlowActions,
} from '../flowers.actions';
import { RoutePaths } from '../../../routing/route-paths';
import { FlowersSelectors } from '../flowers.selector';
import { IFlowerInOrder } from '../flowers-order.store';
import {
    createOrderApiActions,
    createPaymentApiActions,
    fetchCartCalculationApiActions,
} from '../../../api/flowers/cart/cart-api.actions';
import { ICreateOrderRequestDto } from '../../../api/DTOs/create-order-request.dto';
import { IFlower } from '../../../interfaces/flower';
import {
    FlowerDeliveryType,
    IFlowerDeliveryType,
} from '../../../interfaces/flower-delivery-type';
import { FlowerDeadlineType } from '../../../interfaces/flower-deadline';
import { FlowersOrderStatus } from '../flowers-order-status';
import { getMemorialPageUrl } from '../../configuration/selectors/get-memorial-page-url.selector';
import { getFlowerShopProductSelector } from '../../flower-shop/selectors/get-flower-shop-product.selector';
import { getFlowerShopDeadlineSelector } from '../../flower-shop/selectors/get-flower-shop-deadline.selector';
import { getFlowerShopProductPopularVariantsSelector } from '../../flower-shop/selectors/get-flower-shop-product-popular-variants.selector';
import { getInitiallySelectedVariant } from '../../../flowers/helpers/flower-helpers';
import { fetchFlowerShopApiActions } from '../../../api/flowers/flower-shop/flower-shop-api.actions';
import { ICreateCondolenceDto } from '../../../api/DTOs/create-condolence.dto';
import { imageUploadToS3Saga } from '../../../api/image-upload/saga/image-upload-to-s3.saga';
import { mapCondolenceFormValuesToDto } from '../../condolences/mappers/map-condolence-form-values-to-dto';
import { PaymentService } from '../../../api/payment';
import { checkIfDeliveryRecipientIsRequired } from '../../../../shared/helpers/checkIfDeliveryRecipientIsRequired';
import { checkIfDeliveryAddressIsRequired } from '../../../../shared/helpers/checkIfDeliveryAddressIsRequired';
import { getEvents } from '../../events/selectors/get-events.selector';
import { EventType } from '../../../interfaces/event-type';

const uuid = new ShortUniqueId();

function* handleDeliveryChosenSaga(
    action: ReturnType<typeof FlowersFlowActions.deliveryChosen>,
) {
    const { meta } = action.payload;

    if (meta.isEdited) {
        yield put(push(RoutePaths.FLOWERS_SUMMARY));
        return;
    }

    if (!meta.isFilledInSummary) {
        yield put(push(RoutePaths.FLOWERS_CATEGORY));
    }
}

function* handleFlowerCategoryChosenSaga(
    action: ReturnType<typeof FlowersFlowActions.categoryChosen>,
) {
    const id = action.payload.categoryID;

    yield put(push(`${RoutePaths.FLOWERS_TYPE}?category=${id}`));
}

function* handleFlowerTypeChosenSaga(
    action: ReturnType<typeof FlowersFlowActions.productChosen>,
) {
    const { productID, variantID } = action.payload;

    const localID = uuid.randomUUID(8);

    yield put(
        FlowersFlowActions.localProductCreated({
            productID,
            localID,
            variantID,
        }),
    );

    yield put(push(RoutePaths.FLOWERS_DETAILS));
}

function* handleDetailsSetSaga() {
    /**
     * Always redirect to summary with possibility to edit delivery type there
     */
    yield put(push(RoutePaths.FLOWERS_SUMMARY));
}

function* handleEditProductRequested(
    action: ReturnType<typeof FlowersFlowActions.productEditRequested>,
) {
    const { localID } = action.payload;
    const productInSummary: IFlowerInOrder = yield select(
        FlowersSelectors.makeGetProductFromCart(localID),
    );

    yield put(FlowersFlowActions.productEditPrepared(productInSummary));

    yield put(push(RoutePaths.FLOWERS_DETAILS));
}

function* handleCartChanges() {
    const cartData: IFlowerInOrder[] = yield select(
        FlowersSelectors.getCartProducts,
    );

    if (!cartData.length) {
        yield put(FlowersFlowActions.lastProductInCartRemoved());
        return;
    }

    yield put(
        fetchCartCalculationApiActions.request({
            lineItems: cartData.map((item) => {
                return {
                    cardText: item.cardText || undefined,
                    bandLeftText: item.leftBandText || undefined,
                    bandRightText: item.rightBandText || undefined,
                    productVariantId: item.variantID,
                    quantity: item.quantity,
                };
            }),
        }),
    );
}

function* handleDeliveryEditRequest() {
    yield put(
        push(RoutePaths.FLOWERS_DELIVERY, {
            isEdited: true,
        }),
    );
}

function* handleOrderSubmitted() {
    yield put(fetchFlowerShopApiActions.request());
    yield take(fetchFlowerShopApiActions.success);

    const deadline = yield select(getFlowerShopDeadlineSelector);

    const deliveryName: FlowerDeliveryType = yield select(
        FlowersSelectors.getSelectedDeliveryType,
    );
    const recipientData = yield select(FlowersSelectors.getRecipientData);
    const contactData = yield select(FlowersSelectors.getContactData);

    const isDeliveryToCeremony = deliveryName === FlowerDeliveryType.CEREMONY;
    if (isDeliveryToCeremony && deadline === FlowerDeadlineType.PASSED) {
        yield put(
            FlowersFlowActions.deliveryChosen(
                {
                    deliveryType: null,
                    address: undefined,
                    recipientData: recipientData || undefined,
                    contactData: contactData || undefined,
                },
                {
                    isEdited: false,
                    isFilledInSummary: true,
                },
            ),
        );
        yield put(
            FlowersFlowActions.setFlowersOrderStatus(
                FlowersOrderStatus.DEADLINE_PASSED,
            ),
        );
        yield delay(5000);
        yield put(FlowersFlowActions.setFlowersOrderStatus(null));
    } else {
        const productsInCard: IFlowerInOrder[] = yield select(
            FlowersSelectors.getCartProducts,
        );

        const deliveriesTypes: IFlowerDeliveryType[] = yield select(
            FlowersSelectors.getFlowerDeliveryTypes,
        );

        const events = yield select(getEvents);
        const ceremony = events.find(
            (event) => event.type === EventType.CEREMONY,
        );

        const addressData = yield select(FlowersSelectors.getAddressData);

        const isDeliveryRecipientDataRequired = checkIfDeliveryRecipientIsRequired(
            deliveriesTypes,
            deliveryName,
        );

        const isDeliveryAddressDataRequired = checkIfDeliveryAddressIsRequired(
            deliveriesTypes,
            deliveryName,
        );

        const getLocationData = () => {
            if (isDeliveryAddressDataRequired && addressData) {
                return {
                    city: addressData.city,
                    street: addressData.address,
                    zipCode: addressData.postalCode,
                };
            }

            if (isDeliveryToCeremony && ceremony?.location) {
                return {
                    ...ceremony.location,
                    name: ceremony.title,
                };
            }

            return undefined;
        };

        const flowerOrderCondolence = yield select(
            FlowersSelectors.getFlowerOrderCondolence,
        );

        const paymentMethod = yield select(FlowersSelectors.getPaymentMethod);

        let condolenceDto: ICreateCondolenceDto | undefined;

        if (flowerOrderCondolence) {
            const imagesToUpload =
                flowerOrderCondolence.images?.filter((image) => {
                    return image.isNew;
                }) ?? [];

            const uploadedImages = yield all(
                imagesToUpload.map((image) => {
                    return call(imageUploadToS3Saga, image.file as File);
                }),
            );

            condolenceDto = mapCondolenceFormValuesToDto(
                flowerOrderCondolence,
                uploadedImages,
            );
        }

        const orderDto: ICreateOrderRequestDto = {
            lineItems: productsInCard.map((product) => ({
                quantity: product.quantity,
                productVariantId: product.variantID,
                bandRightText: product.rightBandText || undefined,
                bandLeftText: product.leftBandText || undefined,
                cardText: product.cardText || undefined,
            })),
            delivery: {
                deliveryTypeId: deliveriesTypes.find(
                    (deliveryObj) => deliveryObj.name === deliveryName,
                )!.id,
                deliveryTypeName: deliveryName,
            },
            location: getLocationData(),
            profile: {
                displayName: contactData.name,
                email: contactData.email,
                phone: contactData.phone,
            },
            recipient:
                isDeliveryRecipientDataRequired && recipientData
                    ? {
                          email: recipientData.email,
                          fullName: recipientData.name,
                          phone: recipientData.phone || undefined,
                      }
                    : undefined,
            condolence: condolenceDto,
            paymentMethod,
        };

        yield put(createOrderApiActions.request(orderDto));

        const orderResp = yield take([
            createOrderApiActions.success,
            createOrderApiActions.failure,
        ]);

        if (isActionOf(createOrderApiActions.success, orderResp)) {
            const memorialPageUrl = yield select(getMemorialPageUrl);
            const paymentService = PaymentService.initByPaymentMethod(
                orderDto.paymentMethod,
            );

            yield put(
                createPaymentApiActions.request({
                    uuid: orderResp.payload.uuid,
                    cancelUrl: `${memorialPageUrl}${RoutePaths.FLOWERS_PAYMENT_FAILED}`,
                    successUrl: `${memorialPageUrl}${RoutePaths.FLOWERS_CONFIRMATION}`,
                    provider: paymentService.PROVIDER_NAME,
                }),
            );

            const paymentResp: ReturnType<typeof createPaymentApiActions.success> = yield take(
                createPaymentApiActions.success,
            );

            const result = yield call(
                {
                    context: paymentService,
                    fn: paymentService.redirectToCheckout,
                },
                paymentResp.payload.sessionId,
            );

            if (result.error) {
                /**
                 * TODO:
                 *   - Check what possible errors are there
                 *   - Send our own generic errors for now
                 */
                const errorCode = (result.error.code as string) || 'TODO code';
                const errorMessage = result.error.message as string;

                yield put(
                    FlowersFlowActions.paymentFailed({
                        errorCode,
                        errorMessage,
                    }),
                );
                yield put(push(RoutePaths.FLOWERS_PAYMENT_FAILED));
            }
        } else {
            yield put(
                FlowersFlowActions.setFlowersOrderStatus(
                    orderResp.payload.status,
                ),
            );
            yield delay(3000);
            yield put(FlowersFlowActions.setFlowersOrderStatus(null));
        }
    }
}

function* handleFeaturedFlowerBuyRequested(
    action: ReturnType<typeof featuredFlowerBuyRequested>,
) {
    const productID = action.payload.id;

    const relatedProductData: IFlower = yield select(
        getFlowerShopProductSelector(productID),
    );

    const FeaturedProducts = yield select(
        getFlowerShopProductPopularVariantsSelector(productID),
    );

    const productVariantID =
        relatedProductData.variants.find(
            (v) => v.id === FeaturedProducts[productID],
        )?.id || getInitiallySelectedVariant(relatedProductData.variants).id;

    yield put(
        FlowersFlowActions.productChosen({
            productID,
            variantID: productVariantID,
        }),
    );
}

export function* flowersFlowSaga() {
    yield takeEvery(
        getType(FlowersFlowActions.deliveryChosen),
        handleDeliveryChosenSaga,
    );

    yield takeEvery(
        getType(FlowersFlowActions.categoryChosen),
        handleFlowerCategoryChosenSaga,
    );

    yield takeEvery(
        getType(FlowersFlowActions.productChosen),
        handleFlowerTypeChosenSaga,
    );

    yield takeEvery(
        getType(FlowersFlowActions.detailsSet),
        handleDetailsSetSaga,
    );

    yield takeEvery(
        getType(FlowersFlowActions.productEditRequested),
        handleEditProductRequested,
    );

    yield takeEvery(
        getType(FlowersFlowActions.cartCalculationRequested),
        handleCartChanges,
    );

    yield takeEvery(
        getType(FlowersFlowActions.deliveryEditRequested),
        handleDeliveryEditRequest,
    );

    yield takeEvery(
        getType(FlowersFlowActions.orderSubmitted),
        handleOrderSubmitted,
    );

    yield takeEvery(
        getType(featuredFlowerBuyRequested),
        handleFeaturedFlowerBuyRequested,
    );
}
