import { createSelector } from 'reselect';

import { getLanguage } from '../../configuration/selectors/get-language.selector';

import { getEventsStore } from './get-events-store.selector';

export const getEvent = (eventId?: string) =>
    createSelector(getEventsStore, getLanguage, (store, lang) => {
        return store.events.find((item) => item.id === eventId);
    });
