import React, { ReactElement } from 'react';

const generateIndex = (i: number): string =>
    `${Date.now().toString()}.${i.toString()}`;

export const decodeText = (text: string): string => {
    const el = document.createElement('div');

    // eslint-disable-next-line
    return text.replace(/\&#?[0-9a-z]+;/gi, (enc) => {
        el.innerHTML = enc;
        return el.innerText;
    });
};

export const getDecodedText = (text?: string): ReactElement[] | null => {
    return text
        ? text
              .replace(/&lt;3/g, '\u2665')
              .replace(/\r/g, '')
              .split('\n')
              .map((part, i) => {
                  if (part && part.length > 0) {
                      return <p key={generateIndex(i)}>{decodeText(part)}</p>;
                  }
                  return <br key={generateIndex(i)} />;
              })
        : null;
};
