import ky from 'ky-universal';

import { ITokensResponseDto } from '../../DTOs/tokens-response.dto';

export const refreshTokens = (
    refreshToken: string,
): Promise<ITokensResponseDto> => {
    return ky
        .post(`${process.env.REACT_APP_API_HOST as string}/refresh_tokens`, {
            json: {
                auth: {
                    refreshToken,
                },
            },
        })
        .then((resp) => resp.json())
        .then((resp) => resp.data.attributes);
};
