import { getSlugFromLocation } from '../utils/parse-location';

const getStorageKey = () => btoa(`tk-${getSlugFromLocation(window.location)}`);

export const saveTokens = (accessToken: string, refreshToken: string) => {
    localStorage.setItem(
        getStorageKey(),
        JSON.stringify({
            accessToken,
            refreshToken,
        }),
    );
};

export const getTokens = (): {
    accessToken: string;
    refreshToken: string;
} | null => {
    try {
        const tokensString = localStorage.getItem(getStorageKey());

        if (tokensString) {
            return JSON.parse(tokensString);
        }

        return null;
    } catch (e) {
        return null;
    }
};

export const clearTokens = () => {
    localStorage.removeItem(getStorageKey());
};
