import React from 'react';
import { FormikProps, withFormik } from 'formik';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import { TextField } from '../../components/text-field/text-field';
import { TextArea } from '../../components/text-area/text-area';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { IGalleryPhotoMetadataFormValues } from '../gallery-photo-metadata-form-values';
import { ReactComponent as PhotoIcon } from '../../assets/icons/photo-icon.svg';

import styles from './gallery-photo-metadata-form.module.scss';

type OuterProps = {
    onSubmit(values: IGalleryPhotoMetadataFormValues): unknown;
    isPhotoUploaded?: boolean;
};

type Props = FormikProps<IGalleryPhotoMetadataFormValues> & OuterProps;

export const GalleryPhotoMetadataForm = ({
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isPhotoUploaded,
    isValid,
}: Props) => {
    const { t } = useTranslation();
    const canSave = isPhotoUploaded && isValid;

    return (
        <form className={styles.Form} onSubmit={handleSubmit}>
            <div className={styles.FieldsContainer}>
                <TextField
                    autoFocus
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('galleryPhotoUploadForm.titlePlaceholder')}
                    value={values.title}
                />
                <TextField
                    name="year"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('galleryPhotoUploadForm.yearPlaceholder')}
                    value={values.year}
                />
            </div>
            <TextArea
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('galleryPhotoUploadForm.descriptionPlaceholder')}
                rows={10}
                value={values.description}
            />
            <div className={styles.ButtonContainer}>
                <PrimaryButton
                    disabled={!canSave}
                    className={styles.Button}
                    icon={PhotoIcon}
                    type="submit"
                >
                    {t('galleryPhotoUploadForm.addPhotoButton')}
                </PrimaryButton>
            </div>
        </form>
    );
};

export const ConnectedGalleryPhotoMetadataForm = compose<Props, OuterProps>(
    withFormik<OuterProps, IGalleryPhotoMetadataFormValues>({
        handleSubmit(values, { props }) {
            props.onSubmit(values);
        },
        mapPropsToValues() {
            return {
                title: '',
                year: '',
                description: '',
            };
        },
    }),
)(GalleryPhotoMetadataForm);
