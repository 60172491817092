import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { SecondaryButton } from '../../components/secondary-button/secondary-button';
import { ButtonLink } from '../../components/button-link/button-link';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left-icon.svg';
import { RoutePaths } from '../../routing/route-paths';
import { useFlowersData } from '../../model/flowers/hooks/use-flowers-data';
import { FlowersFlowActions } from '../../model/flowers/flowers.actions';
import { useFlowerPhoto } from '../hooks/use-flower-photo';

import { FlowerType } from './flower-type/flower-type';
import styles from './flowers-type.module.scss';

export const FlowersType = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const {
        products,
        category: categoryData,
        onlyOneAvailableCategory,
        setSelectedProductsCategory,
    } = useFlowersData();
    const dispatch = useDispatch();

    const onProductVariantSelected = (productID: string, variantID: string) => {
        dispatch(
            FlowersFlowActions.productChosen({
                productID,
                variantID,
            }),
        );
    };

    useEffect(() => {
        const categoryID = new URLSearchParams(location.search).get('category');

        if (!categoryID) {
            history.push(RoutePaths.FLOWERS_CATEGORY);

            return;
        }

        setSelectedProductsCategory(categoryID);
    }, [setSelectedProductsCategory, history, location.search]);

    const { imageUrl } = useFlowerPhoto({ product: categoryData });

    return (
        <>
            {!onlyOneAvailableCategory && (
                <ButtonLink
                    className={styles.FlowersCategoryButtonLink}
                    onClick={() => {
                        // TODO: Dispatch action
                        return history.push(RoutePaths.FLOWERS_CATEGORY);
                    }}
                >
                    <ArrowLeftIcon
                        className={styles.FlowersCategoryButtonLinkIcon}
                    />
                    {t('flowers.type.changeCategoryLink')}
                </ButtonLink>
            )}
            {categoryData && (
                <div className={styles.FlowersCategoryBox}>
                    {!!imageUrl && (
                        <img
                            alt=""
                            className={styles.FlowersCategoryImage}
                            src={imageUrl}
                        />
                    )}
                    <span className={styles.FlowersCategoryName}>
                        {categoryData.name}
                    </span>
                </div>
            )}
            <div className={styles.FlowersTypeItems}>
                {products.map((item) => (
                    <FlowerType
                        key={item.id}
                        item={item}
                        onVariantChosen={
                            (variantId) =>
                            onProductVariantSelected(item.id, variantId)
                            // prettier-ignore
                        }
                    />
                ))}
            </div>
            {!onlyOneAvailableCategory && (
                <SecondaryButton
                    className={styles.BackButton}
                    icon={ArrowLeftIcon}
                    iconLeft
                    onClick={() => history.push(RoutePaths.FLOWERS_CATEGORY)}
                >
                    {t('flowers.type.backButton')}
                </SecondaryButton>
            )}
        </>
    );
};
