import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentTransitionList, Presets } from 'react-component-transition';

import { useCondolenceCommentsList } from './use-condolence-comments-list';
import { CondolenceCommentsItem } from './condolence-comments-item';
import { ICondolenceCommentsListProps } from './condolence-comments-list-types';
import styles from './condolence-comments-list.module.scss';

export const CondolenceCommentsList = (props: ICondolenceCommentsListProps) => {
    const {
        commentsList,
        isListExpanded,
        commentsLeftToExpand,
        onLikeCondolenceComment,
        onUnlikeCondolenceComment,
    } = useCondolenceCommentsList(props);
    const { t } = useTranslation();

    if (!commentsList.length) {
        return null;
    }

    return (
        <div className={styles.Container}>
            <div className={styles.InnerContainer}>
                <ComponentTransitionList>
                    {commentsList.map((comment) => (
                        <Presets.TransitionFade key={comment.createdAt}>
                            <CondolenceCommentsItem
                                comment={comment}
                                onLikeCondolenceComment={
                                    onLikeCondolenceComment
                                }
                                onUnlikeCondolenceComment={
                                    onUnlikeCondolenceComment
                                }
                            />
                        </Presets.TransitionFade>
                    ))}
                </ComponentTransitionList>
            </div>
            {commentsLeftToExpand > 0 && (
                <div
                    className={styles.ExpandBox}
                    onClick={isListExpanded.handleToggle}
                >
                    <span className={styles.ExpandBoxText}>
                        {isListExpanded.state
                            ? t('condolences.hideAllComments')
                            : `${t(
                                  'condolences.viewAllComments',
                              )} (${commentsLeftToExpand})`}
                    </span>
                </div>
            )}
        </div>
    );
};
