import { call, put } from 'redux-saga/effects';

import { invitationCheckApiActions } from '../invitation-check-api.actions';
import { getSessionFromInvitationToken } from '../http/get-session-from-invitation-token';
import { IInvitationCheckResponseDto } from '../../DTOs/invitation-check-response.dto';

export function* checkInvitationTokenSaga(
    action: ReturnType<typeof invitationCheckApiActions.request>,
) {
    try {
        const resp: IInvitationCheckResponseDto = yield call(
            getSessionFromInvitationToken,
            action.payload.token,
        );

        yield put(invitationCheckApiActions.success(resp));
    } catch (e) {
        yield put(
            invitationCheckApiActions.failure('Error checking token in API'),
        );
    }
}
