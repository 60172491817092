import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { Tabs } from '../../components/tabs/tabs';
import { SecondaryButton } from '../../components/secondary-button/secondary-button';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left-icon.svg';
import { RoutePaths } from '../../routing/route-paths';
import { ReactComponent as ArrowIcon } from '../../assets/icons/icon-arrow-right.svg';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import {
    IVariantPickerFlowerVariant,
    VariantPicker,
} from '../components/variant-picker/variant-picker';
import { FlowersFlowActions } from '../../model/flowers/flowers.actions';
import { FlowersSelectors } from '../../model/flowers/flowers.selector';
import { FlowerAddon } from '../../model/flowers/flowers-order.store';
import { roundPriceToString } from '../../utils/set-price';
import StringToHtmlParser from '../../components/string-to-html-parser/string-to-html-parser';
import { ImageMagnifier } from '../../components/image-magnifier/image-magnifier';
import { getFlowerShopBandExamplesSelector } from '../../model/flower-shop/selectors/get-flower-shop-band-examples.selector';
import { getFlowerShopProductSelector } from '../../model/flower-shop/selectors/get-flower-shop-product.selector';
import { useFlowerPhoto } from '../hooks/use-flower-photo';

import { IBandsFormValues } from './bands-form/bands-form-values';
import { BandsForm } from './bands-form/bands-form';
import { CardForm } from './card-form/card-form';
import { ICardFormValues } from './card-form/card-form-values';
import styles from './flower-details.module.scss';

const ProductType = {
    card: 'card',
    band: 'band',
};

export const FlowerDetails = () => {
    const imagePortalRef = useRef<HTMLDivElement>(null);
    const imagePortalId = 'imagePortalId';

    const [addonSelected, setAddonSelected] = useState<FlowerAddon>(
        FlowerAddon.BANDS_AND_CARD,
    );
    const [
        hoveredVariant,
        setHoveredVariant,
    ] = useState<IVariantPickerFlowerVariant | null>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const flowersBandExamples = useSelector(getFlowerShopBandExamplesSelector);
    const [bandsData, setBandsData] = useState<IBandsFormValues | null>(null);
    const [cardData, setCardData] = useState<ICardFormValues | null>(null);
    const processedProduct = useSelector(FlowersSelectors.getProcessedProduct);
    const relatedProductData = useSelector(
        getFlowerShopProductSelector(get(processedProduct, 'productID', null)),
    );

    const items = relatedProductData?.variants || [];

    const selectedVariant = items?.find(
        (item) => item.id === processedProduct!.variantID,
    );

    const { imageUrl, isPlaceholderPhoto } = useFlowerPhoto({
        product: relatedProductData,
        selectedVariant,
        hoveredVariant,
    });

    const getPriceString = (type: string) => {
        let stringPrice = '';
        let price = 0;
        if (type === ProductType.card) {
            price = relatedProductData?.grossCardPrice || 0;
        } else if (type === ProductType.band) {
            price = relatedProductData?.grossBandPrice || 0;
        }
        const currency = relatedProductData?.variants[0]?.currency || '';

        if (price > 0) {
            stringPrice = `${roundPriceToString(price)} ${currency}`;
        } else {
            stringPrice = t('flowers.details.priceIncluded');
        }

        return stringPrice;
    };

    useEffect(() => {
        dispatch(FlowersFlowActions.cartCalculationRequested());
    }, [dispatch]);

    useEffect(() => {
        if (!processedProduct.id) {
            history.push(RoutePaths.FLOWERS_CATEGORY);
        }
    }, [processedProduct.id, history]);

    useEffect(() => {
        if (processedProduct) {
            setAddonSelected(processedProduct.addon!);
        }
    }, [processedProduct]);

    const onTabChange = (index: number) => {
        setAddonSelected(
            index === 0 ? FlowerAddon.BANDS_AND_CARD : FlowerAddon.CARD,
        );
    };

    const onBandsFormChange = (values: IBandsFormValues) => {
        setBandsData(values);
    };

    const onCardFormChange = (values: ICardFormValues) => {
        setCardData(values);
    };

    const onStepSubmit = () => {
        if (addonSelected === FlowerAddon.BANDS_AND_CARD) {
            dispatch(
                FlowersFlowActions.detailsSet({
                    leftBand: bandsData!.leftBand,
                    rightBand: bandsData!.rightBand,
                    selectedAddon: addonSelected,
                }),
            );
        } else {
            dispatch(
                FlowersFlowActions.detailsSet({
                    card: cardData!.cardText,
                    selectedAddon: addonSelected,
                }),
            );
        }
    };

    const onVariantChanged = (variant: IVariantPickerFlowerVariant) => {
        dispatch(
            FlowersFlowActions.variantChanged({
                localID: processedProduct!.id!,
                newVariant: variant.id,
            }),
        );
    };

    if (!relatedProductData || !processedProduct) {
        return null;
    }

    return (
        <div className={styles.Container}>
            {/* first div fixes safari height issue */}
            <div>
                <div className={styles.Flower}>
                    <div
                        className={styles.ImagePortal}
                        id={imagePortalId}
                        ref={imagePortalRef}
                    />
                    {!isPlaceholderPhoto ? (
                        <ImageMagnifier
                            key={selectedVariant?.id || relatedProductData.id}
                            src={imageUrl}
                            imagePortalId={imagePortalId}
                            imagePortalRef={imagePortalRef}
                        />
                    ) : (
                        <img
                            alt={relatedProductData.name}
                            className={styles.PlaceholderPhoto}
                            src={imageUrl}
                        />
                    )}
                    <div className={styles.Info}>
                        <div className={styles.Name}>
                            {relatedProductData.name}
                        </div>
                        {relatedProductData.description && (
                            <div className={styles.Description}>
                                <StringToHtmlParser
                                    withHeadingsMargin
                                    stringToParse={
                                        relatedProductData.description
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <VariantPicker
                items={items}
                className={styles.Variant}
                selected={processedProduct!.variantID}
                onSelectItem={onVariantChanged}
                onHover={setHoveredVariant}
                oneColumnLayout={false}
            />
            <Tabs
                className={styles.Tabs}
                onChange={onTabChange}
                activeIndex={
                    addonSelected === FlowerAddon.BANDS_AND_CARD ? 0 : 1
                }
                tabs={[
                    t('flowers.details.bandsAndCardTabLabel', {
                        bandPrice: getPriceString(ProductType.band),
                    }),
                    t('flowers.details.cardTabLabel', {
                        cardPrice: getPriceString(ProductType.card),
                    }),
                ]}
            />
            <div>
                {addonSelected === FlowerAddon.BANDS_AND_CARD ? (
                    <BandsForm
                        prefilledValues={{
                            leftBand:
                                processedProduct.leftBandText || undefined,
                            rightBand:
                                processedProduct.rightBandText || undefined,
                        }}
                        onChange={onBandsFormChange}
                        bandExamples={flowersBandExamples}
                    />
                ) : (
                    <CardForm
                        prefilledValues={{
                            cardText: processedProduct.cardText || undefined,
                        }}
                        onChange={onCardFormChange}
                    />
                )}
            </div>
            <div className={styles.Buttons}>
                <SecondaryButton
                    icon={ArrowLeftIcon}
                    iconLeft
                    onClick={() => {
                        return history.push(
                            `${RoutePaths.FLOWERS_TYPE}?category=${relatedProductData.categoryID}`,
                        );
                    }}
                >
                    {t('flowers.details.backButton')}
                </SecondaryButton>
                <PrimaryButton
                    icon={ArrowIcon}
                    type="submit"
                    onClick={onStepSubmit}
                    data-testid="flowers-details-submit-button"
                >
                    {t('flowers.details.submitButton')}
                </PrimaryButton>
            </div>
        </div>
    );
};
