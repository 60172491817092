import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';

import { browserHistory } from './browser-history';

export const withRoutingSetup = (history: History = browserHistory) => <
    Props extends {}
>(
    Component: React.ComponentType<Props>,
) => (props: Props) => {
    return (
        <ConnectedRouter history={history}>
            <Component {...props} />
        </ConnectedRouter>
    );
};
