import { useDispatch } from 'react-redux';

import { facebookSharingRequested } from './model/sharing.actions';

export const useFacebookSharing = () => {
    const dispatch = useDispatch();

    return {
        shareOnFacebook() {
            dispatch(facebookSharingRequested());
        },
    };
};
