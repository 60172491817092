import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './status-message.module.scss';

type Props = React.HTMLAttributes<HTMLSpanElement> & {
    error?: boolean;
};

export const StatusMessage = ({
    children,
    className,
    error = false,
    ...props
}: Props) => {
    const classes = cx(
        styles.Message,
        { [styles.MessageError]: error },
        className,
    );

    return (
        <span className={classes} {...props}>
            {children}
        </span>
    );
};

export const ErrorMessage = ({
    error = true,
    children,
    omitTranslation = false,
    ...props
}: Props & { children: string; omitTranslation?: boolean }) => {
    const { t } = useTranslation();

    if (omitTranslation) {
        return <StatusMessage {...props}>{children}</StatusMessage>;
    }

    return <StatusMessage {...props}>{t(children)}</StatusMessage>;
};
