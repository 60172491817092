import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchPageActions } from '../page-api.actions';

import { fetchPageSaga } from './fetch-page.saga';

export function* pageSaga() {
    yield takeEvery(getType(fetchPageActions.request), fetchPageSaga);
}
