import { createAction } from 'typesafe-actions';

import { IVideoStreamRequestDto } from '../../api/DTOs/video-stream-request.dto';

import { VideoStreamError } from './video-stream.store';

export const videoStreamFormSubmitted = createAction(
    'model/video_stream/VIDEO_STREAM_FORM_SUBMITTED',
    (payload: IVideoStreamRequestDto) => payload,
)();

export const videoStreamPasswordSend = createAction(
    'model/video_stream/VIDEO_STREAM_PASSWORD_SEND',
    (payload: { videoStreamUrl: string }) => payload,
)();

export const setVideoStreamPasswordError = createAction(
    'model/violation_report/SET_VIDEO_STREAM_PASSWORD_ERROR',
    (payload: VideoStreamError | undefined) => payload,
)();
