import { put } from 'redux-saga/effects';

import { loginApiActions } from '../../../api/login/login-api.actions';
import { loginApiErrorReceived } from '../login-page.actions';

export function* handleLoginApiErrorSaga(
    action: ReturnType<typeof loginApiActions.failure>,
) {
    yield put(
        loginApiErrorReceived({
            error: action.payload,
        }),
    );
}
