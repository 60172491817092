import { takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { eventAttendanceFormSubmitted } from '../event-attendance.actions';
import { sendEventAttendanceApiActions } from '../../../api/event-attendance/event-attendance-api.actions';

import { eventAttendanceFormSubmittedSaga } from './event-attendance-form-submitted.saga';
import { handleEventAttendanceApiErrorSaga } from './handle-event-attendance-api-error.saga';

export function* eventAttendanceSaga() {
    yield takeEvery(
        getType(eventAttendanceFormSubmitted),
        eventAttendanceFormSubmittedSaga,
    );
    yield takeEvery(
        getType(sendEventAttendanceApiActions.failure),
        handleEventAttendanceApiErrorSaga,
    );
}
