import { createAction } from 'typesafe-actions';

import { IDeceasedPersonData } from '../../interfaces/deceased-person-data';

export const deceasedDataReceived = createAction(
    'model/deceased/DATA_RECEIVED',
    (payload: {
        data: IDeceasedPersonData;
        photoThumb: string;
        backgroundSrc: string;
        tributeText: string | null;
        obituaryText: string | null;
    }) => payload,
)();
