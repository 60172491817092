import { ICreateContactSuggestionDto } from '../../../api/DTOs/create-contact-suggestion.dto';
import { IContactSuggestionFormValues } from '../../contact-suggestion-form-values';

export const mapContactSuggestionFormToDto = (
    values: IContactSuggestionFormValues,
): ICreateContactSuggestionDto => {
    const dto: ICreateContactSuggestionDto = {
        displayName: values.name,
    };

    if (values.email) {
        dto.email = values.email;
    }

    if (values.phone) {
        dto.phone = values.phone;
    }

    return dto;
};
