import { IMemorialEvent } from '../../app/interfaces/memorial-event';
import { EventType } from '../../app/interfaces/event-type';

export const getClosestUpcomingEvent = (events: IMemorialEvent[]) => {
    const ceremonyEvent = events.find(
        (event) => event.type === EventType.CEREMONY,
    );

    const closestEvent = events.reduce(
        (acc, item) => {
            if (item.date) {
                const timeDifference =
                    new Date(item.date).valueOf() - Date.now();

                if (
                    timeDifference >= 0 &&
                    timeDifference < acc.timeDifference
                ) {
                    return { item, timeDifference };
                }
            }

            return acc;
        },
        {
            item: ceremonyEvent || events[0],
            timeDifference: Number.POSITIVE_INFINITY,
        },
    );

    return closestEvent.item;
};
