import { useCallback, useRef } from 'react';

export const useDisableScrolling = () => {
    const scrollPosition = useRef<number>(0);

    const disableScrolling = useCallback(() => {
        scrollPosition.current = window.scrollY;
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollPosition.current}px`;
        document.body.style.width = '100%';
    }, []);

    const enableScrolling = useCallback(() => {
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('top');
        document.body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition.current);
    }, []);

    return {
        enableScrolling,
        disableScrolling,
    };
};
