import { call, put } from 'redux-saga/effects';

import { loginApiActions } from '../login-api.actions';
import { loginUser } from '../http/login-user';
import { ITokensResponseDto } from '../../DTOs/tokens-response.dto';
import { saveTokens } from '../../auth-token-storage';
import { userLoggedStatusReceived } from '../../../model/user/user.actions';

export function* loginUserToApiSaga(
    action: ReturnType<typeof loginApiActions.request>,
) {
    try {
        const resp: ITokensResponseDto = yield call(loginUser, {
            email: action.payload.email,
            password: action.payload.password,
        });

        /**
         * TODO: Should it be here or maybe another saga responsible on storing keys?
         */
        yield saveTokens(resp.accessToken, resp.refreshToken);

        yield put(loginApiActions.success(resp));
        yield put(userLoggedStatusReceived({ logged: true }));
    } catch (e) {
        yield put(loginApiActions.failure(e));
    }
}
