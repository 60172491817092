import React from 'react';
import cx from 'classnames';
import ReplayIcon from '@mui/icons-material/Replay';

import styles from '../video-player-mask.module.scss';

export const VideoPlayerReplayMask = () => {
    return (
        <div className={cx(styles.MaskContainer, styles.ReplayMaskContainer)}>
            <div className={styles.MaskInnerContainer}>
                <div className={styles.MaskIcon}>
                    <ReplayIcon />
                </div>
            </div>
        </div>
    );
};
