import { IGalleryPhotoDto } from '../../../api/DTOs/gallery-photo.dto';
import { IGalleryPhoto } from '../../../interfaces/gallery-photo';

export const mapGalleryPhotoDtoToGalleryPhoto = (
    dto: IGalleryPhotoDto,
): IGalleryPhoto => ({
    id: dto.id,
    originalUrl: dto.attributes.originalUrl,
    title: dto.attributes.title,
    description: dto.attributes.description,
    year: dto.attributes.year,
});
