import { spawn } from 'redux-saga/effects';

import { pageSaga } from './page/saga/page.saga';
import { loginApiSaga } from './login/saga/login-api.saga';
import { refreshSessionSaga } from './refresh-session/saga/refresh-session.saga';
import { createUserApiSaga } from './create-user/saga/create-user-api.saga';
import { condolencesApiSaga } from './condolences/saga/condolences-api.saga';
import { forgottenPasswordApiSaga } from './forgotten-password/saga/forgotten-password-api.saga';
import { contactSuggestionApiSaga } from './contact-suggestion/saga/contact-suggestion-api.saga';
import { galleryApiSaga } from './gallery/saga/gallery-api.saga';
import { accessRequestApiSaga } from './access-request/saga/access-request-api.saga';
import { eventsApiSaga } from './events/saga/events-api.saga';
import { tasksApiSaga } from './tasks/saga/tasks-api.saga';
import { violationReportApiSaga } from './violation-report/saga/violation-reports-api.saga';
import { eventAttendanceApiSaga } from './event-attendance/saga/event-attendance-api.saga';
import { videoStreamApiSaga } from './video-stream/saga/video-stream-api.saga';
import { flowersApiSaga } from './flowers/flowers.saga';
import { donationApiSaga } from './donation/saga/donation-api.saga';

export function* apiSaga() {
    yield spawn(pageSaga);
    yield spawn(loginApiSaga);
    yield spawn(refreshSessionSaga);
    yield spawn(createUserApiSaga);
    yield spawn(condolencesApiSaga);
    yield spawn(forgottenPasswordApiSaga);
    yield spawn(contactSuggestionApiSaga);
    yield spawn(galleryApiSaga);
    yield spawn(accessRequestApiSaga);
    yield spawn(flowersApiSaga);
    yield spawn(donationApiSaga);
    yield spawn(eventsApiSaga);
    yield spawn(tasksApiSaga);
    yield spawn(violationReportApiSaga);
    yield spawn(eventAttendanceApiSaga);
    yield spawn(videoStreamApiSaga);
}
