import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import {
    condolenceFormSubmitted,
    condolenceDeletionRequested,
    condolenceUpdateRequested,
    condolenceCommentRequested,
    likeCondolenceRequested,
    unlikeCondolenceRequested,
    likeCondolenceCommentRequested,
    unlikeCondolenceCommentRequested,
} from '../condolences.actions';

import { handleCondolenceFormSubmittedSaga } from './handle-condolence-form-submitted.saga';
import { handleCondolenceUpdateSaga } from './handle-condolence-update.saga';
import { handleCondolenceDeletionSaga } from './handle-condolence-deletion.saga';
import { handleAddCommentToCondolenceSaga } from './handle-add-comment-to-condolence.saga';
import { handleLikeCondolenceSaga } from './handle-like-condolence.saga';
import { handleUnlikeCondolenceSaga } from './handle-unlike-condolence.saga';
import { handleUnlikeCondolenceCommentSaga } from './handle-unlike-condolence-comment.saga';
import { handleLikeCondolenceCommentSaga } from './handle-like-condolence-comment.saga';

export function* condolencesSaga() {
    yield takeEvery(
        getType(condolenceFormSubmitted),
        handleCondolenceFormSubmittedSaga,
    );
    yield takeEvery(
        getType(condolenceUpdateRequested),
        handleCondolenceUpdateSaga,
    );
    yield takeEvery(
        getType(condolenceDeletionRequested),
        handleCondolenceDeletionSaga,
    );
    yield takeEvery(
        getType(condolenceCommentRequested),
        handleAddCommentToCondolenceSaga,
    );
    yield takeEvery(getType(likeCondolenceRequested), handleLikeCondolenceSaga);
    yield takeEvery(
        getType(unlikeCondolenceRequested),
        handleUnlikeCondolenceSaga,
    );
    yield takeEvery(
        getType(likeCondolenceCommentRequested),
        handleLikeCondolenceCommentSaga,
    );
    yield takeEvery(
        getType(unlikeCondolenceCommentRequested),
        handleUnlikeCondolenceCommentSaga,
    );
}
