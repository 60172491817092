import { call } from '@redux-saga/core/effects';

import { IImageUploadSessionResponseDto } from '../../DTOs/image-upload-session-response.dto';
import { getImageUploadSession } from '../http/get-image-upload-session';
import { uploadFileToS3 } from '../http/upload-file-to-s3';

export function* imageUploadToS3Saga(file: File) {
    try {
        const sessionData: IImageUploadSessionResponseDto = yield call(
            getImageUploadSession,
            { file },
        );

        const s3Response: string = yield call(uploadFileToS3, {
            sessionData,
            file,
        });

        return JSON.stringify(s3Response);
    } catch (err) {
        return null;
    }
}
