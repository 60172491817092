import { createSelector } from 'reselect';

import { getCondolencesImagesEnabledSelector } from '../../configuration/selectors/get-condolences-images-enabled.selector';

import { getCondolences } from './get-condolences.selector';

export const getCondolencesMediaFiles = createSelector(
    getCondolencesImagesEnabledSelector,
    getCondolences,
    (condolencesImagesEnabled, condolences) =>
        condolencesImagesEnabled
            ? condolences.reduce((acc, item) => {
                  return [...acc, ...(item.mediaFiles ? item.mediaFiles : [])];
              }, [])
            : [],
);
