import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

import { fetchPageActions } from '../../../api/page/page-api.actions';
import { volunteerTaskRequested } from '../tasks.actions';

import { getTasksDataFromMemorialPageFetchSaga } from './get-tasks-data-from-memorial-page-fetch.saga';
import { volunteerTaskSaga } from './volunteer-task.saga';

export function* tasksSaga() {
    yield takeEvery(
        getType(fetchPageActions.success),
        getTasksDataFromMemorialPageFetchSaga,
    );
    yield takeEvery(getType(volunteerTaskRequested), volunteerTaskSaga);
}
