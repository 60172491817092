import { useSelector } from 'react-redux';
import { useState } from 'react';

import { getFlowerShopCategoriesSelector } from '../../flower-shop/selectors/get-flower-shop-categories.selector';
import { getFlowerShopCategoryProductsSelector } from '../../flower-shop/selectors/get-flower-shop-category-products.selector';
import { getFlowerShopCategorySelector } from '../../flower-shop/selectors/get-flower-shop-category.selector';

export function useFlowersData() {
    const [selectedProductsCategory, setSelectedProductsCategory] = useState<
        string | null
    >(null);

    const categories = useSelector(getFlowerShopCategoriesSelector);
    const category = useSelector(
        getFlowerShopCategorySelector(selectedProductsCategory),
    );
    const products = useSelector(
        getFlowerShopCategoryProductsSelector(selectedProductsCategory),
    );

    const noAvailableCategories = !categories?.length;
    const onlyOneAvailableCategory = categories?.length === 1;

    return {
        categories,
        category,
        products,
        selectedProductsCategory,
        setSelectedProductsCategory,
        noAvailableCategories,
        onlyOneAvailableCategory,
    };
}
