import { apiService } from '../../api.service';
import { IMemorialPageDataPublicResponseDto } from '../../DTOs/memorial-page-data-public-response.dto';
import { MemorialPageFetchErrorsDto } from '../../DTOs/memorial-page-fetch-errors.dto';

export const fetchMemorialPagePublicData = (
    slug: string,
): Promise<IMemorialPageDataPublicResponseDto> => {
    const url = `/public/memorial_pages/${slug}`;

    return apiService
        .get(url, {
            throwHttpErrors: false,
        })
        .then(async (resp) => {
            if (resp.status === 200) {
                return resp.json();
            }

            if (resp.status === 401) {
                const body = await resp.json();

                const error = body.errors[0].code;

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(error as MemorialPageFetchErrorsDto);
            }

            return Promise.reject(resp.statusText);
        });
};
