import { call, select, put } from '@redux-saga/core/effects';
import { find } from 'lodash';

import { createCondolenceApiActions } from '../condolences-api.actions';
import { getPageSlug } from '../../../model/configuration/selectors/get-page-slug.selector';
import { createCondolence } from '../http/create-condolence';
import { mapCondolenceDtoToCondolence } from '../mappers/map-condolence-dto-to-condolence';
import { CondolenceStatus } from '../../../model/condolences/condolences.store';
import { fetchPageActions } from '../../page/page-api.actions';

export function* createCondolenceApiSaga(
    action: ReturnType<typeof createCondolenceApiActions.request>,
) {
    const slug = yield select(getPageSlug);

    const response = yield call(createCondolence, {
        slug,
        dto: action.payload,
    });

    if (!response.errors) {
        const createdCondolence = mapCondolenceDtoToCondolence(response.data);

        yield put(createCondolenceApiActions.success(createdCondolence));

        yield put(fetchPageActions.request());
    } else if (find(response.errors, { code: 'not_decent' })) {
        yield put(
            createCondolenceApiActions.failure({
                status: CondolenceStatus.INAPPROPRIATE_WORDS,
            }),
        );
    } else if (find(response.errors, { code: 'is_blank' })) {
        yield put(
            createCondolenceApiActions.failure({
                status: CondolenceStatus.MEDIA_FILE_UPLOAD_ERROR,
            }),
        );
    } else {
        yield put(
            createCondolenceApiActions.failure({
                status: response.errors[0].title,
            }),
        );
    }
}
