import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import cx from 'classnames';

import { IImageUploadWithModalItem } from '../inputs/image-upload-with-modal/image-upload-with-modal-types';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';
import { IIconType } from '../icon/icon-types';

import styles from './upload-input-preview.module.scss';
import { IUploadInputPreviewProps } from './upload-input-preview.types';

export const UploadInputPreview = ({
    files,
    onItemRemove,
}: IUploadInputPreviewProps) => {
    const { t } = useTranslation();

    const [hoveredItem, setHoveredItem] = useState<string>('');
    const handleClearHoveredItem = () => {
        setHoveredItem('');
    };

    const handleItemRemoval = (item: IImageUploadWithModalItem) => {
        onItemRemove(item);

        handleClearHoveredItem();
    };

    const filesToDisplay = files.filter((file) => {
        return !file.isDeleted;
    });

    return (
        <div className={styles.Container}>
            <div className={styles.PreviewList}>
                {filesToDisplay.map((item) => {
                    const isHovered = hoveredItem === item.id;
                    const showImagePreview =
                        !!item.previewUrl ||
                        (item.file && item.file.type.includes('image'));
                    const showVideoPreview =
                        !item.previewUrl &&
                        item.file &&
                        item.file.type.includes('video');

                    const handleSetHoveredItem = () => {
                        setHoveredItem(item.id);
                    };

                    const handleRemoveImage = () => {
                        handleItemRemoval(item);
                    };

                    return (
                        <div
                            key={`preview-item-${item.id}`}
                            className={cx(
                                styles.PreviewListItem,
                                isHovered && styles.PreviewListItemHovered,
                            )}
                            onFocus={handleSetHoveredItem}
                            onMouseEnter={handleSetHoveredItem}
                            onMouseLeave={handleClearHoveredItem}
                            onMouseOver={handleSetHoveredItem}
                        >
                            {showImagePreview && (
                                <img
                                    alt="upload input preview"
                                    className={cx(
                                        styles.PreviewListItemImage,
                                        isHovered && styles.Hovered,
                                    )}
                                    src={item.previewUrl}
                                />
                            )}
                            {showVideoPreview && (
                                <div
                                    className={cx(
                                        styles.Placeholder,
                                        isHovered && styles.Hovered,
                                    )}
                                >
                                    <SmartDisplayIcon
                                        className={styles.VideoPreviewIcon}
                                    />
                                </div>
                            )}
                            {isHovered && (
                                <div className={styles.IconContainer}>
                                    <div className={styles.Icon}>
                                        <IconButtonWithTooltip
                                            iconName={IIconType.Trash}
                                            onClick={handleRemoveImage}
                                            tooltipText={t('labels.remove')}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
