import { apiService } from '../../api.service';
import { ISendAccessRequestDto } from '../../DTOs/send-access-request.dto';

export const submitAccessRequest = ({
    email,
    phone,
    name,
    slug,
}: ISendAccessRequestDto & { slug: string }) => {
    return apiService
        .post(`/public/memorial_pages/${slug}/request_for_access`, {
            json: {
                suggestable: {
                    displayName: name,
                    email,
                    phone,
                },
            },
            throwHttpErrors: false,
        })
        .then((r) => r.json());
};
