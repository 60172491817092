import { createAsyncAction } from 'typesafe-actions';

import { IVideoStreamRequestDto } from '../DTOs/video-stream-request.dto';
import { IVideoStreamResponseDto } from '../DTOs/video-stream-response.dto';

export const sendVideoStreamApiActions = createAsyncAction(
    'api/video-stream/REQUESTED',
    'api/video-stream/SUCCEEDED',
    'api/video-stream/FAILED',
)<IVideoStreamRequestDto, IVideoStreamResponseDto, string>();
