import React from 'react';

import { FlowersSummaryItemMobile } from '../flowers-summary-item-mobile/flowers-summary-item-mobile';
import { IFlowerSummaryEntry } from '../../../interfaces/flower-summary-entry';

import styles from './flowers-summary-listing-mobile.module.scss';

export const FlowersSummaryListingMobile = ({
    items,
}: {
    items: IFlowerSummaryEntry[];
}) => {
    return (
        <div className={styles.FlowersSummaryListingMobile}>
            {items.map((item) => (
                <FlowersSummaryItemMobile
                    key={item.id}
                    item={item}
                    data-testid={`flower-summary-item-id-${item.id}`}
                />
            ))}
        </div>
    );
};
